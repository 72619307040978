import React from 'react';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { ReactComponent as RocketIcon } from '../assets/Rocket.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';
import { Pricing } from '@utils/Data/Pricing';
import { Period, TierType } from '@globals';
import { getMonthsCount } from '@components/DialogsPricing/utils/getMonthsCount';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat } from '@utils/DateTime';

interface UpgradedPricingDialogProps {
  close(): void;
  newNextTierType: TierType;
  prevTierType: TierType;
  newPricing: Pricing;
  newPeriod: Period;
  prevPeriod: Period;
}

export const UpgradedPricingDialog: React.FC<UpgradedPricingDialogProps> = ({
  newNextTierType,
  prevTierType,
  close,
  newPricing,
  newPeriod,
  prevPeriod,
}) => {
  const { t } = useSafeTranslation();
  const nextPrepaymentDate = newPricing.next_prepayment_date
    ? DateFormat.ll(newPricing.next_prepayment_date)
    : '';
  const billingPeriod = t(
    'pages.Pricing.dialogs.UpgradedPricingDialog.everyMonth',
    {
      count: getMonthsCount(
        newPricing.next_subscription_period ||
          newPricing.current_subscription_period,
      ),
    },
  );

  const getDescription = () => {
    if (prevTierType === TierType.trial)
      return t(
        'pages.Pricing.dialogs.UpgradedPricingDialog.newPlanFromTrialDescription',
        {
          planName: getTierNameByType(newNextTierType),
          billingPeriod,
          nextPaymentDate: nextPrepaymentDate,
        },
      );

    if (prevTierType !== newNextTierType)
      return t(
        'pages.Pricing.dialogs.UpgradedPricingDialog.newPeriodDescription',
        {
          startingDate: nextPrepaymentDate,
          billingPeriod,
        },
      );

    if (newPeriod === prevPeriod)
      return t(
        'pages.Pricing.dialogs.UpgradedPricingDialog.newPlanSamePeriodDescription',
        {
          nextPayment: nextPrepaymentDate,
        },
      );

    return t(
      'pages.Pricing.dialogs.UpgradedPricingDialog.newPlanDifferentPeriodDescription',
      {
        billingPeriod,
        startingDate: nextPrepaymentDate,
      },
    );
  };

  return (
    <DefaultPricingDialog
      title={t('pages.Pricing.dialogs.UpgradedPricingDialog.title')}
      icon={<RocketIcon />}
      description={getDescription()}
      button={
        <DefaultPricingBtn
          data-testid="upgraded-pricing-dialog__close"
          intent="primary"
          onClick={close}
        >
          {t('pages.Pricing.dialogs.UpgradedPricingDialog.button')}
        </DefaultPricingBtn>
      }
      onClose={close}
    />
  );
};
