import { useState, useCallback, useLayoutEffect } from 'react';
import { complement, isNil } from 'ramda';

const isNotNil = complement(isNil);

export function useStateWhenDefined<T>(
  defaultValue: T,
): [T, (value: T) => void] {
  const [value, setValue] = useState(defaultValue);
  const [isChanged, setIsChanged] = useState(false);
  const handleChange = useCallback(
    (newValue: T) => {
      setValue(newValue);
      if (!isChanged) setIsChanged(true);
    },
    [isChanged], // eslint-disable-line react-hooks/exhaustive-deps
  );

  useLayoutEffect(() => {
    // use useLayoutEffect for correct apply Effect after get data from angular (react2angular) ((
    if (isNotNil(defaultValue) && isNil(value) && !isChanged) {
      setValue(defaultValue);
      setIsChanged(true);
    }
  }, [defaultValue, value, isChanged]);

  return [value, handleChange];
}
