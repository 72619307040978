import React, { useEffect, useState } from 'react';
import noop from 'lodash-es/noop';
import { useFbPermissions } from '../../utils/FacebookPages/useFbPermissions';
import { useConnectedFacebookAdAccountWithToS } from '../../utils/MarketingApi';
import { ConnectedFacebookAdAccountWithToSQuery_bot_connectedFacebookAdAccount as Account } from '../../utils/MarketingApi/@types/ConnectedFacebookAdAccountWithToSQuery';
import { isBotProOrTrial } from '../../utils/Pro';

export enum AudienceSyncState {
  needPro = 'needPro',
  needPermissions = 'needPermissions',
  needAdAccount = 'needAdAccount',
  needTerms = 'needTerms',
  allowed = 'allowed',
}

export interface AudienceSyncContextType {
  state: AudienceSyncState;
  setState(state: AudienceSyncState): void;
  loading: boolean;
  connectedFacebookAdAccount?: Account | null;
}

const INITIAL_STATE = AudienceSyncState.needPro;
const INITIAL_LOADING = true;

export const AudienceSyncContext = React.createContext<AudienceSyncContextType>(
  {
    state: INITIAL_STATE,
    setState: noop,
    loading: INITIAL_LOADING,
    connectedFacebookAdAccount: null,
  },
);

export interface AudienceSyncProps {
  bot?: any;
}

export const AudienceSyncStateProvider: React.FC<AudienceSyncProps> = ({
  bot,
  children,
}) => {
  const [state, setState] = useState<AudienceSyncState>(INITIAL_STATE);
  const [loading, setLoading] = useState(INITIAL_LOADING);

  const {
    permissions: { hasMarketingPermissions },
    loading: permissionsLoading,
  } = useFbPermissions();

  const { connectedFacebookAdAccount, loading: connectedAdAccountLoading } =
    useConnectedFacebookAdAccountWithToS(bot.id);

  const isProOrTrialBot = isBotProOrTrial(bot) || bot?.workspace_id;

  useEffect(() => {
    if (!permissionsLoading && !connectedAdAccountLoading) {
      setLoading(false);
    }
  }, [permissionsLoading, connectedAdAccountLoading]);

  useEffect(() => {
    if (!isProOrTrialBot) {
      setState(AudienceSyncState.needPro);
      return;
    }

    if (!hasMarketingPermissions) {
      setState(AudienceSyncState.needPermissions);
      return;
    }

    if (!connectedFacebookAdAccount) {
      setState(AudienceSyncState.needAdAccount);
      return;
    }

    const termsAccepted =
      connectedFacebookAdAccount.tos_accepted?.custom_audience_tos === 1;
    if (termsAccepted) {
      setState(AudienceSyncState.allowed);
    } else {
      setState(AudienceSyncState.needTerms);
    }
  }, [
    bot.id,
    loading,
    isProOrTrialBot,
    hasMarketingPermissions,
    connectedFacebookAdAccount,
  ]);

  return (
    <AudienceSyncContext.Provider
      value={{ state, setState, loading, connectedFacebookAdAccount }}
    >
      {children}
    </AudienceSyncContext.Provider>
  );
};
