export { connectPage } from './connectPage';
export {
  useConnectPage,
  useShowConnectPageForTestThisModalIfNeed,
} from './hooks';
export { ConnectPageField, ConnectPageOrigin } from './types';
export {
  getConnectPageEventPostfix,
  getConnectPageUrlParams,
  isConnectPageOrigin,
  runIfConnectPageUrlParamsSubmatches,
} from './helpers';
