import { GroupingCombobox_Group } from '@components/GroupingCombobox/GroupingCombobox';
import {
  BLOCK_SUBTYPES,
  isRootBlock,
  makeSubtypeToBlockTitle,
} from '@components/FlowBuilder/consts';
import { BlockContextType } from '@globals';
import { ChooseFlowBlockQuery_bot_flowBlocks as FlowBlock } from './@types/ChooseFlowBlockQuery';

type GroupsMap = Record<string, FlowBlock[]>;

const BLOCKS_WHICH_CANNOT_HAVE_IN_LINKS = [
  BLOCK_SUBTYPES.comments_autoreply_content,
  BLOCK_SUBTYPES.instagram_comments_autoreply_content,
  BLOCK_SUBTYPES.entrypoint,
  BLOCK_SUBTYPES.reminder,
  BLOCK_SUBTYPES.visitedProductsReminder,
  BLOCK_SUBTYPES.receipt,
  BLOCK_SUBTYPES.calendly,
  BLOCK_SUBTYPES.back_in_stock_otn,
];

export const mapSubTypeAlias = (subtype: string | null) => {
  if (
    [
      BLOCK_SUBTYPES.calendly_reminder,
      BLOCK_SUBTYPES.calendly_confirmation,
    ].includes(subtype as string)
  ) {
    return BLOCK_SUBTYPES.send_message;
  }

  return subtype;
};

export const mapBlocksToGroupingComboboxGroups = (
  blocks: FlowBlock[],
  currentBlockId?: string,
): GroupingCombobox_Group[] => {
  const BLOCK_TITLES_BY_SUBTYPE = makeSubtypeToBlockTitle();

  const groupsMap = blocks.reduce((groupsMap, block) => {
    const { id, subtype: blockSubtype } = block;
    const subtype = mapSubTypeAlias(blockSubtype);
    const hasCommentsContextType =
      block.context_type === BlockContextType.comments;
    const isSameBlock = currentBlockId === id;
    if (
      !subtype ||
      isSameBlock ||
      BLOCKS_WHICH_CANNOT_HAVE_IN_LINKS.includes(subtype) ||
      isRootBlock(subtype) ||
      hasCommentsContextType
    ) {
      return groupsMap;
    }
    if (!groupsMap[subtype]) {
      // eslint-disable-next-line no-param-reassign
      groupsMap[subtype] = [];
    }

    groupsMap[subtype].push(block);

    return groupsMap;
  }, {} as GroupsMap);

  return Object.keys(groupsMap).map((v) => ({
    title: BLOCK_TITLES_BY_SUBTYPE[v],
    items: groupsMap[v],
  }));
};
