import React from 'react';
import { Platform } from '@globals';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { IconNames } from '@ui/Icon/icons';
import { Type } from '@ui/Type';
import css from './CreateBlankBotButton.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Flex } from '@ui/Flex';

export interface BotPlatformSelectorItems {
  id: string;
  title: string;
  iconName: IconNames;
  platforms: Platform[];
}

export const WEBSITE = 'WEBSITE';

const WebsiteTitle = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex gapFactor={1.5} alignItems="center">
      {t('common.BotHeader.website')}
      <Type
        size="9px"
        color="white"
        weight="bold"
        as="div"
        className={css.beta}
      >
        {t('common.BotHeader.beta')}
      </Type>
    </Flex>
  );
};

export const getBotPlatformSelectorItems = memoize(
  () =>
    [
      {
        id: 'fbIgBot',
        title: i18next.t('common.BotHeader.fbIgBot'),
        iconName: 'fbIgBot',
        platforms: [Platform.facebook, Platform.instagram],
      },
      {
        id: 'whatsappBot',
        title: i18next.t('common.BotHeader.whatsappBot'),
        iconName: 'whatsappBot',
        platforms: [Platform.whatsapp],
      },
      {
        id: WEBSITE,
        title: (<WebsiteTitle />) as unknown as string,
        iconName: 'newWebsite',
        platforms: [Platform.whatsapp],
      },
    ] as BotPlatformSelectorItems[],
);
