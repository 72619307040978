import { prop, propEq } from 'ramda';
import { ApolloQueryResult } from 'apollo-client';
import nanoid from 'nanoid';
import client from '../../../common/services/ApolloService';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { getIncrementName } from '../../../common/services/NamingService';
import { OMNIBOX_SEQUENCES_TITLES_MAP_QUERY } from '../../../components/user-filter-component';
import { UpdateBlocksGroup } from './UpdateTypes';
import { GroupsTitlesQuery } from './@types/GroupsTitlesQuery';
import {
  CreateNewGroupMutation,
  CreateNewGroupMutationVariables,
} from './@types/CreateNewGroupMutation';
import {
  BLOCKS_TITLES_QUERY,
  BOT_BLOCKS_GROUPS_FRAGMENT,
  CREATE_NEW_GROUP_MUTATION,
  GROUPS_TITLES_QUERY,
  UPDATE_GROUP_INDEX_MUTATION,
  UPDATE_GROUP_MUTATION,
  UPDATE_STATISTIC_MUTATION,
  DOWNLOAD_STATISTIC_MUTATION,
} from './GQL';
import {
  UpdateGroupIndexMutation,
  UpdateGroupIndexMutation_updateBlocksGroup,
  UpdateGroupIndexMutationVariables,
} from './@types/UpdateGroupIndexMutation';
import { botBlocksGroupsFragment as BotBlocksGroupsFragment } from './@types/botBlocksGroupsFragment';
import { sendEvent } from '../../../utils/Analytics';

export enum GroupType {
  group = 'Group',
  sequence = 'Sequence',
}

export const OPTIMISTIC_GROUP_ID_PREFIX = 'optimistic_';

const showErrorTosterFactory = (message: string) => () =>
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message,
    },
  });

const DEFAULT_GROUP_NAME = 'NEW GROUP';
const DEFAULT_SEQUENCE_NAME = 'NEW SEQUENCE';

export const updateGroup = (params: {
  botId: string;
  groupId: string;
  groupData: UpdateBlocksGroup;
}) => {
  const {
    groupId,
    botId,
    groupData: { sequence, ...group },
  } = params;

  sendEvent({
    category: 'sequence or group',
    action: 'update',
    label: sequence ? 'sequence' : 'group',
  });

  client
    .mutate({
      mutation: UPDATE_GROUP_MUTATION,
      variables: {
        botId,
        group: {
          ...group,
          id: groupId,
        },
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'GroupMutations-string--197-couldnt-update-group-please-try-again-later',
        ),
      ),
    );
};

export const updateStats = (params: {
  botId: string;
  groupId: string;
  groupData: UpdateBlocksGroup;
}) => {
  const { groupId, botId, groupData } = params;

  client
    .mutate({
      mutation: UPDATE_STATISTIC_MUTATION,
      variables: {
        botId,
        group: {
          ...groupData,
          id: groupId,
        },
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'GroupMutations-string--952-couldnt-retrieve-statistics-please-try-again-later',
        ),
      ),
    );
};

export const downloadStats = (params: { botId: string; groupId: string }) => {
  const { groupId, botId } = params;
  client
    .mutate({
      mutation: DOWNLOAD_STATISTIC_MUTATION,
      variables: {
        botId,
        groupId,
      },
    })
    .then(
      ({
        data: {
          generateGroupStatisticDownloadUrl: { downloadUrl },
        },
      }) => window.open(downloadUrl, '_blank'),
    );
};

export const createGroup = async (params: {
  botId: string;
  asSequence: boolean;
  index?: number;
  // eslint-disable-next-line consistent-return
}) => {
  const { botId, asSequence, index } = params;
  let groupsTitlesQueryResult: ApolloQueryResult<GroupsTitlesQuery> | null =
    null;

  sendEvent({
    category: 'sequence or group',
    action: 'add',
    label: asSequence ? 'sequence' : 'group',
  });

  try {
    groupsTitlesQueryResult = (await client.query({
      query: GROUPS_TITLES_QUERY,
      variables: { botId },
    })) as ApolloQueryResult<GroupsTitlesQuery>;
  } catch (e) {
    groupsTitlesQueryResult = null;
  }

  if (groupsTitlesQueryResult) {
    const gropsTitles = groupsTitlesQueryResult.data.bot.blocksGroups.map(
      prop('title'),
    );
    const title = getIncrementName(
      asSequence ? DEFAULT_SEQUENCE_NAME : DEFAULT_GROUP_NAME,
      gropsTitles,
    );

    const optimisticResponse: CreateNewGroupMutation = {
      createBlocksGroup: {
        title,
        __typename: 'BlocksGroup',
        id: `${OPTIMISTIC_GROUP_ID_PREFIX}${nanoid()}`,
        sequence: asSequence,
        builtin: false,
        collapsed: false,
        total_clicks: 0,
        total_sent: 0,
        total_users: 0,
        total_views: 0,
        synced_from: null,
        with_stats: false,
        blocks: [],
      },
    };

    return client
      .mutate<CreateNewGroupMutation, CreateNewGroupMutationVariables>({
        optimisticResponse,
        mutation: CREATE_NEW_GROUP_MUTATION,
        variables: {
          botId,
          title,
          asSequence,
          index,
        },
        refetchQueries: asSequence
          ? [
              {
                query: BLOCKS_TITLES_QUERY,
                variables: {
                  botId,
                },
              },
              {
                query: OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
                variables: {
                  botId,
                },
              },
            ]
          : undefined,
        update: (cache, { data }) => {
          let groupsTitlesQuery: GroupsTitlesQuery | null = null;
          const newGroup = data!.createBlocksGroup;

          try {
            groupsTitlesQuery = cache.readQuery({
              query: GROUPS_TITLES_QUERY,
              variables: { botId },
            });
          } catch (e) {
            groupsTitlesQuery = null;
          }
          if (groupsTitlesQuery && newGroup) {
            const {
              blocksGroups: [...blocksGroups],
            } = groupsTitlesQuery.bot;
            if (index !== undefined) {
              blocksGroups.splice(index, 0, newGroup);
            } else {
              blocksGroups.push(newGroup);
            }

            cache.writeQuery({
              query: GROUPS_TITLES_QUERY,
              variables: { botId },
              data: {
                bot: {
                  ...groupsTitlesQuery.bot,
                  blocksGroups,
                },
              },
            });
          }
        },
      })
      .catch(
        showErrorTosterFactory(
          window.i18next.t(
            'GroupMutations-string-1313-couldnt-create-group-please-try-again-later',
          ),
        ),
      );
  }
  return undefined;
};

export const updateGroupIndex = (params: {
  botId: string;
  groupId: string;
  destinationIndex: number;
}) => {
  sendEvent({
    category: 'sequence or group',
    action: 'reorder',
  });

  const { groupId, botId, destinationIndex } = params;
  client
    .mutate<UpdateGroupIndexMutation, UpdateGroupIndexMutationVariables>({
      mutation: UPDATE_GROUP_INDEX_MUTATION,
      variables: {
        botId,
        group: {
          id: groupId,
          position: destinationIndex,
        },
      },
      optimisticResponse: {
        updateBlocksGroup: {
          __typename: 'UpdateBlocksGroupResult',
          position: destinationIndex,
        } as UpdateGroupIndexMutation_updateBlocksGroup,
      },
      update: (cache, { data }) => {
        if (!data) {
          return;
        }
        let botBlocksGroupsFragment: BotBlocksGroupsFragment | null = null;
        try {
          botBlocksGroupsFragment = cache.readFragment({
            id: `Bot:${botId}`,
            fragment: BOT_BLOCKS_GROUPS_FRAGMENT,
          });
        } catch (e) {
          botBlocksGroupsFragment = null;
        }
        if (botBlocksGroupsFragment) {
          const { position } = data.updateBlocksGroup;
          const { blocksGroups } = botBlocksGroupsFragment;
          const sourceIndex = blocksGroups.findIndex(propEq('id', groupId));
          const movedGroup = blocksGroups.splice(sourceIndex, 1)[0];
          blocksGroups.splice(
            position !== null ? position : destinationIndex,
            0,
            movedGroup,
          );
          cache.writeFragment({
            id: `Bot:${botId}`,
            fragment: BOT_BLOCKS_GROUPS_FRAGMENT,
            data: {
              ...botBlocksGroupsFragment,
              blocksGroups,
            },
          });
        }
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'GroupMutations-string--197-couldnt-update-group-please-try-again-later',
        ),
      ),
    );
};
