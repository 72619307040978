import React from 'react';
import i18next from 'i18next';
import { ApolloProvider, Mutation, Query } from '@apollo/react-components';
import { MutationFunction } from '@apollo/react-common';
import { Flex } from '@ui/Flex';
import gql from 'graphql-tag';
import {
  DialogHeading,
  DialogLoader,
  DefaultDialog,
} from '../../modern-ui/Dialog';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import {
  AdminDeletionQuery_bot_canRemoveAdmin_mustBeFixedReasons as MustReasonType,
  AdminDeletionQuery_bot_canRemoveAdmin_shouldBeFixedReasons as ShouldReasonType,
} from './@types/AdminDeletionQuery';
import {
  CommonAdminQuery as COMMON_ADMIN_QUERYTypes,
  CommonAdminQuery_bot_admins as Admin,
  CommonAdminQueryVariables,
} from './@types/CommonAdminQuery';
import {
  SetAdminMutation,
  SetAdminMutationVariables,
} from './@types/SetAdminMutation';
import {
  AdminDeletionMutation,
  AdminDeletionMutationVariables,
} from './@types/AdminDeletionMutation';
import client from '../../common/services/ApolloService';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Input } from '../../modern-ui/Input';
import { RoleIds } from '../../components/settings/admins/RolesList/AdminsRoles';
import { ROLES_AND_INVITES_QUERY } from '../../components/settings/admins/AdminsConst';
import runnerImg from './assets/runner.png';
import * as css from './AdminPermissionManagerDialog.css';
import { sendEvent } from '../../utils/Analytics';
import { TextEllipsis } from '@ui/TextEllipsis';

interface AdminPermissionManagerDialogProps {
  botId: string;
  userId: string;
  isSmallScreenSize?: boolean;
  roleId?: string;
  onComplete: () => void;
  onDismiss: () => void;
}

interface AdminPermissionManagerDialogState {
  settedNewAdminUserId?: string;
}

const THE_LAST_ROLE_MANAGER_ID = 'THE_LAST_ROLE_MANAGER';

enum DialogType {
  changing = 'changing',
  leaving = 'leaving',
  deleting = 'deleting',
}

const COMMON_ADMIN_QUERY = gql`
  query CommonAdminQuery($botId: String!) {
    bot(id: $botId) {
      id
      title
      admins {
        id
        name
        picture
      }
    }
    me {
      id
    }
  }
`;

const ADMIN_DELETION_QUERY = gql`
  query AdminDeletionQuery($botId: String!, $userId: String!) {
    bot(id: $botId) {
      id
      canRemoveAdmin(userId: $userId) {
        mustBeFixedReasons {
          id
          leaving {
            title
            description
          }
          deleting {
            title
            description
          }
        }
        shouldBeFixedReasons {
          id
          leaving {
            title
            description
          }
          deleting {
            title
            description
          }
        }
      }
    }
  }
`;

export const ADMIN_ASSIGN_QUERY = gql`
  query AdminAssignQuery($botId: String!, $userId: String!, $roleId: String!) {
    bot(id: $botId) {
      id
      canAssignAdmin(userId: $userId, roleId: $roleId) {
        mustBeFixedReasons {
          id
          leaving {
            title
            description
          }
          deleting {
            title
            description
          }
        }
        shouldBeFixedReasons {
          id
          leaving {
            title
            description
          }
          deleting {
            title
            description
          }
        }
      }
    }
  }
`;

export const ADMIN_DELETION_MUTATION = gql`
  mutation AdminDeletionMutation($botId: String!, $userId: String!) {
    removeAdmin(input: { botId: $botId, userId: $userId }) {
      removed
      botId
      userId
    }
  }
`;

export const SET_ADMIN_MUTATION = gql`
  mutation SetAdminMutation(
    $botId: String!
    $userId: String!
    $roleId: String!
  ) {
    assignRole(input: { botId: $botId, roleId: $roleId, userId: $userId }) {
      changed
      botId
      userId
      roleId
    }
  }
`;

export class AdminPermissionManagerDialog extends React.Component<
  AdminPermissionManagerDialogProps,
  AdminPermissionManagerDialogState
> {
  state = {
    settedNewAdminUserId: undefined,
  };

  getButtonsInfo = (
    shouldBeFixedReasons: ShouldReasonType[],
    dialogType: DialogType,
    newAdminMustBeChosenReason?: boolean,
  ) => {
    let actionButtonText: String;
    let actionButtonType: ButtonIntent;
    let postponeButtonText: String = '';

    if (shouldBeFixedReasons.length > 0 && dialogType === DialogType.leaving) {
      actionButtonText = i18next.t(
        'AdminPermissionManagerDialog-string--144-leave-now-anyway',
      );
      actionButtonType = ButtonIntent.secondary;
      postponeButtonText = i18next.t(
        'AdminPermissionManagerDialog-string-1998-address-this-and-leave-later',
      );
    } else if (shouldBeFixedReasons.length > 0) {
      actionButtonText = i18next.t(
        'AdminPermissionManagerDialog-string--199-change-now-anyway',
      );
      actionButtonType = ButtonIntent.secondary;
      postponeButtonText = i18next.t(
        'AdminPermissionManagerDialog-string-1731-address-this-and-change-later',
      );
    } else if (
      newAdminMustBeChosenReason &&
      dialogType === DialogType.leaving
    ) {
      actionButtonText = i18next.t(
        'AdminPermissionManagerDialog-string--136-apply-changes-and-leave',
      );
      actionButtonType = ButtonIntent.primary;
    } else if (dialogType === DialogType.leaving) {
      actionButtonText = i18next.t(
        'AdminPermissionManagerDialog-string-7329-leave',
      );
      actionButtonType = ButtonIntent.primary;
    } else {
      actionButtonText = i18next.t(
        'AdminPermissionManagerDialog-string-2017-change',
      );
      actionButtonType = ButtonIntent.primary;
    }

    return { actionButtonText, actionButtonType, postponeButtonText };
  };

  getModalTitle = (
    reasonsExist: boolean,
    dialogType: DialogType,
    botTitle: string,
    userId: string,
    admins: Admin[],
  ) => {
    let modalTitle;

    const admin = admins.find((admin) => admin.id === userId);

    if (reasonsExist && dialogType === DialogType.leaving) {
      modalTitle = i18next.t(
        'AdminPermissionManagerDialog-string--188-wait-some-housekeeping-before-you-go',
      );
    } else if (dialogType === DialogType.leaving) {
      modalTitle = `${i18next.t(
        'AdminPermissionManagerDialog-Template--202-leave',
      )}${botTitle}`;
    } else {
      modalTitle = `${i18next.t(
        'AdminPermissionManagerDialog-Template--119-changing-permissions-for',
      )}${admin && admin.name}`;
    }

    return modalTitle;
  };

  getDialogType = (currentUserId: string) => {
    const { userId, roleId } = this.props;

    if (roleId) {
      return DialogType.changing;
    }

    if (userId === currentUserId) {
      return DialogType.leaving;
    }

    return DialogType.deleting;
  };

  handleMainAction = (
    dialogType: DialogType,
    setRole: MutationFunction<SetAdminMutation, SetAdminMutationVariables>,
    deleteAdmin: MutationFunction<
      AdminDeletionMutation,
      AdminDeletionMutationVariables
    >,
    botId: string,
    userId: string,
    settedNewAdminUserId?: string,
    roleId?: string,
  ) => {
    if (dialogType === DialogType.leaving) {
      sendEvent({
        category: 'bot',
        action: 'leave',
        propertyBag: {
          botId,
        },
      });
    }
    if (dialogType === DialogType.deleting) {
      sendEvent({
        category: 'bot',
        action: 'delete',
        propertyBag: {
          botId,
        },
      });
    }
    if (dialogType === DialogType.changing) {
      sendEvent({
        category: 'bot',
        action: 'change role',
        propertyBag: {
          botId,
        },
      });
    }

    if (dialogType === DialogType.changing && roleId) {
      setRole({
        variables: {
          botId,
          userId,
          roleId,
        },
      });
    } else if (dialogType === DialogType.leaving && settedNewAdminUserId) {
      setRole({
        variables: {
          botId,
          userId: settedNewAdminUserId || '',
          roleId: RoleIds.admin,
        },
      }).then(() => {
        deleteAdmin();
      });
    } else if (
      dialogType === DialogType.deleting ||
      dialogType === DialogType.leaving
    ) {
      deleteAdmin();
    } else {
      throw new Error(
        `Unknown AdminPermissionManagerAction and params. ${{
          dialogType,
          botId,
          userId,
          roleId,
        }}`,
      );
    }
  };

  getSureText = (dialogType: DialogType, reasonsExist: boolean) => {
    if (dialogType === DialogType.changing && !reasonsExist) {
      return i18next.t('AdminPermissionManagerDialog-string--853-are-you-sure');
    }

    return i18next.t(
      'AdminPermissionManagerDialog-string--129-this-action-cannot-be-undone',
    );
  };

  render() {
    const { onDismiss, botId, userId, roleId, onComplete, isSmallScreenSize } =
      this.props;
    const { settedNewAdminUserId } = this.state;

    return (
      <ApolloProvider client={client}>
        <Query<COMMON_ADMIN_QUERYTypes, CommonAdminQueryVariables>
          query={COMMON_ADMIN_QUERY}
          variables={{ botId }}
          onError={() => {
            onDismiss();
          }}
        >
          {({ data, loading }) => {
            if (loading || !data) {
              return <DialogLoader />;
            }

            const {
              bot: { title, admins },
              me: { id: currentUserId },
            } = data;

            const dialogType = this.getDialogType(currentUserId);
            const currentQuery =
              dialogType === DialogType.changing
                ? ADMIN_ASSIGN_QUERY
                : ADMIN_DELETION_QUERY;

            return (
              <Query<any, any>
                query={currentQuery}
                variables={{ botId, userId, roleId }}
              >
                {({ data, loading }) => {
                  if (loading || !data) {
                    return <DialogLoader />;
                  }

                  const { bot } = data;

                  const curPathReason =
                    dialogType === DialogType.changing
                      ? 'canAssignAdmin'
                      : 'canRemoveAdmin';

                  const { mustBeFixedReasons } = bot[curPathReason];
                  const { shouldBeFixedReasons } = bot[curPathReason];

                  const newAdminMustBeChosenReason = mustBeFixedReasons.find(
                    (reason: any) => reason.id === THE_LAST_ROLE_MANAGER_ID,
                  );
                  const reasonsExist =
                    shouldBeFixedReasons.length > 0 ||
                    mustBeFixedReasons.length > 0;
                  const modalTitle = this.getModalTitle(
                    reasonsExist,
                    dialogType,
                    title,
                    userId,
                    admins,
                  );
                  const {
                    actionButtonText,
                    actionButtonType,
                    postponeButtonText,
                  } = this.getButtonsInfo(
                    shouldBeFixedReasons,
                    dialogType,
                    !!newAdminMustBeChosenReason,
                  );

                  const sureText = this.getSureText(dialogType, reasonsExist);

                  return (
                    <DefaultDialog
                      mobileAdaptive={isSmallScreenSize}
                      onDismiss={onDismiss}
                      className={css.dialog}
                    >
                      {dialogType === DialogType.leaving && reasonsExist && (
                        <div className={css.imageContainer}>
                          <img src={runnerImg} alt="" />{' '}
                        </div>
                      )}

                      <DialogHeading>
                        <TextEllipsis title={modalTitle} width="95%">
                          {modalTitle}
                        </TextEllipsis>
                      </DialogHeading>

                      {shouldBeFixedReasons.map(
                        ({
                          id,
                          leaving: leavingReason,
                          deleting: deletingReason,
                        }: any) => {
                          const { title, description } =
                            dialogType === DialogType.leaving
                              ? leavingReason
                              : deletingReason;

                          return (
                            <p key={id} className={css.reasonParagraph}>
                              <span className={css.reasonTitle}>{title}</span>
                              <br />
                              <span>{description}</span>
                            </p>
                          );
                        },
                      )}

                      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
                      <LastRoleManagerReasonFixer
                        dialogType={dialogType}
                        onChange={(settedNewAdminUserId) => {
                          this.setState({
                            settedNewAdminUserId,
                          });
                        }}
                        onMount={(settedNewAdminUserId) => {
                          this.setState({
                            settedNewAdminUserId,
                          });
                        }}
                        currentUserId={userId}
                        admins={admins}
                        newAdminMustBeChosenReason={newAdminMustBeChosenReason}
                      />

                      <p className={css.reasonParagraph}>{sureText}</p>

                      <Mutation<SetAdminMutation, SetAdminMutationVariables>
                        mutation={SET_ADMIN_MUTATION}
                        onError={() => {
                          onDismiss();
                        }}
                        refetchQueries={[
                          {
                            query: ROLES_AND_INVITES_QUERY,
                            variables: { botId },
                          },
                        ]}
                        awaitRefetchQueries
                        onCompleted={() => {
                          if (dialogType === DialogType.changing) {
                            onComplete();
                          }
                        }}
                      >
                        {(setRole, { loading: setAdminLoading }) => (
                          <Mutation<
                            AdminDeletionMutation,
                            AdminDeletionMutationVariables
                          >
                            mutation={ADMIN_DELETION_MUTATION}
                            variables={{ botId, userId }}
                            refetchQueries={
                              dialogType !== DialogType.leaving
                                ? [
                                    {
                                      query: ROLES_AND_INVITES_QUERY,
                                      variables: { botId },
                                    },
                                  ]
                                : undefined
                            }
                            awaitRefetchQueries
                            onError={() => {
                              onDismiss();
                            }}
                            onCompleted={() => {
                              onComplete();
                            }}
                          >
                            {(deleteAdmin, { loading: deleteAdminLoading }) => (
                              <Flex
                                justifyContent="flex-end"
                                flexDirection={
                                  isSmallScreenSize ? 'column' : 'row'
                                }
                                className={css.buttons}
                              >
                                <Button
                                  intent={actionButtonType}
                                  disabled={
                                    (newAdminMustBeChosenReason &&
                                      !settedNewAdminUserId) ||
                                    setAdminLoading ||
                                    deleteAdminLoading
                                  }
                                  onClick={() => {
                                    this.handleMainAction(
                                      dialogType,
                                      setRole,
                                      deleteAdmin,
                                      botId,
                                      userId,
                                      settedNewAdminUserId,
                                      roleId,
                                    );
                                  }}
                                >
                                  {actionButtonText}
                                </Button>

                                {postponeButtonText && (
                                  <Button
                                    className={css.postponeButton}
                                    intent={ButtonIntent.primary}
                                    disabled={
                                      setAdminLoading || deleteAdminLoading
                                    }
                                    onClick={() => {
                                      onDismiss();
                                    }}
                                  >
                                    {postponeButtonText}
                                  </Button>
                                )}
                              </Flex>
                            )}
                          </Mutation>
                        )}
                      </Mutation>
                    </DefaultDialog>
                  );
                }}
              </Query>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}

interface LastRoleManagerReasonFixerProps {
  dialogType: DialogType;
  onChange: (settedNewAdminUserId?: string) => void;
  onMount: (settedNewAdminUserId?: string) => void;
  currentUserId: string;
  admins: Admin[];
  newAdminMustBeChosenReason?: MustReasonType;
}

class LastRoleManagerReasonFixer extends React.Component<
  LastRoleManagerReasonFixerProps,
  {}
> {
  componentDidMount() {
    const { onMount, admins, newAdminMustBeChosenReason } = this.props;

    if (!newAdminMustBeChosenReason) {
      return null;
    }

    const items = this.getNewAdminItemsFromAdmins(admins);

    if (newAdminMustBeChosenReason) {
      onMount(items[0].id);
    }

    return undefined;
  }

  getNewAdminItemsFromAdmins = (admins: Admin[]) => {
    const { currentUserId } = this.props;

    return admins
      .filter((admin) => admin.id !== currentUserId)
      .map(({ id, name, picture }) => ({
        picture,
        id,
        title: name || '',
      }));
  };

  renderAdminIcon = (item: any) => {
    return (
      item &&
      item.picture && (
        <img
          src={item.picture}
          className={css.adminPicture}
          alt={item && item.title}
          role="presentation"
        />
      )
    );
  };

  render() {
    const { dialogType, onChange, admins, newAdminMustBeChosenReason } =
      this.props;

    if (!newAdminMustBeChosenReason) {
      return null;
    }

    const { title, description } =
      dialogType === DialogType.leaving
        ? newAdminMustBeChosenReason.leaving
        : newAdminMustBeChosenReason.deleting;
    const items = this.getNewAdminItemsFromAdmins(admins);

    return (
      <React.Fragment>
        <p className={css.reasonParagraph}>
          <span className={css.reasonTitle}>{title}</span>
          <br />
          <span>{description}</span>
        </p>
        <div>
          <SimpleCombobox
            items={items}
            initialSelectedItem={items[0]}
            onChange={(item) => {
              onChange(item ? item.id : undefined);
            }}
            renderInput={({
              getInputProps,
              openMenu,
              clearSelection,
              selectedItem,
            }) => (
              <Input
                {...getInputProps({
                  placeholder: i18next.t(
                    'AdminPermissionManagerDialog-string-2013-select-new-admin',
                  ),
                  name: 'newAdmin',
                  onFocus: openMenu,
                  onChange: () => clearSelection(),
                })}
                renderIconEnd={() => {
                  return this.renderAdminIcon(selectedItem);
                }}
              />
            )}
          />
        </div>
      </React.Fragment>
    );
  }
}
