import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from '@apollo/react-hooks';
import { isEmpty, uniq, propEq } from 'ramda';

import { sendEvent } from '@utils/Analytics';
import { useGroupedNotificationMessagesTopics } from '@utils/Data/NotificationMessages/useNotificationMessagesTopics';
import { toaster } from '@services/MessageService';

import {
  ChooseFromListPopup as TagsDialog,
  ListItem,
} from '@ui/ChooseFromListPopup';
import { Loader } from '@ui/Loader';
import { Modal } from '@ui/Modal';
import { SectionHeader } from '@ui/SectionHeader';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { Button, ButtonIntent, ButtonSize } from '@ui/_deprecated/Button';
import { GroupedNotificationMessagesTopicsQuery_page_daily } from '@utils/Data/NotificationMessages/@types/GroupedNotificationMessagesTopicsQuery';

import { Frequency } from '@globals';

import {
  linkToDoc,
  NON_PROMOTIONAL_SUBSCRIPTION_ID,
  OTN_GROUP_ID,
  NTN_GROUP_ID,
  STANDARD_MESSAGING_ID,
  TAG_ID_TO_TAG_MAP,
  TAGS_GROUPS,
  CF_OTN_LINK,
} from './TagsData';

import { NotificationsCombobox } from './components/NotificationsCombobox';
import { PopupSubheading } from './components/PopupSubheading';
import { HelperMessage } from './components/HelperMessage';
import { NotificationsComboboxProps } from './components/NotificationsCombobox/NotificationsCombobox';

import { useStateWhenDefined } from './hooks';
import { getFilteredAndSortedTagList, getInitialSelectedId } from './helpers';
import { MESSAGE_TAGS_QUERY } from './queries';

import {
  MessageTagsAndOtn,
  MessageTagsAndOtnVariables,
  MessageTagsAndOtn_oneTimeNotifications as OneTimeNotifications,
} from './@types/MessageTagsAndOtn';

import * as css from './BroadcastingTags.css';

export interface BroadcastingTagsProps {
  selectedTagId?: string;
  selectedTopicId?: string;
  botId?: string;
  onSelect: (params: {
    id: string | null;
    purpose?: string;
    topicId?: string;
    frequency?: Frequency;
  }) => void;
  selectedPurpose?: string;
}
export const BroadcastingTags: React.FC<BroadcastingTagsProps> = ({
  onSelect,
  selectedTagId,
  selectedTopicId,
  selectedPurpose,
  botId,
}) => {
  const { t } = useSafeTranslation();
  const {
    groupedNotificationMessageTopics,
    notificationMessagesTopics,
    notificationMessagesTopicsLoading,
  } = useGroupedNotificationMessagesTopics();
  const [showModal, setShowModal] = useState(false);
  const [tags, setTags] = useState<ListItem[]>([]);
  const [otns, setOtns] = useState<OneTimeNotifications[]>([]);
  const [selectedId, setSelectedId] = useState(
    getInitialSelectedId(selectedTagId, selectedPurpose, selectedTopicId),
  );

  const [selectedOtnPurpose, setSelectedOtnPurpose] = useStateWhenDefined<
    string | null
  >(selectedPurpose ?? null);
  const [selectedNotificationTopicId, setSelectedNotificationTopicId] =
    useState<string | null>(selectedTopicId ?? null);

  const { data, loading, error } = useQuery<
    MessageTagsAndOtn,
    MessageTagsAndOtnVariables
  >(MESSAGE_TAGS_QUERY, {
    variables: { botId: botId! },
    skip: !botId,
    onError: () => {
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message: t('modernComponents.BroadcastingTags.loadingError'),
        },
      });
    },
    fetchPolicy: 'cache-and-network',
  });

  const handleSelectId = (id: string) => {
    setSelectedId(id);
  };
  const handleSelectOtn = (otnPurpose?: string | null) => {
    if (otnPurpose && otns.some(({ purpose }) => purpose === otnPurpose)) {
      setSelectedId(OTN_GROUP_ID);
      setSelectedOtnPurpose(otnPurpose);
    }
  };
  const handleSelectNtn: NotificationsComboboxProps['onSelect'] = (
    topicId,
    frequency,
  ) => {
    if (
      topicId &&
      frequency &&
      groupedNotificationMessageTopics?.[frequency].some(
        ({ id }) => id === topicId,
      )
    ) {
      setSelectedId(NTN_GROUP_ID);
      setSelectedNotificationTopicId(topicId);
    }
  };

  const handleTagConfirmSelection = () => {
    sendEvent({
      category: 're-engage',
      action: 'select',
      label: 'current tag',
      propertyBag: {
        tag: selectedId,
      },
    });

    switch (selectedId) {
      case OTN_GROUP_ID: {
        onSelect({ id: null, purpose: selectedOtnPurpose ?? undefined });
        break;
      }
      case NTN_GROUP_ID: {
        onSelect({
          id: null,
          topicId: selectedNotificationTopicId ?? undefined,
          frequency: notificationMessagesTopics?.find(
            propEq('id', selectedNotificationTopicId),
          )?.frequency,
        });
        break;
      }
      default:
        onSelect({ id: selectedId ?? null });
    }

    setShowModal(false);
  };

  const handleModalOpen = () => {
    sendEvent({
      category: 're-engage',
      action: 'click',
      label: 'current tag',
      propertyBag: {
        tag: selectedId,
      },
    });
    setShowModal(true);
  };

  const isEuRestricted = !!data?.bot.status?.page_info?.eu_restricted;
  if (isEuRestricted) {
    data?.messageTags.forEach((tag) => {
      if (tag.tag === NON_PROMOTIONAL_SUBSCRIPTION_ID) {
        // eslint-disable-next-line no-param-reassign
        tag.disabled = false; // enable for EU admins (https://chatfuel.atlassian.net/browse/CHAT-4471)
      }
    });
  }

  const defaultBroadcastMessageTag = data?.bot.default_broadcast_message_tag;
  useEffect(() => {
    if (defaultBroadcastMessageTag && !selectedId) {
      setSelectedId(defaultBroadcastMessageTag);
    }
  }, [defaultBroadcastMessageTag, selectedId]);

  useEffect(() => {
    if (selectedTagId || selectedPurpose || selectedTopicId) {
      setSelectedId(
        getInitialSelectedId(selectedTagId, selectedPurpose, selectedTopicId),
      );
    }
  }, [selectedPurpose, selectedTagId, selectedTopicId]);

  useEffect(() => {
    if (data?.messageTags && data?.oneTimeNotifications && isEmpty(tags)) {
      const { messageTags, oneTimeNotifications } = data;
      const tagList: ListItem[] = getFilteredAndSortedTagList(messageTags, t);
      setTags(tagList);
      setOtns(oneTimeNotifications);
    }
  }, [data, tags, t]);

  const selectedTag = TAG_ID_TO_TAG_MAP(t).get(selectedId!);
  const selectedTagTitle = selectedTopicId
    ? t('pages.Reengage.BroadcastingTagsDialog.NTN.title')
    : selectedTag?.leftPartTitle ?? selectedId;
  const hasSubscriptionTag = tags.some(
    (tag) => tag.id === NON_PROMOTIONAL_SUBSCRIPTION_ID && !tag.disabled,
  );

  const otnItem: ListItem = {
    id: OTN_GROUP_ID,
    groupId: OTN_GROUP_ID,
    renderRightPart: (selected) => (
      <NotificationsCombobox
        dataTestId="otn_broadcasting_combobox"
        inputTestId="otn-group__topic-input"
        initialSelectedItem={
          selectedOtnPurpose
            ? {
                id: selectedOtnPurpose,
                title: selectedOtnPurpose,
              }
            : null
        }
        onSelect={handleSelectOtn}
        items={uniq(otns).map(({ purpose }) => ({
          id: purpose,
          title: purpose,
        }))}
        isSelected={Boolean(selected)}
      />
    ),
  };

  const initialNtn =
    notificationMessagesTopics?.find(
      ({ id }) => id === selectedNotificationTopicId,
    ) ?? null;

  const getFrequencyItem = useCallback(
    (
      list: Array<GroupedNotificationMessagesTopicsQuery_page_daily>,
      frequency: Frequency,
    ) => {
      if (!list.length) return [];
      let title = t('NTimeNotificationPlugin.Daily');
      if (frequency === Frequency.MONTHLY)
        title = t('NTimeNotificationPlugin.Monthly');
      if (frequency === Frequency.WEEKLY)
        title = t('NTimeNotificationPlugin.Weekly');
      return [
        {
          id: frequency,
          title,
          isGroupTitle: true,
        },
        ...list,
      ];
    },
    [t],
  );

  const ntnTopics = useMemo(
    () =>
      (groupedNotificationMessageTopics &&
        [
          ...getFrequencyItem(
            groupedNotificationMessageTopics[Frequency.DAILY],
            Frequency.DAILY,
          ),
          ...getFrequencyItem(
            groupedNotificationMessageTopics[Frequency.WEEKLY],
            Frequency.WEEKLY,
          ),
          ...getFrequencyItem(
            groupedNotificationMessageTopics[Frequency.MONTHLY],
            Frequency.MONTHLY,
          ),
        ].map((item) => {
          if ('frequency' in item) {
            const { id, name, frequency } = item;
            return {
              id,
              title: name,
              frequency,
            };
          }
          const { id, title, isGroupTitle } = item;
          return {
            id,
            title,
            isGroupTitle,
          };
        })) ??
      [],
    [getFrequencyItem, groupedNotificationMessageTopics],
  );

  const selectedNtnTopicTitle = useMemo(
    () => ntnTopics.find(({ id }) => id === selectedNotificationTopicId)?.title,
    [ntnTopics, selectedNotificationTopicId],
  );

  const ntnItem: ListItem = {
    id: NTN_GROUP_ID,
    groupId: NTN_GROUP_ID,
    renderRightPart: (selected) => (
      <NotificationsCombobox
        initialSelectedItem={
          initialNtn && {
            id: initialNtn.id,
            title: initialNtn.name,
          }
        }
        dataTestId="ntn_broadcasting_combobox"
        inputTestId="ntn-group__topic-input"
        onSelect={handleSelectNtn}
        items={ntnTopics}
        isSelected={Boolean(selected)}
      />
    ),
  };

  if (
    !botId ||
    !selectedId ||
    loading ||
    notificationMessagesTopicsLoading ||
    error ||
    !data?.messageTags
  ) {
    return <Loader />;
  }

  return (
    <div>
      <SectionHeader intent="section">
        {t('modernComponents.BroadcastingTags.title')}
      </SectionHeader>
      <div className={css.sectionLine}>
        <div className={css.sectionSubtitle}>
          {t('modernComponents.BroadcastingTags.tagSelectorLabel')}
        </div>
        <Button
          data-testid="broadcasting-tags__selected-tag-button"
          onClick={handleModalOpen}
          renderIconRight={() => <div className={css.arrowDown} />}
          intent={ButtonIntent.displayContent}
          size={ButtonSize.s}
        >
          {selectedId === OTN_GROUP_ID
            ? `${t(
                'modernComponents.BroadcastingTags.type.otn',
              )} ${selectedOtnPurpose}`
            : selectedNtnTopicTitle
            ? `${t(
                'modernComponents.BroadcastingTags.type.ntn',
              )} ${selectedNtnTopicTitle}`
            : selectedTagTitle ||
              t('modernComponents.BroadcastingTags.tagSelectorPlaceholder')}
        </Button>
      </div>
      <div className={css.tagExplanation}>
        {selectedTag && selectedTag.id === STANDARD_MESSAGING_ID && (
          <HelperMessage link={linkToDoc(t)}>
            {t('modernComponents.BroadcastingTags.standardMessaging24hWarning')}
          </HelperMessage>
        )}
        {selectedId === OTN_GROUP_ID && (
          <HelperMessage link={CF_OTN_LINK}>
            {t('modernComponents.BroadcastingTags.followUp24hWarning')}
          </HelperMessage>
        )}
        {selectedTag && selectedTag.id === NON_PROMOTIONAL_SUBSCRIPTION_ID && (
          <HelperMessage link={linkToDoc(t)}>
            {hasSubscriptionTag
              ? t('modernComponents.BroadcastingTags.nonPromotional24hWarning')
              : t(
                  'modernComponents.BroadcastingTags.nonPromotionalChangeTagWarning',
                )}
            &nbsp;
          </HelperMessage>
        )}
        {selectedTag &&
          selectedTag.id !== NON_PROMOTIONAL_SUBSCRIPTION_ID &&
          selectedTag.id !== STANDARD_MESSAGING_ID && (
            <HelperMessage link={linkToDoc(t)}>
              {t(
                'modernComponents.BroadcastingTags.cantContainPromotionalContentWarning',
              )}
            </HelperMessage>
          )}
      </div>

      {showModal && (
        <Modal onDismiss={() => setShowModal(false)}>
          <TagsDialog
            onRequestClose={() => setShowModal(false)}
            onConfirmSelection={handleTagConfirmSelection}
            selectedId={selectedId}
            isOtnSelected={!!selectedOtnPurpose}
            isNtnSelected={Boolean(selectedNotificationTopicId)}
            items={[...tags, otnItem, ntnItem]}
            groups={TAGS_GROUPS(t)}
            buttonText={t(
              'modernComponents.BroadcastingTags.tagsDialogSelectButton',
            )}
            dialogHeading={t(
              'modernComponents.BroadcastingTags.tagsDialogHeading',
            )}
            renderSubHeading={() => <PopupSubheading />}
            onSelect={handleSelectId}
          />
        </Modal>
      )}
    </div>
  );
};
