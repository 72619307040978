import gql from 'graphql-tag';

export const CHOOSE_FLOW_BLOCK_QUERY = gql`
  query ChooseFlowBlockQuery($botId: String!, $flowId: MongoObjectId!) {
    bot(id: $botId) {
      id
      flowBlocks(id: $flowId) {
        id
        title
        subtype
        context_type
      }
    }
  }
`;
