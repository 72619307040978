// eslint-disable-next-line import/no-extraneous-dependencies
import { DataProxy } from 'apollo-cache';
import { complement, propEq, prop, last, path } from 'ramda';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import nanoid from 'nanoid';
import { FetchResult } from 'apollo-link';
import { log } from 'cf-common/src/logger';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import * as H from 'history';
import client from '../../../common/services/ApolloService';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { getIncrementName } from '../../../common/services/NamingService';
import { BlocksTitlesFragment } from './@types/BlocksTitlesFragment';
import { DeleteBlockFromGroupMutation_deleteBlockFromGroup_updatedGroup as IGroupForDeleteBlock } from './@types/DeleteBlockFromGroupMutation';
import { GroupFragment } from './@types/GroupFragment';
import {
  CloneBlockMutation,
  CloneBlockMutation_cloneBlock_clonedBlock as IBlock,
} from './@types/CloneBlockMutation';
import { MoveBlockMutation } from './@types/MoveBlockMutation';
import { BlocksTitlesQuery } from './@types/BlocksTitlesQuery';
import { UpdateBlockData } from './UpdateTypes';
import {
  CreateNewBlockMutation,
  CreateNewBlockMutation_createBlock_parentGroup as IGroupForCreateBlock,
  CreateNewBlockMutation_createBlock_parentGroup_blocks as IBlockForCreateBlock,
  CreateNewBlockMutation_createBlock_parentGroup_blocks_broadcast_options,
} from './@types/CreateNewBlockMutation';
import { GroupsTitlesQuery } from './@types/GroupsTitlesQuery';
import {
  BLOCKS_TITLES_FRAGMENT,
  BLOCKS_TITLES_QUERY,
  CLONE_BLOCK_MUTATION,
  CREATE_NEW_BLOCK_MUTATION,
  DELETE_BLOCK_FROM_GROUP_MUTATION,
  GROUP_FRAGMENT,
  MOVE_BLOCK_MUTATION,
  UPDATE_BLOCK_MUTATION,
  GROUPS_TITLES_QUERY,
  UPDATE_BLOCK_INDEX_MUTATION,
} from './GQL';
import { sendEvent } from '../../../utils/Analytics';
import { redirect } from '../../../utils/UrlUtils';
import { BLOCK_LINKS_QUERY } from '../../BlockLinks/BlockLinks.queries';
import { getCurrentBlocks } from '../../../utils/BlocksUtils';

const DEFAULT_BLOCK_NAME = 'Untitled Block';

export const OPTIMISTIC_BLOCK_ID_PREFIX = 'prepare-';
export const getOptimisticBlockId = () =>
  `${OPTIMISTIC_BLOCK_ID_PREFIX}${nanoid()}`;

const goToBlock = (
  botId: string,
  blockId: string | undefined,
  isNew?: boolean,
  history?: H.History,
) => {
  if (blockId) {
    if (history) {
      history.push(
        `/bot/${botId}/structure/${blockId}${isNew ? '?new=true' : ''}`,
      );
    } else {
      redirect(`/bot/${botId}/structure/${blockId}${isNew ? '?new=true' : ''}`);
    }
  }
};

const excludeById = (id: string) => complement(propEq('id', id));

const showErrorTosterFactory = (message: string) => (error: ApolloError) => {
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message,
    },
  });

  log.error({
    error,
    msg: 'automate_core',
    data: {
      label: 'automate_core',
      requestId: getRequestIdFromApolloError(error),
    },
  });
};

export const addBlockToBlockArchiveCache = (
  cache: DataProxy,
  botId: string,
  block: IBlock,
) => {
  let blocksFragmentData: BlocksTitlesFragment | null | undefined;
  try {
    blocksFragmentData = cache.readFragment({
      id: `Bot:${botId}`,
      fragment: BLOCKS_TITLES_FRAGMENT,
    });
  } catch {
    blocksFragmentData = null;
  }
  if (blocksFragmentData) {
    cache.writeFragment({
      id: `Bot:${botId}`,
      fragment: BLOCKS_TITLES_FRAGMENT,
      data: {
        ...blocksFragmentData,
        archiveBlocks: [
          ...blocksFragmentData.archiveBlocks,
          {
            ...block,
            removed: false,
            reachable: true,
          },
        ],
      },
    });
  }
};

export const deleteBlock = (props: {
  blockId: string;
  group: IGroupForDeleteBlock;
  botId: string;
  currentBlockId?: string;
  defaultBlockId?: string;
  history?: H.History;
}) => {
  const { blockId, group, botId, currentBlockId, defaultBlockId, history } =
    props;
  return client
    .mutate({
      mutation: DELETE_BLOCK_FROM_GROUP_MUTATION,
      variables: {
        botId,
        blockId,
        groupId: group.id,
      },
      optimisticResponse: {
        deleteBlockFromGroup: {
          __typename: 'DeleteBlockFromGroupResult',
          updatedGroup: {
            __typename: 'BlocksGroup',
            id: group.id,
            blocks: group.blocks.filter(excludeById(blockId)),
          },
        },
      },
      refetchQueries: [
        {
          query: BLOCK_LINKS_QUERY,
          variables: { botId, blockId: currentBlockId },
        },
      ],
      update: (cache) => {
        if (currentBlockId === blockId) {
          goToBlock(botId, defaultBlockId, false, history);
        }

        let blocksTitlesFragment: BlocksTitlesFragment | null = null;
        try {
          blocksTitlesFragment = cache.readFragment({
            id: `Bot:${botId}`,
            fragment: BLOCKS_TITLES_FRAGMENT,
          });
        } catch (e) {
          blocksTitlesFragment = null;
        }
        if (blocksTitlesFragment) {
          const block = blocksTitlesFragment.archiveBlocks.find(
            propEq('id', blockId),
          );
          if (block) {
            blocksTitlesFragment.archiveBlocks =
              blocksTitlesFragment.archiveBlocks.map((_block) => {
                if (propEq('title', block.title, _block)) {
                  return {
                    ..._block,
                    removed: true,
                    reachable: false,
                  };
                }
                return _block;
              });
            cache.writeFragment({
              id: `Bot:${botId}`,
              fragment: BLOCKS_TITLES_FRAGMENT,
              data: blocksTitlesFragment,
            });
          }
        }
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'BlockMutations-string--109-couldnt-delete-block-please-try-again-later',
        ),
      ),
    );
};

export const cloneBlock = (params: {
  sourceBlock: IBlock;
  targetGroupId: string;
  targetBotId: string;
}) => {
  const { sourceBlock, targetGroupId, targetBotId } = params;
  return client
    .mutate<CloneBlockMutation>({
      mutation: CLONE_BLOCK_MUTATION,
      variables: {
        targetGroupId,
        targetBotId,
        sourceBlockId: sourceBlock.id,
      },
      optimisticResponse: {
        cloneBlock: {
          __typename: 'ClonedBlockResult',
          clonedBlock: {
            ...sourceBlock,
            __typename: 'Block',
            id: nanoid(),
            builtin: false,
          },
        },
      },
      update: (cache, { data }) => {
        if (!data) {
          return;
        }
        let groupFragment: GroupFragment | null = null;
        const { clonedBlock } = data.cloneBlock;
        try {
          groupFragment = cache.readFragment({
            id: targetGroupId,
            fragment: GROUP_FRAGMENT,
          });
        } catch (e) {
          groupFragment = null;
        }
        if (groupFragment && data) {
          cache.writeFragment({
            id: targetGroupId,
            fragment: GROUP_FRAGMENT,
            data: {
              ...groupFragment,
              blocks: [...groupFragment.blocks, clonedBlock],
            },
          });
        }
        addBlockToBlockArchiveCache(cache, targetBotId, clonedBlock);
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'BlockMutations-string-1971-couldnt-clone-block-please-try-again-later',
        ),
      ),
    );
};

export const moveBlock = (params: {
  sourceBlock: IBlock;
  sourceGroupId: string;
  targetGroupId: string;
  targetBotId: string;
  currentBotId: string;
  defaultBlockId: string | undefined;
  history?: H.History;
}) => {
  const {
    sourceBlock,
    sourceGroupId,
    targetGroupId,
    targetBotId,
    currentBotId,
    defaultBlockId,
    history,
  } = params;
  return client
    .mutate<MoveBlockMutation>({
      mutation: MOVE_BLOCK_MUTATION,
      variables: {
        targetGroupId,
        targetBotId,
        sourceBlockId: sourceBlock.id,
      },
      optimisticResponse: {
        moveBlock: {
          __typename: 'MoveBlockResult',
          movedBlock: {
            ...sourceBlock,
            __typename: 'Block',
            builtin: false,
          },
        },
      },
      update: (cache, { data }) => {
        let sourceGroupFragment: GroupFragment | null = null;
        const { movedBlock } = data!.moveBlock;

        try {
          sourceGroupFragment = cache.readFragment({
            id: sourceGroupId,
            fragment: GROUP_FRAGMENT,
          });
        } catch (e) {
          sourceGroupFragment = null;
        }
        if (sourceGroupFragment && data) {
          cache.writeFragment({
            id: sourceGroupId,
            fragment: GROUP_FRAGMENT,
            data: {
              ...sourceGroupFragment,
              blocks: sourceGroupFragment.blocks.filter(
                excludeById(sourceBlock.id),
              ),
            },
          });
        }

        let targetGroupFragment: GroupFragment | null = null;
        try {
          targetGroupFragment = cache.readFragment({
            id: targetGroupId,
            fragment: GROUP_FRAGMENT,
          });
        } catch (e) {
          targetGroupFragment = null;
        }
        if (targetGroupFragment) {
          cache.writeFragment({
            id: targetGroupId,
            fragment: GROUP_FRAGMENT,
            data: {
              ...targetGroupFragment,
              blocks: [...targetGroupFragment.blocks, movedBlock],
            },
          });
        }

        addBlockToBlockArchiveCache(cache, targetBotId, movedBlock);

        if (currentBotId !== targetBotId) {
          goToBlock(currentBotId, defaultBlockId, false, history);
        } else if (movedBlock.id !== sourceBlock.id) {
          goToBlock(currentBotId, movedBlock.id, false, history);
        }
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'BlockMutations-string--181-couldnt-move-block-please-try-again-later',
        ),
      ),
    );
};

export const createBlock = async (params: {
  botId: string;
  group?: IGroupForCreateBlock | undefined;
  title?: string;
  position?: number;
  silent?: boolean;
  noRenameAfterCreate?: boolean;
  optimisticBlockId?: string;
  optimisticBroadcastOptions?: Omit<
    CreateNewBlockMutation_createBlock_parentGroup_blocks_broadcast_options,
    '__typename'
  >;
  history?: H.History;
  // eslint-disable-next-line consistent-return
}): Promise<FetchResult<CreateNewBlockMutation> | void> => {
  sendEvent({
    category: 'block',
    action: 'add',
  });

  const {
    botId,
    group,
    title: expectedTitle,
    position,
    silent,
    noRenameAfterCreate,
    optimisticBroadcastOptions,
    history,
  } = params;

  let blocksTitlesQueryResult: ApolloQueryResult<BlocksTitlesQuery> | null =
    null;

  try {
    blocksTitlesQueryResult = (await client.query({
      query: BLOCKS_TITLES_QUERY,
      variables: { botId },
    })) as ApolloQueryResult<BlocksTitlesQuery>;
  } catch (e) {
    blocksTitlesQueryResult = null;
  }

  if (blocksTitlesQueryResult) {
    const blocksTitles = getCurrentBlocks(
      blocksTitlesQueryResult.data.bot.archiveBlocks,
    ).map(prop('title'));
    const title = getIncrementName(
      expectedTitle || DEFAULT_BLOCK_NAME,
      blocksTitles,
    );
    let optimisticResponse: CreateNewBlockMutation | undefined;

    if (group) {
      /**
       * Block id for optimistic response (for prevent load block before creating, but emulate transition to new block).
       * For more info search by "OPTIMISTIC_BLOCK_ID_PREFIX"
       */
      const optimisticBlockId = params.optimisticBlockId
        ? params.optimisticBlockId
        : `${OPTIMISTIC_BLOCK_ID_PREFIX}${nanoid()}`;
      const blocks = [...group.blocks];
      const optimisticBlock = {
        title,
        __typename: 'Block',
        id: optimisticBlockId,
        builtin: false,
        broadcast_options: {
          __typename: 'BroadcastOptionsType',
          time_period: 'days',
          time_value: 1,
          ...optimisticBroadcastOptions,
        },
        referral_active: null,
        referral: null,
        removed: false,
        reachable: true,
        is_valid: true,
        stats: {
          __typename: 'InlineStats',
          sent: 0,
          seen: 0,
          clicked: 0,
          blocked: 0,
        },
        total_clicks: null,
        total_views: null,
      } as IBlockForCreateBlock;

      if (position === undefined) {
        blocks.push(optimisticBlock);
      } else {
        blocks.splice(position, 0, optimisticBlock);
      }

      optimisticResponse = {
        createBlock: {
          __typename: 'CreateBlockResult',
          parentGroup: {
            ...group,
            blocks,
          },
          block: {
            title,
            __typename: 'Block',
            id: optimisticBlockId,
            removed: false,
            reachable: true,
            is_flow: false,
            stats: {
              __typename: 'InlineStats',
              clicked: 0,
              seen: 0,
            },
          },
        },
      };
    }

    return client
      .mutate<CreateNewBlockMutation>({
        optimisticResponse,
        mutation: CREATE_NEW_BLOCK_MUTATION,
        variables: {
          botId,
          title,
          position,
          groupId: group && group.id,
        },
        update: (cache, { data }) => {
          const blocks = path(
            ['createBlock', 'parentGroup', 'blocks'],
            data,
          ) as IBlock[];
          if (blocks) {
            const block =
              position === undefined ? last(blocks) : blocks[position];

            if (block) {
              addBlockToBlockArchiveCache(cache, botId, block);

              if (!silent) {
                goToBlock(botId, block.id, !noRenameAfterCreate, history);
              }
            }
          }

          if (!group) {
            let groupsTitlesQuery: GroupsTitlesQuery | null = null;
            const newGroup = path(
              ['createBlock', 'parentGroup'],
              data,
            ) as IGroupForCreateBlock;

            try {
              groupsTitlesQuery = cache.readQuery({
                query: GROUPS_TITLES_QUERY,
                variables: { botId },
              });
            } catch (e) {
              groupsTitlesQuery = null;
            }
            if (groupsTitlesQuery && newGroup) {
              const {
                blocksGroups: [...blocksGroups],
              } = groupsTitlesQuery.bot;

              if (!blocksGroups.some(propEq('id', newGroup.id))) {
                blocksGroups.push(newGroup);
                cache.writeQuery({
                  query: GROUPS_TITLES_QUERY,
                  variables: { botId },
                  data: {
                    bot: {
                      ...groupsTitlesQuery.bot,
                      blocksGroups,
                    },
                  },
                });
              }
            }
          }
        },
      })
      .catch(() => {
        showErrorTosterFactory(
          window.i18next.t(
            'BlockMutations-string--730-couldnt-create-block-please-try-again-later',
          ),
        );
      });
  }
  return undefined;
};

export const updateBlockBroadcastOptions = (params: {
  block: IBlock;
  updateBlockData: UpdateBlockData;
}) => {
  const { block, updateBlockData } = params;
  const blockId = block.id;

  return client
    .mutate({
      mutation: UPDATE_BLOCK_MUTATION,
      variables: {
        blockId,
        updateBlockData,
      },
      optimisticResponse: {
        updateBlock: {
          __typename: 'UpdateBlockResult',
          updatedBlock: {
            ...block,
            broadcast_options: {
              __typename: 'BroadcastOptionsType',
              ...updateBlockData.broadcast_options,
            },
            __typename: 'Block',
          },
        },
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'BlockMutations-string-2799-couldnt-update-block-please-try-again-later',
        ),
      ),
    );
};

export const updateBlockIndex = (params: {
  block: { id: string; title: string };
  destinationIndex: number;
  targetGroupId: string;
  sourceGroupId: string;
}) => {
  sendEvent({
    category: 'block',
    action: 'reorder',
  });

  const { block, destinationIndex, targetGroupId, sourceGroupId } = params;
  const inOneGroup = sourceGroupId === targetGroupId;

  return client
    .mutate({
      mutation: UPDATE_BLOCK_INDEX_MUTATION,
      variables: {
        blockId: block.id,
        updateBlockData: {
          title: block.title,
          position: destinationIndex,
          group_id: targetGroupId,
        },
      },
      optimisticResponse: {
        updateBlock: {
          __typename: 'UpdateBlockResult',
          updatedBlock: {
            ...block,
            __typename: 'Block',
          },
        },
      },
      update: (cache) => {
        let sourceGroupFragment: GroupFragment | null = null;
        try {
          sourceGroupFragment = cache.readFragment({
            id: sourceGroupId,
            fragment: GROUP_FRAGMENT,
          });
        } catch (e) {
          sourceGroupFragment = null;
        }
        if (sourceGroupFragment) {
          const { blocks } = sourceGroupFragment;
          const movedBlockSourceIndex = blocks.findIndex(
            propEq('id', block.id),
          );
          const movedBlock = blocks.splice(movedBlockSourceIndex, 1)[0];
          if (inOneGroup && movedBlock) {
            blocks.splice(destinationIndex, 0, movedBlock);
          }
          cache.writeFragment({
            id: sourceGroupId,
            fragment: GROUP_FRAGMENT,
            data: {
              ...sourceGroupFragment,
              blocks,
            },
          });
          if (!inOneGroup) {
            let targetGroupFragment: GroupFragment | null = null;
            try {
              targetGroupFragment = cache.readFragment({
                id: targetGroupId,
                fragment: GROUP_FRAGMENT,
              });
            } catch (e) {
              targetGroupFragment = null;
            }
            if (targetGroupFragment) {
              const { blocks } = targetGroupFragment;
              blocks.splice(destinationIndex, 0, movedBlock);
              cache.writeFragment({
                id: targetGroupId,
                fragment: GROUP_FRAGMENT,
                data: {
                  ...targetGroupFragment,
                  blocks,
                },
              });
            }
          }
        }
      },
    })
    .catch(
      showErrorTosterFactory(
        window.i18next.t(
          'BlockMutations-string--181-couldnt-move-block-please-try-again-later',
        ),
      ),
    );
};

export const isOptimisticBlockId = (blockId: string) =>
  blockId.indexOf(OPTIMISTIC_BLOCK_ID_PREFIX) === 0;

export const isOptimisticBlock = ({ id }: IBlock) => isOptimisticBlockId(id);
