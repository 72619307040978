import React from 'react';
import { CenteredLoader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import * as css from './TemplatesTableLoader.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';

export const TemplatesTableLoader = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      className={css.wrapper}
    >
      <CenteredLoader className={css.loader} />
      <Spacer factor={8} />
      <Type size="15px" color="greyDark">
        {t('pages.BotPage.ReengageWhatsappTab.templateModal.table.loader')}
      </Type>
    </Flex>
  );
};
