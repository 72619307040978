// eslint-disable-next-line
import { DataProxy } from 'apollo-cache';
import React from 'react';
import Downshift from 'downshift';
import gql from 'graphql-tag';
import { Mutation } from '@apollo/react-components';
import { MutationFetchResult, MutationFunction } from '@apollo/react-common';
import { ApolloError } from 'apollo-client';
import { complement, defaultTo, path, pipe, propEq, propOr } from 'ramda';
import { Manager, Popper, Reference } from 'react-popper';
import { Portal } from 'react-portal';
import { log } from 'cf-common/src/logger';
import { AdminFeatures } from '@utils/Data/Admin';
import { BotFeatures } from '@utils/Data/Bot';
import { rearrangeIndexes } from '@ui/SimpleCombobox';
import { ReactComponent as Dots } from '../../../../modern-ui/_deprecated/Icon/icons/3dot.svg';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../../modern-ui/_deprecated/Button';
import { Divider, Menubox, MenuItem } from '../../../../modern-ui/Menu';
import { Modal } from '../../../../modern-ui/Modal';
import { ScrollBox } from '../../../../modern-ui/ScrollBox/index';
import { GroupActionsDialog } from '../../../GroupActionsDialog';
import { ConfirmDeleteGroupDialog } from '../../../ConfirmDeleteGroupDialog';
import { OMNIBOX_SEQUENCES_TITLES_MAP_QUERY } from '../../../../components/user-filter-component';
import { downloadStats } from '../../Mutations/GroupMutations';

import { Permission } from '../../../../common/services/RoleService';
import { RoleConsumer } from '../../../../utils/Roles';
import { toaster } from '../../../../services/MessageService';
import { ServiceMessageType } from '../../../../modern-ui/ServiceMessage2';
import {
  BLOCKS_GROUP_FRAGMENT,
  BLOCKS_TITLES_QUERY,
} from '../../Mutations/GQL';
import { GraphDialog } from '../../../Graph';
import { SimpleBlocksGroupsFragment } from './@types/SimpleBlocksGroupsFragment';
import {
  DeleteGroupMutation,
  DeleteGroupMutation_deleteBlocksGroup as IDeleteGroupResult,
  DeleteGroupMutationVariables,
} from './@types/DeleteGroupMutation';
import {
  SyncedCloneGroupMutation,
  SyncedCloneGroupMutationVariables,
} from './@types/SyncedCloneGroupMutation';
import {
  CloneGroupMutation,
  CloneGroupMutation_cloneBlocksGroup as ICloneGroupResult,
  CloneGroupMutation_cloneBlocksGroup,
  CloneGroupMutationVariables,
} from './@types/CloneGroupMutation';
import * as css from './GroupContextMenu.css';
import { sendEvent } from '../../../../utils/Analytics';

enum DialogAction {
  clone = 'clone',
  syncClone = 'syncClone',
  delete = 'delete',
  divider = 'divider',
  buildGraph = 'buildGraph',
  export = 'export',
}

type MenuItemType = {
  type: DialogAction;
  index?: number;
  title?: React.ReactNode;
  comment?: React.ReactNode;
  label?: string;
};

const getMenuItems = (
  showPremium: boolean,
  showBlocksMap: boolean,
): MenuItemType[] => {
  const unindexedItems = [
    {
      type: DialogAction.clone,
      title: window.i18next.t('GroupContextMenu-string-2106-copy'),
      comment: window.i18next.t(
        'GroupContextMenu-string-7596-future-changes-to-the-original-group-will-not-be-synced-to-the-copied-versions',
      ),
    },
    ...(showPremium
      ? []
      : [
          {
            type: DialogAction.syncClone,
            title: window.i18next.t('GroupContextMenu-string--170-sync-clone'),
            comment: window.i18next.t(
              'GroupContextMenu-string-1310-future-changes-to-the-original-group-will-be-synced-to-all-cloned-versions',
            ),
          },
        ]),
    {
      type: DialogAction.export,
      title: window.i18next.t('GroupContextMenu-string-1977-export-to-csv'),
    },
    ...(showBlocksMap
      ? [
          {
            type: DialogAction.buildGraph,
            title: window.i18next.t(
              'GroupContextMenu-string-1135-show-blocks-map',
            ),
          },
        ]
      : []),
    {
      type: DialogAction.divider,
    },
    {
      type: DialogAction.delete,
      title: window.i18next.t('GroupContextMenu-string-2043-delete'),
    },
  ] as MenuItemType[];

  return rearrangeIndexes(
    unindexedItems,
    (item) => item.type === DialogAction.divider,
  );
};

const itemToString = pipe(
  defaultTo({} as MenuItemType),
  propOr('', 'title'),
) as (item: MenuItemType | null) => string;

export interface IGroupContextMenuProps {
  show: boolean;
  currentBotId: string;
  currentGroup: {
    id: string;
    title: string;
    builtin: boolean | null;
    sequence: boolean | null;
  };
  withSearch: boolean;
  synced: boolean;
  onGroupCloneDone?: (cloneGroupResult: ICloneGroupResult) => void;
  onGroupDeleteDone?: (deleteGroupResult: IDeleteGroupResult) => void;
}

interface IGroupContextMenuState {
  dropdownShow: boolean;
  dialogShow: boolean;
  dialogAction: DialogAction | undefined;
}

const CLONE_GROUP_MUTATION = gql`
  mutation CloneGroupMutation($botId: String!, $groupId: String!) {
    cloneBlocksGroup(botId: $botId, groupId: $groupId) {
      clonedGroup {
        ...blocksGroupFragment
      }
      targetBotId
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

const DELETE_GROUP_MUTATION = gql`
  mutation DeleteGroupMutation($groupId: String!) {
    deleteBlocksGroup(groupId: $groupId) {
      deletedGroupId
    }
  }
`;

const SYNCED_CLONE_GROUP_MUTATION = gql`
  mutation SyncedCloneGroupMutation($botId: String!, $groupId: String!) {
    syncedCloneBlocksGroup(botId: $botId, groupId: $groupId) {
      clonedGroup {
        ...blocksGroupFragment
      }
      targetBotId
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

const SIMPLE_BLOCKS_GROUPS_FRAGMENT = gql`
  fragment SimpleBlocksGroupsFragment on Bot {
    blocksGroups {
      id
    }
  }
`;

const updateCloneBlocksGroups = (
  cache: DataProxy,
  data: CloneGroupMutation_cloneBlocksGroup | null,
  botId: string,
) => {
  if (data) {
    const { clonedGroup } = data;
    let botWhichBlocksGroups: SimpleBlocksGroupsFragment | null = null;
    try {
      botWhichBlocksGroups = cache.readFragment({
        id: `Bot:${botId}`,
        fragment: SIMPLE_BLOCKS_GROUPS_FRAGMENT,
      });
    } catch (error) {
      log.warn({ error, msg: 'Exception during reading a fragment' });
    }

    if (botWhichBlocksGroups) {
      cache.writeFragment({
        id: `Bot:${botId}`,
        fragment: SIMPLE_BLOCKS_GROUPS_FRAGMENT,
        data: {
          ...botWhichBlocksGroups,
          blocksGroups: [
            ...botWhichBlocksGroups.blocksGroups,
            {
              id: clonedGroup.id,
              __typename: 'BlocksGroup',
            },
          ],
        },
      });
    }
  }
};

const updateDeleteBlocksGroups = (
  cache: DataProxy,
  data: DeleteGroupMutation | null,
  botId: string,
) => {
  if (data && data.deleteBlocksGroup) {
    const { deletedGroupId } = data.deleteBlocksGroup;
    let botWhichBlocksGroups: SimpleBlocksGroupsFragment | null = null;
    try {
      botWhichBlocksGroups = cache.readFragment({
        id: `Bot:${botId}`,
        fragment: SIMPLE_BLOCKS_GROUPS_FRAGMENT,
      });
    } catch (error) {
      log.warn({ error, msg: 'Exception during reading a fragment' });
    }

    if (botWhichBlocksGroups) {
      cache.writeFragment({
        id: `Bot:${botId}`,
        fragment: SIMPLE_BLOCKS_GROUPS_FRAGMENT,
        data: {
          ...botWhichBlocksGroups,
          blocksGroups: botWhichBlocksGroups.blocksGroups.filter(
            complement(propEq('id', deletedGroupId)),
          ),
        },
      });
    }
  }
};

export const UPGRADE_PAGE_FROM_GROUP_CONTEXT_MENU_PARAM = 'group-context-menu';

export class GroupContextMenu extends React.Component<
  IGroupContextMenuProps,
  IGroupContextMenuState
> {
  state = {
    dropdownShow: false,
    dialogShow: false,
    dialogAction: undefined,
  };

  sendAnalyticsEvent(action: string) {
    const { withSearch, currentGroup, synced } = this.props;

    return sendEvent({
      category: 'sequence or group',
      action,
      label: withSearch
        ? 'search'
        : currentGroup.sequence
        ? 'sequence'
        : 'group',
      propertyBag: {
        synced,
      },
    });
  }

  handleMenuSelect = (menuItem: MenuItemType) => {
    const {
      currentBotId,
      currentGroup: { id },
    } = this.props;
    if (menuItem.type === DialogAction.export) {
      this.sendAnalyticsEvent('export to csv');
      downloadStats({ botId: currentBotId, groupId: id });
    } else if (menuItem.type === DialogAction.delete) {
      this.sendAnalyticsEvent('delete click');

      this.setState({
        dialogAction: DialogAction.delete,
        dialogShow: true,
      });
    } else if (menuItem.type === DialogAction.syncClone) {
      this.sendAnalyticsEvent('click clone');

      this.setState({
        dialogAction: DialogAction.syncClone,
        dialogShow: true,
      });
    } else {
      this.setState({
        dialogAction: menuItem.type,
        dialogShow: true,
      });
    }
  };

  hideDialog = () => {
    this.setState({
      dialogShow: false,
      dialogAction: undefined,
    });
  };

  handleSyncedCloneGroupDone = async (
    resultPromise: Promise<MutationFetchResult<SyncedCloneGroupMutation> | void>,
  ) => {
    const result = await resultPromise;
    if (result && result.data) {
      toaster.show({
        payload: {
          message: window.i18next.t(
            'GroupContextMenu-string--726-group-successfully-cloned',
          ),
        },
      });
    }
  };

  handleCloneGroupDone = async (
    resultPromise: Promise<MutationFetchResult<CloneGroupMutation> | void>,
  ) => {
    const { onGroupCloneDone } = this.props;
    const result = await resultPromise;
    if (result && result.data) {
      if (onGroupCloneDone) {
        onGroupCloneDone(result.data.cloneBlocksGroup);
      }

      toaster.show({
        payload: {
          message: window.i18next.t(
            'GroupContextMenu-string--726-group-successfully-cloned',
          ),
        },
      });
    }
  };

  handleCloneGroupError = (e: ApolloError) => {
    const message = path(
      ['graphQLErrors', 0, 'extensions', 'response', 'body', 'errors', 0],
      e,
    );
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message:
          message ||
          window.i18next.t(
            'GroupContextMenu-string--278-couldnt-clone-group-please-try-again-later',
          ),
      },
    });
  };

  handleDeleteGroupError = () => {
    toaster.show({
      type: ServiceMessageType.error,
      payload: {
        message: window.i18next.t(
          'GroupContextMenu-string-2033-couldnt-delete-group-please-try-again-later',
        ),
      },
    });
  };

  render() {
    const { show, currentBotId, currentGroup } = this.props;
    const { dropdownShow, dialogAction, dialogShow } = this.state;
    const isContextMenuShow = Boolean(
      !currentGroup.builtin && (show || dropdownShow),
    );

    return (
      <React.Fragment>
        <RoleConsumer
          domain="groups"
          can={Permission.EDIT}
          groupId={currentGroup.id}
        >
          {isContextMenuShow && (
            <div className={css.box}>
              <BotFeatures botId={currentBotId}>
                {({ botFeatures }) => (
                  <AdminFeatures>
                    {({ adminFeatures }) => {
                      const shouldShowBlocksMap = !!adminFeatures?.blocksMap;

                      const shouldShowPremiumLabel =
                        !botFeatures?.sync_clone &&
                        !adminFeatures?.sync_clone &&
                        !adminFeatures?.premium;

                      return (
                        <Downshift
                          selectedItem={null}
                          itemToString={itemToString}
                          onChange={this.handleMenuSelect}
                          onStateChange={(change) => {
                            if ('isOpen' in change) {
                              this.setState({
                                dropdownShow: !!change.isOpen,
                              });
                            }
                          }}
                        >
                          {({
                            isOpen,
                            getToggleButtonProps,
                            getItemProps,
                            highlightedIndex,
                            getMenuProps,
                          }) => {
                            return (
                              <div>
                                <Manager>
                                  <Reference>
                                    {({ ref }) => (
                                      <div ref={ref}>
                                        <Button
                                          data-testid="automate-aside__group-menu-button"
                                          {...getToggleButtonProps()}
                                          intent={ButtonIntent.default}
                                          size={ButtonSize.s}
                                          tall
                                          renderIcon={() => <Dots />}
                                        />
                                      </div>
                                    )}
                                  </Reference>
                                  {isOpen || dropdownShow ? (
                                    <Portal>
                                      <Popper placement="bottom-start">
                                        {({ ref, style }) => {
                                          return (
                                            <div
                                              {...getMenuProps({
                                                ref,
                                                style,
                                                className: css.popover,
                                              })}
                                            >
                                              <Menubox>
                                                <ScrollBox>
                                                  {getMenuItems(
                                                    shouldShowPremiumLabel,
                                                    shouldShowBlocksMap,
                                                  ).map((item, index) =>
                                                    item.type ===
                                                    DialogAction.divider ? (
                                                      // eslint-disable-next-line
                                                      <Divider key={index} />
                                                    ) : (
                                                      <MenuItem
                                                        key={item.title}
                                                        {...getItemProps({
                                                          item,
                                                          index: item.index,
                                                        })}
                                                        active={
                                                          item.index ===
                                                          highlightedIndex
                                                        }
                                                        title={item.title}
                                                        wrapContent
                                                        comment={item.comment}
                                                      />
                                                    ),
                                                  )}
                                                </ScrollBox>
                                              </Menubox>
                                            </div>
                                          );
                                        }}
                                      </Popper>
                                    </Portal>
                                  ) : null}
                                </Manager>
                              </div>
                            );
                          }}
                        </Downshift>
                      );
                    }}
                  </AdminFeatures>
                )}
              </BotFeatures>
            </div>
          )}
        </RoleConsumer>
        {dialogShow && (
          <Modal onDismiss={this.hideDialog}>
            {dialogAction === DialogAction.clone && (
              <Mutation<CloneGroupMutation, CloneGroupMutationVariables>
                mutation={CLONE_GROUP_MUTATION}
                onError={this.handleCloneGroupError}
              >
                {(
                  cloneBlocksGroup: MutationFunction<
                    CloneGroupMutation,
                    CloneGroupMutationVariables
                  >,
                ) => (
                  <GroupActionsDialog
                    onSubmit={({ botId }) => {
                      this.sendAnalyticsEvent('copy');

                      this.handleCloneGroupDone(
                        cloneBlocksGroup({
                          variables: {
                            botId,
                            groupId: currentGroup.id,
                          },
                          update: (cache: DataProxy, { data = null }) => {
                            updateCloneBlocksGroups(
                              cache,
                              data && data.cloneBlocksGroup,
                              botId,
                            );
                          },
                          refetchQueries: currentGroup.sequence
                            ? [
                                {
                                  query: OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
                                  variables: { botId },
                                },
                              ]
                            : undefined,
                          awaitRefetchQueries: true,
                        }),
                      );
                      this.hideDialog();
                    }}
                    renderHeading={() =>
                      window.i18next.t(
                        'GroupContextMenu-string-2446-clone-group',
                      )
                    }
                    renderActionText={() =>
                      window.i18next.t('GroupContextMenu-string-6520-clone')
                    }
                    currentBotId={currentBotId}
                    onDismiss={this.hideDialog}
                  />
                )}
              </Mutation>
            )}
            {dialogAction === DialogAction.syncClone && (
              <Mutation<
                SyncedCloneGroupMutation,
                SyncedCloneGroupMutationVariables
              >
                mutation={SYNCED_CLONE_GROUP_MUTATION}
                onError={this.handleCloneGroupError}
              >
                {(
                  syncedCloneBlocksGroup: MutationFunction<
                    SyncedCloneGroupMutation,
                    SyncedCloneGroupMutationVariables
                  >,
                ) => (
                  <GroupActionsDialog
                    onSubmit={({ botId }) => {
                      this.sendAnalyticsEvent('sync clone');

                      this.handleSyncedCloneGroupDone(
                        syncedCloneBlocksGroup({
                          variables: {
                            botId,
                            groupId: currentGroup.id,
                          },
                          update: (cache: DataProxy, { data = null }) =>
                            updateCloneBlocksGroups(
                              cache,
                              data && data.syncedCloneBlocksGroup,
                              botId,
                            ),
                        }),
                      );
                      this.hideDialog();
                    }}
                    renderHeading={() =>
                      window.i18next.t(
                        'GroupContextMenu-string-1898-synced-clone-group',
                      )
                    }
                    renderActionText={() =>
                      window.i18next.t('GroupContextMenu-string-6520-clone')
                    }
                    currentBotId={currentBotId}
                    excludeCurrentBot
                    onDismiss={this.hideDialog}
                  />
                )}
              </Mutation>
            )}
            {dialogAction === DialogAction.delete && (
              <Mutation<DeleteGroupMutation, DeleteGroupMutationVariables>
                mutation={DELETE_GROUP_MUTATION}
                awaitRefetchQueries
                refetchQueries={[
                  {
                    query: BLOCKS_TITLES_QUERY,
                    variables: {
                      botId: currentBotId,
                    },
                  },
                  {
                    query: OMNIBOX_SEQUENCES_TITLES_MAP_QUERY,
                    variables: {
                      botId: currentBotId,
                    },
                  },
                ]}
                onError={this.handleDeleteGroupError}
              >
                {(
                  deleteBlocksGroup: MutationFunction<
                    DeleteGroupMutation,
                    DeleteGroupMutationVariables
                  >,
                ) => (
                  <ConfirmDeleteGroupDialog
                    onConfirmDelete={(groupId: string) => {
                      this.sendAnalyticsEvent('delete');

                      deleteBlocksGroup({
                        variables: {
                          groupId,
                        },
                        update: (cache: DataProxy, { data = null }) =>
                          updateDeleteBlocksGroups(cache, data, currentBotId),
                        optimisticResponse: {
                          deleteBlocksGroup: {
                            __typename: 'DeleteBlocksGroupResult',
                            deletedGroupId: groupId,
                          },
                        },
                      });
                      this.hideDialog();
                    }}
                    renderHeading={() =>
                      window.i18next.t(
                        'GroupContextMenu-string-1194-confirm-deletion',
                      )
                    }
                    renderDescription={() =>
                      window.i18next.t(
                        'GroupContextMenu-string--164-please-enter-delete-to-delete-this-group-this-is-needed-to-help-you-avoid-accidental-purging-of-the-whole-group',
                      )
                    }
                    renderActionText={() =>
                      window.i18next.t('GroupContextMenu-string-2043-delete')
                    }
                    confirmationText={window.i18next.t(
                      'modernComponents.Aside.GroupContextMenu.deleteConfirmationText',
                    )}
                    group={currentGroup}
                    onDismiss={this.hideDialog}
                  />
                )}
              </Mutation>
            )}
            {dialogAction === DialogAction.buildGraph && (
              <GraphDialog
                botId={currentBotId}
                groupId={currentGroup.id}
                groupTitle={currentGroup.title}
                onDismiss={this.hideDialog}
              />
            )}
          </Modal>
        )}
      </React.Fragment>
    );
  }
}
