import React from 'react';
import ng from 'angular';
import { Router } from 'react-router-dom';
import { react2angular } from 'react2angular';
import { ApolloProvider } from '@apollo/react-common';
import { globalHistory } from '@utils/Routing';
import client from '../../../common/services/ApolloService';
import { ButtonPopupTabs, ButtonPopupTabsProps } from './ButtonPopupTabs';

const ButtonPopupTabsWrapper: React.FC<ButtonPopupTabsProps> = (props) => (
  <Router history={globalHistory}>
    <ApolloProvider client={client}>
      <ButtonPopupTabs {...props} />
    </ApolloProvider>
  </Router>
);

export const ngButtonPopupTabs = ng
  .module('app.modernComponents.buttonPopupTabs', [])
  .component(
    'buttonPopupTabs',
    react2angular(
      ButtonPopupTabsWrapper,
      ['defaultTab', 'onChangeTab', 'showBuyTab', 'showPhoneCallTab'],
      [],
    ),
  ).name;
