import gql from 'graphql-tag';

export const BLOCK_LINKS_QUERY = gql`
  query BlockLinksQuery($botId: String!, $blockId: String!) {
    graph(input: { botId: $botId, blockIds: [$blockId] }) {
      botId
      edges {
        id
        source
        destination
        type
        title
        url
      }
      vertices {
        id
        title
        type
        block_type
      }
    }
  }
`;
