import React from 'react';
import { sendEvent } from '@utils/Analytics';
import { useUnpausePlan } from '@components/DialogsPricing/hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { UnpausePlanConfig } from '../hooks/useUnpausePlan';
import {
  SizeDependentButton,
  SizeDependentButtonProps,
} from '@pages/BotPage/HomeTab/components/BotSectionView/PlanButton/SizeDependentButton';

export interface UnpausePlanButtonProps
  extends UnpausePlanConfig,
    SizeDependentButtonProps {
  botId: string;
  pricingPlanId: string | undefined;
  disabled?: boolean;
}

export const UnpausePlanButton: React.FC<UnpausePlanButtonProps> = ({
  botId,
  from,
  tier,
  pricingPlanId,
  disabled,
  onClick,
  ...props
}) => {
  const { t } = useSafeTranslation();
  const { unpausePlan } = useUnpausePlan(botId, {
    from,
    tier,
  });

  const handleUnpause = (event: React.MouseEvent<HTMLButtonElement>) => {
    sendEvent({
      category: 'billing',
      action: 'click unpause plan',
      label: 'new dialogs pricing',
      propertyBag: { botId, pricingPlanId, tier },
    });
    unpausePlan();
    onClick?.(event);
  };

  return (
    <SizeDependentButton
      intent="primary"
      onClick={handleUnpause}
      disabled={disabled}
      {...props}
    >
      {t('pages.Billing.dialogues.unpauseButton')}
    </SizeDependentButton>
  );
};
