export interface Permissions {
  canEdit?: boolean;
  canView?: boolean;
}

export interface ActionBot<T> {
  admins: Array<T>;
}

export enum BotOptions {
  rename = 'rename',
  copy = 'copy',
  delete = 'delete',
  leave = 'leave',
}

export interface BotOptionsItemProps<T> {
  title: string;
  type: BotOptions;
  canBeShown: (bot: ActionBot<T>, permissions: Permissions) => boolean;
}

export interface Complitable<T> {
  onCompleted?: (data: T) => void;
}
