import React from 'react';
import { filterXSS } from 'xss';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { useLocation } from 'react-router-dom';

import { Button } from '@ui/Button';
import { Emoji } from '@ui/Emoji';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

import { FacebookPagesSetupStatus } from '../types';

import * as css from './FacebookPagesSetupFinished.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Trans } from 'react-i18next';
import { Anchor } from '@ui/Links';
import { FacebookPageBindError } from '@utils/FacebookPages/useBindBotToPage';
import { useFacebookLogin } from '../../../../pages/MultiSystemAuth/hooks/useFacebookLogin';

interface FacebookPagesSetupFinishedProps {
  status: FacebookPagesSetupStatus;
  pageId?: string;
  pageTitle?: string;
  error?: string;
  noWorriesHidden: boolean;
  onDone(): void;
  onGoBack(): void;
  onContactSupport(): void;
  hasProfileConflict: boolean;
}
export const FacebookPagesSetupFinished: React.FC<FacebookPagesSetupFinishedProps> =
  ({
    status,
    pageId,
    pageTitle,
    error,
    noWorriesHidden,
    onDone,
    onGoBack,
    onContactSupport,
    hasProfileConflict,
  }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const { t } = useSafeTranslation();

    const location = useLocation();
    const {
      facebookLogin,
      ready: fbLoginReady,
      inProgressFacebookLogin,
    } = useFacebookLogin({
      // fully reload the page and go to last bot if login with another account
      onAccountConnected: () => window.location.assign('/'),
      location,
      ignoreAutologin: true,
    });

    return (
      <>
        {status === FacebookPagesSetupStatus.succeed && (
          <Flex
            flexDirection="column"
            data-testid="facebook-pages__setup-succeed"
          >
            <Flex alignItems="center">
              <Type size="24px" weight="semibold">
                {t(
                  'modernComponents.FacebookPagesSetup.setupFinished.success.title',
                  { pageTitle: truncateWithEllipses(pageTitle || '', 26) },
                )}
                <Emoji emoji="🎉" size={24} className={css.tadam} />
              </Type>
            </Flex>
            <Flex className={css.description} alignItems="center">
              <Type size="15px">
                {t(
                  'modernComponents.FacebookPagesSetup.setupFinished.success.description',
                )}
              </Type>
            </Flex>
            <Flex justifyContent="flex-end">
              <Button
                intent="primary"
                onClick={onDone}
                data-testid="facebook-pages__setup-succeed__done-button"
              >
                {t(
                  'modernComponents.FacebookPagesSetup.setupFinished.success.done',
                )}
              </Button>
            </Flex>
          </Flex>
        )}
        {status === FacebookPagesSetupStatus.failed && (
          <Flex
            flexDirection="column"
            data-testid="facebook-pages__setup-failed"
          >
            <Flex>
              <Type size="24px" weight="semibold">
                {t(
                  'modernComponents.FacebookPagesSetup.setupFinished.fail.title',
                )}
              </Type>
            </Flex>
            <Flex
              className={cn(!isSmallScreenSize && css.description)}
              alignItems="center"
            >
              <Type size="15px">
                {error && error !== FacebookPageBindError.facebookPageNotAdmin && (
                  <span
                    // eslint-disable-next-line react/no-danger
                    dangerouslySetInnerHTML={{
                      __html: filterXSS(error),
                    }}
                  />
                )}
                {error === FacebookPageBindError.facebookPageNotAdmin && (
                  <Trans
                    t={t}
                    i18nKey="modernComponents.FacebookPagesSetup.setupFinished.fail.errors.facebookPageNotAdmin"
                  >
                    You are not an admin of this Facebook Page. Please ask the
                    Page admin to connect it to Chatfuel or to give you admin
                    access. To check who has the admin role, go to
                    <Anchor
                      intent="external"
                      target="_blank"
                      rel="noopener noreferrer"
                      href={`https://www.facebook.com/${pageId}/settings/?tab=admin_roles`}
                      hideArrow
                    >
                      Facebook Page Settings → Page Roles
                    </Anchor>
                    .
                  </Trans>
                )}
                {!noWorriesHidden && (
                  <>
                    <br />
                    {t(
                      'modernComponents.FacebookPagesSetup.setupFinished.fail.description',
                    )}
                  </>
                )}
              </Type>
            </Flex>
            <Flex
              justifyContent="space-between"
              className={isSmallScreenSize ? css.footer_mobile : css.footer}
            >
              <Button
                intent="secondary"
                onClick={onGoBack}
                icon={<Icon icon="leftArrow" size="12px" />}
                data-testid="facebook-pages__setup-failed__back-button"
              >
                {t(
                  'modernComponents.FacebookPagesSetup.setupFinished.fail.back',
                )}
              </Button>
              <div>
                {status === FacebookPagesSetupStatus.failed &&
                fbLoginReady &&
                hasProfileConflict ? (
                  <Button
                    className={cn(
                      css.loginButton,
                      isSmallScreenSize && css.contactButton_mobile,
                    )}
                    loading={inProgressFacebookLogin}
                    intent="secondary"
                    onClick={() => facebookLogin()}
                    data-testid="facebook-pages__setup-failed__contact-support-button"
                  >
                    {t(
                      'modernComponents.FacebookPagesSetup.setupFinished.fail.login',
                    )}
                  </Button>
                ) : null}

                <Button
                  className={cn(isSmallScreenSize && css.contactButton_mobile)}
                  intent="primary"
                  onClick={onContactSupport}
                  data-testid="facebook-pages__setup-failed__contact-support-button"
                >
                  {t(
                    'modernComponents.FacebookPagesSetup.setupFinished.fail.contact',
                  )}
                </Button>
              </div>
            </Flex>
          </Flex>
        )}
      </>
    );
  };
