import React from 'react';
import { CancelProTranslation } from '@components/CancelProDialog/CancelProTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { SpecialCharacters, Type } from '@ui/Type';
import botBuildingImg from './botBuilding.png';
import { Button } from '@ui/Button';
import { useChurnSurveyBuildBot } from '@utils/Data/Pricing/hooks';
import { CancelPlanBlock } from '../CancelPlanBlock/CancelPlanBlock';
import { DialogContainer } from '../DialogContainer/DialogContainer';
import { ChurnSurveyAdviceType } from '@globals';
import { sendEvent } from '@utils/Analytics';
import line from './line.svg';
import css from './ImproveBotDialog.css';

interface ImproveBotDialogProps {
  onClose: VoidFunction;
  onCancellPro: VoidFunction;
  botId: string;
  churnSurveyAdviceType: ChurnSurveyAdviceType;
}

export const ImproveBotDialog: React.FC<ImproveBotDialogProps> = ({
  onClose,
  onCancellPro,
  botId,
  churnSurveyAdviceType,
}) => {
  const { buildBot } = useChurnSurveyBuildBot();

  return (
    <DialogContainer onClose={onClose}>
      <Flex
        flexDirection="column"
        className={css.header}
        style={{ backgroundImage: `url(${line})` }}
      >
        <Type size="44px" weight="extrabold" color="accent1Semilight">
          <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.header" />
        </Type>
        <Spacer factor={2} />
        <Type size="24px" color="accent1Tertiary">
          <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.description" />
        </Type>
      </Flex>
      <Spacer factor={1} />
      <Flex flexDirection="column" className={css.contentContainer}>
        <img src={botBuildingImg} alt="bot-building" className={css.starSvg} />
        <Flex flexDirection="column" className={css.textContent}>
          <Type size="32px" weight="bold" color="accent1Light">
            <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.ourTeam" />
          </Type>
          <Spacer factor={5} />
          <Type size="18px" weight="medium" color="accent1Light">
            {SpecialCharacters.rhombus}{' '}
            <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.wellTalk" />
          </Type>
          <Spacer factor={3} />
          <Type size="18px" weight="medium" color="accent1Light">
            {SpecialCharacters.rhombus}{' '}
            <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.wellBuild" />
          </Type>
          <Spacer factor={3} />
          <Type size="18px" weight="medium" color="accent1Light">
            {SpecialCharacters.rhombus}{' '}
            <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.wellAlways" />
          </Type>
        </Flex>
        <Spacer factor={5} />
        <Button
          intent="secondary"
          onClick={() => {
            sendEvent({
              category: 'churn survey advice dialog',
              action: 'improve bot button click',
              propertyBag: {
                botId,
                churnSurveyAdviceType,
              },
            });
            onClose();
            buildBot();
          }}
          className={css.writeToUs}
        >
          <CancelProTranslation i18nKey="CancelPro.ImproveBotDialog.writeToUs" />
        </Button>
        <Spacer factor={5} />
      </Flex>
      <Spacer factor={5} />
      <CancelPlanBlock
        botId={botId}
        churnSurveyAdviceType={churnSurveyAdviceType}
        onCancellPro={onCancellPro}
      />
    </DialogContainer>
  );
};
