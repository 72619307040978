import React from 'react';
import { Type, TypeProps } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Circle } from './Circle';

export interface BotStatusViewProps extends TypeProps<HTMLSpanElement> {
  active: boolean;
}

export const BotStatusView: React.FC<BotStatusViewProps> = ({
  active,
  children,
  ...typeProps
}) => (
  <Flex alignItems="center">
    <Circle active={active} />
    <Type weight="medium" {...typeProps}>
      {children}
    </Type>
  </Flex>
);
