import ng from 'angular';
import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { ButtonPopupURLTab, ButtonPopupURLTabProps } from './ButtonPopupURLTab';
import { globalHistory } from '../../../utils/Routing';
import client from '../../../common/services/ApolloService';

const ButtonPopupURLTabWrapper: React.FC<ButtonPopupURLTabProps> = (props) => {
  return (
    <ApolloProvider client={client}>
      <Router history={globalHistory}>
        <ButtonPopupURLTab {...props} />
      </Router>
    </ApolloProvider>
  );
};

export const ngButtonPopupURLTab = ng
  .module('app.modernComponents.buttonPopupUrlTab', [])
  .component(
    'buttonPopupUrlTab',
    react2angular(
      ButtonPopupURLTabWrapper,
      [
        'botId',
        'counterId',
        'onChange',
        'attributeUpdates',
        'blockId',
        'url',
        'webviewHeightRatio',
        'error',
        'onBlocksTitlesChange',
        'allowChangeUserAttributes',
        'donePopup',
        'showAdditionalActions',
      ],
      [],
    ),
  ).name;
