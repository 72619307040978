import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { ReactComponent as AlertIcon } from '../assets/Alert.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';
import { TierType } from '@globals';

interface DowngradeCongratsDialogProps {
  tier: TierType;
  onDismiss(): void;
}

export const DowngradeCongratsDialog: React.FC<DowngradeCongratsDialogProps> =
  ({ tier, onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultPricingDialog
        title={t('pages.Pricing.dialogs.DowngradeCongratsDialog.title', {
          tier: getTierNameByType(tier),
        })}
        icon={<AlertIcon />}
        description={t(
          'pages.Pricing.dialogs.DowngradeCongratsDialog.description',
        )}
        button={
          <DefaultPricingBtn intent="secondary" onClick={onDismiss}>
            {t('pages.Pricing.dialogs.DowngradeCongratsDialog.button')}
          </DefaultPricingBtn>
        }
        onClose={onDismiss}
      />
    );
  };
