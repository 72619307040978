import { LeaveReason, Platform } from '@globals';
import memoize from 'lodash-es/memoize';
import Maybe from 'graphql/tsutils/Maybe';

const leaveReasonList: LeaveReason[] = [
  LeaveReason.wasnt_using_it_enough,
  LeaveReason.hard_to_use,
  LeaveReason.too_expensive,
  LeaveReason.temporary_cancellation,
  LeaveReason.many_technical_issues,
  LeaveReason.project_shut_down,
  LeaveReason.my_page_is_restricted,
  LeaveReason.other,
];

const leaveReasonListWhatsapp: LeaveReason[] = [
  LeaveReason.wasnt_using_it_enough,
  LeaveReason.hard_to_use,
  LeaveReason.too_expensive,
  LeaveReason.temporary_cancellation,
  LeaveReason.many_technical_issues,
  LeaveReason.project_shut_down,
  LeaveReason.other,
];

export const getLeaveReasonList = memoize(
  (allowedPlatforms: Maybe<Platform[]>) => {
    if (allowedPlatforms?.includes(Platform.whatsapp)) {
      return leaveReasonListWhatsapp;
    }
    return leaveReasonList;
  },
);
