import { Modal } from '@services/index';
import React from 'react';
import { ChooseTemplateDialog } from './ChooseTemplateDialog';

interface ShowChooseTemplateDialogParams {
  onSubmit: (
    templateId: string | undefined,
    managedTemplateId: string | undefined,
  ) => void;
  initTemplateId?: string;
}

export const showChooseTemplateDialog = ({
  initTemplateId,
  onSubmit,
}: ShowChooseTemplateDialogParams) => {
  Modal.show(({ close }) => (
    <ChooseTemplateDialog
      onSubmit={(templateId, managedTemplateId) => {
        onSubmit(templateId, managedTemplateId);
        close();
      }}
      initTemplateId={initTemplateId}
      onDismiss={close}
    />
  ));
};
