import React from 'react';
import { Button } from '@ui/Button';
import { Spacer } from '@ui/Spacer';
import { Dialog, DialogHeading } from '../../modern-ui/Dialog';
import {
  FlexDialogContent,
  FormLine,
  SubmitRow,
  CloseButton,
} from '../../modern-ui/Dialog/ActionDialogParts';
import { Flex } from '../../modern-ui/Flex';
import { Input } from '../../modern-ui/Input';
import * as css from './ConfirmDeleteGroupDialog.css';

interface IConfirmDeleteProps {
  confirmationText: string;
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  renderDescription: () => React.ReactNode;
  onDismiss: () => void;
  onConfirmDelete: (groupId: string) => void;
  group?: {
    id: string;
    title: string;
  };
}

interface IConfirmDeleteState {
  deleteGroupInputValue: string;
}

export class ConfirmDeleteGroupDialog extends React.Component<
  IConfirmDeleteProps,
  IConfirmDeleteState
> {
  state = {
    deleteGroupInputValue: '',
  };

  handleDeleteGroupInputValueChange = (
    event: React.FormEvent<HTMLInputElement>,
  ) => {
    this.setState({
      deleteGroupInputValue: event.currentTarget.value,
    });
  };

  handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (this.state.deleteGroupInputValue === this.props.confirmationText) {
      this.props.onConfirmDelete(this.props.group!.id);
    }
  };

  render() {
    const {
      confirmationText,
      renderHeading,
      renderActionText,
      onDismiss,
      renderDescription,
    } = this.props;
    return (
      <Dialog>
        <form onSubmit={this.handleSubmit}>
          <FlexDialogContent>
            <div>
              <DialogHeading>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <div style={{ paddingRight: 8 }}>{renderHeading()}</div>
                  {onDismiss ? (
                    <CloseButton
                      aria-label="close"
                      type="button"
                      onClick={onDismiss}
                    />
                  ) : null}
                </Flex>
              </DialogHeading>
              <FormLine>
                <label>
                  <div className={css.formLabel}>{renderDescription()}</div>
                </label>
                <Input
                  data-testid="group-delete-action__input"
                  value={this.state.deleteGroupInputValue}
                  onChange={this.handleDeleteGroupInputValueChange}
                  placeholder={confirmationText}
                  className="test-delete-input"
                  autoFocus
                />
              </FormLine>
            </div>
            <SubmitRow>
              <Flex justifyContent="flex-end">
                <Button
                  data-testid="group-delete-action__cancel-button"
                  intent="secondary"
                  onClick={this.props.onDismiss}
                >
                  {window.i18next.t(
                    'ConfirmDeleteGroupDialog-JSXText-2673-cancel',
                  )}
                </Button>
                <Spacer horizontalFactor={4} factor={0} />
                <Button
                  data-testid="group-delete-action__submit-button"
                  intent="red"
                  type="submit"
                  disabled={
                    this.state.deleteGroupInputValue !== confirmationText
                  }
                >
                  {renderActionText()}
                </Button>
              </Flex>
            </SubmitRow>
          </FlexDialogContent>
        </form>
      </Dialog>
    );
  }
}
