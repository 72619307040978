import React from 'react';
import { Flex } from '@ui/Flex';
import { IconButton } from '@ui/IconButton';
import css from './DialogContainer.css';

interface DialogContainerProps {
  onClose: VoidFunction;
}

export const DialogContainer: React.FC<DialogContainerProps> = ({
  children,
  onClose,
}) => {
  return (
    <Flex flexDirection="column" className={css.container}>
      <IconButton
        icon="close"
        color="white"
        className={css.closeButton}
        onClick={onClose}
      />
      {children}
    </Flex>
  );
};
