import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Icon, IconSize } from '@ui/_deprecated/Icon';
import { isWhiteLabelAgencyUser } from '@utils/WhiteLabelUtils';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Flex } from '@ui/Flex';
import { ReactComponent as InviteIcon } from './images/invite.svg';
import { ReactComponent as WhiteLabelUserIcon } from './images/white_label_user_pic.svg';
import Maybe from 'graphql/tsutils/Maybe';
import { ADMINS_ROLES_MAP } from '../../components/settings/admins/RolesList/AdminsRoles';
import { WhiteLabelRole } from '@globals';
import * as css from './AdminListItem.css';

export interface Admin {
  id: string;
  name: string;
  picture: Maybe<string>;
  white_label_role: Maybe<WhiteLabelRole>;
  roleId: Maybe<string>;
}

export interface AdminListItemProps {
  admin: Admin;
  currentUserId: Maybe<string>;
  textWidth: number;
}

export const AdminListItem: React.FC<AdminListItemProps> = ({
  admin,
  currentUserId,
  textWidth,
}) => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.adminInfo}>
      <div
        className={css.avaContainer}
        data-testid="admins__admin-picture"
      >
        {admin.picture ? (
          <Icon
            className={css.adminPicture}
            size={IconSize.large}
            url={admin.picture}
            rounded
          />
        ) : isWhiteLabelAgencyUser(admin) ? (
          <WhiteLabelUserIcon />
        ) : (
          <Icon size={IconSize.large} svg={InviteIcon} rounded />
        )}
      </div>
      <div>
        <Flex alignItems="center" flexWrap={false}>
          <TextEllipsis width={textWidth}>
            <span data-testid="admins__admin-name" className={css.adminName}>
              {admin.name}
            </span>
          </TextEllipsis>
          {admin.id === currentUserId && (
            <span
              className={css.you}
              data-testid="admins__current-admin-marker"
            >
              &nbsp;{t('components.settings.adminList.you')}
            </span>
          )}
        </Flex>
        <div
          className={css.role}
          data-testid="admins__admin-role"
        >
          {isWhiteLabelAgencyUser(admin)
            ? 'White-label user'
            : (ADMINS_ROLES_MAP.get(admin.roleId!)?.title as string)}
        </div>
      </div>
    </div>
  );
};
