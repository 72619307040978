import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type, SpecialCharacters } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { CancelProTranslation } from '../../CancelProTranslation';
import { ReactComponent as ArrowSvg } from './arrow.svg';
import pauseImg from './pause.png';
import { CancelPlanBlock } from '../CancelPlanBlock/CancelPlanBlock';
import { formatPrice } from '@utils/Format';
import { usePausePro } from '../../usePausePro';
import { BotInfo } from './BotInfo';
import { Translation } from '@translations';
import { DialogContainer } from '../DialogContainer/DialogContainer';
import { ChurnSurveyAdviceType } from '@globals';
import css from './DiscountProDialog.css';

interface DiscountProProps {
  totalUsers: number;
  monthlyUsers: number;
  totalDialogs: number;
  monthlyDialogs: number;
  pauseAmount: number;
  pauseCurrency: string;
  onClose: VoidFunction;
  onCancellPro: VoidFunction;
  botId: string;
  churnSurveyAdviceType: ChurnSurveyAdviceType;
}

const listTranslations: Translation[] = [
  'CancelPro.DowngradeNotAvailable.yourBotWillBeTurned',
  'CancelPro.DowngradeNotAvailable.allYourData',
  'CancelPro.DowngradeNotAvailable.launchYourBot',
];

export const DowngradeNotAvailable: React.FC<DiscountProProps> = ({
  totalUsers,
  monthlyUsers,
  totalDialogs,
  monthlyDialogs,
  pauseAmount,
  pauseCurrency,
  onClose,
  onCancellPro,
  botId,
  churnSurveyAdviceType,
}) => {
  const [pausePro, pauseProLoading] = usePausePro();

  return (
    <DialogContainer onClose={onClose}>
      <div className={css.header}>
        <Type size="44px" weight="extrabold" color="accent1Semilight">
          <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.header" />
        </Type>
      </div>
      <Spacer factor={1} />
      <BotInfo
        totalUsers={totalUsers}
        monthlyUsers={monthlyUsers}
        totalDialogs={totalDialogs}
        monthlyDialogs={monthlyDialogs}
      />
      <Spacer factor={1} />
      <Flex
        justifyContent="space-between"
        alignItems="center"
        className={css.descriptionBlock}
      >
        <Type
          size="18px"
          weight="medium"
          color="accent1Semilight"
          whiteSpace="pre-line"
        >
          <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.prettyGoodRight" />
        </Type>
        <ArrowSvg className={css.arrowImage} />
      </Flex>
      <Spacer factor={1} />
      <Flex flexDirection="column" className={css.pauseBlock}>
        <img src={pauseImg} alt="pause" className={css.pauseImage} />
        <Type
          size="32px"
          weight="bold"
          color="accent1Light"
          className={css.pauseText}
        >
          <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.pauseYourBotWhile" />
        </Type>
        <Spacer factor={4} />
        {listTranslations.map((textPath) => (
          <>
            <Type
              size="18px"
              weight="medium"
              color="accent1Light"
              className={css.pauseText}
            >
              {SpecialCharacters.rhombus}{' '}
              <CancelProTranslation i18nKey={textPath} />
            </Type>
            <Spacer factor={3} />
          </>
        ))}
        <Spacer factor={4} />
        <Button
          intent="secondary"
          className={css.pauseBotAction}
          textClassName={css.pauseBotActionText}
          onClick={async () => {
            sendEvent({
              category: 'churn survey advice dialog',
              action: 'pause pro button click',
              propertyBag: {
                botId,
                churnSurveyAdviceType,
              },
            });
            await pausePro();
            onClose();
          }}
          loading={pauseProLoading}
        >
          <CancelProTranslation
            i18nKey="CancelPro.DowngradeNotAvailable.pauseBot"
            options={{
              cost: pauseAmount
                ? formatPrice(pauseAmount, {
                    currency: pauseCurrency,
                    decimals: 0,
                  })
                : '',
            }}
          />
        </Button>
      </Flex>
      <Spacer factor={5} />
      <CancelPlanBlock
        botId={botId}
        churnSurveyAdviceType={churnSurveyAdviceType}
        onCancellPro={onCancellPro}
      />
    </DialogContainer>
  );
};
