import React from 'react';

import { Type } from '../../../../modern-ui/Type';
import { Anchor } from '../../../../modern-ui/Links';

export const HelperMessage: React.FC<{ link: string }> = ({
  children,
  link,
}) => (
  <>
    <Type size="15px_DEPRECATED">{children}</Type>
    <Anchor
      intent="external"
      hideArrow
      href={link}
      target="_blank"
      rel="noopener noreferrer"
    >
      {window.i18next.t('HelperMessage-JSXText-1076-learn-more')}
    </Anchor>
  </>
);
