import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Translation } from '@translations';

import { Modal } from '@services/.';

import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { ActivatePageDiscountExtendedStatus } from '../../types';

import { ActivatePageDiscountStatus } from '@globals';

import { ReactComponent as AttentionImage } from './images/attention.svg';

import * as css from './ActivatePageDiscountFinishedDialog.css';

interface ActivatePageDiscountFinishedDialogProps {
  status?:
    | ActivatePageDiscountStatus
    | ActivatePageDiscountExtendedStatus
    | null;
  onRetry?: () => void;
  onClose: () => void;
}
export const ActivatePageDiscountFinishedDialog: React.FC<ActivatePageDiscountFinishedDialogProps> =
  ({ status, onRetry, onClose }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultDialog header={<></>} onDismiss={onClose}>
        <Flex
          className={css.container}
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <AttentionImage />
          <Spacer factor={6} />
          <Type size="24px" weight="semibold" as="h3">
            {t(`pages.ActivateDiscount.dialog.${status}.header` as Translation)}
          </Type>
          <Spacer factor={4} />
          <Type size="15px">
            {t(
              `pages.ActivateDiscount.dialog.${status}.description` as Translation,
            )}
          </Type>
          <Spacer factor={6} />
          {status === ActivatePageDiscountExtendedStatus.is_failed ? (
            <Button intent="primary" onClick={onRetry}>
              {t('pages.ActivateDiscount.dialog.retryButton')}
            </Button>
          ) : (
            <Button intent="primary" onClick={onClose}>
              {t('pages.ActivateDiscount.dialog.closeButton')}
            </Button>
          )}
        </Flex>
      </DefaultDialog>
    );
  };

export const useActivatePageDiscountFinishedDialog = () => {
  const showActivatePageDiscountFinishedDialog = ({
    status,
    onRetry,
  }: Omit<ActivatePageDiscountFinishedDialogProps, 'onClose'>) => {
    if (status) {
      Modal.show(({ close }) => (
        <ActivatePageDiscountFinishedDialog
          status={status}
          onRetry={onRetry}
          onClose={close}
        />
      ));
    }
  };
  return { showActivatePageDiscountFinishedDialog };
};
