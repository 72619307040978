import React, { useState } from 'react';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import * as css from './CopyBotDialog.css';
import { BOTS_LIST_QUERY } from '../../common/services/GQLqueries/BotGQService.const';
import { BotsListQuery } from '../../common/services/GQLqueries/@types/BotsListQuery';

import { toaster } from '../../services/MessageService';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';

import { DefaultDialog } from '../../modern-ui/Dialog';
import { Input } from '../../modern-ui/Input/Input';

import { useSafeTranslation } from '@utils/useSafeTranslation';
import { addToWorkspacesBotsCache } from '@utils/Data/Workspaces/addToWorkspacesBotsCache';
import Maybe from 'graphql/tsutils/Maybe';
import { Button } from '@ui/Button';
import { COPY_BOT_ASYNC_MUTATION } from '@utils/Data/Bot/mutations';
import {
  CopyBotMutation,
  CopyBotMutationVariables,
} from '@utils/Data/Bot/@types/CopyBotMutation';
import { mapQuery } from '@utils/GQL/mapQuery';

export interface CopyBotDialogProps {
  title: string;
  idFrom: string;
  copyToWorkspace: boolean;
  workspaceId?: Maybe<string>;
  onClose: () => void;
  onCompleted?: (data: CopyBotMutation) => void;
  mobileAdaptive?: boolean;
}

function showCopyError(error: Error) {
  log.warn({ error, msg: 'Copy bot' });
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: window.i18next.t(
        'CopyBotDialog-string-4204-couldnt-copy-bot-please-try-again-later',
      ),
    },
  });
}

export function CopyBotDialog(props: CopyBotDialogProps) {
  const {
    title,
    idFrom,
    onClose,
    onCompleted,
    mobileAdaptive,
    workspaceId,
    copyToWorkspace,
  } = props;

  const { t } = useSafeTranslation();
  const [cloneBotAsync, { loading }] = useMutation<
    CopyBotMutation,
    CopyBotMutationVariables
  >(COPY_BOT_ASYNC_MUTATION, {
    onCompleted(data) {
      onCompleted?.(data);
      onClose();
    },
    onError(error) {
      showCopyError(error);
      onClose();
    },
  });
  const [newTitle, updateTitle] = useState(title);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    updateTitle(event.target.value);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();

    if (loading) {
      return;
    }

    cloneBotAsync({
      variables: {
        title: newTitle,
        idFrom,
        workspaceId: copyToWorkspace ? workspaceId : null,
      },
      update: (store, mutationResult) => {
        if (!mutationResult.data) {
          return;
        }

        mapQuery<BotsListQuery>(store, { query: BOTS_LIST_QUERY }, (data) => ({
          ...data,
          bots: [mutationResult.data!.cloneBotAsync!, ...data.bots],
        }));

        const { workspace_id } = mutationResult.data.cloneBotAsync;
        addToWorkspacesBotsCache(
          mutationResult.data.cloneBotAsync,
          workspace_id,
          store,
        );
      },
    });
  }

  const isCopyButtonDisabled = newTitle.trim().length === 0;

  return (
    <DefaultDialog
      header={t('CopyBotDialog-JSXText--135-copy-chatbot')}
      mobileAdaptive={mobileAdaptive}
      onDismiss={onClose}
    >
      <form noValidate onSubmit={handleSubmit}>
        <p className={css.paragraph}>
          {t('CopyBotDialog-JSXText--163-choose-destination-chatbot-name')}
        </p>
        <Input
          autoFocus
          value={newTitle}
          placeholder={title}
          onChange={handleChange}
        />
        <div className={css.buttons}>
          <Button
            loading={loading}
            onClick={onClose}
            type="button"
            className={css.button}
            intent="secondary"
          >
            {t('CopyBotDialog-JSXText-2673-cancel')}
          </Button>
          <Button
            loading={loading}
            disabled={isCopyButtonDisabled}
            type="submit"
            className={css.button}
            intent="primary"
          >
            {t('CopyBotDialog-JSXText-6076-copy')}
          </Button>
        </div>
      </form>
    </DefaultDialog>
  );
}
