import React from 'react';
import * as ng from 'angular';
import { react2angular } from 'react2angular';
import { ApolloProvider } from '@apollo/react-common';
import { Router } from 'react-router-dom';
import { BlocksSelectorData } from '@components/BlocksSelector2';
import { BlocksSelectorDataQuery_bot_flowGroupsSuggest_flows } from '@components/BlocksSelector2/@types/BlocksSelectorDataQuery';

import { BlockLinks, BlockLinksProps } from './BlockLinks';
import client from '../../common/services/ApolloService';

import { globalHistory } from '../../utils/Routing';

const BlockLinksWrapper: React.FC<BlockLinksProps> = (props) => (
  <Router history={globalHistory}>
    <ApolloProvider client={client}>
      <BlocksSelectorData botId={props.botId}>
        {({ flowsGroups }) => {
          const flowsInfo = flowsGroups
            .flatMap(({ flows }) => flows)
            // TS cannot infer that array was filtered
            .filter(
              Boolean,
            ) as BlocksSelectorDataQuery_bot_flowGroupsSuggest_flows[];
          return <BlockLinks {...props} flowsInfo={flowsInfo} />;
        }}
      </BlocksSelectorData>
    </ApolloProvider>
  </Router>
);

export const ngBlockLinks = ng
  .module('app.modernComponents.blockLinks', [])
  .component(
    'blockLinks',
    react2angular(
      BlockLinksWrapper,
      ['botId', 'blockId', 'blockTitle', 'onLinkLabelClick'],
      [],
    ),
  ).name;
