import React from 'react';
import { BotFeatures } from '@utils/Data/Bot';
import { Button } from '@ui/Button';
import { Type } from '../../../../modern-ui/Type';
import { Banner, Text } from '../Banner';
import { redirect } from '../../../../utils/UrlUtils';
import { sendEvent } from '../../../../utils/Analytics';
import { getBillingPageUrl } from '@utils/Routing';

interface NeedProBannerProps {
  botId: string;
}

export const NeedProBanner: React.FC<NeedProBannerProps> = ({ botId }) => (
  <Banner>
    {() => (
      <>
        <Text>
          <Type as="p" size="15px_DEPRECATED">
            {window.i18next.t(
              'NeedProBanner-JSXText--106-sync-audience-segments-from-chatfuel-to-facebook-ads-manager',
            )}
          </Type>
          <Type as="p" size="15px_DEPRECATED">
            {window.i18next.t(
              'NeedProBanner-JSXText--106-a-pro-plan-subscription-is-required-for-this-feature',
            )}
          </Type>
        </Text>
        <div>
          <BotFeatures botId={botId}>
            {({ botFeatures, botFeaturesLoading }) => (
              <Button
                disabled={botFeaturesLoading}
                onClick={() => {
                  sendEvent(
                    {
                      category: 'people tab',
                      action: 'upgrade to pro',
                      label: 'segments sync',
                    },
                    true,
                  );
                  // TODO routing: need fix
                  redirect(
                    getBillingPageUrl(
                      botId,
                      !!botFeatures?.dialogs_pricing_enabled,
                    ),
                  );
                }}
              >
                {window.i18next.t('NeedProBanner-JSXText-1227-upgrade-to-pro')}
              </Button>
            )}
          </BotFeatures>
        </div>
      </>
    )}
  </Banner>
);
