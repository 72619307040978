import React from 'react';
import cn from 'classnames';
import { Type } from '@ui/Type';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import * as css from './BotTab.css';

/*
 we can need add old rcol and tabName class
 to save selectors styles in angular parts
*/
interface BotTabProps extends React.HTMLProps<HTMLDivElement> {
  tabName?: string;
  rcol?: boolean;
}

export const BotTabAngular: React.FC<BotTabProps> = ({
  children,
  style,
  tabName,
  rcol,
  ...props
}) => {
  return (
    <section className={tabName} {...props}>
      <div
        className={cn(css.innerDashboardContent, rcol && 'rcol')}
        style={style}
      >
        {children}
      </div>
    </section>
  );
};

interface BotTabCenteredProps {
  className?: string;
  containerClassName?: string;
}

export const BotTabCentered: React.FC<BotTabCenteredProps> = ({
  children,
  className,
  containerClassName,
}) => (
  <Flex
    flexDirection="column"
    alignItems="center"
    className={cn(css.botTabCentered, containerClassName)}
  >
    <Flex
      flexDirection="column"
      className={cn(css.botTabCenteredWrapper, className)}
    >
      {children}
    </Flex>
  </Flex>
);

export const BotTabHeader: React.FC = ({ children }) => {
  return (
    <div className={css.botTabHeader}>
      {children}
      <Spacer factor={8} />
    </div>
  );
};

export const BotTabTitle: React.FC = ({ children }) => {
  return (
    <Type as="h4" size="24px">
      {children}
    </Type>
  );
};

export const BotTabSubtitle: React.FC = ({ children }) => {
  return (
    <Type as="div" size="15px_DEPRECATED" color="greyDark">
      {children}
    </Type>
  );
};

interface BotTabPlaceholderProps {
  icon?: JSX.Element;
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  renderFooter?: () => JSX.Element;
}

export const BotTabPlaceholder: React.FC<BotTabPlaceholderProps> = ({
  icon,
  title,
  subtitle,
  renderFooter,
}) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
      className={css.botTabPlaceholder}
    >
      {icon}
      <Spacer factor={6} />
      <Type size="24px" weight="semibold">
        {title}
      </Type>
      <Spacer factor={2} />
      <Type size="15px_DEPRECATED" color="greyDark" align="center">
        {subtitle}
      </Type>
      {renderFooter && (
        <>
          <Spacer factor={4} />
          {renderFooter()}
        </>
      )}
    </Flex>
  );
};
