import React, { useEffect } from 'react';
import cn from 'classnames';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useDialoguesPricing } from '@utils/Data/Pricing';
import { usePausePricing } from '@utils/Data/Pricing/hooks';
import { useCurrentBotId } from '@utils/Routing';
import { CancelProTranslation } from '../CancelProTranslation';
import { ReactComponent as PauseSvg } from './pause.svg';
import css from './PauseProDialog.css';
import { LoadingPlaceholder } from '@ui/LoadingPlaceholder';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface PauseProProps {
  onPaused(): void;
  onCancel(): void;
  onClose(): void;
  cancelProButton?: boolean;
}

export const PauseProDialog: React.FC<PauseProProps> = ({
  onPaused,
  onCancel,
  onClose,
  cancelProButton,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const botId = useCurrentBotId();

  const { pricing } = useDialoguesPricing();

  const { pausePrice, loading } = usePausePricing(botId, pricing?.currency);

  useEffect(() => {
    sendEvent({
      category: 'churn survey',
      action: 'show pause pro dialog',
      label: 'pause pro dialog',
    });
  }, []);

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      className={cn(css.dialog, isSmallScreenSize && css.mobile)}
      header={null}
      onDismiss={() => {
        onClose();
        sendEvent({
          category: 'churn survey',
          action: 'close pause pro dialog',
          label: 'pause pro dialog',
        });
      }}
      footer={
        <Flex
          justifyContent="space-between"
          flexWrap
          className={cn(css.footer, isSmallScreenSize && css.mobile)}
        >
          <Button
            intent="transparent"
            data-testid="churn-survey__pause-dialog__cancel-pro-button"
            className={css.cancel}
            onClick={() => {
              onCancel();
              sendEvent({
                category: 'churn survey',
                action: cancelProButton
                  ? 'click cancel anyway'
                  : 'click cancel',
                label: 'pause pro dialog',
              });
            }}
          >
            <CancelProTranslation i18nKey="CancelPro.PausePro.cancel" />
          </Button>
          <Button
            className={css.pause}
            data-testid="churn-survey__pause-dialog__pause-pro-button"
            loading={loading}
            intent="secondary"
            onClick={() => {
              onPaused();
              sendEvent({
                category: 'churn survey',
                action: 'click pause bot',
                label: 'pause pro dialog',
              });
            }}
          >
            <CancelProTranslation
              i18nKey="CancelPro.PausePro.pause"
              options={{ pausePrice }}
            />
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <PauseSvg />
        <Spacer factor={4} />
        <Type size="24px" weight="semibold" color="white">
          <CancelProTranslation
            i18nKey={
              cancelProButton
                ? 'CancelPro.PausePro.header'
                : 'CancelPro.PausePro.pausingPlan'
            }
          />
        </Type>
        <Spacer factor={2} />
        <Type size="15px" weight="medium" color="white">
          {loading ? (
            <>
              <LoadingPlaceholder className={css.loadingPlaceholder} />
              <LoadingPlaceholder className={css.loadingPlaceholder} />
              <LoadingPlaceholder className={css.loadingPlaceholder} />
            </>
          ) : (
            <CancelProTranslation
              i18nKey="CancelPro.PausePro.description"
              options={{ pausePrice }}
            />
          )}
        </Type>
      </Flex>
    </DefaultDialog>
  );
};
