import React, { useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { sendEvent } from '@utils/Analytics';
import { BotStatus } from '@utils/Bot';
import { FLOW_FEATURES } from '@utils/Data/Flow/Aggregated';
import { BindBotToPageMutation_bindBotToPage_verifiedPermissions } from '@utils/FacebookPages/@types/BindBotToPageMutation';
import { RoleProvider } from '@utils/Roles';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { TutorialLinks } from '@ui/Links';
import { Spacer } from '@ui/Spacer';

import { FacebookPagesSetup } from './components/FacebookPagesSetup';

import {
  getConnectPageEventPostfix,
  getConnectPageUrlParams,
  resetConnectPageUrl,
  setConnectPageUrl,
} from './helpers';
import {
  ConnectPageField,
  ConnectPageOrigin,
  ConnectPageUrlParams,
} from './types';

import * as css from './ConnectPageDialog.css';

export interface OnPageConnectedParams {
  status: BotStatus;
  pageId?: string | null;
  verifiedPermissions?: BindBotToPageMutation_bindBotToPage_verifiedPermissions[];
  close?: () => void;
  urlParams?: ConnectPageUrlParams;
}

export interface ConnectPageDialogProps {
  botId: string;
  urlParams?: ConnectPageUrlParams;
  onClose(isTested?: boolean): void;
  onPageConnected?(params: OnPageConnectedParams): void;
  onAccountConnected?(): void;
  header?: React.ReactNode;
  eventData?: {
    actionPostfix?: string;
    propertyBag?: Record<any, any>;
  };
  dontShowSuccessState?: boolean;
}

export type ConnectPageDialogPropsLite = Omit<
  ConnectPageDialogProps,
  'onClose'
> & {
  onClose?: ConnectPageDialogProps['onClose'];
};

export const ConnectPageDialog: React.FC<ConnectPageDialogProps> = ({
  botId,
  urlParams,
  onClose,
  onPageConnected,
  onAccountConnected,
  eventData,
  dontShowSuccessState,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const [connectPageUrlParams, setConnectPageUrlParams] =
    useState<ConnectPageUrlParams | null>(null);
  const {
    [ConnectPageField.origin]: origin,
    [ConnectPageField.name]: label,
    [ConnectPageField.flowId]: flowId,
    [ConnectPageField.blockId]: blockId,
    [ConnectPageField.cardId]: cardId,
    [ConnectPageField.tierId]: tierId,
  } = connectPageUrlParams ?? {
    [ConnectPageField.origin]: ConnectPageOrigin.default,
  };
  const eventPostfix =
    eventData?.actionPostfix ?? getConnectPageEventPostfix(origin);

  useEffect(() => {
    if (!connectPageUrlParams) {
      if (urlParams) {
        setConnectPageUrl(urlParams);
      }
      const extractedUrlParams = getConnectPageUrlParams(urlParams);
      setConnectPageUrl(extractedUrlParams);
      const {
        [ConnectPageField.flowId]: flowId,
        [ConnectPageField.blockId]: blockId,
        [ConnectPageField.cardId]: cardId,
        [ConnectPageField.tierId]: tierId,
      } = extractedUrlParams;

      setConnectPageUrlParams(extractedUrlParams);

      sendEvent({
        category: 'connect page modal',
        action: `show modal${eventPostfix}`,
        propertyBag:
          flowId || blockId || cardId || tierId
            ? { flowId, blockId, cardId, tierId, ...eventData?.propertyBag }
            : eventData?.propertyBag,
      });
    }
  }, [connectPageUrlParams, eventData, eventPostfix, urlParams]);

  return (
    <RoleProvider botId={botId}>
      <DefaultDialog
        mobileAdaptive={isSmallScreenSize}
        className={isSmallScreenSize ? css.dialog_mobile : css.dialog}
        header={
          <Flex alignItems="center">
            <Icon icon="facebook" size="30px" />
            <Spacer horizontalFactor={6} />
            <TutorialLinks
              variant="default"
              linkItems={[
                {
                  text: t(
                    'modernComponents.ConnectPageDialog.default.readInstructions',
                  ),
                  href: t(
                    'common.helpDocLinks.problems_with_facebook_page_connection',
                  ),
                  maxWidth: 200,
                },
              ]}
            />
          </Flex>
        }
        onDismiss={() => {
          sendEvent({
            category: 'connect page modal',
            action: `close connect page${eventPostfix}`,
            label,
            propertyBag: { flowId, blockId, cardId, tierId },
          });
          onClose?.();
        }}
      >
        <FacebookPagesSetup
          botId={botId}
          requestPermissionOnBind={FLOW_FEATURES}
          origin={origin}
          onPageConnected={(status, pageId, verifiedPermissions) => {
            onPageConnected?.({ status, pageId, verifiedPermissions });
            resetConnectPageUrl();
          }}
          onAccountConnected={onAccountConnected}
          onConnectedBotClick={onClose}
          onDone={onClose}
          dontShowSuccessState={dontShowSuccessState}
        />
      </DefaultDialog>
    </RoleProvider>
  );
};
