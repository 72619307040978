import React from 'react';
import { useToaster } from '@ui/Toaster';
import {
  ActivateDiscountMutation,
  ActivateDiscountMutationVariables,
} from '@utils/Data/Pricing/@types/ActivateDiscountMutation';
import { useMutation } from 'react-apollo';
import {
  ACTIVATE_DISCOUNT_MUTATION,
  GET_CONFIGURE_TAB_BOT_DATA,
} from '@utils/Data/Pricing';
import { ApolloError } from 'apollo-client';
import { ToasterConfig } from '@ui/Toaster/Toaster';
import { CancelProTranslation } from './CancelProTranslation';
import { useCurrentBotId } from '@utils/Routing';
import { log } from 'cf-common/src/logger';

enum ActivateDiscountErrors {
  USER_ALREADY_USED_DISCOUNT = 'USER_ALREADY_USED_DISCOUNT',
  PAGE_ALREADY_USED_DISCOUNT = 'PAGE_ALREADY_USED_DISCOUNT',
  PRICING_PLAN_DOES_NOT_SUPPORT_DISCOUNTS = 'PRICING_PLAN_DOES_NOT_SUPPORT_DISCOUNTS',
}

const getDiscountErrorMessage = (error?: ApolloError): JSX.Element => {
  const errorWithMessage = error?.graphQLErrors.find(
    (gqlError) => gqlError.message,
  );
  const errorMessage = errorWithMessage?.message;

  switch (errorMessage) {
    case ActivateDiscountErrors.USER_ALREADY_USED_DISCOUNT:
      return (
        <CancelProTranslation i18nKey="CancelPro.errors.userAlreadyUsedDiscount" />
      );
    case ActivateDiscountErrors.PAGE_ALREADY_USED_DISCOUNT:
      return (
        <CancelProTranslation i18nKey="CancelPro.errors.pageAlreadyUsedDiscount" />
      );
    case ActivateDiscountErrors.PRICING_PLAN_DOES_NOT_SUPPORT_DISCOUNTS:
      return (
        <CancelProTranslation i18nKey="CancelPro.errors.pricingPlanDoesntSupportDiscount" />
      );
    default:
      return <CancelProTranslation i18nKey="CancelPro.errors.couldNotApply" />;
  }
};

const getDiscountError = (error?: ApolloError): ToasterConfig => ({
  type: 'error',
  content: getDiscountErrorMessage(error),
  timeout: 5000,
  closeButton: true,
});

export const useDiscountPro = () => {
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();

  const [activateDiscount, { loading }] = useMutation<
    ActivateDiscountMutation,
    ActivateDiscountMutationVariables
  >(ACTIVATE_DISCOUNT_MUTATION, {
    onError: (error) => {
      log.error({
        error,
        msg: 'activate discount pro',
      });
      addToaster(getDiscountError(error));
    },
    onCompleted: (isDiscountActivated) => {
      if (!isDiscountActivated) addToaster(getDiscountError());
    },
    refetchQueries: [
      { query: GET_CONFIGURE_TAB_BOT_DATA, variables: { botId } },
    ],
  });

  const handleDiscountClick = () => {
    if (botId) return activateDiscount({ variables: { botId } });
    return Promise.reject();
  };

  return [handleDiscountClick, loading] as const;
};
