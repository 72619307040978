import React from 'react';
import Downshift, { GetToggleButtonPropsOptions } from 'downshift';
import { Manager, Popper, Reference } from 'react-popper';
import { Portal } from 'react-portal';
import { defaultTo, pipe, propOr } from 'ramda';
import { Divider, Menubox, MenuItem } from '../../../modern-ui/Menu';
import { RoleConsumer } from '../../../utils/Roles';
import { Permission } from '../../../common/services/RoleService';
import ScrollBox from '../../../modern-ui/ScrollBox/ScrollBox';
import { GroupType } from '../Mutations/GroupMutations';
import * as css from './AddGroup.css';

export interface IAddGroupDropDownProps {
  anchorElementRender: (
    getToggleButtonProps: (options?: GetToggleButtonPropsOptions) => any,
  ) => React.ReactNode;
  onSelect: (groupType: GroupType) => void;
  onOpenStateChange?: (isOpen: boolean) => void;
}

enum MenuItemEnum {
  group = 'Group',
  sequence = 'Sequence',
  divider = 'divider',
}

type MenuItemType = {
  type: MenuItemEnum;
  actionIndex?: number;
};

const menuItems: MenuItemType[] = [
  {
    actionIndex: 0,
    type: MenuItemEnum.group,
  },
  {
    type: MenuItemEnum.divider,
  },
  {
    actionIndex: 1,
    type: MenuItemEnum.sequence,
  },
];

const itemToString = pipe(
  defaultTo({} as MenuItemType),
  propOr('', 'type'),
) as (item: MenuItemEnum | null) => string;

export const AddGroupDropDown: React.SFC<IAddGroupDropDownProps> = ({
  anchorElementRender,
  onSelect,
  onOpenStateChange,
}) => (
  <RoleConsumer domain="groups" can={Permission.EDIT}>
    <div>
      <Downshift
        selectedItem={null}
        onChange={(item: MenuItemType) =>
          onSelect(item.type.toString() as GroupType)
        }
        itemToString={itemToString}
        onStateChange={(change) => {
          if ('isOpen' in change && onOpenStateChange) {
            onOpenStateChange(!!change.isOpen);
          }
        }}
      >
        {({
          isOpen,
          getToggleButtonProps,
          getItemProps,
          highlightedIndex,
          getMenuProps,
        }) => {
          return (
            <div>
              <Manager>
                <Reference>
                  {({ ref }) => (
                    <div ref={ref}>
                      {anchorElementRender(getToggleButtonProps)}
                    </div>
                  )}
                </Reference>
                {isOpen ? (
                  <Portal>
                    <Popper placement="bottom-start">
                      {({ ref, style }) => (
                        <div
                          {...getMenuProps({
                            ref,
                            style,
                            className: css.popover,
                          })}
                        >
                          <Menubox>
                            <ScrollBox>
                              {menuItems.map((item, index) =>
                                item.type === MenuItemEnum.divider ? (
                                  // eslint-disable-next-line react/no-array-index-key
                                  <Divider key={index} />
                                ) : (
                                  <MenuItem
                                    key={item.type}
                                    {...getItemProps({ item })}
                                    active={
                                      item.actionIndex === highlightedIndex
                                    }
                                    title={item.type}
                                  />
                                ),
                              )}
                            </ScrollBox>
                          </Menubox>
                        </div>
                      )}
                    </Popper>
                  </Portal>
                ) : null}
              </Manager>
            </div>
          );
        }}
      </Downshift>
    </div>
  </RoleConsumer>
);
