import React, { useEffect, useState } from 'react';
import gql from 'graphql-tag';
import { log } from 'cf-common/src/logger';
import { uniq, sortBy, prop } from 'ramda';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { EXCLUDE_WHITELISTING_DOMAIN } from '@utils/FacebookPages/addCurrentDomainToWhitelistIfNeed';
import {
  extractGQLErrorData,
  getRequestIdFromApolloError,
} from '@utils/GQL/utils';
import { useCurrentBotId } from '@utils/Routing';
import { Input } from '@ui/Input';
import { defaultItemToString, Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { Messages, toaster } from '../../services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import {
  DomainsWhitelistQuery,
  DomainsWhitelistQueryVariables,
} from './@types/DomainsWhitelistQuery';
import {
  UpdateDomainsWhitelistMutation,
  UpdateDomainsWhitelistMutationVariables,
} from './@types/UpdateDomainsWhitelistMutation';
import { MenuItem } from '@ui/Menu';
import { TextEllipsis } from '@ui/TextEllipsis';
import { CenteredLoader } from '@ui/Loader';

const DOMAINS_WHITELIST_QUERY = gql`
  query DomainsWhitelistQuery($botId: String!) {
    bot(id: $botId) {
      id
      domains_whitelist
      domains_whitelist_max
    }
  }
`;

const UPDATE_DOMAINS_WHITELIST_MUTATION = gql`
  mutation UpdateDomainsWhitelistMutation(
    $botId: String!
    $domains: [String!]!
  ) {
    domainsWhitelist(id: $botId, domains: $domains) {
      id
      domains_whitelist
    }
  }
`;

interface DomainsWhitelistingProps
  extends Omit<
    React.HTMLProps<HTMLInputElement>,
    'onChange' | 'value' | 'onSelect'
  > {
  onChange: (domain: string) => void;
  onSelect: (domain: string) => void;
  value: string;
}

const regExpSimpleDomainValidate = /^.{1,}\..{2,}$/;

export const simpleDomainValidate = (domain: string) =>
  Boolean(domain && regExpSimpleDomainValidate.test(domain.trim()));

export const simpleMultiDomainsValidate = (domains: string[] | null) =>
  domains?.length && domains.every(simpleDomainValidate);

const extractDomain = (path: string) => {
  try {
    return new URL(`${path.indexOf('http') !== 0 ? 'https://' : ''}${path}`)
      .host;
  } catch {
    return path;
  }
};

export const DomainsWhitelisting: React.FC<DomainsWhitelistingProps> = ({
  onChange,
  onSelect,
  onBlur,
  onFocus,
  value,
  ...props
}) => {
  const [focused, setFocused] = useState<boolean>(false);
  const botId = useCurrentBotId() || '';
  const { data, loading } = useQuery<
    DomainsWhitelistQuery,
    DomainsWhitelistQueryVariables
  >(DOMAINS_WHITELIST_QUERY, {
    variables: {
      botId,
    },
    skip: !botId,
    fetchPolicy: 'cache-and-network',
  });

  const [updateWhitelist] = useMutation<
    UpdateDomainsWhitelistMutation,
    UpdateDomainsWhitelistMutationVariables
  >(UPDATE_DOMAINS_WHITELIST_MUTATION, {
    onError: (error) => {
      const { message: apiMessage } = extractGQLErrorData(error) || {};
      toaster.show({
        type: ServiceMessageType.error,
        payload: {
          message:
            typeof apiMessage === 'string'
              ? apiMessage
              : Messages.somethingWentWrong,
        },
      });
      log.error({
        error,
        data: {
          label: 'domain_whitelisting',
          requestId: getRequestIdFromApolloError(error),
        },
      });
    },
  });

  useEffect(() => {
    if (!focused && data && !loading) {
      const whitelist = data.bot?.domains_whitelist || [];
      const clearValue = extractDomain(value.trim().toLowerCase());
      if (!simpleDomainValidate(clearValue)) {
        return;
      }
      if (
        !whitelist.includes(clearValue) &&
        !EXCLUDE_WHITELISTING_DOMAIN.includes(clearValue)
      ) {
        updateWhitelist({
          variables: {
            botId,
            domains: uniq([...whitelist, clearValue]),
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, focused]);

  const items: Item[] = sortBy(
    prop('id'),
    uniq(
      data?.bot.domains_whitelist
        ?.filter((domain) => domain !== window.location.host)
        .map((domain) => ({ id: domain, title: domain })) || [],
    ),
  );

  if (loading) {
    return <CenteredLoader />;
  }

  return (
    <SimpleCombobox
      items={items}
      selectedItem={items.find(({ id }) => id === value) || null}
      onChange={(item) => {
        if (item) {
          onChange(item?.id || '');
          onSelect(item?.id || '');
        }
      }}
      boundariesElement="viewport"
      renderInput={({ getInputProps, openMenu, clearSelection }) => (
        <Input
          {...getInputProps({
            ...props,
            placeholder: window.i18next.t(
              'DomainsWhitelisting-string-2052-domain',
            ),
            onFocus: (event: React.FocusEvent<HTMLInputElement>) => {
              onFocus?.(event);
              openMenu();
              setFocused(true);
            },
            onBlur: (event: React.FocusEvent<HTMLInputElement>) => {
              onBlur?.(event);
              setFocused(false);
            },
            onChange: ({
              currentTarget: { value },
            }: React.FormEvent<HTMLInputElement>) => {
              clearSelection();
              onChange(value);
            },
            onPaste: (event: React.ClipboardEvent) => {
              event.preventDefault();
              const plainText = event.clipboardData.getData('text/plain');
              const cleanText = extractDomain(plainText);
              document.execCommand('inserttext', false, cleanText);
            },
            value,
            error: !simpleDomainValidate(value) && !focused,
            disabled: loading,
          })}
        />
      )}
      renderItem={({ getItemProps, item, index, highlightedIndex }) => {
        const title = defaultItemToString(item) as string;
        return (
          <MenuItem
            key={item.id}
            {...getItemProps({ item })}
            active={index === highlightedIndex}
            titleElement={
              <TextEllipsis width={300} title={title}>
                {title}
              </TextEllipsis>
            }
          />
        );
      }}
    />
  );
};
