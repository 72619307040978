import { propEq, uniqBy, sortBy, prop, compose } from 'ramda';
import gql from 'graphql-tag';
import {
  BlockLinksQuery_graph_edges,
  BlockLinksQuery_graph_vertices,
} from '../@types/BlockLinksQuery';
import { BotTabs } from '@utils/Routing';

export type AnyRecord = Record<any, any>;
export type EdgePoint = 'source' | 'destination';
export type GraphEdge = Pick<
  BlockLinksQuery_graph_edges,
  EdgePoint | 'type' | 'url'
> &
  AnyRecord;
export type GraphVertice = Pick<
  BlockLinksQuery_graph_vertices,
  'id' | 'title' | 'type' | 'block_type'
> &
  AnyRecord;

export enum LinkMetaType {
  aiIntent = 'aiIntent',
  button = 'button',
  referral = 'referral',
  url = 'url',
  qr = 'qr',
  external = 'external',
  broadcast = 'broadcast',
  persistentMenu = 'persistentMenu',
  sequence = 'sequence',
  facebookPage = 'facebookPage',
  comment = 'comment_rule',
  gotoPlugin = 'goto_plugin',
}

export enum Titles {
  externalEntryPoints = 'External Entry Points',
  aiRule = 'AI Rules',
  persistentMenu = 'Persistent menu',
  reengage = 'Reengage',
  other = 'Other',
  referral = 'Bot Link',
}

export interface LinkMeta {
  id: string;
  title: Titles | string;
  url: string | null;
  type: LinkMetaType;
  order: number;
  source: string | null;
  destination: string | null;
  isExternal: boolean;
  isFlow: boolean;
}

const getLinkTitle = (edge: GraphEdge, vertice?: GraphVertice): string => {
  switch (edge.type) {
    case LinkMetaType.broadcast:
      return Titles.reengage;
    case LinkMetaType.url:
      return edge.title;
    case LinkMetaType.referral:
      return Titles.referral;
    case LinkMetaType.comment:
      return edge.title;
    default:
      break;
  }

  const verticeTitle = vertice?.title ?? '';
  switch (verticeTitle) {
    case 'External':
      return Titles.externalEntryPoints;
    case 'AI':
      return Titles.aiRule;
    default:
      return verticeTitle;
  }
};

const getLinkType = (
  edge: GraphEdge,
  title: GraphEdge['url'],
  vertice?: GraphVertice,
): LinkMetaType => {
  if (vertice?.type === LinkMetaType.sequence) {
    return LinkMetaType.sequence;
  }

  if (edge.type === LinkMetaType.referral) {
    return LinkMetaType.referral;
  }

  if (edge.type === LinkMetaType.comment) {
    return LinkMetaType.comment;
  }

  switch (title) {
    case Titles.externalEntryPoints:
      return LinkMetaType.external;
    case Titles.reengage:
      return LinkMetaType.broadcast;
    case Titles.aiRule:
      return LinkMetaType.aiIntent;
    case Titles.persistentMenu:
      return LinkMetaType.persistentMenu;
    default:
      return edge.type as LinkMetaType;
  }
};

const TITLES_ORDER = {
  [Titles.externalEntryPoints]: 0,
  [Titles.referral]: 0,
  [Titles.aiRule]: 1,
  [Titles.persistentMenu]: 2,
  [Titles.reengage]: 3,
  [Titles.other]: 4,
};

const getLinkOrder = (title: Titles | string): number => {
  const order = TITLES_ORDER[title as Titles];
  return order ?? TITLES_ORDER[Titles.other];
};

export const PAGES: Partial<Record<LinkMetaType, string>> = {
  [LinkMetaType.persistentMenu]: `${BotTabs.configure}#persistent-menu`,
  [LinkMetaType.aiIntent]: BotTabs.keywords,
  [LinkMetaType.external]: BotTabs.grow,
  [LinkMetaType.broadcast]: BotTabs.reEngage,
};

export const SEQUENCE_BLOCK_FRAGMENT = gql`
  fragment SequenceBlockFragment on BlocksGroup {
    id
    blocks {
      id
    }
  }
`;

export const getLinkUrl = (
  botId: string,
  url: GraphEdge['url'],
  id: string,
  type: LinkMetaType,
  isFlow: boolean,
) => {
  if (url) {
    return { url, isExternal: true };
  }

  let page: string | undefined;
  if (type === LinkMetaType.comment) {
    page = `grow#grow-rule-${id}`;
  } else {
    page = PAGES[type];
  }

  let redirectUrl;
  if (page) {
    redirectUrl = `/bot/${botId}/${page}`;
  } else if (isFlow) {
    redirectUrl = `/bot/${botId}/flows/${id}`;
  } else {
    redirectUrl = `/bot/${botId}/structure/${id}`;
  }

  return {
    isExternal: false,
    url: redirectUrl,
  };
};

const getLinkUniqueField = (link: LinkMeta) => {
  switch (link.type) {
    case LinkMetaType.url:
      return link.url;
    case LinkMetaType.external:
    case LinkMetaType.aiIntent:
    case LinkMetaType.broadcast:
      return link.type;
    case LinkMetaType.button:
      return link.title;
    default:
      return `${link.source}:${link.destination}`;
  }
};

const getLinkId = (type: LinkMetaType, rawId: string, title: string) =>
  type === LinkMetaType.url ? `${rawId}-${title}` : rawId;

export interface FlowInfo {
  id: string;
  title: string;
}

/**
 * flow с id равным originalFlowId может не существовать (удален, скопирован с другоо бота и тд)
 * поэтому если такого flow нет, то по бизнес логике нужно найти flow с таким же именем
 * если и такого flow нет, то отдаем originalFlowId, и в дальнейшем при переходе на такой flow
 * сработает локика с попыткой создать flow, с именем, которй когда то был у этого flow
 */
const getSuitableFlowId = (
  originalFlowId: string,
  title: string,
  flowsInfo: FlowInfo[],
) => {
  if (flowsInfo.find((flowInfo) => flowInfo.id === originalFlowId)) {
    return originalFlowId;
  }
  const flowWithSameTitle = flowsInfo.find(
    (flowInfo) => flowInfo.title === title,
  );
  if (flowWithSameTitle) {
    return flowWithSameTitle.id;
  }
  return originalFlowId;
};

const getUniqueAndSortedLinks = compose(
  sortBy(prop('order')),
  uniqBy(getLinkUniqueField),
);

interface EdgesAndVertices {
  edges: GraphEdge[];
  vertices: GraphVertice[];
  fieldToCompare: EdgePoint;
  fieldToGet: EdgePoint;
  blockId: string;
  botId: string;
  flowsInfo: FlowInfo[];
}

export const getBlockLinksFromEdgesAndVertices = ({
  edges,
  vertices,
  fieldToCompare,
  fieldToGet,
  blockId,
  botId,
  flowsInfo,
}: EdgesAndVertices): LinkMeta[] => {
  if (fieldToGet === fieldToCompare) {
    return [];
  }

  const properlyOrientedEdges = edges.filter(propEq(fieldToCompare, blockId));
  const links = properlyOrientedEdges
    .map((edge): LinkMeta => {
      const value = edge[fieldToGet];
      const vertice = vertices.find(propEq('id', value));
      const title = getLinkTitle(edge, vertice);
      const type = getLinkType(edge, title, vertice);
      const isFlow = vertice?.block_type === 'flow';
      const id = isFlow
        ? getSuitableFlowId(value, title, flowsInfo)
        : getLinkId(type, value, title);
      const order = getLinkOrder(title);
      const { isExternal, url } = getLinkUrl(botId, edge.url, id, type, isFlow);
      const { source, destination } = edge;

      return {
        id,
        title,
        type,
        order,
        url,
        source,
        destination,
        isExternal,
        isFlow,
      };
    })
    .filter((link) => link.type !== LinkMetaType.external);

  return getUniqueAndSortedLinks(links);
};
