import React, { useState, useEffect } from 'react';

export const DebugCrashingComponent: React.FC = () => {
  const [shouldCrash, setShouldCrash] = useState(false);

  useEffect(() => {
    (window as any).debugSetShouldCrash = setShouldCrash;
  }, []);

  if (shouldCrash) {
    throw new Error('[Debug]: Crashing app on purpose');
  }
  return null;
};
