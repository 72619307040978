import React from 'react';
import { compose } from 'ramda';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Dialog, DialogHeading } from '@ui/Dialog';
import {
  CloseButton,
  FlexDialogContent,
  FormLine,
  SubmitRow,
} from '@ui/Dialog/ActionDialogParts';
import { Flex } from '@ui/Flex';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { Input } from '@ui/Input';
import { ReactComponent as DefaultBotIcon } from '../../modern-ui/_deprecated/Icon/icons/default-bot.svg';
import { defaultItemToString, SimpleCombobox } from '@ui/SimpleCombobox';
import { ShimmerText } from '@ui/ShimmerText';
import { Autofocus } from '@ui/Autofocus';
import { getCurrentBlocks } from '@utils/BlocksUtils';
import * as s from './CardActionsDialog.css';
import { Bots } from './@types/Bots';
import {
  BotBlocks,
  BotBlocks_bot_archiveBlocks as BotBlock,
  BotBlocksVariables,
} from './@types/BotBlocks';
import { MenuItem } from '@ui/Menu';
import { TextEllipsis } from '@ui/TextEllipsis';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { Platform } from '@globals';

interface Block {
  id: string;
  title: string;
}

interface ICardActionsDialogProps {
  onSubmit: (params: { blockId: string; botId: string }) => void;
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  onDismiss?: () => void;
  currentBotId: string;
  currentBlockId: string;
  currentBotBlocks: Block[];
}

interface ICardActionsDialogState {
  [formValue: string]: string | null;
}

const botsQuery = gql`
  query Bots {
    bots {
      id
      title
      allowedPlatforms
      status {
        page_info {
          picture
        }
      }
    }
  }
`;

const blocksQuery = gql`
  query BotBlocks($botId: String) {
    bot(id: $botId) {
      id
      archiveBlocks {
        id
        title
        removed
        reachable
      }
    }
  }
`;

export class CardActionsDialog extends React.Component<
  ICardActionsDialogProps,
  ICardActionsDialogState
> {
  constructor(props: ICardActionsDialogProps) {
    super(props);
    this.state = { botId: props.currentBotId, blockId: props.currentBlockId };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setFieldValue(name: string, value: string) {
    this.setState({ [name]: value });
    if (name === 'botId' && this.state.botId !== value) {
      // reset blockId if bot is changed
      this.setState({ blockId: '' });
    }
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      return;
    }

    this.props.onSubmit(this.state as { botId: string; blockId: string });
  }

  render() {
    const {
      renderHeading,
      renderActionText,
      onDismiss,
      currentBotId,
      currentBotBlocks,
    } = this.props;
    const { botId, blockId } = this.state;
    return (
      <Dialog>
        <form onSubmit={this.handleSubmit}>
          <FlexDialogContent>
            <div>
              <DialogHeading>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <div style={{ paddingRight: 8 }}>{renderHeading()}</div>
                  {onDismiss ? (
                    <CloseButton
                      aria-label="close"
                      type="button"
                      onClick={onDismiss}
                    />
                  ) : null}
                </Flex>
              </DialogHeading>
              <Query<Bots> query={botsQuery}>
                {({ error, loading, data }) => (
                  <React.Fragment>
                    {error ? (
                      <p>
                        {window.i18next.t(
                          'CardActionsDialog-JSXText--117-an-error-occured-while-loading-bot-list',
                        )}
                      </p>
                    ) : null}
                    {!error ? (
                      <React.Fragment>
                        <FormLine>
                          <label>
                            <div className={s.formLabel}>
                              {window.i18next.t(
                                'CardActionsDialog-JSXText--150-to-the-bot',
                              )}
                            </div>
                            <SimpleCombobox
                              key={loading ? 'loading' : 'ready'}
                              initialSelectedItem={
                                loading || !data
                                  ? null
                                  : data.bots.find(
                                      (bot: any) => bot.id === botId,
                                    )
                              }
                              onChange={(item) =>
                                this.setFieldValue('botId', item ? item.id : '')
                              }
                              items={
                                data
                                  ? data.bots.filter(
                                      ({ allowedPlatforms }) =>
                                        !allowedPlatforms ||
                                        testAllowedPlatforms(
                                          [Platform.facebook],
                                          allowedPlatforms,
                                        ),
                                    )
                                  : []
                              }
                              renderInput={({
                                getInputProps,
                                openMenu,
                                selectedItem,
                                selectItem,
                                selectInputValue,
                                ref,
                              }) => (
                                <Autofocus
                                  shouldFocus={!loading && !botId}
                                  render={({ bind }) => (
                                    <Input
                                      {...getInputProps({
                                        ref: loading
                                          ? undefined
                                          : (n: HTMLInputElement) => {
                                              ref(n);
                                              bind.ref(n);
                                            },
                                        onFocus: compose(
                                          openMenu,
                                          selectInputValue,
                                        ),
                                        onChange: () => {
                                          selectItem(null);
                                        },
                                      })}
                                      name="botId"
                                      placeholder={window.i18next.t(
                                        'CardActionsDialog-string--159-enter-bot-name',
                                      )}
                                      render={
                                        loading
                                          ? () => (
                                              <div>
                                                <ShimmerText
                                                  play
                                                  style={{
                                                    paddingLeft: 12,
                                                    fontSize: 15,
                                                    lineHeight:
                                                      'var(--control-decorator-min-height)',
                                                  }}
                                                >
                                                  {window.i18next.t(
                                                    'CardActionsDialog-JSXText-1258-loading-bot-list',
                                                  )}
                                                </ShimmerText>
                                                <input
                                                  type="text"
                                                  name="botId"
                                                  // render invalid input
                                                  // so that the form cannot be submitted
                                                  // while loading
                                                  required
                                                  style={{
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0,
                                                  }}
                                                />
                                              </div>
                                            )
                                          : undefined
                                      }
                                      renderIconEnd={() => {
                                        if (
                                          !selectedItem ||
                                          !selectedItem.status.page_info ||
                                          !selectedItem.status.page_info.picture
                                        ) {
                                          return <DefaultBotIcon />;
                                        }
                                        return (
                                          <img
                                            src={
                                              selectedItem.status.page_info
                                                .picture
                                            }
                                            width={24}
                                            height={24}
                                            alt="page info"
                                          />
                                        );
                                      }}
                                      required
                                    />
                                  )}
                                />
                              )}
                              renderItem={({
                                getItemProps,
                                item,
                                index,
                                highlightedIndex,
                              }) => {
                                const title = defaultItemToString(
                                  item,
                                ) as string;
                                return (
                                  <MenuItem
                                    key={item.id}
                                    {...getItemProps({ item })}
                                    active={index === highlightedIndex}
                                    titleElement={
                                      <TextEllipsis width={344} title={title}>
                                        {title}
                                      </TextEllipsis>
                                    }
                                  />
                                );
                              }}
                            />
                          </label>
                        </FormLine>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              </Query>
              {botId ? (
                <Query<BotBlocks, BotBlocksVariables>
                  key={botId} // reset component if selected bot changes
                  query={blocksQuery}
                  variables={{ botId }}
                  fetchPolicy="cache-and-network"
                >
                  {({ error, loading, data }) => {
                    if (error) {
                      return (
                        <span>
                          {window.i18next.t(
                            'CardActionsDialog-JSXText-1556-some-error',
                          )}
                        </span>
                      );
                    }
                    const fetchingBlocks =
                      loading &&
                      (!data || !data.bot || !data.bot.archiveBlocks);

                    let items: (BotBlock | Block)[] = [];
                    if (fetchingBlocks && botId === currentBotId) {
                      items = currentBotBlocks;
                    } else if (!fetchingBlocks && data) {
                      items = getCurrentBlocks(data.bot.archiveBlocks);
                    }
                    const selectedItem =
                      items.find((block) => block.id === blockId) || null;
                    if (!fetchingBlocks && !items.length) {
                      return (
                        <FormLine>
                          <p>
                            <i>
                              {window.i18next.t(
                                'CardActionsDialog-JSXText-8113-this-bot-has-not-available-blocks',
                              )}
                            </i>
                          </p>
                        </FormLine>
                      );
                    }
                    return (
                      <FormLine>
                        <label>
                          <div className={s.formLabel}>
                            {window.i18next.t(
                              'CardActionsDialog-JSXText-3079-to-the-block',
                            )}
                          </div>
                          <div>
                            <SimpleCombobox
                              onChange={(item) =>
                                this.setFieldValue(
                                  'blockId',
                                  item ? item.id : '',
                                )
                              }
                              items={items}
                              initialSelectedItem={selectedItem}
                              renderInput={({
                                getInputProps,
                                openMenu,
                                ref,
                                selectInputValue,
                                selectItem,
                              }) => (
                                <Autofocus
                                  shouldFocus={!fetchingBlocks}
                                  render={({ bind }) => (
                                    <Input
                                      {...getInputProps({
                                        ref: fetchingBlocks
                                          ? undefined
                                          : (n: HTMLInputElement) => {
                                              ref(n);
                                              bind.ref(n);
                                            },
                                        onFocus: compose(
                                          openMenu,
                                          selectInputValue,
                                        ),
                                        onChange: () => {
                                          selectItem(null);
                                        },
                                      })}
                                      name="blockId"
                                      placeholder={window.i18next.t(
                                        'CardActionsDialog-string-1609-enter-block-name',
                                      )}
                                      required
                                      render={
                                        fetchingBlocks && !selectedItem
                                          ? () => (
                                              <div>
                                                <ShimmerText
                                                  play
                                                  style={{
                                                    paddingLeft: 12,
                                                    fontSize: 15,
                                                    lineHeight:
                                                      'var(--control-decorator-min-height)',
                                                  }}
                                                >
                                                  {window.i18next.t(
                                                    'CardActionsDialog-JSXText-6163-loading-blocks-list',
                                                  )}
                                                </ShimmerText>
                                                <input
                                                  type="text"
                                                  name="blockId"
                                                  // render invalid input
                                                  // so that the form cannot be submitted
                                                  // while loading
                                                  required
                                                  style={{
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0,
                                                  }}
                                                />
                                              </div>
                                            )
                                          : undefined
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </FormLine>
                    );
                  }}
                </Query>
              ) : null}
            </div>
            <SubmitRow>
              <Button
                type="submit"
                disabled={!botId || !blockId}
                intent={ButtonIntent.primary}
              >
                {renderActionText()}
              </Button>
            </SubmitRow>
          </FlexDialogContent>
        </form>
      </Dialog>
    );
  }
}
