import React from 'react';
import {
  Button,
  ButtonIntent,
  ButtonColorWay,
} from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as PlayIcon } from '../../../modern-ui/_deprecated/Icon/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../../modern-ui/_deprecated/Icon/icons/pause.svg';

const minWidth = 110;
export const BroadcastToggle: React.SFC<{
  on: boolean;
  onClick: () => void;
  disabled: boolean;
}> = ({ on, onClick, disabled }) =>
  on ? (
    <Button
      data-testid="broadcast-header__pause-button"
      style={{ minWidth }}
      intent={ButtonIntent.primary}
      colorWay={ButtonColorWay.white}
      renderIcon={() => <PauseIcon />}
      onClick={onClick}
    >
      {window.i18next.t('BroadcastToggle-JSXText--848-pause')}
    </Button>
  ) : (
    <Button
      data-testid="broadcast-header__set-live-button"
      style={{ minWidth }}
      intent={ButtonIntent.primary}
      renderIcon={() => <PlayIcon />}
      onClick={onClick}
      disabled={disabled}
    >
      {window.i18next.t('BroadcastToggle-JSXText--757-set-live')}
    </Button>
  );
