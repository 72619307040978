import React from 'react';
import cn from 'classnames';
import * as css from './SequenceBlockStat.css';
import { ReactComponent as ClicksIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_clicks.svg';
import { ReactComponent as TotalIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_sent.svg';
import { ReactComponent as ViewsIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_views.svg';
import { ButtonUnstyled } from '../../../../../modern-ui/Button';

interface ISequenceBlockStatProps extends React.HTMLProps<HTMLDivElement> {
  sent: number;
  views: number;
  clicks: number;
  isActive: boolean;
  onReceivedClick?: () => void;
  onClickRateClick?: () => void;
  onOpenedRateClick?: () => void;
}

export const SequenceBlockStat: React.FC<ISequenceBlockStatProps> = React.memo(
  ({
    sent,
    views,
    clicks,
    className,
    isActive,
    onReceivedClick,
    onClickRateClick,
    onOpenedRateClick,
    ...props
  }) => {
    const totalSent = sent || 0;
    const openRate = Math.min(
      100,
      Math.floor((100 * (views || 0)) / Math.max(1, totalSent)),
    );
    const clickedRate = Math.min(
      100,
      Math.floor((100 * (clicks || 0)) / Math.max(1, totalSent)),
    );

    return (
      <div
        className={cn(css.SequenceBlockStat, className, {
          [css.isActive]: isActive,
        })}
        {...props}
      >
        <div className={css.box}>
          <ButtonUnstyled
            data-testid="automate-aside__block-stats-total"
            title={window.i18next.t(
              'SequenceBlockStat-string-1811-of-people-that-received',
            )}
            className={css.itemMargin}
            onClick={totalSent > 0 ? onReceivedClick : undefined}
          >
            <TotalIcon className={css.icon} />
            <span>{totalSent.toLocaleString('en-EN')}</span>
          </ButtonUnstyled>
          <ButtonUnstyled
            data-testid="automate-aside__block-stats-opened"
            title={window.i18next.t(
              'SequenceBlockStat-string--160-of-people-that-opened',
            )}
            className={css.itemMargin}
            onClick={openRate > 0 ? onOpenedRateClick : undefined}
          >
            <ViewsIcon className={css.icon} />
            <span>{openRate}%</span>
          </ButtonUnstyled>
          <ButtonUnstyled
            data-testid="automate-aside__block-stats-clicked"
            title={window.i18next.t(
              'SequenceBlockStat-string--297-of-people-that-clicked',
            )}
            onClick={clickedRate > 0 ? onClickRateClick : undefined}
          >
            <ClicksIcon className={css.icon} />
            <span>{clickedRate}%</span>
          </ButtonUnstyled>
        </div>
      </div>
    );
  },
);
