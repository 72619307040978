import React from 'react';
import { Anchor } from '@ui/Links';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';

export const WhatsappManagerAnchor = () => {
  const { t } = useSafeTranslation();

  return (
    <Anchor
      id={OnboardingTourHTMLElementId.WATemplateCreateAnchor}
      intent="external"
      target="_blank"
      rel="noopener noreferrer"
      href="https://business.facebook.com/wa/manage/message-templates/"
      onClick={() => {
        OnboardingEmitter.emit(OnboardingTourEventType.click, {
          element: OnboardingTourHTMLElementId.WATemplateCreateAnchor,
          value: null,
        });
      }}
    >
      {t('pages.BotPage.ReengageWhatsappTab.templateModal.whatsappManagerLink')}
    </Anchor>
  );
};
