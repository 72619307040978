import ng from 'angular';
import { react2angular } from 'react2angular';
import { BroadcastHeader } from './BroadcastHeader';

export const ngBroadcastHeader = ng
  .module('app.components.broadcastHeader', [])
  .component(
    'broadcastHeader',
    react2angular(
      BroadcastHeader,
      [
        'type',
        'onSend',
        'toggleOn',
        'onToggleClick',
        'broadcastId',
        'pageId',
        'blockId',
        'showCopy',
        'onCopy',
        'onRemove',
        'disabled',
        'isBroadcastCanBeSent',
      ],
      [],
    ),
  ).name;
