import React from 'react';
import gql from 'graphql-tag';
import { ApolloProvider, Query } from '@apollo/react-components';
import { AdminFeatures } from '@utils/Data/Admin';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { sendEvent } from '@utils/Analytics';
import { useCopyText } from '@utils/Copy';
import { IconButton } from '@ui/IconButton';
import { Tooltip2 } from '@ui/Tooltip2';
import { Hover } from 'react-powerplug';
import { Flex } from '@ui/Flex';
import client from '../../common/services/ApolloService';
import { BotLinkQuery, BotLinkQueryVariables } from './@types/BotLinkQuery';
import * as css from './BotLink.css';
import { useInstagramAccount } from '@utils/Data/Instagram/BotAccount';

interface BotLinkChildrenProps {
  href: string;
}

export interface BotLinkProps {
  botId?: string;
  children: (props: BotLinkChildrenProps) => React.ReactElement;
}

const BOT_LINK_QUERY = gql`
  query BotLinkQuery($botId: String!) {
    me {
      id
    }
    bot(id: $botId) {
      id
      status {
        page
        page_info {
          id
          username
        }
      }
    }
  }
`;

interface CollapsedLinkProps {
  href: string;
}

export const CollapsedLink: React.FC<CollapsedLinkProps> = ({ href }) => {
  const { copied, copy } = useCopyText();

  return (
    <HoverDisclosure
      render={({ isVisible: expanded, bind }) => (
        <div {...bind} className={css.container}>
          <a
            href={href}
            target="_blank"
            rel="noopener noreferrer"
            onClick={() => {
              sendEvent({ category: 'header bot link', action: 'click' });
            }}
            className={css.link}
          >
            {href.replace('https://', '').slice(0, expanded ? undefined : 10)}
            {expanded ? '' : '...'}
          </a>
          {expanded && (
            <Tooltip2
              content={
                copied
                  ? window.i18next.t('BotLink-string-2024-copied')
                  : window.i18next.t('BotLink-string-3947-copy-to-clipboard')
              }
              placement="right"
              type="small"
            >
              {(ref, toolTipBind) => (
                <Hover>
                  {({ hovered, bind }) => (
                    <Flex ref={ref} {...toolTipBind}>
                      <IconButton
                        icon="copyBotLink"
                        color={hovered ? 'blue' : 'grey'}
                        className={css.copyButton}
                        onClick={() => {
                          copy(href);
                          sendEvent({
                            category: 'header bot link',
                            action: 'copy',
                          });
                        }}
                        {...bind}
                      />
                    </Flex>
                  )}
                </Hover>
              )}
            </Tooltip2>
          )}
        </div>
      )}
    />
  );
};

export const InstagramBotLinkBase: React.FC<BotLinkProps> = (props) => {
  const { botId, children } = props;
  const {
    instagramConnectedAccount,
    instagramConnected,
    instagramDataIsLoading,
  } = useInstagramAccount(botId!);

  if (!botId || !instagramConnected || instagramDataIsLoading) {
    return null;
  }

  const href = `https://ig.me/m/${instagramConnectedAccount.handle}`;

  return children({ href });
};

export const FacebookMessengerBotLinkBase: React.FC<BotLinkProps> = (props) => {
  const { botId, children } = props;

  // Otherwise we have to wait for link on every page
  if (!botId) {
    return null;
  }

  return (
    <ApolloProvider client={client}>
      <AdminFeatures>
        {({ adminFeaturesLoading, adminFeaturesError }) => (
          <Query<BotLinkQuery, BotLinkQueryVariables>
            query={BOT_LINK_QUERY}
            variables={{ botId }}
          >
            {({ data, loading, error }) => {
              if (
                adminFeaturesError ||
                adminFeaturesLoading ||
                error ||
                loading ||
                !data ||
                !data.me ||
                !data.bot
              ) {
                return null;
              }

              const { status } = data.bot;
              let pageId;
              let username;

              if (status) {
                pageId = status.page;
                username = status.page_info && status.page_info.username;
              }

              if (!username && !pageId) {
                return null;
              }

              const href = `https://m.me/${username || pageId}`;

              return children({ href });
            }}
          </Query>
        )}
      </AdminFeatures>
    </ApolloProvider>
  );
};
