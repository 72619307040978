import React from 'react';
import nanoid from 'nanoid';
import { UserAttributeEditor } from './UserAttributesEditor';
import {
  UserAttribute,
  UserAttributeType,
} from '../../UiUserAttributeEditor/UserAttributeTypes';

export interface Attribute {
  name: string;
  value: string | null;
}

interface ButtonUserAttributeEditorProps {
  attributeUpdates?: Attribute[];
  botId: string;
  onChange: (config: any) => void;
}

export const ButtonUserAttributeEditor: React.FC<ButtonUserAttributeEditorProps> = (
  props,
) => {
  const attributesToCommonAttributes: () => UserAttribute[] = () => {
    if (!props.attributeUpdates) {
      return [] as UserAttribute[];
    }
    return props.attributeUpdates.map(({ name, value }) => ({
      __typename: 'UserAttribute',
      id: nanoid(),
      name,
      values: value ? [value] : [],
      type: UserAttributeType.custom,
    }));
  };

  const commonAttributesToObject: (
    attributesArray: UserAttribute[],
  ) => Attribute[] = (attributesArray) =>
    attributesArray.map(({ name, values: [value] }) => ({ name, value }));

  return (
    <UserAttributeEditor
      attributes={attributesToCommonAttributes()}
      botId={props.botId}
      onChange={(attributes) =>
        props.onChange({
          attributeUpdates: commonAttributesToObject(attributes),
        })
      }
      attributeNamePlaceholder={window.i18next.t(
        'ButtonPopupUserAttributesEditor-string-1272-attribute-name',
      )}
      attributeValuePlaceholder={window.i18next.t(
        'ButtonPopupUserAttributesEditor-string-8242-value',
      )}
    />
  );
};
