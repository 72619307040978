import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { ButtonUnstyled } from '@ui/Button';
import { CreateBlankBotButton } from './CreateBlankBotButton';
import css from './ActionButtons.css';
import { Spacer } from '@ui/Spacer';
import { Platform } from '@globals';

export interface ActionButtonsProps {
  hideSearch: boolean;
  search: string;
  changeSearch: (newSearch: string) => void;
  disabled: boolean;
  hide: boolean;
  onCreateBlankBot: (platforms?: Platform[]) => void;
}

export const ActionButtons: React.FC<ActionButtonsProps> = ({
  hideSearch,
  search,
  changeSearch,
  disabled,
  hide,
  onCreateBlankBot,
}) => {
  const { t } = useSafeTranslation();
  const resetSearch = () => changeSearch('');
  return (
    <div className={css.actionButtons}>
      {!hideSearch && (
        <div className={css.searchInput}>
          <Input
            data-testid="botlist_search_input"
            value={search}
            placeholder={t('BotList.searchInput.placeholder')}
            disabled={disabled}
            onChange={(event) => changeSearch(event.target.value)}
            renderIcon={() => <Icon icon="search" color="grey" />}
            renderIconEnd={() => {
              if (!search) {
                return null;
              }
              return (
                <ButtonUnstyled
                  onClick={resetSearch}
                  data-testid="botlist_search_input_clear"
                >
                  <Icon icon="close" />
                </ButtonUnstyled>
              );
            }}
          />
        </div>
      )}
      {!hide && (
        <>
          <Spacer horizontalFactor={3} factor={0} />
          <CreateBlankBotButton
            onCreateBlankBot={(platforms) => {
              resetSearch();
              onCreateBlankBot(platforms);
            }}
            disabled={disabled}
          />
        </>
      )}
    </div>
  );
};
