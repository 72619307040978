import { ServiceMessageType } from '@ui/ServiceMessage2';
import { useToaster } from '@ui/Toaster';
import { BOT_PRO_STATUS_QUERY } from '@utils/Data/Bot/BotProStatus/queries';
import {
  CANCEL_PRO_MUTATION,
  DIALOGS_PRICING_QUERY,
} from '@utils/Data/Pricing';
import {
  CancelProMutation,
  CancelProMutationVariables,
} from '@utils/Data/Pricing/@types/CancelProMutation';
import { USER_DATA_FOR_INTERCOM_QUERY } from '@utils/Intercom';
import { useCurrentBotId } from '@utils/Routing';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';

export const useCancelPro = () => {
  const botId = useCurrentBotId();
  const { t } = useSafeTranslation();
  const { addToaster } = useToaster();

  const [cancelPro, { loading }] = useMutation<
    CancelProMutation,
    CancelProMutationVariables
  >(CANCEL_PRO_MUTATION, {
    refetchQueries: [
      { query: USER_DATA_FOR_INTERCOM_QUERY },
      { query: DIALOGS_PRICING_QUERY, variables: { botId } },
      { query: BOT_PRO_STATUS_QUERY, variables: { botId } },
    ],
    awaitRefetchQueries: true,
    onError: (error) => {
      log.error({
        error,
        msg: 'cancel pro mutation',
      });
      addToaster({
        type: ServiceMessageType.error,
        content: t(
          'CancelProDialog-string-1956-could-not-cancel-pro-please-try-again-later',
        ),
        timeout: 5000,
        closeButton: true,
      });
    },
  });

  const handleCancelPro = () => {
    if (botId) return cancelPro({ variables: { botId } });
    return Promise.reject();
  };

  return [handleCancelPro, loading] as const;
};
