import React from 'react';
import { TFunction } from 'i18next';

import { isWhiteLabelDomain } from '@utils/WhiteLabelUtils';
import { Anchor } from '@ui/Links';

import { ErrorBlock } from '../components/ErrorBlock';
import { getRenderRightPart } from '../components/RightPartBlock';

export const FB_POLICY_LINK =
  'https://developers.facebook.com/docs/messenger-platform/policy/policy-overview';
export const CF_OTN_LINK =
  'http://docs.chatfuel.com/en/articles/3705639-one-time-notification';

export const linkToDoc = (t: TFunction) =>
  isWhiteLabelDomain() ? FB_POLICY_LINK : t('common.helpDocLinks.message_tags');

export const NON_PROMOTIONAL_GROUP_ID = 'NON_PROMOTIONAL';
export const PROMOTIONAL_GROUP_ID = 'PROMOTIONAL';
export const OTN_GROUP_ID = 'OTN';
export const NTN_GROUP_ID = 'NTN';

export const TAGS_GROUPS = (t: TFunction) =>
  [
    {
      id: PROMOTIONAL_GROUP_ID,
      title: t('Tags-string--936-promotional-content'),
    },
    {
      id: NTN_GROUP_ID,
      render: () => (
        <div data-testid="broadcasting-tags__ntn-group">
          <div>{t('pages.Reengage.BroadcastingTagsDialog.NTN.title')}</div>
          <ErrorBlock>
            {t('pages.Reengage.BroadcastingTagsDialog.NTN.warning24h')}
          </ErrorBlock>
        </div>
      ),
    },

    {
      id: OTN_GROUP_ID,
      render: () => (
        <div data-testid="broadcasting-tags__otn-group">
          <div>{t('Tags-JSXText-1975-one-time-notification')}</div>
          <ErrorBlock>
            {t(
              'Tags-JSXText-1372-send-users-one-follow-up-message-outside-the-24-hour-window',
            )}
            <Anchor
              intent="external"
              hideArrow
              target="_blank"
              href={CF_OTN_LINK}
            >
              {t('Tags-JSXText-1076-learn-more')}
            </Anchor>
          </ErrorBlock>
        </div>
      ),
    },
    {
      id: NON_PROMOTIONAL_GROUP_ID,
      render: () => (
        <>
          <div>{t('Tags-JSXText-1793-non-promotional-content')}</div>
          <ErrorBlock
            text={t(
              'Tags-string-1948-these-tags-allow-you-to-send-updates-outside-the-standard-messaging-window-applying-them-to-content-outside-of-the-approved-use-cases-may-result-in-restrictions-on-your-ability-to-send-messages',
            )}
          />
        </>
      ),
    },
  ].filter(Boolean);

export const NON_PROMOTIONAL_SUBSCRIPTION_ID = 'NON_PROMOTIONAL_SUBSCRIPTION';
export const STANDARD_MESSAGING_ID = 'UPDATE';
export const CONFIRMED_EVENT_UPDATE = 'CONFIRMED_EVENT_UPDATE';
export const POST_PURCHASE_UPDATE = 'POST_PURCHASE_UPDATE';
export const ACCOUNT_UPDATE = 'ACCOUNT_UPDATE';

export const TAG_ORDER: { [key: string]: number } = {
  [STANDARD_MESSAGING_ID]: 1,
  [NON_PROMOTIONAL_SUBSCRIPTION_ID]: 2,
  [CONFIRMED_EVENT_UPDATE]: 3,
  [POST_PURCHASE_UPDATE]: 4,
  [ACCOUNT_UPDATE]: 5,
};
export const TAG_DEFAULT_ORDER = 6;

const disabledAppendRenderForBlackListRender = (selected?: boolean) => (
  <ErrorBlock
    selected={selected}
    text="This tag has been temporarily disabled due to suspicious messaging activity. Contact support via the dashboard chat or at team@chatfuel.com to get more details."
  />
);

type TagTooltipType = {
  text: string;
  linkHref: string;
  linkTitle: string;
  disabledAppendRender?: (selected?: boolean) => JSX.Element;
};

export const TAGS_TOOLTIP_LIST: (
  t: TFunction,
) => Record<string, TagTooltipType> = (t) => ({
  [STANDARD_MESSAGING_ID]: {
    text: t(
      'Tags-string-1865-respond-to-the-user-within-the-24-hour-standard-messaging-window-messages-may-contain-promotional-content',
    ),
    linkHref: linkToDoc(t),
    linkTitle: t('Tags-string--138-learn-more'),
  },
  [NON_PROMOTIONAL_SUBSCRIPTION_ID]: {
    text: t(
      'Tags-string--160-send-non-promotional-messages-only-for-news-pages-that-are-registered-under-the-facebook-news-page-index-npi',
    ),
    linkHref: linkToDoc(t),
    linkTitle: t('Tags-string--138-learn-more'),
  },
  [CONFIRMED_EVENT_UPDATE]: {
    text: t(
      'Tags-string-8628-send-the-user-reminders-or-updates-for-an-event-they-have-registered-for-e-g-rsv-pd-purchased-tickets-this-tag-may-be-used-for-upcoming-events-and-events-in-progress',
    ),
    linkHref: linkToDoc(t),
    linkTitle: t('Tags-string--138-learn-more'),
    disabledAppendRender: disabledAppendRenderForBlackListRender,
  },
  [POST_PURCHASE_UPDATE]: {
    text: t(
      'Tags-string-5954-notify-the-user-of-an-update-on-a-recent-purchase',
    ),
    linkHref: linkToDoc(t),
    linkTitle: t('Tags-string--138-learn-more'),
    disabledAppendRender: disabledAppendRenderForBlackListRender,
  },
  [ACCOUNT_UPDATE]: {
    text: t(
      'Tags-string--161-notify-the-user-of-a-non-recurring-change-to-their-application-or-account',
    ),
    linkHref: linkToDoc(t),
    linkTitle: t('Tags-string--138-learn-more'),
    disabledAppendRender: disabledAppendRenderForBlackListRender,
  },
});

const TAGS_LIST = (t: TFunction) => [
  {
    id: STANDARD_MESSAGING_ID,
    groupId: PROMOTIONAL_GROUP_ID,
    renderRightPart: getRenderRightPart(
      TAGS_TOOLTIP_LIST(t)[STANDARD_MESSAGING_ID],
    ),
    leftPartTitle: t('Tags-string-9452-standard-messaging'),
  },
  {
    id: NON_PROMOTIONAL_SUBSCRIPTION_ID,
    groupId: NON_PROMOTIONAL_GROUP_ID,
    renderRightPart: getRenderRightPart(
      TAGS_TOOLTIP_LIST(t)[NON_PROMOTIONAL_SUBSCRIPTION_ID],
    ),
    leftPartTitle: t('Tags-string-6736-non-promotional-subscription'),
  },
  {
    id: CONFIRMED_EVENT_UPDATE,
    groupId: NON_PROMOTIONAL_GROUP_ID,
    renderRightPart: getRenderRightPart(
      TAGS_TOOLTIP_LIST(t)[CONFIRMED_EVENT_UPDATE],
    ),
    leftPartTitle: t('Tags-string-1254-confirmed-event-update'),
  },
  {
    id: POST_PURCHASE_UPDATE,
    groupId: NON_PROMOTIONAL_GROUP_ID,
    renderRightPart: getRenderRightPart(
      TAGS_TOOLTIP_LIST(t)[POST_PURCHASE_UPDATE],
    ),
    leftPartTitle: t('Tags-string-1729-post-purchase-update'),
  },
  {
    id: ACCOUNT_UPDATE,
    groupId: NON_PROMOTIONAL_GROUP_ID,
    renderRightPart: getRenderRightPart(TAGS_TOOLTIP_LIST(t)[ACCOUNT_UPDATE]),
    leftPartTitle: t('Tags-string--107-account-update'),
  },
  {
    id: NTN_GROUP_ID,
    groupId: NTN_GROUP_ID,
    renderRightPart: () => null,
    leftPartTitle: t('pages.Reengage.BroadcastingTagsDialog.NTN.title'),
  },
];

export const TAG_ID_TO_TAG_MAP = (t: TFunction) =>
  new Map(TAGS_LIST(t).map((tag) => [tag.id, tag]));
