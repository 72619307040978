import React from 'react';
import { CancelProTranslation } from '@components/CancelProDialog/CancelProTranslation';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { ChurnSurveyAdviceType } from '@globals';
import css from './CancelPlanBlock.css';

interface CancelPlanBlockProps {
  onCancellPro: VoidFunction;
  botId: string;
  churnSurveyAdviceType: ChurnSurveyAdviceType;
}

export const CancelPlanBlock: React.FC<CancelPlanBlockProps> = ({
  onCancellPro,
  botId,
  churnSurveyAdviceType,
}) => {
  return (
    <Flex flexDirection="column" className={css.cancelBlock}>
      <Type size="15px" weight="bold" color="baseLight">
        <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.cancelPlan" />
        ?
      </Type>
      <Spacer factor={1} />
      <Type size="15px" color="baseLight">
        <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.allOfYourData" />
      </Type>
      <Spacer factor={4} />
      <Button
        className={css.cancelPlanAction}
        textClassName={css.cancelPlanActionText}
        onClick={() => {
          sendEvent({
            category: 'churn survey advice dialog',
            action: 'cancel pro anyway button click',
            propertyBag: {
              botId,
              churnSurveyAdviceType,
            },
          });
          onCancellPro();
        }}
        data-testid="cancel-plan-anyway-button"
      >
        <Flex alignItems="center" justifyContent="center">
          <Icon icon="close" color="accent4Secondary" />
          <Spacer factor={0} horizontalFactor={1} />
          <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.cancelPlan" />
        </Flex>
      </Button>
    </Flex>
  );
};
