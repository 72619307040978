import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { BotOptionsMenuQuery } from './@types/BotOptionsMenuQuery';

export const BOT_OPTIONS_MENU = gql`
  query BotOptionsMenuQuery($botId: String!) {
    bot(id: $botId) {
      id
      admins {
        id
      }
    }
  }
`;

export const useBotOptionsMenu = (botId: string) => {
  const { data, ...queryResult } = useQuery<BotOptionsMenuQuery>(
    BOT_OPTIONS_MENU,
    {
      variables: { botId },
      skip: !botId,
      fetchPolicy: 'network-only',
    },
  );

  return {
    bot: data?.bot,
    ...queryResult,
  };
};
