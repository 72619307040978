import React from 'react';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { CancelProTranslation } from '../../CancelProTranslation';
import usersImg from './users.png';
import messageImg from './message.png';
import { numberFormatter } from '@utils/Format/numberFormatter';
import css from './DiscountProDialog.css';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface BotInfoProps {
  totalUsers: number;
  monthlyUsers: number;
  totalDialogs: number;
  monthlyDialogs: number;
}

export const BotInfo: React.FC<BotInfoProps> = ({
  totalUsers,
  monthlyUsers,
  totalDialogs,
  monthlyDialogs,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  return (
    <Flex gapFactor={1} className={isSmallScreenSize ? 'column' : 'row'}>
      <Flex flexDirection="column" className={css.botInfo}>
        <img src={usersImg} alt="users" className={css.infoImage} />
        <Flex flexDirection="column" className={css.botInfoText}>
          <Type size="32px" weight="extrabold" color="accent1Semilight">
            {numberFormatter(totalUsers ?? 0)}
          </Type>
          <Type size="18px" weight="medium" color="accent1Semilight">
            <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.usersReachedTotal" />
          </Type>
          <Spacer factor={5} />
          <Type size="32px" weight="extrabold" color="accent1Semilight">
            {numberFormatter(monthlyUsers ?? 0)}
          </Type>
          <Type size="18px" weight="medium" color="accent1Semilight">
            <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.inThisMonth" />
          </Type>
        </Flex>
      </Flex>
      <Flex flexDirection="column" className={css.botInfo}>
        <img src={messageImg} alt="message" className={css.infoImage} />
        <Flex flexDirection="column" className={css.botInfoText}>
          <Type size="32px" weight="extrabold" color="accent1Semilight">
            {numberFormatter(totalDialogs ?? 0)}
          </Type>
          <Type size="18px" weight="medium" color="accent1Semilight">
            <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.conversationsSent" />
          </Type>
          <Spacer factor={5} />
          <Type size="32px" weight="extrabold" color="accent1Semilight">
            {numberFormatter(monthlyDialogs ?? 0)}
          </Type>
          <Type size="18px" weight="medium" color="accent1Semilight">
            <CancelProTranslation i18nKey="CancelPro.DowngradeNotAvailable.inThisMonth" />
          </Type>
        </Flex>
      </Flex>
    </Flex>
  );
};
