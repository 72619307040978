import React, { useState } from 'react';
import cn from 'classnames';
import AutosizeInput from 'react-input-autosize';
import { Autofocus } from '../../../../modern-ui/Autofocus';
import { Input } from '../../../../modern-ui/Input';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import * as css from './GroupTitle.css';

interface IGroupTitle extends React.HTMLProps<HTMLButtonElement> {
  title: string;
  onTitleChange: (title: string) => void;
  readOnly?: boolean;
  forSequence?: boolean;
}

const GROUP_TITLE_LIMIT = 25;
const GROUP_TITLE_ALERT_LIMIT = 18;

export const GroupTitle: React.FC<IGroupTitle> = React.memo(
  ({ title: initTitle, onTitleChange, readOnly, forSequence, ...props }) => {
    const [title, setTitle] = useState(initTitle.toUpperCase());
    const [isEditMode, setIsEditMode] = useState(false);
    const doneEditEndSave = () => {
      setIsEditMode(false);
      onTitleChange(title.toUpperCase());
    };
    const doneEditEndReset = () => {
      setIsEditMode(false);
      setTitle(initTitle.toUpperCase());
    };
    const handleKeyDown = ({ key }: React.KeyboardEvent) => {
      if (key === 'Enter') {
        doneEditEndSave();
      }

      if (key === 'Escape') {
        doneEditEndReset();
      }
    };
    return (
      <ButtonUnstyled
        {...(props as any)}
        className={cn(css.GroupTitle, props.className)}
        onClick={() => readOnly || setIsEditMode(true)}
      >
        {isEditMode ? (
          <Autofocus
            selectAll
            render={({ bind }) => (
              <Input
                containerClassName={css.inputBox}
                render={({ getInputProps }) => (
                  <div className={css.layout}>
                    <AutosizeInput
                      inputClassName={cn(css.input, {
                        [css.forSequence]: forSequence,
                      })}
                      style={{ maxWidth: '100%', minWidth: 0 }}
                      {...getInputProps({
                        name: 'group-title',
                        type: 'text',
                        placeholder: 'ENTER GROUP NAME',
                        value: title,
                        maxLength: GROUP_TITLE_LIMIT,
                        onChange: (e: React.ChangeEvent<HTMLInputElement>) =>
                          setTitle(e.target.value),
                        onBlur: doneEditEndSave,
                        onKeyDown: handleKeyDown,
                      })}
                      {...bind}
                    />
                    <div
                      className={cn(css.discount, {
                        [css.red]: title.length > GROUP_TITLE_ALERT_LIMIT,
                      })}
                    >
                      {GROUP_TITLE_LIMIT - title.length}
                    </div>
                  </div>
                )}
              />
            )}
          />
        ) : (
          <div className={css.title}>{title}</div>
        )}
      </ButtonUnstyled>
    );
  },
  ({ title: prevTitle }, { title }) => prevTitle === title,
);
