import React, { useRef, useState, useLayoutEffect } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router-dom';

import { Icon } from '@ui/Icon';
import { Anchor } from '../../../../modern-ui/Links';
import { Type } from '../../../../modern-ui/Type';
import { ButtonUnstyled } from '../../../../modern-ui/Button';
import { LoadingPlaceholder } from '../../../../modern-ui/LoadingPlaceholder';
import { ReactComponent as ToggleLinksIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { LinkMeta, LinkMetaType } from '../../utils/links';
import { isSafeUrl, isUrlWithProtocol } from '../../../../utils/UrlUtils';
import { sendEvent } from '../../../../utils/Analytics';
import * as css from './BlockLinksGroup.css';

interface BlockLinksGroupProps {
  links: LinkMeta[];
  botId: string;
  groupTitle: string;
  loading: boolean;
  emptyMessage: React.ReactNode;
  sendEventCategory: 'inbound link' | 'outbound link';

  onReferralLinkClick?(): void;
}

const ONE_LINE_HEIGHT = 30;

export const BlockLinksGroup: React.FC<BlockLinksGroupProps> = ({
  groupTitle,
  links,
  loading,
  emptyMessage,
  sendEventCategory,
  onReferralLinkClick,
}) => {
  const history = useHistory();
  const [expanded, setExpanded] = useState(false);
  const [toggleState, setToggleState] = useState(false);
  const groupItemsContainerRef = useRef<HTMLDivElement | null>(null);

  useLayoutEffect(() => {
    const groupItemsHeight = groupItemsContainerRef.current?.offsetHeight ?? 0;
    setToggleState(groupItemsHeight > ONE_LINE_HEIGHT);
  }, [links.length]);

  const handleLinkClick = (link: LinkMeta) => (
    e: React.MouseEvent<HTMLAnchorElement>,
  ) => {
    sendEvent({
      category: sendEventCategory,
      action: 'click',
      label: link.title,
      propertyBag: {
        type: 'block',
        target: link.id,
      },
    });

    if (!link.isExternal && link.url) {
      e.preventDefault();
      history.push(link.url);
    }
  };

  const handleLinksToggle = () => {
    setExpanded(!expanded);
  };

  const isEmpty = links.length === 0;

  return (
    <div
      className={cn(css.blockLinksGroupContainer, {
        [css.blockLinksGroupContainerExpanded]: expanded,
      })}
    >
      {loading && (
        <div className={css.blockLinksGroupLoaderContainer}>
          <LoadingPlaceholder
            data-testid="placeholder"
            className={css.blockLinksGroupLoader}
          />
        </div>
      )}

      {!loading && isEmpty && (
        <div
          className={css.blockLinksGroupItemsContainer}
          data-testid="emptyMessage"
        >
          <div className={css.blockLinksGroupItem}>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <LinksGroupTitle
              title={groupTitle}
              onTitleClick={handleLinksToggle}
              expanded={expanded}
              disabled={!toggleState}
            />
          </div>

          <div className={css.blockLinksGroupItem}>
            <div className={css.blockLinksEmptyMessage}>
              <Type color="greyDark" size="15px_DEPRECATED">
                {emptyMessage}
              </Type>
            </div>
          </div>
        </div>
      )}

      {!loading && !isEmpty && (
        <div
          ref={groupItemsContainerRef}
          className={css.blockLinksGroupItemsContainer}
        >
          <div className={css.blockLinksGroupItem}>
            {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
            <LinksGroupTitle
              title={groupTitle}
              onTitleClick={handleLinksToggle}
              expanded={expanded}
              disabled={!toggleState}
            />
          </div>
          {links.map((link) => {
            let LinkComponent: React.ReactNode;

            if (link.type === LinkMetaType.referral) {
              LinkComponent = (
                <Anchor
                  intent="external"
                  hideArrow
                  className={css.blockLinksGroupItemButton}
                  onClick={() => {
                    onReferralLinkClick?.();
                  }}
                >
                  {link.title}
                </Anchor>
              );
            } else {
              let href = link.url && isSafeUrl(link.url) ? link.url : '';
              if (href && link.isExternal && !isUrlWithProtocol(href)) {
                href = `https://${href}`;
              }
              LinkComponent = (
                <div
                  className={cn(css.blockLinksGroupItemElem, {
                    [css.blockLinksGroupItemElemFlow]: link.isFlow,
                  })}
                >
                  <Anchor
                    intent="external"
                    hideArrow={!link.isExternal}
                    forceArrow={link.isExternal}
                    className={css.blockLinksGroupItemAnchor}
                    href={href}
                    rel="noopener noreferrer"
                    target={link.type === LinkMetaType.url ? '_blank' : '_self'}
                    onClick={handleLinkClick(link)}
                    data-testid={`link-${link.id}`}
                  >
                    {link.title}
                  </Anchor>
                  {link.isFlow && (
                    <div className={css.blockLinksGroupItemIconWrapper}>
                      <Icon icon="flow" color="blue" size="16px" />
                    </div>
                  )}
                </div>
              );
            }

            return (
              <div
                key={`${link.type}:${link.id}`}
                className={css.blockLinksGroupItem}
              >
                {LinkComponent}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const LinksGroupTitle: React.FC<{
  title: string;
  expanded: boolean;
  disabled: boolean;
  onTitleClick(): void;
}> = ({ title, expanded, disabled, onTitleClick }) => (
  <ButtonUnstyled
    onClick={onTitleClick}
    className={css.blockLinksToggleLinksButton}
    disabled={disabled}
  >
    <div
      className={cn(css.blockLinksToggleLinksIcon, {
        [css.blockLinksToggleLinksIconExpanded]: expanded,
      })}
    >
      {!disabled && <ToggleLinksIcon />}
    </div>
    <div className={css.blockLinksToggleLinksTitle}>
      <Type weight="semibold" size="15px_DEPRECATED">
        {title}
      </Type>
    </div>
  </ButtonUnstyled>
);
