import React, { useState } from 'react';
import gql from 'graphql-tag';
import { ApolloProvider, Query } from '@apollo/react-components';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { Loader } from '@ui/Loader';
import { useCurrentBotId } from '@utils/Routing';
import client from '../../../common/services/ApolloService';
import {
  BlockSelectorQuery,
  BlocksSelector,
} from '@pages/AiSetupPage/BlocksSelector';
import { SelectBlockPopup } from '../../SelectBlockPopup/SelectBlockPopup';
import {
  BlockGroupsAndBlocksTitlesQuery,
  BlockGroupsAndBlocksTitlesQuery_bot_archiveBlocks as BlockType,
  BlockGroupsAndBlocksTitlesQuery_bot_archiveBlocks,
  BlockGroupsAndBlocksTitlesQueryVariables,
} from './@types/BlockGroupsAndBlocksTitlesQuery';
import { BlockWithPermissions } from '@pages/AiSetupPage';
import * as css from './ButtonPopupCommon.css';
import { useButtonPopupBlocksSelectorAngularForceUpdate } from './hooks/useButtonPopupBlocksSelectorAngularForceUpdate';
import { Platform } from '@globals';

export const FLOW_GROUPS_SUGGEST_FRAGMENT = gql`
  fragment FlowGroupsSuggest on Bot {
    flowGroupsSuggest {
      id
      title
      flows {
        id
        title
        is_flow
        platform
      }
    }
  }
`;

export const ARCHIVE_BLOCKS_FRAGMENT = gql`
  fragment ArchiveBlocksFragment on Bot {
    archiveBlocks {
      id
      title
      removed
      is_flow
    }
  }
`;

export const BLOCK_GROUPS_AND_BLOCKS_TITLES_QUERY = gql`
  query BlockGroupsAndBlocksTitlesQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...BlocksSelector_blocksGroups
      ...ArchiveBlocksFragment
      ...FlowGroupsSuggest
    }
  }
  ${BlockSelectorQuery.blocksGroups}
  ${ARCHIVE_BLOCKS_FRAGMENT}
  ${FLOW_GROUPS_SUGGEST_FRAGMENT}
`;

export interface ButtonTabBlockSelectorProps {
  botId?: string;
  onChange: (config: any) => void;
  blockId?: string[];
  tutorialModal?: {
    currentBlockId: string;
    showModal: boolean;
    onTutorialShow: (status: boolean) => void;
  };
  autofocus?: boolean;
  validateOnBlur?: boolean;
  error?: boolean;
  blockTitles?: BlockGroupsAndBlocksTitlesQuery_bot_archiveBlocks[];
  enablePortal?: boolean;
  onBlocksTitlesChange?: (titles: string[]) => void;
  onFocus?: () => void;
  onGoToBlock?: () => void;
  keyId?: string;
  placeholder?: string;
}

const getBlocksById: (ids?: string[], blocks?: BlockType[]) => BlockType[] = (
  ids?,
  blocks?,
) => {
  if (ids && blocks) {
    const result = [] as BlockType[];
    ids.forEach((id) => {
      const block = blocks.find((block) => block.id === id);
      if (block) {
        result.push(block);
      }
    });
    return result;
  }
  return [] as BlockType[];
};

export const ButtonPopupBlocksSelector: React.FC<ButtonTabBlockSelectorProps> =
  (props) => {
    const { t } = useSafeTranslation();
    const [selectedOptions, setSelectedOptions] = useState<BlockType[]>([]);
    const [initOptions, setInitOptions] = useState<boolean>(false);
    const [showTutorialModal, setShowTutorialModal] = useState<boolean>(false);
    const [validationError, setValidationError] = useState<boolean>(
      props.error || false,
    );
    const { isAutomateEnabled } = useAutomateEnabled();

    const botId = useCurrentBotId() || props.botId || '';

    useButtonPopupBlocksSelectorAngularForceUpdate(props.keyId, setInitOptions);

    return (
      <div className={css.blocksSelectorContainer}>
        <ApolloProvider client={client}>
          <Query<
            BlockGroupsAndBlocksTitlesQuery,
            BlockGroupsAndBlocksTitlesQueryVariables
          >
            query={BLOCK_GROUPS_AND_BLOCKS_TITLES_QUERY}
            variables={{ botId }}
            skip={!botId}
            returnPartialData
          >
            {({ error, loading, data }) => {
              if (!initOptions && !error && !loading && data && data.bot) {
                const blocks = getBlocksById(
                  props.blockId,
                  props.blockTitles || data.bot.archiveBlocks,
                );
                setSelectedOptions(blocks);
                setInitOptions(true);
                if (props.onBlocksTitlesChange) {
                  props.onBlocksTitlesChange(
                    blocks.map((block) => block.title),
                  );
                }
              }

              if (error || loading || !data || !data.bot) {
                return (
                  <div className={css.loaderWrapper}>
                    <Loader />
                  </div>
                );
              }

              return (
                <>
                  <BlocksSelector
                    platform={Platform.facebook}
                    placeholder={
                      props.placeholder ?? isAutomateEnabled
                        ? t(
                            'modernComponents.ButtonEditorPopup.ButtonPopupBlocksSelector.addBlock',
                          )
                        : t(
                            'modernComponents.ButtonEditorPopup.ButtonPopupBlocksSelector.addFlow',
                          )
                    }
                    currentBotId={botId}
                    borderless={false}
                    enablePortal={props.enablePortal}
                    blocksSelected={selectedOptions.map(
                      (option) =>
                        data.bot.archiveBlocks.find(
                          (block) => block.id === option.id,
                        ) || option,
                    )}
                    blocksGroups={
                      data.bot.blocksGroups as BlockWithPermissions[]
                    }
                    flowsGroups={data.bot.flowGroupsSuggest || []}
                    onChange={(options) => {
                      setSelectedOptions(options);
                      props.onChange({
                        blockId: options.map((item) => item.id),
                      });
                      if (props.onBlocksTitlesChange) {
                        props.onBlocksTitlesChange(
                          options.map((item) => item.title),
                        );
                      }
                    }}
                    inputWithSelectedBlocksClassName={
                      css.inputWithSelectedBlocks
                    }
                    error={validationError || props.error}
                    onGoToBlock={props.onGoToBlock}
                    autofocus={props.autofocus}
                    onFocus={() => {
                      if (props.onFocus) {
                        props.onFocus();
                      }
                      if (
                        props.tutorialModal &&
                        props.tutorialModal.showModal &&
                        selectedOptions.length === 0
                      ) {
                        setShowTutorialModal(true);
                        props.tutorialModal.onTutorialShow(true);
                      }
                      setValidationError(false);
                    }}
                    onBlur={() => {
                      if (props.validateOnBlur) {
                        if (selectedOptions.length === 0) {
                          setValidationError(true);
                        }
                      }
                    }}
                  />
                  {showTutorialModal &&
                    props.tutorialModal &&
                    isAutomateEnabled && (
                      <SelectBlockPopup
                        botId={botId}
                        currentBlockId={props.tutorialModal.currentBlockId}
                        onRequestClose={() => {
                          setShowTutorialModal(false);
                          if (props.tutorialModal) {
                            props.tutorialModal.onTutorialShow(false);
                          }
                        }}
                        onBlockSelected={(blockId) => {
                          setSelectedOptions(
                            data
                              ? getBlocksById([blockId], data.bot.archiveBlocks)
                              : [],
                          );
                          setValidationError(false);
                          props.onChange({ blockId: [blockId] });
                        }}
                      />
                    )}
                </>
              );
            }}
          </Query>
        </ApolloProvider>
      </div>
    );
  };
