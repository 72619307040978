import React from 'react';
import cn from 'classnames';
import { AsideGroupsQuery_bot_blocksGroups as IGroup } from '../../@types/AsideGroupsQuery';
import { ReactComponent as ClicksIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_clicks.svg';
import { ReactComponent as TotalIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_total-users.svg';
import { ReactComponent as ViewsIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_views.svg';
import * as css from './SequenceStat.css';

interface ISequenceStatProps extends React.HTMLProps<HTMLDivElement> {
  group: IGroup;
  on: boolean;
}

export const SequenceStat: React.FC<ISequenceStatProps> = React.memo(
  ({ group, className, on, ...props }) => {
    const totalUsers = group.total_users || 0;
    const totalSent = group.total_sent || 0;
    const openRate = Math.min(
      100,
      Math.floor((100 * (group.total_views || 0)) / Math.max(1, totalSent)),
    );
    const clickedRate = Math.min(
      100,
      Math.floor((100 * (group.total_clicks || 0)) / Math.max(1, totalSent)),
    );

    return (
      <div
        className={cn(css.SequenceStat, className, { [css.on]: on })}
        {...props}
      >
        <div className={css.box}>
          <div
            title={window.i18next.t('SequenceStat-string-8409-of-subscribers')}
            className={css.itemMargin}
          >
            <TotalIcon className={css.icon} />
            <span>{totalUsers.toLocaleString('en-EN')}</span>
          </div>
          <div
            title={window.i18next.t('SequenceStat-string--452-avg-open-rate')}
            className={css.itemMargin}
          >
            <ViewsIcon className={css.icon} />
            <span>{openRate}%</span>
          </div>
          <div title={window.i18next.t('SequenceStat-string-1032-avg-ctr')}>
            <ClicksIcon className={css.icon} />
            <span>{clickedRate}%</span>
          </div>
        </div>
      </div>
    );
  },
);
