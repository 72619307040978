import React from 'react';
import { ToasterConfig } from '@ui/Toaster/Toaster';
import { CancelProTranslation } from './CancelProTranslation';
import { useMutation } from 'react-apollo';
import {
  ProPlanUnpauseMutation,
  ProPlanUnpauseMutationVariables,
} from '@utils/Data/Pricing/@types/ProPlanUnpauseMutation';
import {
  GET_CONFIGURE_TAB_BOT_DATA,
  PRO_PLAN_PAUSE_MUTATION,
} from '@utils/Data/Pricing';
import { useCurrentBotId } from '@utils/Routing';
import { useToaster } from '@ui/Toaster';
import { log } from 'cf-common/src/logger';

const pauseError: ToasterConfig = {
  type: 'error',
  content: <CancelProTranslation i18nKey="CancelPro.errors.pausing" />,
  timeout: 5000,
  closeButton: true,
};

export const usePausePro = () => {
  const botId = useCurrentBotId();
  const { addToaster } = useToaster();

  const [pauseProPlan, { loading }] = useMutation<
    ProPlanUnpauseMutation,
    ProPlanUnpauseMutationVariables
  >(PRO_PLAN_PAUSE_MUTATION, {
    onError(error) {
      log.error({
        error,
        msg: 'pause pro plan',
      });
      addToaster(pauseError);
    },
    onCompleted(isPaused) {
      if (!isPaused) addToaster(pauseError);
    },
    awaitRefetchQueries: true,
    refetchQueries: [
      {
        query: GET_CONFIGURE_TAB_BOT_DATA,
        variables: { botId },
      },
    ],
  });

  const handlePauseClick = () => {
    if (botId) return pauseProPlan({ variables: { botId } });
    return Promise.reject();
  };

  return [handlePauseClick, loading] as const;
};
