import React, { useCallback } from 'react';
import Maybe from 'graphql/tsutils/Maybe';
import { useApolloClient } from 'react-apollo';
import { filter, lensPath, lensProp, over } from 'ramda';
import { Modal } from '@services/index';
import { toaster } from '@services/MessageService';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { mapQuery } from '@utils/GQL/mapQuery';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { AdminPermissionManagerDialog } from '@components/AdminPermissionManagerDialog';
import {
  LIGHT_BOTS_QUERY,
  LIGHT_WORKSPACE_BOTS_QUERY,
} from '@utils/Data/Bot/queries';
import { LightBotsQuery } from '@utils/Data/Bot/@types/LightBotsQuery';
import { LightWorkspaceBotsQuery } from '@utils/Data/Bot/@types/LightWorkspaceBotsQuery';
import { Complitable } from '../types';
import { BOTS_LIST_QUERY } from '@common/services/GQLqueries/BotGQService.const';
import { BotsListQuery } from '@common/services/GQLqueries/@types/BotsListQuery';

const workspaceBotsLens = (id: string, data: LightWorkspaceBotsQuery) => {
  const workspaceIndex = data?.lightWorkspaceBots?.workspaces.findIndex(
    ({ workspace }) => workspace.id === id,
  );

  return lensPath(['lightWorkspaceBots', 'workspaces', workspaceIndex, 'bots']);
};

interface UpdateCacheBotLeave {
  id: string;
  workspace_id: Maybe<string>;
}

export const useUpdateCacheAfterBotLeave = <
  T extends UpdateCacheBotLeave,
>() => {
  const client = useApolloClient();

  const handleBotLeave = useCallback(
    (bot: T) => {
      const removeById = filter(({ id }) => id !== bot.id);

      mapQuery<BotsListQuery>(
        client,
        { query: BOTS_LIST_QUERY },
        over(lensProp('bots'), removeById),
      );

      mapQuery<LightBotsQuery>(
        client,
        { query: LIGHT_BOTS_QUERY },
        over(lensPath(['lightBots']), removeById),
      );

      if (bot.workspace_id) {
        mapQuery<LightWorkspaceBotsQuery>(
          client,
          { query: LIGHT_WORKSPACE_BOTS_QUERY },
          (data) => {
            return over(
              workspaceBotsLens(bot.workspace_id!, data),
              removeById,
              data,
            );
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [client],
  );

  return handleBotLeave;
};

export interface LeaveBotDialogProps<T> extends Complitable<T> {
  userId: string;
}

export interface LeaveBot extends UpdateCacheBotLeave {
  id: string;
  title: string;
}

export const useLeaveBotDialog = <T extends LeaveBot>({
  userId,
  onCompleted,
}: LeaveBotDialogProps<T>) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();
  const handleBotLeave = useUpdateCacheAfterBotLeave();

  const showLeaveBotDialog = useCallback(
    (bot: T) => {
      Modal.show(
        ({ close }) => (
          <AdminPermissionManagerDialog
            isSmallScreenSize={isSmallScreenSize}
            userId={userId}
            botId={bot.id}
            onComplete={() => {
              handleBotLeave(bot);
              onCompleted?.(bot);
              close();

              toaster.default({
                payload: {
                  message: t('BotList.notifications.leaveFromBot', {
                    botname: bot.title,
                  }),
                  showCloseButton: true,
                },
              });
            }}
            onDismiss={close}
          />
        ),
        { mobileAdaptive: true },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, onCompleted, handleBotLeave, userId, isSmallScreenSize],
  );

  return showLeaveBotDialog;
};
