import React, { useCallback } from 'react';
import { Modal } from '@services/index';
import { ChurnSurveyAdvice } from './ChurnSurveyAdvice';
import {
  ShowCancelProSubscriptionPayload,
  useCancelProSubscriptionDialog,
} from '../showCancelProSubscriptionDialog';

const CHURN_SURVEY_ADVICE_MODAL = 'CHURN_SURVEY_ADVICE_MODAL';

interface ShowChurnSurveyAdviceDialogPayload {
  onClose?: VoidFunction;
  onCancellPro: VoidFunction;
}

export const showChurnSurveyAdviceDialog = (
  payload: ShowChurnSurveyAdviceDialogPayload,
) =>
  Modal.show(
    ({ close }) => (
      <ChurnSurveyAdvice
        onClose={() => {
          close();
          payload?.onClose?.();
        }}
        onCancellPro={() => {
          close();
          payload.onCancellPro();
        }}
      />
    ),
    {
      id: CHURN_SURVEY_ADVICE_MODAL,
    },
  );

export const useShowChurnSurveyAdviceDialog = (
  cancelingPaywallCard?: boolean,
) => {
  const { showCancelProSubscriptionDialog, loading } =
    useCancelProSubscriptionDialog(cancelingPaywallCard);

  const show = useCallback(
    (payload?: ShowCancelProSubscriptionPayload) =>
      showChurnSurveyAdviceDialog({
        onClose: payload?.onGoBack,
        onCancellPro: () => showCancelProSubscriptionDialog(payload),
      }),
    [showCancelProSubscriptionDialog],
  );

  return { loading, show };
};
