import React from 'react';
import { ReactComponent as DotsIcon } from '../../../modern-ui/_deprecated/Icon/icons/3dots.svg';
import { SimpleCombobox, Item } from '@ui/SimpleCombobox';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';

interface ButtonTabActionMenu {
  hovered: boolean;
  onItemDelete: () => void;
}

enum ActionType {
  deleteAction = 'deleteAction',
}

const itemToString = (item: Item | null) => (item ? item.title : '');

export const ButtonPopupActionMenu: React.FC<ButtonTabActionMenu> = (props) => {
  const items: Item[] = [
    {
      id: ActionType.deleteAction,
      title: window.i18next.t(
        'ButtonPopupActionMenu-string--779-delete-action',
      ),
    },
  ];

  const onChange = (item: Item | null) => {
    if (!item) {
      return;
    }

    if (item.id === ActionType.deleteAction) {
      props.onItemDelete();
    }
  };

  return (
    <div style={{ opacity: props.hovered ? 1 : 0 }}>
      <SimpleCombobox
        itemToString={itemToString}
        renderInput={({ getToggleButtonProps }) => (
          <Button
            {...getToggleButtonProps()}
            renderIcon={() => <DotsIcon />}
            intent={ButtonIntent.default}
            size={ButtonSize.s}
            tall
          />
        )}
        items={items}
        onChange={onChange}
        menuboxStyle={{ width: '240px' }}
        unsaveSelectedOption
      />
    </div>
  );
};
