import React from 'react';
import cn from 'classnames';
import { globalHistory } from '@utils/Routing';
import { Router } from 'react-router-dom';
import { TestThisSimple } from '@components/QuickAccessToolbar/TestThis';
import { sendEvent } from '@utils/Analytics';
import { WhiteLabelUserDataWithApolloProvider } from '@utils/WhiteLabelUtils';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { ReactComponent as CopyIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_duplicate.svg';
import { ReactComponent as TrashIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_trashbin.svg';
import { ReactComponent as SendIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_send.svg';
import { BroadcastToggle } from './BroadcastToggle';
import * as css from './BroadcastHeader.css';

export interface IBroadcastHeaderProps {
  type: 'now' | 'sequence' | 'schedule' | 'auto';
  toggleOn: boolean;
  onSend: () => void;
  onToggleClick: () => void;
  broadcastId: string;
  pageId: string;
  blockId: string;
  showCopy: boolean;
  onCopy: () => void;
  onRemove: () => void;
  disabled?: boolean;
  isBroadcastCanBeSent: boolean;
}

const HeaderText = () => ({
  now: window.i18next.t('BroadcastHeader-string--764-deliver-your-message-now'),
  sequence: window.i18next.t('BroadcastHeader-string--185-add-a-trigger'),
  schedule: window.i18next.t('BroadcastHeader-string--137-schedule-for-later'),
  auto: window.i18next.t(
    'BroadcastHeader-string--170-autopost-from-external-source',
  ),
});

export const BroadcastHeader: React.FC<IBroadcastHeaderProps> = ({
  type,
  onSend,
  toggleOn,
  onToggleClick,
  broadcastId,
  blockId,
  showCopy,
  onCopy,
  onRemove,
  disabled = false,
  isBroadcastCanBeSent,
}) => {
  return (
    <Router history={globalHistory}>
      <div className={css.header}>
        <div className={css.titleContainer}>
          <span className={css.title}>{HeaderText()[type]}</span>
          {showCopy && (
            // We need to add disabled-overrided-button class, because of global angular styles
            // see packages/dashboard/src/ui/_common/element-state.less
            <CopyIcon
              className={cn(
                {
                  [css.iconCopy]: disabled,
                  'disabled-overrided-button': disabled,
                },
                css.icon,
              )}
              onClick={onCopy}
            />
          )}
        </div>
        <div className={css.buttonsContainer}>
          {type === 'now' ? (
            <Button
              data-testid="broadcast-header__send-now-button"
              renderIcon={() => <SendIcon />}
              intent={ButtonIntent.primary}
              onClick={onSend}
              disabled={!isBroadcastCanBeSent}
            >
              {window.i18next.t('BroadcastHeader-JSXText-1056-send')}
            </Button>
          ) : (
            type !== 'auto' && (
              <BroadcastToggle
                on={toggleOn}
                onClick={() => {
                  onToggleClick();
                  sendEvent({
                    category: 're-engage',
                    action: !toggleOn ? 'set live' : 'pause',
                    propertyBag: {
                      type,
                      broadcastId,
                    },
                  });
                }}
                disabled={!isBroadcastCanBeSent}
              />
            )
          )}
          <div className={css.secondButton}>
            <WhiteLabelUserDataWithApolloProvider>
              {({ isWhiteLabelUser, loading }) => {
                if (loading || type === 'auto' || isWhiteLabelUser) {
                  return null;
                }
                return (
                  <TestThisSimple
                    blockId={blockId}
                    buttonLabel={window.i18next.t(
                      'BroadcastHeader-string-1346-preview',
                    )}
                  />
                );
              }}
            </WhiteLabelUserDataWithApolloProvider>
          </div>
        </div>
        <div className={css.trashIconContainer}>
          <TrashIcon
            data-testid="broadcast-header__remove-button"
            className={css.icon}
            onClick={onRemove}
          />
        </div>
      </div>
    </Router>
  );
};
