import React from 'react';
import cn from 'classnames';
import { ReactComponent as AddIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import * as css from './AddBlock.css';

export const AddBlock: React.FC<React.HTMLProps<HTMLDivElement>> = React.memo(
  (props) => (
    <div {...props} className={cn(css.AddBlock, props.className)}>
      <AddIcon />
    </div>
  ),
);
