import React, { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { BLOCK_LINKS_QUERY } from './BlockLinks.queries';
import { BlockLinksGroup } from './components/BlockLinksGroup';
import {
  FlowInfo,
  getBlockLinksFromEdgesAndVertices,
  LinkMeta,
  LinkMetaType,
} from './utils/links';
import { sendEvent } from '../../utils/Analytics';
import GrimacingFace from '../../assets2/images/emojis/grimacing_face.png';
import {
  BlockLinksQuery,
  BlockLinksQueryVariables,
} from './@types/BlockLinksQuery';
import * as css from './BlockLinks.css';

const WELCOME_MESSAGE_BLOCK_TITLE = 'welcome message';

export interface BlockLinksProps {
  botId: string;
  blockId: string;
  blockTitle: string;
  flowsInfo: FlowInfo[];
  onLinkLabelClick(): void;
}

export const BlockLinks: React.FC<BlockLinksProps> = ({
  botId,
  blockId,
  blockTitle,
  flowsInfo,
  onLinkLabelClick,
}) => {
  const [incomingLinks, setIncomingLinks] = React.useState<LinkMeta[]>([]);
  const [outgoingLinks, setOutgoingLinks] = React.useState<LinkMeta[]>([]);
  const [isDataPrepared, setIsDataPrepared] = React.useState(false);
  const { data, loading } = useQuery<BlockLinksQuery, BlockLinksQueryVariables>(
    BLOCK_LINKS_QUERY,
    {
      variables: { blockId, botId },
      fetchPolicy: 'cache-and-network',
    },
  );
  const [isEventsSent, setIsEventsSent] = React.useState(false);
  const withFbPageLink =
    blockTitle.toLowerCase() === WELCOME_MESSAGE_BLOCK_TITLE;

  const FB_PAGE_INBOUND_LINK: LinkMeta = {
    id: 'facebook_page',
    title: window.i18next.t('BlockLinks-string-1687-facebook-page'),
    order: 0,
    source: null,
    destination: null,
    type: LinkMetaType.facebookPage,
    url: `/bot/${botId}/home`,
    isExternal: false,
    isFlow: false,
  };

  useEffect(() => {
    if (loading) {
      return;
    }
    const edges = data?.graph?.edges || [];
    const vertices = data?.graph?.vertices || [];

    const incomingLinks = getBlockLinksFromEdgesAndVertices({
      edges,
      vertices,
      fieldToCompare: 'destination',
      fieldToGet: 'source',
      blockId,
      botId,
      flowsInfo,
    });

    if (withFbPageLink) {
      setIncomingLinks([FB_PAGE_INBOUND_LINK, ...incomingLinks]);
    } else {
      setIncomingLinks(incomingLinks);
    }

    const outgoingLinks = getBlockLinksFromEdgesAndVertices({
      edges,
      vertices,
      fieldToCompare: 'source',
      fieldToGet: 'destination',
      blockId,
      botId,
      flowsInfo,
    });
    setOutgoingLinks(outgoingLinks);

    if (data?.graph?.botId && !isEventsSent) {
      if (incomingLinks.length === 0) {
        sendEvent({
          category: 'entry point',
          action: 'show',
          label: 'empty',
        });
      }
      if (outgoingLinks.length === 0) {
        sendEvent({
          category: 'exit point',
          action: 'show',
          label: 'empty',
        });
      }
      setIsEventsSent(true);
    }

    setIsDataPrepared(true);
    // DO NOT FIX due to infinity loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, withFbPageLink, blockId, botId, isEventsSent, loading]);

  return (
    <div className={css.blockLinks} data-testid="blockLinks">
      <BlockLinksGroup
        groupTitle={window.i18next.t('BlockLinks-string--198-inbound-links')}
        links={incomingLinks}
        botId={botId}
        loading={loading || !isDataPrepared}
        emptyMessage={
          <>
            {window.i18next.t('BlockLinks-JSXText-1798-none')}
            <img
              alt={window.i18next.t('BlockLinks-string-2463-oops')}
              src={GrimacingFace}
              aria-label={window.i18next.t('BlockLinks-string-2463-oops')}
              className={css.blockEmptyMessageIcon}
            />
            {window.i18next.t(
              'BlockLinks-JSXText-1800-send-users-here-from-an-ad-or-from-another-block',
            )}
          </>
        }
        sendEventCategory="inbound link"
        onReferralLinkClick={onLinkLabelClick}
      />
      <BlockLinksGroup
        groupTitle={window.i18next.t('BlockLinks-string-1777-outbound-links')}
        links={outgoingLinks}
        botId={botId}
        loading={loading || !isDataPrepared}
        emptyMessage={
          <>
            {window.i18next.t('BlockLinks-JSXText-1276-none')}{' '}
            <img
              alt={window.i18next.t('BlockLinks-string-2463-oops')}
              src={GrimacingFace}
              aria-label={window.i18next.t('BlockLinks-string-2463-oops')}
              className={css.blockEmptyMessageIcon}
            />{' '}
            {window.i18next.t(
              'BlockLinks-JSXText--480-send-users-to-another-block-or-a-url-from-here',
            )}
          </>
        }
        sendEventCategory="outbound link"
      />
    </div>
  );
};
