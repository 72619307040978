import React from 'react';
import {
  NeedPermissionsBanner,
  NeedProBanner,
  SyncBanner,
  BannerPlaceholder,
} from './components';
import {
  AudienceSyncState,
  AudienceSyncProps,
  AudienceSyncStateProvider,
} from './AudienceSyncContext';
import { useAudienceSyncState } from './hooks/useAudienceSyncState';

export const AudienceSync: React.FC<AudienceSyncProps> = (props) => (
  <AudienceSyncStateProvider {...props}>
    {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
    <AudienceSyncBanner {...props} />
  </AudienceSyncStateProvider>
);

const AudienceSyncBanner: React.FC<AudienceSyncProps> = ({ bot: { id } }) => {
  const { state, loading } = useAudienceSyncState();

  if (loading) {
    return <BannerPlaceholder />;
  }

  switch (state) {
    case AudienceSyncState.needPro:
      return <NeedProBanner botId={id} />;
    case AudienceSyncState.needPermissions:
      return <NeedPermissionsBanner />;
    default:
      return <SyncBanner botId={id} />;
  }
};
