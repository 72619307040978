/**
 * This file is just an abstraction over InfiniteLoader + FixedSizeList
 * They can be used separately or composed in place if desired
 */
import React, { useRef } from 'react';
import InfiniteLoader, {
  InfiniteLoaderProps,
} from 'react-window-infinite-loader';
import { FixedSizeList as List, FixedSizeListProps } from 'react-window';

type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

export interface DynamicListViewProps
  extends Omit<InfiniteLoaderProps, 'children'>,
    Omit<FixedSizeListProps, 'onItemsRendered'> {}

export const DynamicListView: React.FC<DynamicListViewProps> = (props) => {
  const {
    itemCount,
    loadMoreItems,
    isItemLoaded,
    threshold = 40,
    ...listProps
  } = props;
  const loadingMore = useRef(false);
  return (
    <InfiniteLoader
      itemCount={itemCount + 1}
      isItemLoaded={isItemLoaded}
      loadMoreItems={(...args) => {
        if (loadingMore.current) {
          // NOTE: Maybe this logic better belongs to the consumer components
          // Do not request another fetch when one is in progress already
          return Promise.resolve();
        }
        loadingMore.current = true;
        return loadMoreItems(...args).then((result) => {
          loadingMore.current = false;
          return result;
        });
      }}
      threshold={threshold}
    >
      {({ onItemsRendered, ref }) => (
        <List
          ref={ref}
          onItemsRendered={onItemsRendered}
          itemCount={itemCount}
          {...listProps}
        />
      )}
    </InfiniteLoader>
  );
};
