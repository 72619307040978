import { Period } from '@globals';

export enum ConnectPageOrigin {
  entryPointDeepLink = 'entryPointDeepLink',
  entryPoint = 'entryPoint',
  botLinkEntryPoint = 'botLinkEntryPoint',
  testThis = 'testThis',
  upgrade = 'upgrade',
  headerActions = 'headerActions',
  botList = 'botList',
  users = 'users',
  otn = 'otn',
  ntn = 'ntn',
  commentsAddPosts = 'commentsAddPosts',
  instagramConnect = 'instagramConnect',
  instagramEpConnect = 'instagramEpConnect',
  facebookConnect = 'facebookConnect',
  movedBlock = 'movedBlock',
  hometabUpgrade = 'hometabUpgrade',
  default = 'default',
  plans = 'plans',
  billing = 'billing',
  liveChatFb = 'liveChatFb',
  liveChatIg = 'liveChatIg',
}

export enum ConnectPageField {
  origin = 'cpOrigin',
  botId = 'cpBotId',
  blockId = 'cpBlockId',
  cardId = 'cpCardId',
  flowId = 'cpFlowId',
  tierId = 'cpTierId',
  subscriptionPeiod = 'cpSubscriptionPeriod',
  entryPointId = 'cpEntryPointId',
  actionId = 'cpActionId',
  name = 'cpName',
}

export type ConnectPageUrlParams = Partial<Record<ConnectPageField, string>> & {
  [ConnectPageField.origin]: ConnectPageOrigin;
  [ConnectPageField.subscriptionPeiod]?: Period;
};
