import React from 'react';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import * as css from './ChooseTemplateEmptyState.css';
import { ReactComponent as MagnifyerIcon } from './magnifyer.svg';
import { OnboardingTourHTMLElementId } from '@globals';

export interface ChooseTemplateEmptyStateProps {
  header: string;
  message: string;
  buttonText: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const ChooseTemplateEmptyState: React.FC<ChooseTemplateEmptyStateProps> =
  ({ onClick, buttonText, header, message }) => {
    return (
      <Flex flexDirection="column" className={css.emptyState}>
        <MagnifyerIcon />

        <Spacer factor={4} />

        <Flex
          flexDirection="column"
          alignItems="center"
          id={OnboardingTourHTMLElementId.WATemplateCreateContainer}
        >
          <Type as="p" size="15px" color="greyDark">
            {header}
          </Type>
          <Type as="p" size="15px" color="greyDark">
            {message}
          </Type>
        </Flex>

        <Spacer factor={4} />

        <Button
          intent="primary"
          onClick={onClick}
          data-testid="whatsapp-templates__empty-state__button"
        >
          {buttonText}
        </Button>
      </Flex>
    );
  };
