import React from 'react';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { Query } from '@apollo/react-components';
import {
  BlocksSelectorDataQuery,
  BlocksSelectorDataQuery_bot_blocksGroups as BlocksGroup,
  BlocksSelectorDataQuery_bot_flowGroupsSuggest,
  BlocksSelectorDataQueryVariables,
} from './@types/BlocksSelectorDataQuery';

interface BlocksSelectorDataProps {
  botId: string;
  children: (params: {
    blocksGroups: BlocksGroup[];
    flowsGroups: BlocksSelectorDataQuery_bot_flowGroupsSuggest[];
    loading: boolean;
    error: ApolloError | undefined;
  }) => JSX.Element | null;
}

export const FLOW_GROUPS_SUGGEST_FRAGMENT = gql`
  fragment flowGroupsSuggestFragment on Bot {
    id
    flowGroupsSuggest {
      id
      title
      flows {
        id
        title
        is_flow
        platform
      }
    }
  }
`;

export const FLOW_GROUPS_SUGGEST_QUERY = gql`
  query FlowGroupsSuggestQuery($botId: String!) {
    bot(id: $botId) {
      id
      ...flowGroupsSuggestFragment
    }
  }
  ${FLOW_GROUPS_SUGGEST_FRAGMENT}
`;

export const BLOCKS_SELECTOR_DATA_QUERY = gql`
  query BlocksSelectorDataQuery($botId: String!) {
    bot(id: $botId) {
      id
      blocksGroups {
        id
        title
        builtin
        sequence
        synced_from {
          bot {
            title
          }
        }
        blocks {
          id
          title
          removed
          is_flow
        }
      }
      ...flowGroupsSuggestFragment
    }
  }
  ${FLOW_GROUPS_SUGGEST_FRAGMENT}
`;

export const BlocksSelectorData: React.FC<BlocksSelectorDataProps> = ({
  botId,
  children,
}) => {
  return (
    <Query<BlocksSelectorDataQuery, BlocksSelectorDataQueryVariables>
      query={BLOCKS_SELECTOR_DATA_QUERY}
      variables={{ botId }}
      skip={!botId}
    >
      {({ data, loading, error }) => {
        const { blocksGroups = [], flowGroupsSuggest: flowsGroups } =
          data?.bot || {};
        return children({
          blocksGroups,
          loading,
          error,
          flowsGroups: flowsGroups || [],
        });
      }}
    </Query>
  );
};
