import React from 'react';
import { Spacer } from '@ui/Spacer';
import {
  DynamicListView,
  DynamicListViewProps,
} from '@components/DynamicListView';
import { TableRow } from './TableRow';
import * as css from './TemplatesTable.css';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TableRowBox } from './TableRowBox';
import { OnboardingTourHTMLElementId } from '@globals';
import { Template } from '../../../types';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

export interface TemplatesTableProps
  extends Pick<DynamicListViewProps, 'loadMoreItems'> {
  items: Array<Template>;
  value: string;
  onChange: React.FormEventHandler<HTMLInputElement>;
}

export const TemplatesTable: React.FC<TemplatesTableProps> = ({
  items,
  value,
  onChange,
  loadMoreItems,
}) => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();

  return (
    <table className={css.table} data-testid="whatsapp-templates__table">
      <TableRowBox>
        <th>
          {t(
            'pages.BotPage.ReengageWhatsappTab.templateModal.table.header.name',
          )}
        </th>
        <th>
          {t(
            'pages.BotPage.ReengageWhatsappTab.templateModal.table.header.language',
          )}
        </th>
        <th>
          {t(
            'pages.BotPage.ReengageWhatsappTab.templateModal.table.header.status',
          )}
        </th>
      </TableRowBox>

      <Spacer factor={2.5} />

      <DynamicListView
        height={isCreateWhatsappTemplateInCfEnable ? 354 : 294}
        width="100%"
        itemSize={52}
        isItemLoaded={(index) => index < items.length}
        itemCount={items.length}
        threshold={20}
        loadMoreItems={loadMoreItems}
        style={{ width: 'unset', marginRight: '-16px' }}
      >
        {({ index, style }) => {
          const data = items[index];
          return (
            <TableRow
              rowBoxId={`${OnboardingTourHTMLElementId.WATemplateTableRow}${index}`}
              name={data.id}
              id={data.id}
              onChange={onChange}
              value={value}
              style={style}
              rowData={data}
            />
          );
        }}
      </DynamicListView>
    </table>
  );
};
