import React, { useMemo } from 'react';
import { useAutomateEnabled } from '@utils/Data/Admin/Automate';
import { Tabs2, TabItem } from '../../../modern-ui/Tabs2';
import { ReactComponent as BlocksIcon } from './icons/ic_blocks_small.svg';
import { ReactComponent as UrlIcon } from './icons/ic_link_small.svg';
import { ReactComponent as PhoneCallIcon } from './icons/ic_call_small.svg';
import { ReactComponent as BuyIcon } from './icons/ic_buy_small.svg';
import { ButtonPopupTabsTypes } from '../common/tabs';
import * as css from './ButtonPopupTabs.css';

export interface ButtonPopupTabsProps {
  defaultTab?: string;
  onChangeTab: (id: string) => void;
  showBuyTab?: boolean;
  showPhoneCallTab?: boolean;
}

export const ButtonPopupTabs: React.FC<ButtonPopupTabsProps> = (props) => {
  const { isAutomateEnabled } = useAutomateEnabled();
  const tabs: TabItem[] = useMemo(
    () => [
      {
        id: ButtonPopupTabsTypes.blocks,
        title: isAutomateEnabled
          ? window.i18next.t('ButtonPopupTabs-string-1992-blocks')
          : window.i18next.t('ButtonPopupTabs-string-6797-flows'),
        icon: <BlocksIcon />,
      },
      {
        id: ButtonPopupTabsTypes.url,
        title: 'URL',
        icon: <UrlIcon />,
      },
      {
        id: ButtonPopupTabsTypes.phoneCall,
        title: window.i18next.t('ButtonPopupTabs-string-1098-phone-call'),
        hide: !props.showPhoneCallTab,
        icon: <PhoneCallIcon />,
      },
      {
        id: ButtonPopupTabsTypes.buy,
        title: window.i18next.t('ButtonPopupTabs-string-6717-buy'),
        hide: !props.showBuyTab,
        icon: <BuyIcon />,
      },
    ],
    [isAutomateEnabled, props.showBuyTab, props.showPhoneCallTab],
  );

  return (
    <Tabs2
      className={css.tabs}
      items={tabs}
      active={props.defaultTab || 'blocks'}
      onChangeTab={(tab) => props.onChangeTab(tab)}
    />
  );
};
