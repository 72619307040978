import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Modal } from '@services/.';
import { CloseHandler } from '@services/ModalService/types';
import { useUnbindPage } from '@utils/FacebookPages/useUnbindPage';
import { useCurrentBotId } from '@utils/Routing';
import { Flex } from '@ui/Flex';
import { RemoveEntityDialog } from '@components/RemoveEntityDialog';

import * as css from './FacebookPagesList.css';

const DisconnectPageDialog = ({
  pageId,
  close,
}: {
  pageId: string;
  close: CloseHandler;
}) => {
  const { t } = useSafeTranslation();
  const botId = useCurrentBotId()!;
  const { unbindPage } = useUnbindPage(botId);

  const handleDisconnectPage = async () => {
    await unbindPage(pageId);
    close();
  };

  return (
    <RemoveEntityDialog
      onSubmit={handleDisconnectPage}
      onDismiss={close}
      renderHeading={() =>
        t('modernComponents.FacebookPagesSetup.disconnectPage.title')
      }
      renderActionText={() =>
        t('modernComponents.FacebookPagesSetup.disconnectPage.action')
      }
      renderNoteText={() => (
        <Flex className={css.disconnectModalDescription}>
          {t('modernComponents.FacebookPagesSetup.disconnectPage.description')}
        </Flex>
      )}
    />
  );
};

export const useShowDisconnectPageDialog = () => {
  const showDisconnectPageDialog = (pageId: string) => {
    Modal.showImmediate(
      ({ close }) => <DisconnectPageDialog pageId={pageId} close={close} />,
      { id: 'disconnectPageDialog', mobileAdaptive: true },
    );
  };
  return { showDisconnectPageDialog };
};
