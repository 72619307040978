import React, { useCallback, useEffect, useRef, useState } from 'react';
import useOnClickOutside from 'use-onclickoutside';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';
import * as css from '../BotList.css';
import { Button, ButtonIntent } from '../../../modern-ui/_deprecated/Button';
import {
  BotsListQuery_bots,
  BotsListQuery,
} from '../../../common/services/GQLqueries/@types/BotsListQuery';
import { toaster } from '../../../services/MessageService';
import { ServiceMessageType } from '../../../modern-ui/ServiceMessage2';
import { sendEvent } from '../../../utils/Analytics';

import {
  BOTS_LIST_QUERY,
  UPDATE_BOT_TITLE_MUTATION,
} from '../../../common/services/GQLqueries/BotGQService.const';

interface BotTitleInputProps {
  bot: BotsListQuery_bots;
  onClose: () => void;
  rankIndex: number;
}

function showRenameError(error: Error) {
  log.warn({ error, msg: 'Rename bot' });
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: window.i18next.t(
        'BotTitleInput-string--204-couldnt-rename-bot-please-try-again-later',
      ),
    },
  });
}

const ESC_KEYCODE = 27;

export const BotTitleInput: React.FC<BotTitleInputProps> = (props) => {
  const { bot, onClose } = props;

  const ref = useRef(null);
  useOnClickOutside(ref, onClose);

  const [title, updateTitle] = useState(bot.title);
  const [renameBot] = useMutation(UPDATE_BOT_TITLE_MUTATION, {
    onError: (error) => {
      showRenameError(error);
      onClose();
    },
    optimisticResponse: {
      updateBotTitle: true,
    },
  });

  const handleEscPress = useCallback(
    (event: KeyboardEvent) => {
      if (event.keyCode === ESC_KEYCODE) {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleEscPress);
    return () => document.removeEventListener('keydown', handleEscPress);
  }, [handleEscPress]);

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    updateTitle(event.target.value);
  }

  function handleSubmit(event: React.FormEvent) {
    event.preventDefault();
    const clearTitle = title.trim();
    if (clearTitle && bot.title !== clearTitle) {
      renameBot({
        variables: {
          botId: bot.id,
          title: clearTitle,
        },
        update: (store, options) => {
          if (!options.data || !options.data.updateBotTitle) {
            return;
          }

          const data: BotsListQuery | null = store.readQuery({
            query: BOTS_LIST_QUERY,
          });
          if (!data) {
            return;
          }

          const mutateBot = data.bots.find(({ id }) => id === bot.id);
          if (!mutateBot) {
            return;
          }

          mutateBot.title = clearTitle;
          store.writeQuery({
            query: BOTS_LIST_QUERY,
            data,
          });
        },
      });
    }

    onClose();
  }

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <form
      ref={ref}
      className={css.renameForm}
      onSubmit={handleSubmit}
      onClick={(event) => event.stopPropagation()}
    >
      <input
        type="text"
        name="botTitle"
        className={css.renameInput}
        value={title}
        onClick={(event) => event.stopPropagation()}
        onChange={handleChange}
        autoFocus
        maxLength={255}
      />
      <Button
        type="submit"
        intent={ButtonIntent.primary}
        onClick={(event) => {
          event.stopPropagation();
          sendEvent({
            category: 'bot list',
            action: 'rename done',
            label: 'bot',
            propertyBag: {
              botId: bot.id,
              rank: props.rankIndex,
              'new title': title,
            },
          });
        }}
        disabled={title.trim().length === 0}
      >
        {window.i18next.t('BotTitleInput-JSXText-6362-done')}
      </Button>
    </form>
  );
};
