import React from 'react';
import cn from 'classnames';
import * as css from './TableRowBox.css';

export interface TableRowBoxProps {
  id?: string;
  style?: React.CSSProperties;
  className?: string;
}

export const TableRowBox: React.FC<TableRowBoxProps> = ({
  id,
  children,
  className,
  style,
}) => (
  <tr
    id={id}
    className={cn(css.row, className)}
    style={style}
    data-testid="whatsapp-templates__table-row"
  >
    {children}
  </tr>
);
