import React, { useEffect } from 'react';

interface CloseDialogProps {
  onClose: VoidFunction;
}

export const CloseDialog: React.FC<CloseDialogProps> = ({ onClose }) => {
  useEffect(() => {
    onClose();
  }, [onClose]);

  return null;
};
