import React, { useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { PaymentErrorProps } from '@components/Payments/PaymentDialog/PaymentError';
import {
  getErrorDescription,
  getErrorHeader,
} from '@components/Payments/PaymentDialog/PaymentError/messages';
import { sendEvent } from '@utils/Analytics';
import { ReactComponent as ErrorIcon } from '../assets/Error.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';

interface PaymentFailureDialogProps extends PaymentErrorProps {}

export const PaymentFailureDialog: React.FC<PaymentFailureDialogProps> = ({
  error,
  onDismiss,
  onTryAgain,
}) => {
  const { t } = useSafeTranslation();

  useEffect(() => {
    sendEvent({
      category: 'pro plan payments',
      action: 'show error popup',
      label: '3d secure',
      propertyBag: {
        declineCode: error?.declineCode,
      },
    });
  }, [error]);

  return (
    <DefaultPricingDialog
      title={getErrorHeader(error)}
      icon={<ErrorIcon />}
      description={getErrorDescription(error)}
      button={
        <DefaultPricingBtn onClick={onTryAgain}>
          {t('pages.Pricing.dialogs.PaymentFailureDialog.button')}
        </DefaultPricingBtn>
      }
      onClose={onDismiss}
    />
  );
};
