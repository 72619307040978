import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import {
  DeleteUserMutation,
  DeleteUserMutationVariables,
} from './@types/DeleteUserMutation';

const DELETE_USER_MUTATION = gql`
  mutation DeleteUserMutation(
    $botId: String!
    $segmentation: SegmentationInput!
  ) {
    deleteUsers(botId: $botId, segmentation: $segmentation)
  }
`;

export const useDeleteUsers = ({
  onCompleted,
}: {
  onCompleted: VoidFunction;
}) => {
  const [deleteUsers, mutationData] = useMutation<
    DeleteUserMutation,
    DeleteUserMutationVariables
  >(DELETE_USER_MUTATION, { onCompleted });

  return { deleteUsers, ...mutationData };
};
