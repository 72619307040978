import React, { useCallback } from 'react';
import { Modal } from '@services/index';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { toaster } from '@services/MessageService';
import { DeleteBotDialog } from '@components/DeleteBotDialog';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import { Complitable } from '../types';
import { BOT_NAME_TRIM_LIMIT } from '@components/BotActions/consts';

export interface DeleteBot {
  id: string;
  title: string;
}

export interface DeleteBotDialogProps<T> extends Complitable<T> {}

export const useDeleteBotDialog = <T extends DeleteBot>({
  onCompleted,
}: DeleteBotDialogProps<T> = {}) => {
  const { t } = useSafeTranslation();
  const { isSmallScreenSize } = useDeviceMedia();

  const showDeleteBotDialog = useCallback(
    (bot: T) => {
      Modal.show(
        ({ close }) => (
          <DeleteBotDialog
            botId={bot.id}
            mobileAdaptive={isSmallScreenSize}
            onComplete={() => {
              close();
              onCompleted?.(bot);

              toaster.default({
                payload: {
                  message: t('BotList.notifications.botDeleted', {
                    botname: truncateWithEllipses(
                      bot.title,
                      BOT_NAME_TRIM_LIMIT,
                    ),
                  }),
                  showCloseButton: true,
                },
              });
            }}
            onDismiss={close}
          />
        ),
        { mobileAdaptive: true },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, isSmallScreenSize, onCompleted],
  );

  return showDeleteBotDialog;
};
