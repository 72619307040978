import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useCurrentBotId } from '@utils/Routing';
import {
  BotOptions,
  DeleteBot,
  LeaveBot,
  RenameBot,
  CopyBot,
  useDeleteBotDialog,
  useLeaveBotDialog,
  useCopyBotDialog,
  useStateForRenameBot,
} from '@components/BotActions';

export interface BotOptionsHandlerProps {
  userId: string;
}

interface OptionsHandlerBot extends RenameBot, DeleteBot, LeaveBot, CopyBot {}

export const useBotOptionsHandler = ({ userId }: BotOptionsHandlerProps) => {
  const botId = useCurrentBotId()!;
  const history = useHistory();

  const goToBotsPage = useCallback(
    (bot) => {
      if (bot.id === botId) {
        history.push('/bots');
      }
    },
    [botId, history],
  );

  const showCopyBotDialog = useCopyBotDialog();
  const showBotLeaveDialog = useLeaveBotDialog({
    userId,
    onCompleted: goToBotsPage,
  });
  const showDeleteBotDialog = useDeleteBotDialog({ onCompleted: goToBotsPage });
  const { setRenameMode, removeRenameMode, isRenameMode, setIsRenameMode } =
    useStateForRenameBot();

  const handleOptionSelect = useCallback(
    (bot: OptionsHandlerBot, type: BotOptions) => {
      const optionsMap: Record<BotOptions, (bot: OptionsHandlerBot) => void> = {
        [BotOptions.rename]: setRenameMode,
        [BotOptions.delete]: showDeleteBotDialog,
        [BotOptions.leave]: showBotLeaveDialog,
        [BotOptions.copy]: (bot) =>
          showCopyBotDialog(bot, { copyToWorkspace: false }),
      };
      optionsMap[type](bot);
    },
    [showDeleteBotDialog, showBotLeaveDialog, showCopyBotDialog, setRenameMode],
  );

  return {
    isRenameMode,
    setIsRenameMode,
    exitRenameMode: removeRenameMode,
    handleOptionSelect,
  } as const;
};
