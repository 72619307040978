import React from 'react';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Color, ColorKey, HEXColors } from '@ui/_common/colors';
import * as css from './StatusColumn.css';

export interface StatusStyles {
  statusColor: ColorKey;
  textColor: ColorKey;
  textHEXColor: HEXColors;
}

export interface StatusColumnProps extends StatusStyles {
  name: string;
}

export const StatusColumn: React.FC<StatusColumnProps> = ({
  name,
  textColor,
  statusColor,
}) => (
  <div className={css.wrapper} style={{ backgroundColor: Color[statusColor] }}>
    <TextEllipsis width="180px">
      <Type
        size="15px"
        color={textColor}
        data-testid="whatsapp-templates__status"
      >
        {name}
      </Type>
    </TextEllipsis>
  </div>
);
