import React from 'react';
import { CancelProTranslation } from '@components/CancelProDialog/CancelProTranslation';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import oldPlansImg from './oldPlans.png';
import oldPlansMobileImg from './oldPlansMobile.png';
import { Button } from '@ui/Button';
import { formatPrice } from '@utils/Format';
import { numberFormatter } from '@utils/Format/numberFormatter';
import { CancelPlanBlock } from '../CancelPlanBlock/CancelPlanBlock';
import { useDeleteUsers } from './useDeleteUsers';
import { ChurnSurveyAdviceType, ParametersOperator } from '@globals';
import { Modal } from '@services/index';
import { RemoveUserSuccesDialog } from './RemoveUserSuccesDialog';
import { DialogContainer } from '../DialogContainer/DialogContainer';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import line from './line.svg';
import css from './RemoveUsersDialog.css';

interface ImproveBotDialogProps {
  totalUsers: number;
  currentAmount: number;
  futureAmount: number;
  currency: string;
  onClose: VoidFunction;
  onCancellPro: VoidFunction;
  botId: string;
  churnSurveyAdviceType: ChurnSurveyAdviceType;
}

export const RemoveUsersDialog: React.FC<ImproveBotDialogProps> = ({
  totalUsers,
  currentAmount,
  futureAmount,
  currency,
  onCancellPro,
  onClose,
  botId,
  churnSurveyAdviceType,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const userCount = numberFormatter(totalUsers ?? 0);
  const currentCostPerMonth = formatPrice(currentAmount, {
    currency,
    decimals: 0,
  });
  const futureCostPerMonth = formatPrice(futureAmount, {
    currency,
    decimals: 0,
  });

  const { deleteUsers, loading: deleteUsersLoading } = useDeleteUsers({
    onCompleted: () => {
      onClose();
      Modal.show(
        ({ close }) => (
          <RemoveUserSuccesDialog
            onClose={close}
            userCount={userCount}
            costPerMonth={futureCostPerMonth}
          />
        ),
        { mobileAdaptive: true },
      );
    },
  });

  return (
    <DialogContainer onClose={onClose}>
      <Flex
        flexDirection="column"
        className={css.header}
        style={{ backgroundImage: `url(${line})` }}
      >
        <Type size="44px" weight="extrabold" color="accent1Semilight">
          <CancelProTranslation i18nKey="CancelPro.RemoveUsersDialog.header" />
        </Type>
        <Spacer factor={2} />
        <Type size="24px" color="accent1Tertiary">
          <CancelProTranslation i18nKey="CancelPro.RemoveUsersDialog.description" />
        </Type>
      </Flex>
      <Spacer factor={1} />
      <Flex flexDirection="column" className={css.contentContainer}>
        <img
          src={isSmallScreenSize ? oldPlansMobileImg : oldPlansImg}
          alt="old-plans"
          className={css.removeSvg}
        />
        <Flex flexDirection="column" className={css.textContent}>
          <Type size="32px" weight="bold" color="accent1Light">
            <CancelProTranslation
              i18nKey="CancelPro.RemoveUsersDialog.removePrice"
              options={{
                userCount,
                cost: futureCostPerMonth,
              }}
            />
          </Type>
          <Spacer factor={5} />
          <Type size="18px" weight="medium" color="accent1Light">
            <CancelProTranslation
              i18nKey="CancelPro.RemoveUsersDialog.costPerMonth"
              options={{
                costPerMonth: currentCostPerMonth,
              }}
            />
          </Type>
        </Flex>
        <Spacer factor={7} />
        <Button
          intent="secondary"
          className={css.removeUsersAction}
          loading={deleteUsersLoading}
          onClick={async () => {
            sendEvent({
              category: 'churn survey advice dialog',
              action: 'delete users button click',
              propertyBag: {
                botId,
                churnSurveyAdviceType,
              },
            });
            await deleteUsers({
              variables: {
                botId: botId!,
                segmentation: {
                  operation: ParametersOperator.and,
                  parameters: [],
                  valid: true,
                },
              },
            });
          }}
        >
          <CancelProTranslation i18nKey="CancelPro.RemoveUsersDialog.removeUsers" />
        </Button>
      </Flex>
      <Spacer factor={5} />
      <CancelPlanBlock
        botId={botId}
        churnSurveyAdviceType={churnSurveyAdviceType}
        onCancellPro={onCancellPro}
      />
    </DialogContainer>
  );
};
