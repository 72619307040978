import React from 'react';
import Downshift from 'downshift';
import cn from 'classnames';
import { Manager, Popper, Reference } from 'react-popper';
import { botActionsSorted, BotOptionsItemProps } from '@components/BotActions';
import { useBotOptionsMenu } from './hooks';
import {
  Button,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import * as css from '../BotList.css';
import { RoleConsumer, RoleProvider } from '../../../utils/Roles';
import { Permission } from '../../../common/services/RoleService';
import { Menubox, MenuItem } from '../../../modern-ui/Menu';
import { CenteredLoader } from '../../../modern-ui/Loader';
import { sendEvent } from '../../../utils/Analytics';
import { Icon } from '@ui/Icon';

interface BotOptionsMenuContentProps {
  botId: string;
  getItemProps: Function;
  highlightedIndex: number | null;
  scheduleUpdate: VoidFunction;
}

const BotOptionsMenuContent: React.FC<BotOptionsMenuContentProps> = (props) => {
  const { botId, getItemProps, highlightedIndex, scheduleUpdate } = props;
  const { bot, loading: currentBotLoading } = useBotOptionsMenu(botId);

  return (
    <Menubox className={css.settingsMenuBox}>
      <RoleProvider botId={botId}>
        <RoleConsumer domain="bot" can={Permission.EDIT}>
          {({ allowed: canEdit }) => (
            <RoleConsumer domain="bot" can={Permission.VIEW}>
              {({ allowed: canView, loading: viewRoleLoading }) => {
                const loading = viewRoleLoading || currentBotLoading || !bot;

                if (loading) {
                  return (
                    <div className={css.settingsMenuBoxFetchingStatus}>
                      <CenteredLoader />
                    </div>
                  );
                }

                const menuItems = botActionsSorted().filter((item) =>
                  item.canBeShown(bot, { canEdit, canView }),
                );

                scheduleUpdate();

                return menuItems.map((item, idx) => (
                  <React.Fragment key={item.title}>
                    <MenuItem
                      {...getItemProps({
                        item,
                        onClick: (event: React.SyntheticEvent) => {
                          event.stopPropagation();
                          sendEvent({
                            category: 'bot list',
                            action: item.title?.toLowerCase(),
                            label: 'bot',
                            propertyBag: {
                              botId: bot.id,
                            },
                          });
                        },
                      })}
                      active={highlightedIndex === idx}
                      title={item.title}
                      data-testid={`botlist_action_item_button_${item.type}`}
                      className={cn(css.settingsMenuItem, {
                        [css.settingsMenuItemActive]: highlightedIndex === idx,
                      })}
                    />
                  </React.Fragment>
                ));
              }}
            </RoleConsumer>
          )}
        </RoleConsumer>
      </RoleProvider>
    </Menubox>
  );
};

export interface BotOptionsMenuProps<T> {
  botId: string;
  disabled: boolean;
  onActionSelect: (item: BotOptionsItemProps<T>) => void;
  isLast: boolean;
}

export const BotOptionsMenu = <T,>({
  botId,
  disabled,
  onActionSelect,
  isLast,
}: BotOptionsMenuProps<T>) => {
  return (
    <Downshift
      selectedItem={null}
      onChange={onActionSelect}
      itemToString={(item) => item?.title}
    >
      {(downshift) => {
        const {
          getItemProps,
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
        } = downshift;

        return (
          <div>
            <Manager>
              <Reference>
                {({ ref }) => (
                  <div ref={ref}>
                    <Button
                      className={css.settingsButton}
                      renderIcon={() => <Icon icon="more" />}
                      intent={ButtonIntent.default}
                      size={ButtonSize.s}
                      tall
                      disabled={disabled}
                      data-testid="botlist_action_toggle"
                      {...getToggleButtonProps({
                        onClick(event) {
                          event.stopPropagation();
                          sendEvent({
                            category: 'bot list',
                            action: 'click menu',
                            label: 'bot',
                          });
                        },
                      })}
                    />
                  </div>
                )}
              </Reference>
              <div {...getMenuProps()}>
                {isOpen && (
                  <Popper placement={isLast ? 'top-end' : 'bottom-end'}>
                    {({ ref, style, scheduleUpdate }) => (
                      <div ref={ref} style={{ ...style, zIndex: 1 }}>
                        <BotOptionsMenuContent
                          botId={botId}
                          getItemProps={getItemProps}
                          highlightedIndex={highlightedIndex}
                          scheduleUpdate={scheduleUpdate}
                        />
                      </div>
                    )}
                  </Popper>
                )}
              </div>
            </Manager>
          </div>
        );
      }}
    </Downshift>
  );
};
