import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from 'react-apollo';
import React, { useMemo, useState } from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { CenteredLoader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { Input } from '@ui/Input';
import { GroupingCombobox } from '@components/GroupingCombobox/GroupingCombobox';
import { useCurrentBotId } from '@utils/Routing';
import { CHOOSE_FLOW_BLOCK_QUERY } from './queries';
import { mapBlocksToGroupingComboboxGroups } from './helpers';
import {
  ChooseFlowBlockQuery,
  ChooseFlowBlockQueryVariables,
} from './@types/ChooseFlowBlockQuery';

export interface ChooseFlowBlockDialogProps {
  flowId: string;
  currentBlockId?: string;
  onChooseBlock?(id: string): void;
  onConnectBlock?(id: string): void;
  onDismiss(): void;
}

export const ChooseFlowBlockDialog: React.FC<ChooseFlowBlockDialogProps> = ({
  flowId,
  currentBlockId,
  onChooseBlock,
  onConnectBlock,
  onDismiss,
}) => {
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);
  const botId = useCurrentBotId()!;
  const { data, loading } = useQuery<
    ChooseFlowBlockQuery,
    ChooseFlowBlockQueryVariables
  >(CHOOSE_FLOW_BLOCK_QUERY, {
    variables: { botId, flowId },
  });

  const blocks = data?.bot.flowBlocks ?? [];

  const groups = useMemo(
    () => mapBlocksToGroupingComboboxGroups(blocks, currentBlockId),
    [blocks, currentBlockId],
  );

  const { t } = useSafeTranslation();

  return (
    <DefaultDialog
      dialogStyle={{ width: '432px' }}
      header={window.i18next.t(
        'ChooseFlowBlockDialog-string-2065-choose-block',
      )}
      onDismiss={onDismiss}
    >
      <Type weight="semibold" size="15px_DEPRECATED">
        {window.i18next.t('ChooseFlowBlockDialog-JSXText-6838-block')}
      </Type>
      <Spacer factor={1} />
      {loading ? (
        <CenteredLoader />
      ) : (
        <GroupingCombobox
          simpleComboboxProps={{
            renderInput: ({ getInputProps, openMenu }) => (
              <Input
                {...getInputProps({
                  name: 'flowName',
                  placeholder: t(
                    groups.length
                      ? window.i18next.t(
                          'ChooseFlowBlockDialog-string-7282-select-block',
                        )
                      : window.i18next.t(
                          'ChooseFlowBlockDialog-string--332-no-blocks-yet',
                        ),
                  ),
                  onFocus: () => {
                    openMenu();
                  },
                })}
                disabled={!groups.length}
              />
            ),
          }}
          groups={groups}
          selectedItemId={selectedBlockId}
          onSelect={(id) => {
            if (id && id !== selectedBlockId) {
              onChooseBlock?.(id);
              setSelectedBlockId(id);
            }
          }}
        />
      )}
      <Spacer factor={10} />
      <Flex justifyContent="flex-end">
        <Button
          disabled={!selectedBlockId || loading}
          onClick={() => {
            if (selectedBlockId) {
              onConnectBlock?.(selectedBlockId);
            }
          }}
        >
          {window.i18next.t('ChooseFlowBlockDialog-JSXText--135-connect')}
        </Button>
      </Flex>
    </DefaultDialog>
  );
};
