import React, { useState } from 'react';
import cn from 'classnames';
import { Modal } from '../../../../modern-ui/Modal';
import { DefaultDialog } from '../../../../modern-ui/Dialog/DefaultDialog';
import * as css from './Banner.css';

export type ToggleFunction = (open: boolean) => void;
interface BannerProps {
  children(toggleDialog: ToggleFunction): React.ReactNode;
  renderDialog?(toggleDialog: ToggleFunction): React.ReactNode;
  loading?: boolean;
}

export const Banner: React.FC<BannerProps> = ({
  children,
  renderDialog,
  loading,
}) => {
  const [isOpen, setOpenDialog] = useState(false);

  if (loading) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    return <BannerPlaceholder />;
  }

  const toggleDialog: ToggleFunction = (open) => setOpenDialog(open);

  return (
    <>
      <div className={css.banner}>{children(toggleDialog)}</div>

      {renderDialog && isOpen && (
        <Modal onDismiss={() => toggleDialog(false)}>
          {renderDialog(toggleDialog)}
        </Modal>
      )}
    </>
  );
};

export const BannerPlaceholder: React.FC<{}> = () => (
  <>
    <div className={css.banner}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <TextPlaceholder />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <PlaceholderItem className={css.buttonPlaceholder} />
    </div>
  </>
);

export const Text: React.FC<{}> = ({ children }) => (
  <div className={css.bannerText}>{children}</div>
);

export const PlaceholderItem: React.FC<{ className?: string }> = ({
  className,
}) => (
  <div
    data-testid="audience-sync__placeholder"
    className={cn(css.placeholder, className)}
  />
);

const TextPlaceholder: React.FC<{}> = () => (
  <div className={css.placeholderContainer}>
    <PlaceholderItem />
    <PlaceholderItem />
  </div>
);

interface BannerDialogProps {
  onDismiss(): void;
}

export const BannerDialog: React.FC<BannerDialogProps> = ({
  children,
  onDismiss,
}) => (
  <DefaultDialog
    header={window.i18next.t('Banner-string-1232-send-segments-to-ads-manager')}
    onDismiss={onDismiss}
    dialogStyle={{ width: '620px' }}
    contentStyle={{
      maxHeight: 'calc(100vh - 100px)',
      display: 'flex',
      flexDirection: 'column',
    }}
  >
    <div className={css.dialogContent}>{children}</div>
  </DefaultDialog>
);
