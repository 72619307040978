import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as RocketIcon } from '../assets/Rocket.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';
import { getTierNameByType } from '../../constants';
import { TierType } from '@globals';

interface SwitchTierCongratsDialogProps {
  name?: string;
  tier: TierType;
  onDismiss(): void;
}

export const SwitchTierCongratsDialog: React.FC<SwitchTierCongratsDialogProps> =
  ({ name, tier, onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultPricingDialog
        title={
          name
            ? t(
                'pages.Pricing.dialogs.SwitchTierCongratsDialog.titleWithName',
                {
                  name,
                },
              )
            : t('pages.Pricing.dialogs.SwitchTierCongratsDialog.title')
        }
        icon={<RocketIcon />}
        description={t(
          'pages.Pricing.dialogs.SwitchTierCongratsDialog.description',
          { tier: getTierNameByType(tier) },
        )}
        button={
          <DefaultPricingBtn onClick={onDismiss}>
            {t('pages.Pricing.dialogs.SwitchTierCongratsDialog.button')}
          </DefaultPricingBtn>
        }
        onClose={onDismiss}
      />
    );
  };
