import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Input } from '@ui/Input';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { TemplatesTable } from './components/TemplatesTable';
import { TemplatesTableLoader } from './components/TemplatesTableLoader';
import { TemplatesNotFound } from './TemplatesNotFound';
import { TemplatesCreatedIn } from './TemplatesCreatedIn';
import { OnboardingEmitter } from '@components/Onboarding/OnboardingTour/utils/onboardingEmitter';
import { OnboardingTourEventType, OnboardingTourHTMLElementId } from '@globals';
import { Template } from '@components/ChooseWhatsappTempalteDialog/types';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';

export interface ChooseTemplateContentProps {
  templates: Array<Template>;
  loading: boolean;
  disabled: boolean;
  initTemplateId?: string;
  searchValue: string;
  setSearchValue(value: string): void;
  fetchMore(): Promise<unknown>;
  onSubmit: (templateId: string) => void;
  isNothingFound: boolean;
}

export const ChooseTemplateContent: React.FC<ChooseTemplateContentProps> = ({
  searchValue,
  setSearchValue,
  loading,
  disabled,
  templates,
  fetchMore,
  onSubmit,
  initTemplateId,
  isNothingFound,
}) => {
  const { t } = useSafeTranslation();
  const { isCreateWhatsappTemplateInCfEnable } =
    useCreateWhatsappTemplateInCfEnable();
  const [templateId, setTemplateId] = useState<string | null>(
    initTemplateId ?? templates[0]?.id,
  );
  const onSubmitHandler = useCallback(() => {
    if (!templateId) {
      return;
    }
    onSubmit(templateId);
    OnboardingEmitter.emit(OnboardingTourEventType.click, {
      element: OnboardingTourHTMLElementId.WATemplateModalNextButton,
      value: null,
    });
  }, [onSubmit, templateId]);

  const selectedTemplateIndex = useMemo(
    () => templates.findIndex((item) => item.id === templateId),
    [templates, templateId],
  );

  useEffect(() => {
    OnboardingEmitter.emit(OnboardingTourEventType.change, {
      element: `${OnboardingTourHTMLElementId.WATemplateTableRow}${selectedTemplateIndex}`,
      value: templateId,
    });
  }, [selectedTemplateIndex, templateId]);

  const getContent = () => {
    if (isNothingFound) {
      return <TemplatesNotFound />;
    }

    return (
      <>
        {!isCreateWhatsappTemplateInCfEnable ? (
          <>
            <TemplatesCreatedIn />

            <Spacer factor={5} />
          </>
        ) : null}

        {loading ? (
          <TemplatesTableLoader />
        ) : (
          <TemplatesTable
            value={templateId || ''}
            // @ts-expect-error
            onChange={(e) => setTemplateId(e.target.value)}
            loadMoreItems={fetchMore}
            items={templates}
          />
        )}
      </>
    );
  };

  return (
    <Flex flexDirection="column">
      <Input
        inputContainerId={
          OnboardingTourHTMLElementId.ChooseWATemplateDialogInputSearch
        }
        autoFocus
        value={searchValue}
        onChange={(e) => {
          setSearchValue(e.target.value);
          OnboardingEmitter.emit(OnboardingTourEventType.change, {
            element:
              OnboardingTourHTMLElementId.ChooseWATemplateDialogInputSearch,
            value: e.target.value,
          });
        }}
        renderIcon={() => <Icon icon="search" color="grey" />}
        placeholder={t(
          'pages.BotPage.ReengageWhatsappTab.templateModal.placeholder',
        )}
        data-testid="whatsapp-templates__search-input"
      />

      <Spacer factor={5} />

      {getContent()}

      <Spacer factor={5} />

      <Flex justifyContent="flex-end">
        <Button
          id={OnboardingTourHTMLElementId.WATemplateModalNextButton}
          intent="primary"
          disabled={isNothingFound || disabled || !templateId}
          onClick={onSubmitHandler}
          data-testid="whatsapp-templates__submit"
        >
          {t('pages.BotPage.ReengageWhatsappTab.templateModal.submitButton')}
        </Button>
      </Flex>
    </Flex>
  );
};
