import React from 'react';
import { Type } from '../../../../../modern-ui/Type';
import * as css from '../SyncBanner.css';

export const FacebookAdAccountName: React.FC<{}> = ({ children }) => (
  <div className={css.connectedFbAdAccountName}>
    <Type as="p" noWrap size="15px_DEPRECATED">
      {children}
    </Type>
  </div>
);
