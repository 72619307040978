import gql from 'graphql-tag';
import React, { useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useMutation } from '@apollo/react-hooks';

import { Modal } from '@services/.';
import { sendEvent } from '@utils/Analytics';
import { DefaultDialog } from '@ui/Dialog';
import { Spacer } from '@ui/Spacer';
import { Flex } from '@ui/Flex';
import { Button } from '@ui/Button';

import { InitAuthProps } from '@utils/AuthFlow';
import { Level, log } from 'cf-common/src/logger';
import ApplauseGif from '../../assets2/images/shia.gif';
import { VerifyEmail, VerifyEmailVariables } from './@types/VerifyEmail';

const VERIFY_EMAIL = gql`
  mutation VerifyEmail($verificationId: String!) {
    verifyEmail(verificationId: $verificationId)
  }
`;

export const EmailVerify: React.FC<InitAuthProps> = ({ location, history }) => {
  const match = useRouteMatch<{ verificationId: string }>(
    '/email_verify/:verificationId',
  );
  const { t } = useSafeTranslation();
  const [verifyEmail, { called }] = useMutation<
    VerifyEmail,
    VerifyEmailVariables
  >(VERIFY_EMAIL);
  const state = new URLSearchParams(location.search).get('state');

  let interruptedHref = '/';
  let silent = false;

  try {
    ({ interruptedHref = '/', silent = false } = state
      ? JSON.parse(decodeURIComponent(state))
      : {});
  } catch {
    log({
      msg: `Error parse state in EmailVerify page`,
      data: { state },
      level: Level.warn,
    });
  }

  const { verificationId } = match?.params || {};

  useEffect(() => {
    if (verificationId && !called) {
      verifyEmail({ variables: { verificationId } }).then(
        (verificationResult) => {
          if (verificationResult.data?.verifyEmail) {
            history.push(interruptedHref);

            sendEvent({
              category: 'email',
              action: 'verified',
            });

            if (silent) {
              return;
            }

            Modal.show(({ close }) => (
              <DefaultDialog
                header={t('modernComponents.EmailVerify.title')}
                onDismiss={close}
                dialogStyle={{ width: 520 }}
              >
                <img
                  src={ApplauseGif}
                  alt={window.i18next.t(
                    'EmailVerify-string--103-congratulations',
                  )}
                  width="100%"
                />
                <Spacer factor={8} />
                <Flex justifyContent="flex-end">
                  <Button
                    intent="secondary"
                    onClick={() => {
                      close();
                    }}
                  >
                    {t('modernComponents.EmailVerify.returnToDashboard')}
                  </Button>
                </Flex>
              </DefaultDialog>
            ));
          } else {
            sendEvent({
              category: 'email',
              action: 'verified error',
            });
            history.push('/');
          }
        },
      );
    }
  }, [
    t,
    called,
    verificationId,
    verifyEmail,
    history,
    silent,
    interruptedHref,
  ]);

  return null;
};
