import React, { useCallback, useMemo } from 'react';
import { useQuery } from 'react-apollo';
import { ReactComponent as PlusIcon } from '../../../../modern-ui/_deprecated/Icon/icons/plus.svg';
import { BotsListQuery } from '@common/services/GQLqueries/@types/BotsListQuery';
import { sendEvent } from '@utils/Analytics';
import { MenuCombobox } from '@ui/SimpleCombobox';
import { MenuItem } from '@ui/Menu';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  BotPlatformSelectorItems,
  getBotPlatformSelectorItems,
  WEBSITE,
} from './consts';
import { Platform } from '@globals';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { useIsCreateBotByPlatform } from '@utils/Data/Admin/AdminFeatures/useIsCreateBotByPlatform';
import * as css from './CreateBlankBotButton.css';
import { LIGHT_BOTS_QUERY } from '@utils/Data/Bot/queries';
import * as PopperJS from 'popper.js';
import { NEW_PRODUCT_LINK } from '../../../PageHeader/BotHeader/components/BotHeader/components/TopPanel/consts';

export interface CreateBlankBotButtonProps {
  onCreateBlankBot: (platforms?: Platform[]) => void;
  disabled: boolean;
  className?: string;
  title?: string;
  platformSelectorPlacement?: PopperJS.Placement;
}

const ButtonPlaceholder = () => {
  const { t } = useSafeTranslation();
  return (
    <Button
      intent="primary"
      icon={<PlusIcon />}
      style={{ visibility: 'hidden' }}
      disabled
      loading
    >
      {t('CreateBlankBotButton-JSXText--111-add-blank-bot')}
    </Button>
  );
};

export const CreateBlankBotButton: React.FC<CreateBlankBotButtonProps> = (
  props,
) => {
  const { t } = useSafeTranslation();
  const { loading } = useQuery<BotsListQuery>(LIGHT_BOTS_QUERY);
  const { isCreateBotByPlatform: isNeedShowPlatformSelector } =
    useIsCreateBotByPlatform();

  const buttonContent = useMemo(
    () => (
      <Flex alignItems="center" justifyContent="center">
        <PlusIcon />
        <Type color="white">
          {props.title ?? t('CreateBlankBotButton-JSXText--111-add-blank-bot')}
        </Type>
      </Flex>
    ),
    [props.title, t],
  );

  const handleClick = useCallback(
    (platforms?: Platform[]) => {
      sendEvent({
        category: 'bot list',
        action: 'add blank bot',
        propertyBag: {
          platforms,
        },
      });
      props.onCreateBlankBot(platforms);
    },
    [props],
  );

  if (loading) {
    return <ButtonPlaceholder />;
  }

  if (!isNeedShowPlatformSelector) {
    return (
      <Button
        data-testid="bot-list__add-blank-bot"
        intent="primary"
        onClick={() => {
          handleClick();
        }}
        disabled={props.disabled}
        className={props.className}
      >
        {buttonContent}
      </Button>
    );
  }

  return (
    <MenuCombobox<BotPlatformSelectorItems>
      placement={props.platformSelectorPlacement ?? 'bottom-end'}
      onSelect={(item) => {
        if (item.id === WEBSITE) {
          window.open(NEW_PRODUCT_LINK, '__blank');
        } else {
          handleClick(item.platforms);
        }
      }}
      items={getBotPlatformSelectorItems()}
      renderInput={({ popperReference, downshift }) => (
        <Button
          ref={popperReference.ref}
          {...downshift.getToggleButtonProps()}
          data-testid="bot-list__add-blank-bot_platform-menu-button"
          intent="primary"
          disabled={props.disabled}
          className={props.className}
        >
          {buttonContent}
        </Button>
      )}
    >
      {({
        downshift: { getItemProps, highlightedIndex },
        props: { item, index },
      }) => (
        <MenuItem
          active={index === highlightedIndex}
          titleElement={
            <Flex alignItems="center">
              <Icon icon={item.iconName} size="24px" />
              <Spacer factor={0} horizontalFactor={1} />
              <Type color={index === highlightedIndex ? 'white' : 'black'}>
                {item.title}
              </Type>
            </Flex>
          }
          key={item.id}
          className={css.menuItem}
          data-testid={`bot-list__add-blank-bot__platform-item__${item.id}`}
          {...getItemProps({ item })}
        />
      )}
    </MenuCombobox>
  );
};
