import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { List, ListItem } from '../../../../modern-ui/List';
import { Button, ButtonIntent } from '../../../../modern-ui/_deprecated/Button';
import { Spacer } from '../../../../modern-ui/Spacer';
import { Type } from '../../../../modern-ui/Type';
import { ReactComponent as PlayIcon } from '../../../../modern-ui/_deprecated/Icon/icons/play.svg';
import { ReactComponent as PauseIcon } from '../../../../modern-ui/_deprecated/Icon/icons/pause.svg';
import { ReactComponent as RetryIcon } from '../../../../modern-ui/_deprecated/Icon/icons/retry.svg';
import { ReactComponent as AlertIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { CenteredLoader } from '../../../../modern-ui/Loader';
import { Anchor } from '../../../../modern-ui/Links';
import {
  useFacebookSyncedSegments,
  useSyncFacebookSegment,
} from '../../../../utils/MarketingApi';
import { TooltipPure } from '../../../../modern-ui/Tooltip2';
import { getTabLink, BotTabs } from '../../../../utils/Routing';
import { sendEvent } from '../../../../utils/Analytics';
import {
  useServerStorage,
  ServerStorageItemKeys,
} from '../../../../utils/ServerStorage';
import { FacebookSyncedSegmentsQuery_bot_facebookSyncedSegments as FacebookSyncedSegment } from '../../../../utils/MarketingApi/@types/FacebookSyncedSegmentsQuery';
import {
  SyncingState,
  SyncingActionType,
} from '../../../../../@types/globalTypes';
import * as css from './SyncSegmentsTable.css';
import { TextEllipsis } from '@ui/TextEllipsis';
import { DateUtils } from '@utils/DateTime';

interface SyncSegmentsTableProps {
  botId: string;
  disabled?: boolean;
  disabledIdle?: boolean;
}

export const SyncSegmentsTable: React.FC<SyncSegmentsTableProps> = ({
  botId,
  disabled,
  disabledIdle,
}) => {
  const { syncedSegments, loading } = useFacebookSyncedSegments(
    botId,
    'network-only',
  );
  const [
    facebookSegmentsIdsWithShownFbAlert,
    setFacebookSegmentsIdsWithShownFbAlert,
  ] = useServerStorage<string[]>(
    ServerStorageItemKeys.facebookSegmentsIdsWithShownFbAlert,
  );

  const safeFacebookSegmentsIdsWithShownFbAlert =
    facebookSegmentsIdsWithShownFbAlert ?? [];

  const deleteFromShownSegments = (segmentId: string) => {
    setFacebookSegmentsIdsWithShownFbAlert(
      safeFacebookSegmentsIdsWithShownFbAlert.filter((id) => id !== segmentId),
    );
  };

  useEffect(
    () => () => {
      setFacebookSegmentsIdsWithShownFbAlert(
        syncedSegments.reduce((acc, { id, status_description }) => {
          if (status_description) {
            acc.push(id);
          }
          return acc;
        }, [] as string[]),
      );
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <>
      <List>
        <ListItem boxClassName={css.listItem} itemClassName={css.headerItem}>
          <div className={css.row}>
            <div>
              <Type
                color={disabled ? 'grey' : 'greyDark'}
                size="15px_DEPRECATED"
              >
                {window.i18next.t('SyncSegmentsTable-JSXText-1888-segment')}
              </Type>
            </div>
            <div>
              <Type
                color={disabled ? 'grey' : 'greyDark'}
                size="15px_DEPRECATED"
              >
                {window.i18next.t('SyncSegmentsTable-JSXText-6494-last-synced')}
              </Type>
            </div>
            <div>
              <Type
                color={disabled ? 'grey' : 'greyDark'}
                size="15px_DEPRECATED"
              >
                {window.i18next.t('SyncSegmentsTable-JSXText--119-state')}
              </Type>
            </div>
            <div>
              <Type
                color={disabled ? 'grey' : 'greyDark'}
                size="15px_DEPRECATED"
              >
                {window.i18next.t('SyncSegmentsTable-JSXText--126-action')}
              </Type>
            </div>
          </div>
        </ListItem>
        {loading ? (
          <>
            <Spacer />
            <CenteredLoader />
          </>
        ) : (
          syncedSegments.map((segment, index) => (
            <ListItem
              data-testid="sync-segments-table__row"
              key={segment.id}
              boxClassName={css.listItem}
              drawDivider={index < syncedSegments.length - 1}
            >
              {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
              <Segment
                botId={botId}
                segment={segment}
                disabled={disabled}
                onSyncStart={deleteFromShownSegments}
                disabledIdle={
                  disabledIdle && segment.state !== SyncingState.SYNCING
                }
                showAlert={
                  !safeFacebookSegmentsIdsWithShownFbAlert.includes(segment.id)
                }
              />
            </ListItem>
          ))
        )}
      </List>
    </>
  );
};

const Placeholder: React.FC<{ disabled: boolean }> = ({ disabled }) => (
  <Type color={disabled ? 'grey' : 'black'} size="15px_DEPRECATED">
    –
  </Type>
);

interface SyncedSegmentProps {
  botId: string;
  segment: FacebookSyncedSegment;
  onSyncStart(segmentId: string): void;
  disabled?: boolean;
  disabledIdle?: boolean;
  showAlert?: boolean;
}

const Segment = React.memo<SyncedSegmentProps>(
  ({ botId, disabled, disabledIdle, onSyncStart, showAlert, segment }) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [syncErrorMessage, setSyncErrorMessage] = useState('');
    const { id, name, last_synced, state, status_description } = segment;
    const { syncSegment, segmentSyncing, error } =
      useSyncFacebookSegment(botId);

    const renderIcon = useCallback(() => {
      if (
        ((syncErrorMessage || error) && !segmentSyncing) ||
        state === SyncingState.FAILED
      ) {
        return <RetryIcon data-testid="sync-segments-table__retry-icon" />;
      }
      switch (state) {
        case SyncingState.SYNCING:
          return <PauseIcon data-testid="sync-segments-table__pause-icon" />;
        case SyncingState.PAUSED:
        case SyncingState.NOT_STARTED:
        default:
          return (
            <PlayIcon
              data-testid="sync-segments-table__play-icon"
              className={css.playIcon}
            />
          );
      }
    }, [error, syncErrorMessage, state, segmentSyncing]);

    const renderState = useCallback(() => {
      if (syncErrorMessage) {
        return (
          <TooltipPure
            placement="left"
            show={showTooltip && !disabled}
            content={
              <Type as="p" color="white" size="15px_DEPRECATED">
                {syncErrorMessage}
              </Type>
            }
            positionFixed
          >
            {(ref) => (
              <div ref={ref} className={css.alertState}>
                <AlertIcon
                  data-testid="sync-segments-table__failed-sync-alert"
                  className={cn(css.alertIcon, {
                    [css.disabledIcon]: disabled,
                  })}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
                <Type color={disabled ? 'grey' : 'red'} size="15px_DEPRECATED">
                  {window.i18next.t('SyncSegmentsTable-JSXText-1065-failed')}
                </Type>
              </div>
            )}
          </TooltipPure>
        );
      }

      if (showAlert && status_description) {
        return (
          <TooltipPure
            placement="left"
            boundariesElement="viewport"
            show={showTooltip && !disabled}
            content={
              <Type as="p" color="white" size="15px_DEPRECATED">
                {status_description}
              </Type>
            }
            positionFixed
          >
            {(ref) => (
              <div ref={ref} className={css.alertState}>
                <AlertIcon
                  data-testid="sync-segments-table__fb-sync-alert"
                  className={cn(css.fbAlertIcon, {
                    [css.disabledIcon]: disabled,
                  })}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
                <div className={css.fbAlertTitle}>
                  <Type
                    color={disabled ? 'grey' : 'black'}
                    size="15px_DEPRECATED"
                  >
                    {window.i18next.t(
                      'SyncSegmentsTable-JSXText-2108-facebook-alert',
                    )}
                  </Type>
                </div>
              </div>
            )}
          </TooltipPure>
        );
      }

      switch (state) {
        case SyncingState.SYNCING:
          return (
            <Type color={disabled ? 'grey' : 'greyDark'} size="15px_DEPRECATED">
              {window.i18next.t('SyncSegmentsTable-JSXText-8599-live')}
            </Type>
          );
        case SyncingState.PAUSED:
          if (last_synced) {
            return (
              <Type
                color={disabled ? 'grey' : 'greyDark'}
                size="15px_DEPRECATED"
              >
                {window.i18next.t('SyncSegmentsTable-JSXText-1664-paused')}
              </Type>
            );
          }
          return <Placeholder disabled />;
        case SyncingState.NOT_STARTED:
        case SyncingState.FAILED:
        default:
          return <Placeholder disabled />;
      }
    }, [
      disabled,
      syncErrorMessage,
      last_synced,
      showTooltip,
      showAlert,
      state,
      status_description,
    ]);

    useEffect(() => {
      if (showTooltip && !disabled) {
        sendEvent({
          category: 'people tab',
          action: 'show tooltip',
          label: 'segments sync',
          propertyBag: {
            reason: syncErrorMessage || status_description,
            segmentId: id,
            segmentName: name,
          },
        });
      }
    }, [id, name, status_description, showTooltip, disabled, syncErrorMessage]);

    return (
      <div className={cn(css.row, css.rowItem)}>
        <div>
          <Anchor
            intent="internal"
            className={cn(css.syncName, {
              [css.columnDisabled]: disabled,
            })}
            href={`${window.location.origin}${getTabLink(
              BotTabs.people,
              botId,
              {
                segmentId: id,
              },
            )}`}
            rel="noopener norefferer"
            target="_blank"
          >
            <TextEllipsis width={190} className={css.link}>
              {name}
            </TextEllipsis>
          </Anchor>
        </div>
        <div>
          {last_synced ? (
            <Type color={disabled ? 'grey' : 'black'} size="15px_DEPRECATED">
              {DateUtils.fromDate(+last_synced)}
            </Type>
          ) : (
            <Placeholder disabled />
          )}
        </div>
        <div data-testid="sync-segments-table__segment-state">
          {renderState()}
        </div>
        <div>
          <Button
            data-testid="sync-segments-table__segment-action-button"
            intent={ButtonIntent.secondary}
            renderIcon={renderIcon}
            onClick={async () => {
              let actionLabel: string;
              switch (state) {
                case SyncingState.SYNCING:
                  actionLabel = 'pause';
                  break;
                case SyncingState.PAUSED:
                  actionLabel = 'restart';
                  break;
                case SyncingState.NOT_STARTED:
                default: {
                  if (status_description) {
                    actionLabel = 'restart';
                  } else {
                    actionLabel = 'start';
                  }
                }
              }

              sendEvent({
                category: 'people tab',
                action: `${actionLabel} segment sync`,
                propertyBag: {
                  segmentId: id,
                  segmentName: name,
                },
              });

              const action =
                state === SyncingState.SYNCING
                  ? SyncingActionType.PAUSE
                  : SyncingActionType.START;

              if (action === SyncingActionType.START) {
                onSyncStart(segment.id);
              }

              const { data } = await syncSegment(segment, action);
              if (data?.syncFacebookSegment.userErrors.length) {
                setSyncErrorMessage(
                  data.syncFacebookSegment.userErrors[0].message,
                );
              }
            }}
            disabled={disabled || disabledIdle}
          />
        </div>
      </div>
    );
  },
  (
    {
      botId: prevBotId,
      segment: prevSegment,
      disabled: prevDisabled,
      disabledIdle: prevDisabledIdle,
    },
    { botId, segment, disabled, disabledIdle },
  ) =>
    botId === prevBotId &&
    disabled === prevDisabled &&
    disabledIdle === prevDisabledIdle &&
    segment.id === prevSegment.id &&
    segment.state === prevSegment.state &&
    segment.status_description === prevSegment.status_description,
);
