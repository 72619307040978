import React, { useEffect, useState } from 'react';
import { BotProStatus } from '@utils/Bot';
import {
  NotificationTag,
  NotificationTagType,
} from '../../../../modern-ui/NotificationTag';
import { Tooltip2 } from '../../../../modern-ui/Tooltip2';
import { isBotPro } from '../../../../utils/Pro';
import { sendEvent } from '../../../../utils/Analytics';
import { BotsListQuery_bots } from '../../../../common/services/GQLqueries/@types/BotsListQuery';
import * as css from './BotNotificationTag.css';
import { DateUtils } from '@utils/DateTime';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ChargingEntityType } from '@globals';

interface BotNotificationHandlers {
  onUpgradeClick: () => void;
  onSettingsClick: () => void;
  onPageConnectClick: () => void;
}

interface BotNotificationTagProps extends BotNotificationHandlers {
  bot: BotsListQuery_bots;
  currentUsers: number;
  isWhatsappBot?: boolean;
}

const USERS_SHOW_NOTIFICATION_THRESHOLD = 0.5;
const DAYS_TO_SHOW_DISCONNECTED_ALERT = 10;

export enum BotNotificationTagType {
  BOT_DISCONNECTED = 'BOT_DISCONNECTED',
  SOON_PAYMENT_PROBLEM = 'SOON_PAYMENT_PROBLEM',
  PAYMENT_PROBLEM = 'PAYMENT_PROBLEM',
  LIMIT_REACHED = 'LIMIT_REACHED',
  LIMIT_REACHING = 'LIMIT_REACHING',
  N_USERS_LEFT = 'N_USERS_LEFT',
  PAUSED = 'PAUSED',
  NONE = 'NONE',
}

const usNumberFormatter = new Intl.NumberFormat('en-US');

export const getNotificationType = (
  bot: BotsListQuery_bots,
  current: number,
  limit: number,
  notificationsLimit: number,
) => {
  if (bot.status?.status === 'draft') {
    return BotNotificationTagType.BOT_DISCONNECTED;
  }
  if (bot.pro?.status === BotProStatus.enabled_with_debt && !bot.pro?.manual) {
    return BotNotificationTagType.SOON_PAYMENT_PROBLEM;
  }
  if (
    bot.pro?.status === BotProStatus.disabled &&
    (bot.pro.debt ?? 0) > 0 &&
    !bot.pro?.manual
  ) {
    return BotNotificationTagType.PAYMENT_PROBLEM;
  }
  if (bot.pro?.is_paused) {
    return BotNotificationTagType.PAUSED;
  }

  if (
    !isBotPro(bot) &&
    !bot.workspace_id &&
    bot.status?.charging_entity_type !== ChargingEntityType.dialogs
  ) {
    if (current >= notificationsLimit && current < limit) {
      return BotNotificationTagType.LIMIT_REACHING;
    }
    if (current >= limit) {
      return BotNotificationTagType.LIMIT_REACHED;
    }
    if (current < limit) {
      return BotNotificationTagType.N_USERS_LEFT;
    }
  }

  return BotNotificationTagType.NONE;
};

export const analyticsDescription = {
  [BotNotificationTagType.PAUSED]: 'bot paused',
  [BotNotificationTagType.BOT_DISCONNECTED]: 'bot disconnected',
  [BotNotificationTagType.SOON_PAYMENT_PROBLEM]: 'soon payment problem',
  [BotNotificationTagType.PAYMENT_PROBLEM]: 'payment problem',
  [BotNotificationTagType.LIMIT_REACHED]: 'limit reached',
  [BotNotificationTagType.LIMIT_REACHING]: 'limit reaching',
  [BotNotificationTagType.N_USERS_LEFT]: 'n-subscribers left',
};

const logTooltipClick = (
  botId: String,
  type: BotNotificationTagType,
  restProperties?: { [key: string]: any },
) => {
  sendEvent({
    category: 'bot',
    action: 'tooltip click',
    label: (analyticsDescription as any)[type],
    propertyBag: {
      botId,
      ...restProperties,
    },
  });
};

export const BotNotificationTag: React.FC<BotNotificationTagProps> = ({
  bot,
  currentUsers,
  isWhatsappBot,
  onPageConnectClick,
  onSettingsClick,
  onUpgradeClick,
}) => {
  const { t } = useSafeTranslation();
  const [showTooltip, setShowTooltip] = useState(false);
  const usersLeft = bot.limits.usersLimit - currentUsers;

  const botNotificationTagsMap = React.useMemo(() => {
    const botNotificationTags = [
      {
        id: BotNotificationTagType.BOT_DISCONNECTED,
        title: isWhatsappBot
          ? t('pages.BotPage.HomeTab.Whatsapp.noPhoneConnected')
          : t('BotNotificationTag-string-1865-page-disconnected'),
        notificationType: NotificationTagType.info,
        tooltip: isWhatsappBot
          ? null
          : (params: BotNotificationHandlers) => (
              <p className={css.tooltipText}>
                {t(
                  'BotNotificationTag-JSXText--184-your-bot-is-currently-not-linked-to-a-facebook-page',
                )}{' '}
                <button
                  onClick={(event) => {
                    event.stopPropagation();
                    logTooltipClick(
                      bot.id,
                      BotNotificationTagType.BOT_DISCONNECTED,
                    );
                    params.onPageConnectClick();
                  }}
                  className={css.tooltipLink}
                >
                  {t('BotNotificationTag-JSXText--135-connect')}
                </button>{' '}
                {t(
                  'BotNotificationTag-JSXText--914-one-now-so-your-bot-can-interact-with-users',
                )}
              </p>
            ),
      },
      {
        id: BotNotificationTagType.SOON_PAYMENT_PROBLEM,
        title: t('BotNotificationTag-string-2057-payment-problem'),
        notificationType: NotificationTagType.error,
        tooltip: (params: BotNotificationHandlers) => (
          <p className={css.tooltipText}>
            {t(
              'BotNotificationTag-JSXText-1386-soon-new-users-will-not-be-able-to-communicate-with-your-bot',
            )}{' '}
            <button
              onClick={(event) => {
                event.stopPropagation();
                logTooltipClick(
                  bot.id,
                  BotNotificationTagType.SOON_PAYMENT_PROBLEM,
                );
                params.onSettingsClick();
              }}
              className={css.tooltipLink}
            >
              {t('BotNotificationTag-JSXText--646-update-payment-method')}
            </button>{' '}
            {t(
              'BotNotificationTag-JSXText--105-to-prevent-this-from-happening',
            )}
          </p>
        ),
      },
      {
        id: BotNotificationTagType.PAYMENT_PROBLEM,
        title: t('BotNotificationTag-string-2057-payment-problem'),
        notificationType: NotificationTagType.error,
        tooltip: (params: BotNotificationHandlers) => (
          <p className={css.tooltipText}>
            {t(
              'BotNotificationTag-JSXText--445-new-users-cant-communicate-with-your-bot-until-your-outstanding-balance-is-paid',
            )}{' '}
            <button
              onClick={(event) => {
                event.stopPropagation();
                logTooltipClick(bot.id, BotNotificationTagType.PAYMENT_PROBLEM);
                params.onSettingsClick();
              }}
              className={css.tooltipLink}
            >
              {t('BotNotificationTag-JSXText-2125-update-your-payment-method')}
            </button>{' '}
            {t('BotNotificationTag-JSXText-1838-now')}
          </p>
        ),
      },
      {
        id: BotNotificationTagType.LIMIT_REACHING,
        title: `${bot.limits.usersLimit - currentUsers}`,
        notificationType: NotificationTagType.success,
        tooltip: (params: BotNotificationHandlers) => (
          <p className={css.tooltipText}>
            {t('BotNotificationTag-JSXText-1277-only')}
            {bot.limits.usersLimit - currentUsers}
            {t('BotNotificationTag-JSXText-1139-users-left-in-your-free')}
            <br />
            {t('BotNotificationTag-JSXText-2013-trial')}{' '}
            <button
              onClick={(event) => {
                event.stopPropagation();
                logTooltipClick(bot.id, BotNotificationTagType.LIMIT_REACHING);
                params.onUpgradeClick();
              }}
              className={css.tooltipLink}
            >
              {t('BotNotificationTag-JSXText-1227-upgrade-to-pro')}
            </button>{' '}
            {t('BotNotificationTag-JSXText--320-to-keep')}
            <br />
            {t('BotNotificationTag-JSXText-1912-responding-to-new-users')}
          </p>
        ),
      },
      {
        id: BotNotificationTagType.LIMIT_REACHED,
        title: t('BotNotificationTag-string--103-limit-reached'),
        notificationType: NotificationTagType.error,
        tooltip: (params: BotNotificationHandlers) => (
          <p className={css.tooltipText}>
            {t('BotNotificationTag-JSXText--107-bot-user-limit-reached')}
            <br />
            <button
              onClick={(event) => {
                event.stopPropagation();
                logTooltipClick(bot.id, BotNotificationTagType.LIMIT_REACHED);
                params.onUpgradeClick();
              }}
              className={css.tooltipLink}
            >
              {t('BotNotificationTag-JSXText-1227-upgrade-to-pro')}
            </button>{' '}
            {t('BotNotificationTag-JSXText-1389-to-respond-to')}
            <br />
            {t('BotNotificationTag-JSXText-6152-new-prospects')}
          </p>
        ),
      },
      {
        id: BotNotificationTagType.N_USERS_LEFT,
        title: '',
        notificationType: NotificationTagType.info,
        tooltip: (params: BotNotificationHandlers) => (
          <p className={css.tooltipText}>
            {t(
              'BotNotificationTag-JSXText--199-youve-almost-reached-your-subscriber-limit',
            )}
            <br />
            {t(
              'BotNotificationTag-JSXText--130-to-allow-your-bot-to-continue-to-receive-new-users',
            )}{' '}
            <button
              onClick={(event) => {
                event.stopPropagation();
                logTooltipClick(bot.id, BotNotificationTagType.N_USERS_LEFT, {
                  subscribersLeft: usersLeft,
                });
                params.onUpgradeClick();
              }}
              className={css.tooltipLink}
            >
              {t('BotNotificationTag-JSXText-2070-upgrade-to-pro')}
            </button>{' '}
            {t('BotNotificationTag-JSXText-1838-now')}
          </p>
        ),
      },
    ];

    return new Map(botNotificationTags.map((item) => [item.id, item]));
  }, [
    bot.id,
    bot.limits.usersLimit,
    currentUsers,
    usersLeft,
    t,
    isWhatsappBot,
  ]);

  const notificationType = getNotificationType(
    bot,
    currentUsers,
    bot.limits.usersLimit,
    bot.limits.notificationsLimit,
  );

  useEffect(() => {
    if (notificationType !== BotNotificationTagType.NONE) {
      sendEvent({
        category: 'bot list',
        action: 'show notification',
        label: analyticsDescription[notificationType],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (showTooltip && notificationType !== BotNotificationTagType.NONE) {
      sendEvent({
        category: 'bot list',
        action: 'show tooltip',
        label: analyticsDescription[notificationType],
        propertyBag: {
          botId: bot.id,
          ...(notificationType === BotNotificationTagType.N_USERS_LEFT && {
            subscribersLeft: usersLeft,
          }),
        },
      });
    }
  }, [showTooltip, notificationType, usersLeft, bot.id]);

  if (notificationType === BotNotificationTagType.NONE) {
    return null;
  }

  const disconnectedMoreThan10Days =
    notificationType === BotNotificationTagType.BOT_DISCONNECTED &&
    bot.disconnected_date &&
    DateUtils.daysPassedFromCurrrentDate(+bot.disconnected_date) >
      DAYS_TO_SHOW_DISCONNECTED_ALERT;

  if (disconnectedMoreThan10Days) {
    return null;
  }

  const currentNotificationTag = botNotificationTagsMap.get(notificationType);

  if (!currentNotificationTag) {
    return null;
  }

  const usersLeftFormatted = usNumberFormatter.format(usersLeft);
  if (
    notificationType === BotNotificationTagType.N_USERS_LEFT &&
    currentUsers < bot.limits.usersLimit * USERS_SHOW_NOTIFICATION_THRESHOLD
  ) {
    return bot.time_limited_trial && !isBotPro(bot) ? null : (
      <span>{usersLeftFormatted}</span>
    );
  }

  if (isWhatsappBot) {
    return (
      <div className={css.notificationTag}>
        <span className={css.notificationTagTitle}>
          {notificationType === BotNotificationTagType.N_USERS_LEFT
            ? usersLeftFormatted
            : currentNotificationTag.title}
        </span>
      </div>
    );
  }

  return (
    <Tooltip2
      show={showTooltip}
      content={currentNotificationTag.tooltip?.({
        onPageConnectClick,
        onSettingsClick,
        onUpgradeClick,
      })}
      placement="top"
      style={{ maxWidth: '400px' }}
      hoverable
    >
      {(ref, bind) => (
        <div className={css.notificationTag}>
          <div className={css.notificationTagButton} {...bind} ref={ref}>
            <NotificationTag
              onMouseEnter={() => setShowTooltip(true)}
              onMouseLeave={() => setShowTooltip(false)}
              notificationTagType={
                notificationType === BotNotificationTagType.N_USERS_LEFT
                  ? currentUsers > bot.limits.notificationsLimit
                    ? NotificationTagType.error
                    : NotificationTagType.info
                  : currentNotificationTag.notificationType
              }
            />
          </div>
          <span className={css.notificationTagTitle}>
            {notificationType === BotNotificationTagType.N_USERS_LEFT
              ? usersLeftFormatted
              : currentNotificationTag.title}
          </span>
        </div>
      )}
    </Tooltip2>
  );
};
