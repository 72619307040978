import React from 'react';
import cn from 'classnames';
import { FacebookAdAccountName } from './FacebookAdAccountName';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { Tooltip2 } from '../../../../../modern-ui/Tooltip2';
import {
  Button,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';
import { Type } from '../../../../../modern-ui/Type';
import { Text, PlaceholderItem } from '../../Banner';
import {
  useConnectedFacebookAdAccountWithToS,
  useFacebookSyncedSegments,
  useFacebookAdHasSyncedBefore,
} from '../../../../../utils/MarketingApi';
import { FacebookSyncedSegmentsQuery_bot_facebookSyncedSegments as FacebookSyncedSegment } from '../../../../../utils/MarketingApi/@types/FacebookSyncedSegmentsQuery';
import { sendEvent } from '../../../../../utils/Analytics';
import * as css from '../SyncBanner.css';
import { DateUtils } from '@utils/DateTime';

interface SyncBannerProps {
  toggleDialog(open: boolean): void;
}

export const SyncPrepareBanner: React.FC<SyncBannerProps> = ({
  toggleDialog,
}) => (
  <>
    <Text>
      <Type as="p" size="15px_DEPRECATED">
        {window.i18next.t(
          'Banners-JSXText--106-sync-audience-segments-from-chatfuel-to-facebook-ads-manager',
        )}
      </Type>
      <Type as="p" size="15px_DEPRECATED">
        {window.i18next.t(
          'Banners-JSXText--407-send-sponsored-messages-to-targeted-groups-of-subscribers-easily',
        )}
      </Type>
    </Text>
    <div>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <SyncSegmentsButton toggleDialog={toggleDialog} />
    </div>
  </>
);

interface SyncPreviewBannerProps extends SyncBannerProps {
  botId: string;
}

export const SyncPreviewBanner: React.FC<SyncPreviewBannerProps> = ({
  botId,
  toggleDialog,
}) => {
  const { connectedFacebookAdAccount } =
    useConnectedFacebookAdAccountWithToS(botId);
  const { syncingSegments, syncedSegments } = useFacebookSyncedSegments(botId);
  const { facebookAdHasSyncedBefore, loading } = useFacebookAdHasSyncedBefore(
    connectedFacebookAdAccount?.id,
  );
  const lastSyncedSegment =
    !!syncedSegments.length &&
    syncedSegments.reduce((lastSegment, segment) =>
      (!lastSegment.last_synced && segment.last_synced) ||
      (lastSegment.last_synced &&
        segment.last_synced &&
        +segment.last_synced > +lastSegment.last_synced)
        ? segment
        : lastSegment,
    );
  const isAlertInSyncingSegment = syncingSegments.some(
    (segment) => segment.status_description,
  );
  const lastSyncedDate = lastSyncedSegment && lastSyncedSegment.last_synced;
  const lastSyncedUsersCount =
    lastSyncedSegment && lastSyncedSegment.synced_users_count;

  return (
    <>
      <div className={css.bannerStats}>
        <div>
          <Type as="p" weight="medium" size="15px_DEPRECATED">
            {window.i18next.t('Banners-JSXText--213-segments-syncing')}
          </Type>
          <div>
            <Type
              data-testid="sync-preview-banner__syncing-segments-count-label"
              size="15px_DEPRECATED"
            >
              {syncingSegments.length}
            </Type>
            {isAlertInSyncingSegment && (
              <Tooltip2
                placement="bottom"
                boundariesElement="viewport"
                content={
                  <Type as="p" color="white" size="15px_DEPRECATED">
                    {window.i18next.t(
                      'Banners-JSXText-1832-ads-manager-errors-may-cause-some',
                    )}
                    <br />
                    {window.i18next.t(
                      'Banners-JSXText-2115-segments-to-appear-incorrectly',
                    )}
                    <br />
                    {window.i18next.t(
                      'Banners-JSXText-1363-resolve-these-errors-in-ads-manager',
                    )}
                  </Type>
                }
                positionFixed
              >
                {(ref, bind) => (
                  <div ref={ref} className={css.infoIconContainer}>
                    <InfoIcon
                      className={cn(css.infoIcon, css.bannerStatsAlertIcon)}
                      {...bind}
                    />
                  </div>
                )}
              </Tooltip2>
            )}
          </div>
        </div>
        <div>
          <Type as="p" weight="medium" size="15px_DEPRECATED">
            {window.i18next.t('Banners-JSXText-2047-last-updated')}
          </Type>
          <FacebookAdAccountName>
            {lastSyncedDate ? DateUtils.fromDate(+lastSyncedDate) : '-'}
          </FacebookAdAccountName>
        </div>
        <div>
          <Type as="p" weight="medium" size="15px_DEPRECATED">
            {window.i18next.t('Banners-JSXText-6494-last-synced')}
          </Type>
          <FacebookAdAccountName>
            {lastSyncedUsersCount
              ? `${lastSyncedUsersCount}${window.i18next.t(
                  'Banners-Template-1027-users',
                )}`
              : '-'}
          </FacebookAdAccountName>
        </div>
      </div>
      <div>
        {loading ? (
          <Button
            className={css.loadingButton}
            intent={ButtonIntent.primary}
            disabled
          >
            <PlaceholderItem />
          </Button>
        ) : (
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          <SyncSegmentsButton
            title={
              facebookAdHasSyncedBefore
                ? window.i18next.t('Banners-string-1631-manage-sync')
                : window.i18next.t('Banners-string-7189-start-syncing')
            }
            adAccountId={connectedFacebookAdAccount?.id}
            toggleDialog={toggleDialog}
            segments={syncingSegments}
          />
        )}
      </div>
    </>
  );
};

interface SyncSegmentsButtonProps extends SyncBannerProps {
  title?: string;
  adAccountId?: string;
  segments?: FacebookSyncedSegment[];
}

const SyncSegmentsButton: React.FC<SyncSegmentsButtonProps> = ({
  title = window.i18next.t('Banners-string-8968-sync-segments'),
  adAccountId,
  toggleDialog,
  segments,
}) => (
  <Button
    data-testid="audience-sync__sync-segments-button"
    intent={ButtonIntent.primary}
    onClick={() => {
      sendEvent({
        category: 'people tab',
        action: 'sync segments',
        label: 'segment sync',
        propertyBag: {
          ...(adAccountId && { adAccountId }),
          ...(segments && { 'segments syncing': segments?.length }),
        },
      });
      toggleDialog(true);
    }}
  >
    {title}
  </Button>
);
