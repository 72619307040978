import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './TemplatesNotFound.css';

export const TemplatesNotFound = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex alignItems="center" justifyContent="center" className={css.wrapper}>
      <Type size="18px" weight="medium">
        {t('pages.BotPage.ReengageWhatsappTab.templateModal.notFound')}
      </Type>
    </Flex>
  );
};
