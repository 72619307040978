import React, { useEffect } from 'react';
import { ChurnSurveyAdviceType } from '@globals';
import { CenteredLoader } from '@ui/Loader';
import { useChurnSurveyAdvice } from '@utils/Data/Pricing/hooks';
import { DowngradeAvailableDialog } from './DowngradeAvailableDialog/DowngradeAvailableDialog';
import { DowngradeNotAvailable } from './DowngradeNotAvailable/DowngradeNotAvailable';
import { CloseDialog } from './CloseDialog';
import { ImproveBotDialog } from './ImproveBotDialog/ImproveBotDialog';
import { RemoveUsersDialog } from './RemoveUsersDialog/RemoveUsersDialog';
import {
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan,
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdvicePauseBot,
  ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceRemoveUsers,
} from '@utils/Data/Pricing/@types/ChurnSurveyAdviceQuery';
import { sendEvent } from '@utils/Analytics';
import { useCurrentBotId } from '@utils/Routing';

interface ChurnSurveyAdviceProps {
  onClose: VoidFunction;
  onCancellPro: VoidFunction;
}

export const ChurnSurveyAdvice: React.FC<ChurnSurveyAdviceProps> = ({
  onClose,
  onCancellPro,
}) => {
  const {
    churnSurveyAdviceLoading,
    churnSurveyAdviceType,
    churnSurveyAdviceData,
  } = useChurnSurveyAdvice();
  const botId = useCurrentBotId()!;

  useEffect(() => {
    if (churnSurveyAdviceLoading) return;
    sendEvent({
      category: 'churn survey advice dialog',
      action: 'show dialog',
      propertyBag: {
        churnSurveyAdviceType,
        botId,
      },
    });
  }, [churnSurveyAdviceType, botId, churnSurveyAdviceLoading]);

  if (churnSurveyAdviceLoading) return <CenteredLoader />;

  switch (churnSurveyAdviceType) {
    case ChurnSurveyAdviceType.downgrade_plan: {
      return (
        <DowngradeAvailableDialog
          {...(churnSurveyAdviceData as ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan)}
          botId={botId}
          churnSurveyAdviceType={churnSurveyAdviceType}
          onClose={onClose}
          onCancellPro={onCancellPro}
        />
      );
    }
    case ChurnSurveyAdviceType.pause_bot: {
      return (
        <DowngradeNotAvailable
          {...(churnSurveyAdviceData as ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdvicePauseBot)}
          botId={botId}
          churnSurveyAdviceType={churnSurveyAdviceType}
          onClose={onClose}
          onCancellPro={onCancellPro}
        />
      );
    }
    case ChurnSurveyAdviceType.remove_users: {
      return (
        <RemoveUsersDialog
          {...(churnSurveyAdviceData as ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceRemoveUsers)}
          botId={botId}
          churnSurveyAdviceType={churnSurveyAdviceType}
          onClose={onClose}
          onCancellPro={onCancellPro}
        />
      );
    }
    case ChurnSurveyAdviceType.improve_bot: {
      return (
        <ImproveBotDialog
          botId={botId}
          churnSurveyAdviceType={churnSurveyAdviceType}
          onClose={onClose}
          onCancellPro={onCancellPro}
        />
      );
    }
    default: {
      return <CloseDialog onClose={onCancellPro} />;
    }
  }
};
