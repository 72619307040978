import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';
import { sendEvent } from '@utils/Analytics';
import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { useLocation } from 'react-router-dom';
import { TokenError } from '@globals';
import { getConnectPageEventPostfix } from '../../helpers';
import { ConnectPageField, ConnectPageOrigin } from '../../types';
import * as css from './FacebookPagesConnectPage.css';
import { useFacebookLogin } from '../../../../pages/MultiSystemAuth/hooks/useFacebookLogin';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

const sendEventFn = (action: string) => {
  const url = new URL(window.location.href);
  const {
    [ConnectPageField.name]: label,
    [ConnectPageField.flowId]: flowId,
    [ConnectPageField.blockId]: blockId,
    [ConnectPageField.cardId]: cardId,
  } = Object.fromEntries(url.searchParams);
  sendEvent({
    category: 'connect facebook page dialog',
    action,
    label,
    propertyBag: { flowId, blockId, cardId },
  });
};

interface FacebookPagesConnectPageProps {
  origin: keyof typeof ConnectPageOrigin;
  onAccountConnected: () => void;
  onAccountConnectFailed?(error: TokenError): void;
}

export const FacebookPagesConnectPage: React.FC<FacebookPagesConnectPageProps> =
  ({ origin, onAccountConnected, onAccountConnectFailed }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const eventsPostfix = getConnectPageEventPostfix(origin);
    const { t } = useSafeTranslation();
    const location = useLocation();
    const { facebookLogin, ready: fbLoginReady } = useFacebookLogin({
      interruptedHref: window.location.href,
      location,
      connectAccountOnly: true,
      onAccountConnectFailed,
      onAccountConnected,
    });

    return (
      <Flex flexDirection="column" data-testid="facebook-pages__connect-page">
        <div className={cn(!isSmallScreenSize && css.titleContainer)}>
          <Type size="24px" weight="semibold">
            {t('modernComponents.FacebookPagesSetup.connectPage.title')}
          </Type>
        </div>
        <Spacer factor={1} />
        <Flex
          className={cn(!isSmallScreenSize && css.descriptionContainer)}
          alignItems="center"
          justifyContent="space-between"
        >
          <div className={cn(!isSmallScreenSize && css.description)}>
            <Type size="15px">
              {t(
                [
                  ConnectPageOrigin.instagramConnect,
                  ConnectPageOrigin.instagramEpConnect,
                ].includes(ConnectPageOrigin[origin])
                  ? 'modernComponents.FacebookPagesSetup.connectPage.descriptionIg'
                  : 'modernComponents.FacebookPagesSetup.connectPage.description',
              )}
            </Type>
          </div>
        </Flex>
        <Flex
          alignItems="center"
          justifyContent="flex-end"
          className={cn(!isSmallScreenSize && css.box)}
          data-testid="facebook-pages__footer"
        >
          <Button
            intent="primary"
            data-testid="facebook-pages__connect-page-button"
            onClick={async () => {
              sendEventFn(`connect page click${eventsPostfix}`);
              facebookLogin();
            }}
            className={isSmallScreenSize ? css.button_mobile : css.button}
            disabled={!fbLoginReady}
          >
            {t('modernComponents.FacebookPagesSetup.connectPage.button')}
          </Button>
        </Flex>
      </Flex>
    );
  };
