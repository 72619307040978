import React from 'react';
import i18next from 'i18next';
import { ApolloProvider, Query, Mutation } from '@apollo/react-components';
import { Button } from '@ui/Button';
import { DialogLoader, DefaultDialog } from '../../modern-ui/Dialog';
import { Input } from '../../modern-ui/Input/Input';
import { Autofocus } from '../../modern-ui/Autofocus';
import client from '../../common/services/ApolloService';
import * as css from './DeleteBotDialog.css';
import {
  BotDeletionQuery as BotQuery,
  BotDeletionQueryVariables as BotQueryVariables,
} from './@types/BotDeletionQuery';
import {
  DeleteBotMutation,
  DeleteBotMutationVariables,
} from './@types/DeleteBotMutation';
import { sendEvent } from '../../utils/Analytics';
import { BOT_DELETION_QUERY } from './queries';
import { deleteBotDialogUtils } from './utils';
import { DELETE_BOT_MUTATION } from './mutations';

interface DeleteBotDialogProps {
  botId: string;
  mobileAdaptive?: boolean;
  onComplete: () => void;
  onDismiss: () => void;
}

interface DeleteBotDialogState {
  safeDeletionPhrase: string;
}

export class DeleteBotDialog extends React.PureComponent<
  DeleteBotDialogProps,
  DeleteBotDialogState
> {
  state = {
    safeDeletionPhrase: '',
  };

  onsafeDeletionPhraseChange: React.ChangeEventHandler<HTMLInputElement> = (
    e,
  ) => {
    const { value } = e.currentTarget;

    this.setState({
      safeDeletionPhrase: value,
    });
  };

  render() {
    const { onDismiss, botId, onComplete, mobileAdaptive } = this.props;
    const { safeDeletionPhrase } = this.state;

    return (
      <ApolloProvider client={client}>
        <Query<BotQuery, BotQueryVariables>
          query={BOT_DELETION_QUERY}
          variables={{ botId }}
          onError={() => {
            onDismiss();
          }}
        >
          {({ data, loading }) => {
            if (loading || !data) {
              return <DialogLoader />;
            }

            const {
              bot: { title, workspace_id },
            } = data;

            const updateStore =
              deleteBotDialogUtils.getUpdateStore(workspace_id);

            return (
              <DefaultDialog
                mobileAdaptive={mobileAdaptive}
                header={
                  <div>
                    <span>
                      {i18next.t('DeleteBotDialog-JSXText--107-delete')}
                    </span>
                    {/* the title might have arbitrary length,
                     * so it's important no to break styles if it is
                     * too long
                     */}
                    <span style={{ wordBreak: 'break-all' }}>{title}</span>
                  </div>
                }
                onDismiss={onDismiss}
              >
                <Mutation<DeleteBotMutation, DeleteBotMutationVariables>
                  mutation={DELETE_BOT_MUTATION}
                  variables={{ botId }}
                  update={updateStore}
                  onCompleted={() => {
                    onComplete();
                  }}
                  onError={(error) => {
                    deleteBotDialogUtils.showDeleteError(error);
                    onDismiss();
                  }}
                >
                  {(deleteBot, { loading }) => {
                    const invalidOrNotReady =
                      safeDeletionPhrase !== title || loading;
                    return (
                      <form
                        noValidate
                        onSubmit={(event) => {
                          event.preventDefault();
                          if (invalidOrNotReady) {
                            return;
                          }

                          sendEvent({
                            category: 'bot',
                            action: 'delete',
                            propertyBag: {
                              botId,
                            },
                          });

                          deleteBot();
                        }}
                      >
                        <p className={css.paragraph}>
                          {i18next.t(
                            'DeleteBotDialog-JSXText-1168-this-action-cannot-be-undone',
                          )}
                        </p>
                        <p className={css.paragraph}>
                          {i18next.t(
                            'DeleteBotDialog-JSXText-1876-enter-the-name-of-the-bot-below-to-confirm-that-you-want-to-permanently-delete-it-for-all-user',
                          )}
                        </p>

                        <Autofocus
                          render={({ bind }) => (
                            <Input
                              {...bind}
                              value={safeDeletionPhrase}
                              placeholder={title}
                              onChange={this.onsafeDeletionPhraseChange}
                            />
                          )}
                        />
                        <div className={css.buttons}>
                          <Button intent="secondary" onClick={onDismiss}>
                            {i18next.t('DeleteBotDialog-JSXText-2673-cancel')}
                          </Button>
                          <Button
                            intent="red"
                            type="submit"
                            disabled={invalidOrNotReady}
                          >
                            {i18next.t(
                              'DeleteBotDialog-JSXText-6333-delete-bot',
                            )}
                          </Button>
                        </div>
                      </form>
                    );
                  }}
                </Mutation>
              </DefaultDialog>
            );
          }}
        </Query>
      </ApolloProvider>
    );
  }
}
