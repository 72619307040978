import { useCurrentBotId } from '@utils/Routing';
import debounce from 'lodash-es/debounce';
import { useCallback } from 'react';
import { useQuery } from 'react-apollo';

import { GET_WHATSAPP_TEMPLATES } from './queries';
import {
  GetWhatsappTemplates,
  GetWhatsappTemplatesVariables,
} from './@types/GetWhatsappTemplates';

interface WhatsappTemplatesArgs {
  skip?: boolean;
}

export const useWhatsappTemplates = (args?: WhatsappTemplatesArgs) => {
  const botId = useCurrentBotId()!;
  const { data, fetchMore, refetch, ...queryResult } = useQuery<
    GetWhatsappTemplates,
    GetWhatsappTemplatesVariables
  >(GET_WHATSAPP_TEMPLATES, {
    variables: { botId },
    skip: args?.skip,
  });

  const { after } = data?.getWhatsappTemplates?.paging.cursors || {};

  const onFetchMore = useCallback(() => {
    return fetchMore({
      query: GET_WHATSAPP_TEMPLATES,
      variables: { botId, after },
      updateQuery(previousQueryResult, options) {
        const { data: prevData } =
          previousQueryResult.getWhatsappTemplates || {};
        const { data, paging } =
          options.fetchMoreResult?.getWhatsappTemplates || {};

        const result: GetWhatsappTemplates = {
          getWhatsappTemplates: {
            __typename: 'WhatsappTemplatesResponse',
            data: (prevData || []).concat(data || []),
            paging: {
              __typename: 'FbPaging',
              cursors: {
                __typename: 'FbPagingCursors',
                after: paging?.cursors.after || null,
                before: paging?.cursors.before || null,
              },
            },
          },
        };

        return result;
      },
    });
  }, [fetchMore, botId, after]);

  const onSearch = useCallback(
    debounce((name: string) => refetch({ botId, name }), 300, {
      leading: true,
    }),
    [refetch, botId],
  );

  const onRefetch = useCallback(() => refetch({ botId }), [refetch, botId]);

  return {
    templates: data?.getWhatsappTemplates?.data || [],
    fetchMore: onFetchMore,
    search: onSearch,
    refetch: onRefetch,
    ...queryResult,
  };
};
