import i18next from 'i18next';
import { BotLivelinessStatus } from '@globals';
import Maybe from 'graphql/tsutils/Maybe';
import { DateFormat } from '@utils/DateTime';

export const getStatusName = (status: BotLivelinessStatus) => {
  return {
    [BotLivelinessStatus.LIVE]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.live',
    ),
    [BotLivelinessStatus.LIVE_WITH_DEBT]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.live',
    ),
    [BotLivelinessStatus.OFF_WITHOUT_INGRESS_CONTROLLERS]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.OFF_WITHOUT_PAGE]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.OFF_WITHOUT_PERMISSIONS]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.PAUSED]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.paused',
    ),
    [BotLivelinessStatus.STOPPED]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.stopped',
    ),
    [BotLivelinessStatus.INSTAGRAM_NOT_LINKED]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.SHOPIFY_OFF_WITHOUT_SUBSCRIPTION]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_CAP_REACHED]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_CONNECTION_ISSUES]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_STORE_FROZEN]: i18next.t(
      'modernComponents.BotLivelinessStatus.status.off',
    ),
  }[status];
};

export const getTooltipContent = (
  status: BotLivelinessStatus,
  isWhatsappBot: boolean,
  botWillBeStoppedAt: Maybe<number>,
) => {
  return {
    [BotLivelinessStatus.LIVE]: null,
    [BotLivelinessStatus.LIVE_WITH_DEBT]: null,
    [BotLivelinessStatus.OFF_WITHOUT_INGRESS_CONTROLLERS]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.enableEntryPointOrSetUpKeywords',
    ),
    [BotLivelinessStatus.OFF_WITHOUT_PAGE]: isWhatsappBot
      ? i18next.t(
          'modernComponents.BotLivelinessStatus.tooltips.offWithoutPhone',
        )
      : i18next.t(
          'modernComponents.BotLivelinessStatus.tooltips.offWithoutPage',
        ),
    [BotLivelinessStatus.OFF_WITHOUT_PERMISSIONS]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.offWithoutPermissions',
    ),
    [BotLivelinessStatus.PAUSED]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.paused',
    ),
    [BotLivelinessStatus.STOPPED]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.limitReached',
    ),
    [BotLivelinessStatus.INSTAGRAM_NOT_LINKED]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.instagramNotLinked',
    ),
    [BotLivelinessStatus.SHOPIFY_OFF_WITHOUT_SUBSCRIPTION]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.shopifySubscriptionCanceled',
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_CAP_REACHED]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.shopifyStoppedCapReached',
      { date: botWillBeStoppedAt ? DateFormat.MMMDD(botWillBeStoppedAt) : '' },
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_CONNECTION_ISSUES]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.shopifyStoppedConnectionIssues',
    ),
    [BotLivelinessStatus.SHOPIFY_STOPPED_STORE_FROZEN]: i18next.t(
      'modernComponents.BotLivelinessStatus.tooltips.shopifyStoppedStoreFrozen',
    ),
  }[status];
};
