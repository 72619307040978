import React, { useCallback, useEffect, useState } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { BotStatus } from '@utils/Bot';
import { useFacebookPages } from '@utils/FacebookPages/useFacebookPages';
import { BindBotToPageMutation_bindBotToPage_verifiedPermissions } from '@utils/FacebookPages/@types/BindBotToPageMutation';
import { showIntercom } from '@utils/Intercom';
import { PermissionGroup } from '@utils/Permissions';
import { RoleConsumer } from '@utils/Roles';

import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { ConnectPageOrigin } from '@components/ConnectPageDialog';

import { useRefetchAdminData } from '@utils/Admin/useRefetchAdminData';

import { TokenError } from '@globals';
import { Permission } from '@common/services/RoleService';

import { FacebookPagesSetupFinished } from '../FacebookPagesSetupFinished';
import { FacebookPagesList } from '../FacebookPagesList';
import { FacebookPagesGivePermissions } from '../FacebookPagesGivePermissions';

import { FacebookPagesSetupStatus } from '../types';

import * as css from './FacebookPagesSetup.css';
import { useAccountStatus } from '@pages/MultiSystemAuth/hooks/useAccountStatus';
import { FacebookPagesConnectPage } from '../FacebookPagesConnectPage';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

const getFacebookPagesSetupStatus = (
  hasManagePagesPermissions: boolean,
  isPageConnected: boolean,
  isPageBindPermissionsError: boolean,
  isFacebookAccountConnected: boolean,
  isFacebookAccountConnectError: boolean,
): FacebookPagesSetupStatus | null => {
  if (!isFacebookAccountConnected && !isFacebookAccountConnectError) {
    return FacebookPagesSetupStatus.needConnectAccount;
  }

  if (hasManagePagesPermissions && isPageConnected) {
    return FacebookPagesSetupStatus.succeed;
  }

  if (isFacebookAccountConnectError || isPageBindPermissionsError) {
    return FacebookPagesSetupStatus.failed;
  }

  if (!hasManagePagesPermissions) {
    return FacebookPagesSetupStatus.givePermissions;
  }

  if (hasManagePagesPermissions && !isPageConnected) {
    return FacebookPagesSetupStatus.pagesList;
  }

  return null;
};

interface FacebookPagesSetupProps {
  botId: string;
  origin: keyof typeof ConnectPageOrigin;
  onPageConnected(
    status: BotStatus,
    pageId?: string | null,
    verifiedPermissions?: BindBotToPageMutation_bindBotToPage_verifiedPermissions[],
  ): void;
  onDone(): void;
  onAccountConnected?(): void;
  onConnectedBotClick?(): void;
  requestPermissionOnBind?: PermissionGroup[];
  isNarrow?: boolean;
  dontShowSuccessState?: boolean;
}

export const FacebookPagesSetup: React.FC<FacebookPagesSetupProps> = ({
  origin,
  onPageConnected,
  onConnectedBotClick,
  onAccountConnected,
  requestPermissionOnBind,
  botId,
  onDone,
  dontShowSuccessState,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const refetchAdminData = useRefetchAdminData();
  const facebookPagesQueryResult = useFacebookPages(botId);
  const {
    facebookPagesData,
    facebookPagesLoading,
    facebookPagesCalled,
    facebookPagesRefetch,
  } = facebookPagesQueryResult;
  const [pageBindPermissionsError, setPageBindPermissionsError] = useState<
    string | undefined
  >(undefined);
  const [connectingPageId, setConnectingPageId] = useState<string | undefined>(
    undefined,
  );
  const [accountConnectError, setAccountConnectError] = useState<
    string | undefined
  >(undefined);
  const {
    status: facebookAccountStatus,
    loading: facebookAccountStatusLoading,
  } = useAccountStatus();
  const [isProfileConflict, setProfileConflict] = useState(false);

  const refetchData = useCallback(() => {
    refetchAdminData();
    facebookPagesRefetch();
  }, [facebookPagesRefetch, refetchAdminData]);

  const connectedPage = facebookPagesData?.bot.status?.page_info;

  useEffect(() => {
    if (dontShowSuccessState && connectedPage) {
      onDone();
    }
  }, [dontShowSuccessState, connectedPage, onDone]);

  if (
    facebookPagesLoading ||
    !facebookPagesCalled ||
    facebookAccountStatusLoading
  ) {
    return (
      <Flex
        className={css.box}
        justifyContent="center"
        alignItems="center"
        data-testid="facebook-pages__container"
      >
        <div data-testid="facebook-pages__loader">
          <CenteredLoader />
        </div>
      </Flex>
    );
  }

  if (!facebookPagesData && !facebookAccountStatus) {
    return (
      <div className={css.box} data-testid="facebook-pages__container">
        <div data-testid="facebook-pages__error">
          <Flex justifyContent="center" alignItems="center">
            {t('FacebookPagesSetup-JSXText--142-loading-error')}
          </Flex>
        </div>
      </div>
    );
  }

  const hasManagePagesPermissions =
    !!facebookPagesData?.me.fbPermissions.hasManagePagesPermissions;
  const facebookPagesSetupStatus = getFacebookPagesSetupStatus(
    hasManagePagesPermissions,
    !!connectedPage,
    !!pageBindPermissionsError,
    !!facebookAccountStatus?.facebook_account_connected,
    !!accountConnectError,
  );

  return (
    <RoleConsumer domain="configure" can={Permission.VIEW}>
      {({ allowed }) =>
        allowed && (
          <>
            <div
              className={cn(!isSmallScreenSize && css.box)}
              data-testid="facebook-pages__container"
            >
              {facebookPagesSetupStatus ===
                FacebookPagesSetupStatus.needConnectAccount && (
                <FacebookPagesConnectPage
                  onAccountConnected={() => {
                    refetchData();
                    onAccountConnected?.();
                  }}
                  onAccountConnectFailed={(connectAccountError) => {
                    switch (connectAccountError) {
                      case TokenError.FB_PROFILE_CONFLICT:
                        sendEvent({
                          category: 'connect facebook page dialog',
                          action: 'already signed up',
                        });
                        setAccountConnectError(
                          t(
                            'modernComponents.ConnectPageDialog.errors.alreadySignedUp',
                          ),
                        );
                        setProfileConflict(true);
                        break;
                      default:
                        setAccountConnectError(
                          t(
                            'Auth-string-5848-there-was-an-error-logging-you-into-facebook',
                          ),
                        );
                    }
                  }}
                  origin={origin}
                />
              )}
              {facebookPagesSetupStatus ===
                FacebookPagesSetupStatus.givePermissions && (
                <FacebookPagesGivePermissions
                  botId={botId}
                  origin={origin}
                  facebookPagesRefetch={facebookPagesRefetch}
                  onPermissionsUpdated={() => {
                    refetchData();
                  }}
                />
              )}
              {facebookPagesSetupStatus ===
                FacebookPagesSetupStatus.pagesList && (
                <FacebookPagesList
                  onConnectedBotClick={onConnectedBotClick}
                  onPageConnect={(pageId) => {
                    setConnectingPageId(pageId);
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'page connect started',
                    });
                    setPageBindPermissionsError(undefined);
                  }}
                  onPageConnectSucceed={(
                    status,
                    pageId,
                    verifiedPermissions,
                  ) => {
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'page connect succeeded',
                    });
                    onPageConnected(status, pageId, verifiedPermissions);
                  }}
                  onPageConnectFailed={(error) => {
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'page connect failed',
                    });
                    setPageBindPermissionsError(error);
                  }}
                  botId={botId}
                  requestPermissionOnBind={requestPermissionOnBind}
                  {...facebookPagesQueryResult}
                />
              )}
              {(facebookPagesSetupStatus === FacebookPagesSetupStatus.failed ||
                (facebookPagesSetupStatus ===
                  FacebookPagesSetupStatus.succeed &&
                  !dontShowSuccessState)) && (
                <FacebookPagesSetupFinished
                  status={facebookPagesSetupStatus}
                  pageId={connectingPageId}
                  pageTitle={connectedPage?.title}
                  error={accountConnectError || pageBindPermissionsError}
                  noWorriesHidden={
                    !!accountConnectError || !!pageBindPermissionsError
                  }
                  hasProfileConflict={isProfileConflict}
                  onGoBack={() => {
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'click back to pages',
                    });
                    setAccountConnectError(undefined);
                    setPageBindPermissionsError(undefined);
                  }}
                  onContactSupport={() => {
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'click contact support',
                    });
                    showIntercom();
                  }}
                  onDone={() => {
                    sendEvent({
                      category: 'connect facebook page dialog',
                      action: 'click add template',
                    });
                    onDone();
                  }}
                />
              )}
              <RoleConsumer domain="configure" can={Permission.EDIT}>
                {({ allowed: allowedEdit }) =>
                  !allowedEdit && <div className={css.veil} />
                }
              </RoleConsumer>
            </div>
          </>
        )
      }
    </RoleConsumer>
  );
};
