import React from 'react';
import { compose } from 'ramda';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { Dialog, DialogHeading } from '../../modern-ui/Dialog';
import {
  FlexDialogContent,
  FormLine,
  SubmitRow,
  CloseButton,
} from '../../modern-ui/Dialog/ActionDialogParts';
import { Flex } from '../../modern-ui/Flex';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { Input } from '../../modern-ui/Input';
import { ReactComponent as DefaultBotIcon } from '../../modern-ui/_deprecated/Icon/icons/default-bot.svg';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { ShimmerText } from '../../modern-ui/ShimmerText';
import { Autofocus } from '../../modern-ui/Autofocus';
import * as s from './BlockActionsDialog.css';
import {
  BlockActionBots as Bots,
  BlockActionBots_bots as Bot,
} from './@types/BlockActionBots';
import {
  BotGroups,
  BotGroupsVariables,
  BotGroups_bot_blocksGroups as BotGroup,
} from './@types/BotGroups';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { Platform } from '@globals';

interface Group {
  id: string;
  title: string;
  builtin: boolean | null;
  synced_from: {} | null;
}

function notBuiltAndSyncedIn(group: Group | BotGroup) {
  return !group.builtin && !group.synced_from;
}

interface IBlockActionsDialogProps {
  onSubmit: (params: { groupId: string; botId: string }) => void;
  renderHeading: () => React.ReactNode;
  renderActionText: () => React.ReactNode;
  onDismiss?: () => void;
  currentBotId: string;
  currentGroupId: string;
  currentBotGroups: Group[];
}

interface IBlockActionsDialogState {
  [formValue: string]: string | null;
}

const botsQuery = gql`
  query BlockActionBots {
    bots {
      id
      title
      allowedPlatforms
      status {
        page_info {
          id
          picture
        }
      }
    }
  }
`;

const botGroupsQuery = gql`
  query BotGroups($botId: String) {
    bot(id: $botId) {
      id
      blocksGroups {
        id
        title
        builtin
        synced_from {
          bot {
            title
          }
        }
      }
    }
  }
`;

export class BlockActionsDialog extends React.Component<
  IBlockActionsDialogProps,
  IBlockActionsDialogState
> {
  constructor(props: IBlockActionsDialogProps) {
    super(props);
    this.state = { botId: props.currentBotId, groupId: props.currentGroupId };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  setFieldValue(name: string, value: string) {
    this.setState({ [name]: value });
    if (name === 'botId' && this.state.botId !== value) {
      // reset groupId if bot is changed
      this.setState({ groupId: '' });
    }
  }

  handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!event.currentTarget.checkValidity()) {
      return;
    }

    this.props.onSubmit(this.state as { botId: string; groupId: string });
  }

  render() {
    const {
      renderHeading,
      renderActionText,
      onDismiss,
      currentBotId,
      currentBotGroups,
    } = this.props;
    const { botId, groupId } = this.state;
    return (
      <Dialog>
        <form onSubmit={this.handleSubmit}>
          <FlexDialogContent>
            <div>
              <DialogHeading>
                <Flex alignItems="baseline" justifyContent="space-between">
                  <div className={s.title}>{renderHeading()}</div>
                  {onDismiss ? (
                    <CloseButton
                      aria-label="close"
                      type="button"
                      onClick={onDismiss}
                    />
                  ) : null}
                </Flex>
              </DialogHeading>
              <Query<Bots> query={botsQuery}>
                {({ error, loading, data }) => (
                  <React.Fragment>
                    {!error ? (
                      <React.Fragment>
                        <FormLine>
                          <label>
                            <div className={s.formLabel}>
                              {window.i18next.t(
                                'BlockActionsDialog-JSXText--150-to-the-bot',
                              )}
                            </div>
                            <SimpleCombobox
                              key={loading ? 'loading' : 'ready'}
                              initialSelectedItem={
                                loading || !data
                                  ? null
                                  : data.bots.find(
                                      (bot: any) => bot.id === botId,
                                    )
                              }
                              onChange={(item) =>
                                this.setFieldValue('botId', item ? item.id : '')
                              }
                              items={
                                data
                                  ? (data.bots as Bot[]).filter(
                                      ({ allowedPlatforms }) =>
                                        !allowedPlatforms ||
                                        testAllowedPlatforms(
                                          [Platform.facebook],
                                          allowedPlatforms,
                                        ),
                                    )
                                  : []
                              }
                              renderInput={({
                                getInputProps,
                                openMenu,
                                selectedItem,
                                selectItem,
                                selectInputValue,
                                ref,
                              }) => (
                                <Autofocus
                                  shouldFocus={!loading && !botId}
                                  render={({ bind }) => (
                                    <Input
                                      {...getInputProps({
                                        ref: loading
                                          ? undefined
                                          : (n: HTMLInputElement) => {
                                              ref(n);
                                              bind.ref(n);
                                            },
                                        onFocus: compose(
                                          openMenu,
                                          selectInputValue,
                                        ),
                                        onChange: () => {
                                          selectItem(null);
                                        },
                                      })}
                                      name="botId"
                                      placeholder={window.i18next.t(
                                        'BlockActionsDialog-string--159-enter-bot-name',
                                      )}
                                      render={
                                        loading
                                          ? () => (
                                              <div>
                                                <ShimmerText
                                                  play
                                                  style={{
                                                    paddingLeft: 12,
                                                    fontSize: 15,
                                                    lineHeight:
                                                      'var(--control-decorator-min-height)',
                                                  }}
                                                >
                                                  {window.i18next.t(
                                                    'BlockActionsDialog-JSXText-1258-loading-bot-list',
                                                  )}
                                                </ShimmerText>
                                                <input
                                                  type="text"
                                                  name="botId"
                                                  // render invalid input
                                                  // so that the form cannot be submitted
                                                  // while loading
                                                  required
                                                  style={{
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0,
                                                  }}
                                                />
                                              </div>
                                            )
                                          : undefined
                                      }
                                      renderIconEnd={() => {
                                        if (
                                          !selectedItem ||
                                          !selectedItem.status.page_info ||
                                          !selectedItem.status.page_info.picture
                                        ) {
                                          return <DefaultBotIcon />;
                                        }
                                        return (
                                          <img
                                            src={
                                              selectedItem.status.page_info
                                                .picture
                                            }
                                            width={24}
                                            height={24}
                                            alt="status info"
                                          />
                                        );
                                      }}
                                      required
                                    />
                                  )}
                                />
                              )}
                            />
                          </label>
                        </FormLine>
                      </React.Fragment>
                    ) : null}
                    {error ? (
                      <p>
                        {window.i18next.t(
                          'BlockActionsDialog-JSXText--117-an-error-occured-while-loading-bot-list',
                        )}
                      </p>
                    ) : null}
                  </React.Fragment>
                )}
              </Query>
              {botId ? (
                <Query<BotGroups, BotGroupsVariables>
                  key={botId} // reset component if selected bot changes
                  query={botGroupsQuery}
                  variables={{ botId }}
                  fetchPolicy="cache-and-network"
                >
                  {({ error, loading, data }) => {
                    if (error) {
                      return (
                        <span>
                          {window.i18next.t(
                            'BlockActionsDialog-JSXText-1556-some-error',
                          )}
                        </span>
                      );
                    }
                    const fetchingGroups =
                      loading && (!data || !data.bot || !data.bot.blocksGroups);

                    let items: (BotGroup | Group)[] = [];
                    if (fetchingGroups && botId === currentBotId) {
                      items = currentBotGroups.filter(notBuiltAndSyncedIn);
                    } else if (!fetchingGroups && data) {
                      items = (data.bot.blocksGroups as BotGroup[]).filter(
                        notBuiltAndSyncedIn,
                      );
                    }
                    const selectedItem =
                      items.find((group) => group.id === groupId) || null;
                    if (!fetchingGroups && !items.length) {
                      return (
                        <FormLine>
                          <p>
                            <i>
                              {window.i18next.t(
                                'BlockActionsDialog-JSXText-1126-this-bot-has-not-available-groups',
                              )}
                            </i>
                          </p>
                        </FormLine>
                      );
                    }
                    return (
                      <FormLine>
                        <label>
                          <div className={s.formLabel}>
                            {window.i18next.t(
                              'BlockActionsDialog-JSXText-3127-to-the-group',
                            )}
                          </div>
                          <div>
                            <SimpleCombobox
                              onChange={(item) =>
                                this.setFieldValue(
                                  'groupId',
                                  item ? item.id : '',
                                )
                              }
                              items={items}
                              initialSelectedItem={selectedItem}
                              renderInput={({
                                getInputProps,
                                openMenu,
                                ref,
                                selectInputValue,
                                selectItem,
                              }) => (
                                <Autofocus
                                  shouldFocus={!fetchingGroups}
                                  render={({ bind }) => (
                                    <Input
                                      {...getInputProps({
                                        ref: fetchingGroups
                                          ? undefined
                                          : (n: HTMLInputElement) => {
                                              ref(n);
                                              bind.ref(n);
                                            },
                                        onFocus: compose(
                                          openMenu,
                                          selectInputValue,
                                        ),
                                        onChange: () => {
                                          selectItem(null);
                                        },
                                      })}
                                      name="groupId"
                                      placeholder={window.i18next.t(
                                        'BlockActionsDialog-string-1605-enter-group-name',
                                      )}
                                      required
                                      render={
                                        fetchingGroups && !selectedItem
                                          ? () => (
                                              <div>
                                                <ShimmerText
                                                  play
                                                  style={{
                                                    paddingLeft: 12,
                                                    fontSize: 15,
                                                    lineHeight:
                                                      'var(--control-decorator-min-height)',
                                                  }}
                                                >
                                                  {window.i18next.t(
                                                    'BlockActionsDialog-JSXText-2342-loading-groups-list',
                                                  )}
                                                </ShimmerText>
                                                <input
                                                  type="text"
                                                  name="groupId"
                                                  // render invalid input
                                                  // so that the form cannot be submitted
                                                  // while loading
                                                  required
                                                  style={{
                                                    position: 'absolute',
                                                    opacity: 0,
                                                    width: 0,
                                                    height: 0,
                                                  }}
                                                />
                                              </div>
                                            )
                                          : undefined
                                      }
                                    />
                                  )}
                                />
                              )}
                            />
                          </div>
                        </label>
                      </FormLine>
                    );
                  }}
                </Query>
              ) : null}
            </div>
            <SubmitRow>
              <Button
                type="submit"
                disabled={!botId || !groupId}
                intent={ButtonIntent.primary}
              >
                {renderActionText()}
              </Button>
            </SubmitRow>
          </FlexDialogContent>
        </form>
      </Dialog>
    );
  }
}
