import React from 'react';
import i18next from 'i18next';
import memoize from 'lodash-es/memoize';
import { TierType } from '@globals';
import { Emoji } from '@ui/Emoji';
import { TierTypeRegular } from '@pages/Plans/types';
import { Translation } from '@translations';
import { isSomeEnum } from '@utils/isSomeEnum';

export const getTierNameByType = memoize((type: string | null) => {
  if (isSomeEnum(TierType, type))
    return i18next.t<string, Translation>(`pages.Pricing.tier.${type}`);
  return '';
});

interface TierTypeEmojiProps {
  type: string | null;
}

export const TierTypeEmoji: React.FC<TierTypeEmojiProps> = ({ type }) => {
  if (isSomeEnum(TierTypeRegular, type))
    return {
      [TierType.trial]: <Emoji emoji="👻" size={14} />,
      [TierType.start]: <Emoji emoji="✨" size={14} />,
      [TierType.entrepreneur]: <Emoji emoji="💡" size={14} />,
      [TierType.startup]: <Emoji emoji="🚀" size={14} />,
      [TierType.smallBusiness]: <Emoji emoji="🛍" size={14} />,
      [TierType.growingBusiness]: <Emoji emoji="📈" size={14} />,
      [TierType.establishedBusiness]: <Emoji emoji="🖌" size={14} />,
      [TierType.manual]: <Emoji emoji="🔧" size={14} />,
    }[type];

  return null;
};
