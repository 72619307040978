import React from 'react';
import { SimpleCombobox, Item } from '@ui/SimpleCombobox';
import {
  ButtonSize,
  ButtonIntent,
  Button,
  ButtonColorWay,
} from '@ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';

interface Props {
  items: Item[];
  disabled?: boolean;
  onChange: (item: Item | null) => void;
}

export const ButtonPopupActionAdder: React.FC<Props> = ({
  items,
  disabled = false,
  onChange,
}) => {
  return (
    <SimpleCombobox
      renderInput={({ getToggleButtonProps }) => (
        <Button
          {...getToggleButtonProps()}
          type="button"
          disabled={disabled}
          renderIcon={() => <AddIcon />}
          intent={ButtonIntent.primary}
          colorWay={ButtonColorWay.white}
          size={ButtonSize.m}
        >
          {window.i18next.t('ButtonPopupActionAdder-JSXText-1457-add-action')}
        </Button>
      )}
      items={items}
      onChange={onChange}
      menuboxStyle={{ width: 'auto' }}
      unsaveSelectedOption
    />
  );
};
