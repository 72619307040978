import React from 'react';
import i18next from 'i18next';
import { AutomateEnabled } from '@utils/Data/Admin/Automate';
import * as css from '../common/ButtonPopupCommon.css';
import { ButtonPopupBlocksSelector } from '../common/ButtonPopupBlocksSelector';
import {
  Attribute,
  ButtonUserAttributeEditor,
} from '../common/ButtonPopupUserAttributesEditor';
import { ButtonPopupCommon } from '../common/ButtonPopupCommon';
import { ButtonErrors } from '../common/validation';

interface ButtonBlockTabConfig {
  blockId?: string[];
  attributeUpdates?: Attribute[];
}

export interface ButtonBlockTabProps extends ButtonBlockTabConfig {
  botId: string;
  counterId: string;
  onChange: (config: ButtonBlockTabConfig) => void;
  onTutorialShow: (status: boolean) => void;
  error: ButtonErrors[];
  showAdditionalActions?: boolean;
  onBlocksTitlesChange: (titles: string[]) => void;
  allowChangeUserAttributes?: boolean;
  donePopup?: () => void;
}

interface ButtonBlockTabState {
  blocksError: boolean;
  blocks: string[];
}

enum ButtonBlockTabActions {
  SET_USER_ATTRIBUTE = 'SET_USER_ATTRIBUTE',
}

interface ButtonBlockTabActionItem {
  id: ButtonBlockTabActions;
  title: string;
}

const BLOCK_BUTTON_EXTRA_ACTIONS: () => {
  [key: string]: ButtonBlockTabActionItem;
} = () => ({
  [ButtonBlockTabActions.SET_USER_ATTRIBUTE]: {
    id: ButtonBlockTabActions.SET_USER_ATTRIBUTE,
    title: window.i18next.t(
      'ButtonPopupBlocksTab-string--180-and-set-user-attribute',
    ),
  },
});

type TGetActionItem = (type: string) => JSX.Element | null;

export class ButtonPopupBlocksTab extends React.Component<
  ButtonBlockTabProps,
  ButtonBlockTabState
> {
  state = {
    blocksError:
      this.props.error && this.props.error.indexOf(ButtonErrors.blocks) !== -1,
    blocks: this.props.blockId || [],
  };

  validateRequiredField = () => {
    if (this.state.blocks.length === 0) {
      this.setState({ blocksError: true });
    }
  };

  initActions = () => {
    const constructedActions = [] as ButtonBlockTabActions[];
    if (
      this.props.attributeUpdates &&
      Object.keys(this.props.attributeUpdates).length > 0
    ) {
      constructedActions.push(ButtonBlockTabActions.SET_USER_ATTRIBUTE);
    }
    return constructedActions;
  };

  getActionItem: TGetActionItem = (action) => {
    if (action === ButtonBlockTabActions.SET_USER_ATTRIBUTE) {
      return (
        <ButtonUserAttributeEditor
          attributeUpdates={this.props.attributeUpdates}
          botId={this.props.botId}
          key={this.props.counterId}
          onChange={(attributes) => {
            this.props.onChange(attributes);
            this.validateRequiredField();
          }}
        />
      );
    }
    return null;
  };

  onActionDelete = (type: string) => {
    if (type === ButtonBlockTabActions.SET_USER_ATTRIBUTE) {
      this.props.onChange({ attributeUpdates: [] });
    }
  };

  render() {
    return (
      <ButtonPopupCommon
        botId={this.props.botId}
        actionConfig={BLOCK_BUTTON_EXTRA_ACTIONS()}
        getActionItem={this.getActionItem}
        initActions={this.initActions}
        onActionDelete={this.onActionDelete}
        onTutorialShow={this.props.onTutorialShow}
        actionFilter={(key) =>
          key !== ButtonBlockTabActions.SET_USER_ATTRIBUTE ||
          !!this.props.allowChangeUserAttributes
        }
        showAdditionalActions={this.props.showAdditionalActions}
      >
        {({ show }) => (
          <>
            <div className={css.actionTitle}>
              <AutomateEnabled>
                {({ isAutomateEnabled }) => (
                  <span>
                    {show ? '' : '1. '}
                    {isAutomateEnabled
                      ? i18next.t(
                          'modernComponents.ButtonEditorPopup.ButtonPopupBlocksTab.redirectToBlock',
                        )
                      : i18next.t(
                          'modernComponents.ButtonEditorPopup.ButtonPopupBlocksTab.redirectToFlow',
                        )}
                  </span>
                )}
              </AutomateEnabled>
            </div>
            <ButtonPopupBlocksSelector
              botId={this.props.botId}
              onChange={(config) => {
                this.props.onChange(config);
                this.setState({ blocks: config.blockId });
              }}
              blockId={this.props.blockId}
              tutorialModal={{
                showModal: show,
                currentBlockId: this.props.blockId ? this.props.blockId[0] : '',
                onTutorialShow: this.props.onTutorialShow,
              }}
              validateOnBlur
              error={this.state.blocksError}
              onFocus={() => this.setState({ blocksError: false })}
              onBlocksTitlesChange={this.props.onBlocksTitlesChange}
              onGoToBlock={this.props.donePopup}
            />
          </>
        )}
      </ButtonPopupCommon>
    );
  }
}
