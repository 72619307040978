import React from 'react';
import ng from 'angular';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import { react2angular } from 'react2angular';
import { globalHistory } from '@utils/Routing';
import { BroadcastingTags, BroadcastingTagsProps } from './BroadcastingTags';
import client from '../../common/services/ApolloService';

const BroadcastingTagsWrapper: React.FC<BroadcastingTagsProps> = (props) => (
  <Router history={globalHistory}>
    <ApolloProvider client={client}>
      <BroadcastingTags {...props} />
    </ApolloProvider>
  </Router>
);

export const ngBroadcastingTags = ng
  .module('app.components.broadcastingTags', [])
  .component(
    'broadcastingTags',
    react2angular(
      BroadcastingTagsWrapper,
      [
        'botId',
        'selectedTagId',
        'selectedPurpose',
        'onSelect',
        'selectedTopicId',
      ],
      [],
    ),
  ).name;
