import React from 'react';

import { Modal } from '@services/.';

import { resetConnectPageUrl } from './helpers';
import {
  ConnectPageDialog,
  ConnectPageDialogPropsLite,
} from './ConnectPageDialog';

export const connectPage = ({
  botId,
  urlParams,
  onClose,
  onPageConnected,
  onAccountConnected,
  eventData,
  dontShowSuccessState,
}: ConnectPageDialogPropsLite) => {
  const handleClose = () => {
    resetConnectPageUrl();
    onClose?.();
  };
  Modal.show(
    ({ close }) => (
      <ConnectPageDialog
        botId={botId}
        urlParams={urlParams}
        onClose={() => {
          close();
          onClose?.();
        }}
        onPageConnected={({ status, pageId, verifiedPermissions }) => {
          onPageConnected?.({
            status,
            pageId,
            verifiedPermissions,
            close,
            urlParams,
          });
        }}
        onAccountConnected={onAccountConnected}
        eventData={eventData}
        dontShowSuccessState={dontShowSuccessState}
      />
    ),
    {
      // skipOverloadActive: T,
      onOverlayDismiss: handleClose,
      mobileAdaptive: true,
      id: 'ConnectPageDialog',
    },
  )?.onClose(handleClose);
};
