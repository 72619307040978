import gql from 'graphql-tag';

export const BOTS_LIST_QUERY_IN_DELETION = gql`
  query BotListQueryInDeletion {
    bots {
      id
    }
  }
`;

export const BOT_DELETION_QUERY = gql`
  query BotDeletionQuery($botId: String!) {
    bot(id: $botId) {
      id
      title
      workspace_id
    }
  }
`;
