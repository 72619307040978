import gql from 'graphql-tag';

export const UPDATE_GROUP_MUTATION = gql`
  mutation UpdateGroupMutation($botId: String!, $group: UpdateBlocksGroup!) {
    updateBlocksGroup(botId: $botId, group: $group) {
      updatedGroup {
        id
        title
        with_stats
        collapsed
      }
      position
    }
  }
`;

export const GROUPS_TITLES_QUERY = gql`
  query GroupsTitlesQuery($botId: String!) {
    bot(id: $botId) {
      id
      blocksGroups {
        id
        title
      }
    }
  }
`;

export const BLOCKS_TITLES_FRAGMENT = gql`
  fragment BlocksTitlesFragment on Bot {
    archiveBlocks {
      id
      title
      removed
      reachable
    }
  }
`;

export const BLOCK_FRAGMENT = gql`
  fragment blockFragment on Block {
    id
    title
    builtin
    referral
    referral_active
    is_valid
    total_clicks
    total_views
    broadcast_options {
      time_period
      time_value
    }
    stats {
      sent
      seen
      clicked
    }
  }
`;

export const BLOCKS_GROUP_FRAGMENT = gql`
  fragment blocksGroupFragment on BlocksGroup {
    id
    title
    sequence
    builtin
    with_stats
    collapsed
    total_clicks
    total_sent
    total_users
    total_views
    synced_from {
      bot {
        title
      }
    }
    blocks {
      ...blockFragment
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const CREATE_NEW_GROUP_MUTATION = gql`
  mutation CreateNewGroupMutation(
    $botId: String!
    $title: String!
    $asSequence: Boolean
    $index: Int
  ) {
    createBlocksGroup(
      botId: $botId
      title: $title
      asSequence: $asSequence
      index: $index
    ) {
      ...blocksGroupFragment
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

export const DELETE_BLOCK_FROM_GROUP_MUTATION = gql`
  mutation DeleteBlockFromGroupMutation(
    $blockId: String!
    $groupId: String!
    $botId: String!
  ) {
    deleteBlockFromGroup(blockId: $blockId, groupId: $groupId, botId: $botId) {
      updatedGroup {
        id
        blocks {
          id
        }
      }
    }
  }
`;

export const CLONE_BLOCK_MUTATION = gql`
  mutation CloneBlockMutation(
    $sourceBlockId: String!
    $targetBotId: String!
    $targetGroupId: String!
  ) {
    cloneBlock(
      sourceBlockId: $sourceBlockId
      targetBotId: $targetBotId
      targetGroupId: $targetGroupId
    ) {
      clonedBlock {
        ...blockFragment
      }
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const MOVE_BLOCK_MUTATION = gql`
  mutation MoveBlockMutation(
    $sourceBlockId: String!
    $targetBotId: String!
    $targetGroupId: String!
  ) {
    moveBlock(
      sourceBlockId: $sourceBlockId
      targetBotId: $targetBotId
      targetGroupId: $targetGroupId
    ) {
      movedBlock {
        ...blockFragment
      }
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const GROUP_FRAGMENT = gql`
  fragment GroupFragment on BlocksGroup {
    blocks {
      id
      title
    }
  }
`;

export const CREATE_NEW_BLOCK_MUTATION = gql`
  mutation CreateNewBlockMutation(
    $botId: String!
    $title: String!
    $groupId: String
    $position: Int
  ) {
    createBlock(
      botId: $botId
      title: $title
      groupId: $groupId
      position: $position
    ) {
      parentGroup {
        ...blocksGroupFragment
      }
      block {
        id
        title
        removed
        reachable
        is_flow
        stats {
          seen
          clicked
        }
      }
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

export const BLOCKS_TITLES_QUERY = gql`
  query BlocksTitlesQuery($botId: String!) {
    bot(id: $botId) {
      id
      archiveBlocks {
        id
        title
        removed
        reachable
      }
    }
  }
`;

export const UPDATE_BLOCK_MUTATION = gql`
  mutation UpdateBlockMutation(
    $blockId: String!
    $updateBlockData: UpdateBlockData!
  ) {
    updateBlock(blockId: $blockId, updateBlockData: $updateBlockData) {
      updatedBlock {
        ...blockFragment
      }
    }
  }
  ${BLOCK_FRAGMENT}
`;

export const UPDATE_BLOCK_INDEX_MUTATION = gql`
  mutation UpdateBlockIndexMutation(
    $blockId: String!
    $updateBlockData: UpdateBlockData!
  ) {
    updateBlock(blockId: $blockId, updateBlockData: $updateBlockData) {
      updatedBlock {
        id
      }
    }
  }
`;

export const UPDATE_GROUP_INDEX_MUTATION = gql`
  mutation UpdateGroupIndexMutation(
    $botId: String!
    $group: UpdateBlocksGroup!
  ) {
    updateBlocksGroup(botId: $botId, group: $group) {
      position
    }
  }
`;

export const BOT_BLOCKS_GROUPS_FRAGMENT = gql`
  fragment botBlocksGroupsFragment on Bot {
    blocksGroups {
      id
    }
  }
`;

export const UPDATE_STATISTIC_MUTATION = gql`
  mutation UpdateStatisticMutation(
    $botId: String!
    $group: UpdateBlocksGroup!
  ) {
    updateBlocksGroup(botId: $botId, group: $group) {
      updatedGroup {
        id
        title
        with_stats
        collapsed
        blocks {
          id
          stats {
            sent
            seen
            clicked
            blocked
          }
        }
      }
      position
    }
  }
`;

export const DOWNLOAD_STATISTIC_MUTATION = gql`
  mutation generateGroupStatisticDownloadUrl(
    $botId: String!
    $groupId: String!
  ) {
    generateGroupStatisticDownloadUrl(botId: $botId, groupId: $groupId) {
      downloadUrl
    }
  }
`;
