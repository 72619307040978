import React from 'react';
import gql from 'graphql-tag';
import { ApolloProvider, Mutation } from '@apollo/react-components';
import client from '../../common/services/ApolloService';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import { Modal } from '../../modern-ui/Modal';
import { Input } from '../../modern-ui/Input';
import {
  Dialog,
  DialogHeading,
  DialogContent,
  DialogCloseButton,
  DialogDivider,
} from '../../modern-ui/Dialog';
import { toaster } from '../../services/MessageService';
import { ServiceMessageType } from '../../modern-ui/ServiceMessage2';
import * as css from './DashboardTokenAPIDialog.css';
import { GenerateDashboardApiTokenMutation } from './@types/GenerateDashboardApiTokenMutation';

const copy = require('clipboard-copy');

interface DashboardTokenAPIDialogProps {
  onRequestClose: () => void;
}

const GENERATE_TOKEN_MUTATION = gql`
  mutation GenerateDashboardApiTokenMutation {
    generateTokenAPI
  }
`;

export const DashboardTokenAPIDialog: React.SFC<DashboardTokenAPIDialogProps> = (
  props: DashboardTokenAPIDialogProps,
) => {
  const { onRequestClose } = props;

  return (
    <Modal onDismiss={onRequestClose}>
      <ApolloProvider client={client}>
        <Dialog className={css.dashboardTokenAPIDialog}>
          <DialogContent>
            <DialogCloseButton
              aria-label="close"
              type="button"
              onClick={onRequestClose}
            />
            <DialogHeading>
              {window.i18next.t(
                'DashboardTokenAPIDialog-JSXText--128-personal-dashboard-api-token',
              )}
            </DialogHeading>
            <span className={css.warning}>
              {window.i18next.t(
                'DashboardTokenAPIDialog-JSXText--211-the-api-is-in-early-beta',
              )}
            </span>
            <br />
            <span>
              {window.i18next.t(
                'DashboardTokenAPIDialog-JSXText--166-we-may-change-it-without-prior-notice',
              )}
            </span>
            <br />
            <br />
            <span>
              {window.i18next.t(
                'DashboardTokenAPIDialog-JSXText--117-personal-dashboard-api-token-allows-you-to-manage-your-bots-learn-more-about',
              )}{' '}
              <a
                className={css.link}
                target="_blank"
                rel="noreferrer noopener"
                href="https://docs.chatfuel.com/api/dashboard-api"
              >
                {window.i18next.t(
                  'DashboardTokenAPIDialog-JSXText--782-dashboard-api',
                )}
              </a>
            </span>

            <Mutation<GenerateDashboardApiTokenMutation>
              mutation={GENERATE_TOKEN_MUTATION}
              onError={() => {
                toaster.show({
                  type: ServiceMessageType.error,
                  payload: {
                    message: window.i18next.t(
                      'DashboardTokenAPIDialog-string-7230-could-not-generate-new-api-token-please-try-again-later',
                    ),
                  },
                });
                onRequestClose();
              }}
            >
              {(generate, { loading, data }) => {
                if (!data) {
                  return (
                    <React.Fragment>
                      <br />
                      <br />
                      <span>
                        {window.i18next.t(
                          'DashboardTokenAPIDialog-JSXText--203-the-token-you-generated-previously-will-no-longer-work',
                        )}
                      </span>
                      <div className={css.generateButtonContainer}>
                        <Button
                          intent={ButtonIntent.primary}
                          onClick={() => {
                            generate();
                          }}
                          disabled={loading}
                        >
                          {window.i18next.t(
                            'DashboardTokenAPIDialog-JSXText-2712-generate-token',
                          )}
                        </Button>
                      </div>
                    </React.Fragment>
                  );
                }

                const token = data.generateTokenAPI;

                return (
                  <div>
                    <DialogDivider className={css.divider} />
                    <span>
                      {window.i18next.t(
                        'DashboardTokenAPIDialog-JSXText-1325-copy-your-personal-access-token-now-keep-it-in-a-safe-place-you-wont-be-able-to-access-it-right-later-you-can-always-generate-a-new-token-in-which-case-the-current-one-will-no-longer-work',
                      )}
                    </span>
                    <div className={css.copyContainer}>
                      <Input
                        value={token}
                        readOnly
                        className={css.tokenInput}
                      />
                      <Button
                        intent={ButtonIntent.primary}
                        onClick={() => {
                          copy(token);
                        }}
                      >
                        {window.i18next.t(
                          'DashboardTokenAPIDialog-JSXText-6076-copy',
                        )}
                      </Button>
                    </div>
                  </div>
                );
              }}
            </Mutation>
          </DialogContent>
        </Dialog>
      </ApolloProvider>
    </Modal>
  );
};
