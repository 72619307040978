import React, { useCallback } from 'react';
import { Modal } from '@services/index';
import { PaymentDialog } from '@components/Payments';
import { PaymentFailureDialog } from '../dialogs';
import { PaymentDialogProps } from '@components/Payments/PaymentDialog/types';

export const useUpdatePaymentDialog = () => {
  const showPaymentUpdateDialog = useCallback(
    (setupPayment?: PaymentDialogProps['setupPayment']) =>
      Modal.show(
        ({ close, resolve }) => (
          <PaymentDialog
            onSuccess={resolve}
            onClose={close}
            renderPaymentError={(props) => <PaymentFailureDialog {...props} />}
            setupPayment={setupPayment}
          />
        ),
        {
          id: 'ShowPaymentUpdateDialog',
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      ),
    [],
  );

  return showPaymentUpdateDialog;
};
