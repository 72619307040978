import React, { useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Focus } from 'react-powerplug';
import { Input } from '@ui/Input';
import * as css from './ButtonPopupCommon.css';
import { ButtonPopupTabsTypes } from './tabs';
import { Flex } from '@ui/Flex';
import { TooltipPure } from '@ui/Tooltip2';
import { ButtonErrors } from './validation';
import { SymbolsLimitBubble } from '@ui/SymbolsLimitBubble';
import { EmojiPicker } from '@ui/EmojiPicker';
import { insertText } from '@utils/documentExecCommand';

interface ButtonPopupTitleProps {
  popupOpen?: boolean;
  buttonTitle: string;
  onTitleChange: (value: string) => void;
  error?: ButtonErrors[];
  activeTab: ButtonPopupTabsTypes;
  cost?: string;
  allowBuyButton?: boolean;
  buyButtonWarningText?: string;
  costError?: boolean;
}

export const BUTTON_TITLE_MAX_LENGTH = 20;

const isTitleValid = (title?: string) =>
  !title || title.length === 0 || !/^\s+$/.test(title);

export const ButtonPopupTitle: React.FC<ButtonPopupTitleProps> = (props) => {
  const [title, setTitle] = useState<string>(props.buttonTitle || '');
  const [showCostErrorTooltip, setShowCostErrorTooltip] =
    useState<boolean>(false);
  const [showBuyButtonTooltip, setShowBuyButtonTooltip] =
    useState<boolean>(false);
  const [titleError, setTitleError] = useState<boolean>(
    !!props.error && props.error.indexOf(ButtonErrors.title) !== -1,
  );

  const titleInputRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (props.popupOpen && titleInputRef && titleInputRef.current) {
      titleInputRef.current.focus();
    }
  }, [props.popupOpen]);

  useEffect(() => {
    setTitleError(
      !!props.error && props.error.indexOf(ButtonErrors.title) !== -1,
    );
  }, [props.error]);

  // update state title and only when popup closed (for title don't blink on mutate button object)
  useEffect(() => {
    if (!props.popupOpen) {
      setTitle(props.buttonTitle || '');
    }
  }, [props.buttonTitle, props.popupOpen]);

  return (
    <div className={css.buttonTitle}>
      <div className={css.sectionTitle}>
        {window.i18next.t('ButtonPopupTitle-JSXText-1028-if-user-clicks')}
      </div>
      {[
        ButtonPopupTabsTypes.blocks,
        ButtonPopupTabsTypes.url,
        ButtonPopupTabsTypes.phoneCall,
        ButtonPopupTabsTypes.submenu,
      ].includes(props.activeTab) && (
        <Focus>
          {({ bind, focused }) => (
            <Input
              error={titleError}
              render={({ getInputProps }) => (
                <EmojiPicker
                  onSelect={(emoji, el) => {
                    insertText(emoji.native, el);
                  }}
                >
                  {({ bind: { ref, ...emojiPickerBind } }) => (
                    <Flex
                      alignItems="center"
                      onClick={() => {
                        if (titleInputRef.current) {
                          titleInputRef.current.focus();
                        }
                      }}
                    >
                      <input
                        {...getInputProps({
                          value: title,
                          onChange: (e: React.FormEvent<HTMLInputElement>) => {
                            const { value } = e.currentTarget;
                            const isValid = isTitleValid(value);
                            if (isValid) {
                              setTitle(value);
                            }
                            setTitleError(!isValid);
                          },
                          onFocus: () => {
                            setTitleError(!isTitleValid(title));
                            bind.onFocus();
                            emojiPickerBind.onFocus();
                          },
                          onBlur: () => {
                            const isValid = isTitleValid(title);
                            if (isValid) {
                              props.onTitleChange(title);
                            }
                            setTitleError(!isValid);
                            bind.onBlur();
                            emojiPickerBind.onBlur();
                          },

                          maxLength: BUTTON_TITLE_MAX_LENGTH,
                          placeholder: window.i18next.t(
                            'ButtonPopupTitle-string-1280-add-button-title',
                          ),
                          ref: (el: HTMLInputElement) => {
                            titleInputRef.current = el;
                            // eslint-disable-next-line no-param-reassign
                            ref.current = el;
                          },
                          className: css.buttonTitleInput,
                        })}
                      />
                      {focused ? (
                        <SymbolsLimitBubble
                          value={BUTTON_TITLE_MAX_LENGTH - title.length}
                        />
                      ) : null}
                    </Flex>
                  )}
                </EmojiPicker>
              )}
            />
          )}
        </Focus>
      )}
      {props.activeTab === ButtonPopupTabsTypes.buy && (
        <Flex alignItems="center" className={css.nonInputTitle}>
          <TooltipPure
            placement="top-start"
            show={showCostErrorTooltip}
            content={window.i18next.t(
              'ButtonPopupTitle-string-1085-total-price-cannot-be-less-than-0-50',
            )}
            positionFixed
          >
            {(ref) => (
              <div
                ref={ref}
                onMouseEnter={() => setShowCostErrorTooltip(!!props.costError)}
                onMouseLeave={() => setShowCostErrorTooltip(false)}
                className={cn(
                  css.buyTitleCoast,
                  !!props.costError && css.buyButtonCoastError,
                )}
              >
                {props.cost}
              </div>
            )}
          </TooltipPure>
          <TooltipPure
            placement="top-start"
            show={showBuyButtonTooltip && !props.allowBuyButton}
            content={props.buyButtonWarningText}
            positionFixed
          >
            {(ref) => (
              <div
                style={{ display: 'flex', alignItems: 'center' }}
                ref={ref}
                onMouseEnter={() => setShowBuyButtonTooltip(true)}
                onMouseLeave={() => setShowBuyButtonTooltip(false)}
              >
                <span
                  className={cn(
                    css.nonInputTitleText,
                    css.buyTitleText,
                    !props.allowBuyButton && css.nonInputTitleTextError,
                  )}
                >
                  {window.i18next.t('ButtonPopupTitle-JSXText-1265-buy')}
                </span>
              </div>
            )}
          </TooltipPure>
        </Flex>
      )}
    </div>
  );
};
