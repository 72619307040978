import React from 'react';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { WhatsappManagerAnchor } from './components/WhatsappManagerAnchor';
import * as css from './TemplatesCreatedIn.css';
import { OnboardingTourHTMLElementId } from '@globals';

export const TemplatesCreatedIn = () => {
  const { t } = useSafeTranslation();

  return (
    <Flex
      id={OnboardingTourHTMLElementId.WATemplateCreateContainer}
      className={css.createdIn}
    >
      <Type size="15px" weight="medium">
        {t('pages.BotPage.ReengageWhatsappTab.templateModal.createdIn')}
      </Type>
      &nbsp;
      <WhatsappManagerAnchor />
    </Flex>
  );
};
