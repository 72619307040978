import React, { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Modal } from '@services/index';
import { CopyBotDialog, CopyBotDialogProps } from '@components/CopyBotDialog';
import { toaster } from '@services/MessageService';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { truncateWithEllipses } from 'cf-common/src/utils/String/truncateWithEllipses';
import Maybe from 'graphql/tsutils/Maybe';
import { BOT_NAME_TRIM_LIMIT } from '../consts';

export interface UseCopyBotDialogPayload {
  onCopyCompleted?: CopyBotDialogProps['onCompleted'];
}

export interface CopyBot {
  id: string;
  title: string;
  workspace_id: Maybe<string>;
}

export interface CopyOptions {
  copyToWorkspace: boolean;
}

export const useCopyBotDialog = (payload?: UseCopyBotDialogPayload) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const history = useHistory();
  const isBotsPage = useRouteMatch('/bots');

  const { onCopyCompleted } = payload ?? {};

  return useCallback(
    (bot: CopyBot, options: CopyOptions) => {
      Modal.show(
        ({ close }) => (
          <CopyBotDialog
            copyToWorkspace={options.copyToWorkspace}
            mobileAdaptive={isSmallScreenSize}
            title={`${bot.title}${t('BotList-Template-3261-copy')}`}
            idFrom={bot.id}
            workspaceId={bot.workspace_id}
            onClose={close}
            onCompleted={(data) => {
              toaster.default({
                payload: {
                  message: t('pages.GrowPage.BotPanel.copied', {
                    bot: truncateWithEllipses(
                      bot?.title || '',
                      BOT_NAME_TRIM_LIMIT,
                    ),
                  }),
                  showCloseButton: isBotsPage,
                  buttonLabel: t('pages.GrowPage.BotPanel.view'),
                  onButtonClick: () => history.push('/bots'),
                },
              });

              onCopyCompleted?.(data);
            }}
          />
        ),
        { mobileAdaptive: true },
      );
    },
    [t, history, isSmallScreenSize, onCopyCompleted, isBotsPage],
  );
};
