import gql from 'graphql-tag';

export const GET_WHATSAPP_TEMPLATES = gql`
  query GetWhatsappTemplates(
    $botId: String!
    $name: String
    $after: String
    $before: String
  ) {
    getWhatsappTemplates(
      botId: $botId
      name: $name
      after: $after
      before: $before
    ) {
      data {
        id
        name
        language
        category
        status
        components {
          type
          text
          format
          buttons {
            type
            text
            url
          }
        }
        quality_score {
          score
        }
      }
      paging {
        cursors {
          after
          before
        }
      }
    }
  }
`;
