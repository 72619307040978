import React from 'react';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';
import * as css from './NameColumn.css';

export interface NameColumnProps {
  name: string;
  category: string;
}

export const NameColumn: React.FC<NameColumnProps> = ({ name, category }) => (
  <TextEllipsis className={css.wrapper}>
    <Type size="15px" data-testid="whatsapp-templates__template-title">
      {name}
    </Type>

    <Type
      size="12px"
      color="grey"
      data-testid="whatsapp-templates__template-category"
    >
      {category}
    </Type>
  </TextEllipsis>
);
