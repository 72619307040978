import { Callout } from '@ui/Callout';
import cn from 'classnames';
import React, { HTMLProps } from 'react';
import * as css from './DeprecateMessage.css';

export interface DeprecateMessageProps extends HTMLProps<HTMLDivElement> {
  button?: React.ReactNode;
  text: string;
}

export const DeprecateMessage = ({
  button,
  text,
  className,
  ...props
}: DeprecateMessageProps) => {
  return (
    <Callout
      {...props}
      className={cn(className, css.container)}
      type="info"
      text={text}
      button={button}
    />
  );
};
