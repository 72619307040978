import React, { useState, useEffect } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import useVisibilityChange from 'use-visibility-change';
import cn from 'classnames';
import { FacebookAdAccountName } from './FacebookAdAccountName';
import { Anchor } from '../../../../../modern-ui/Links';
import {
  Button,
  ButtonIntent,
} from '../../../../../modern-ui/_deprecated/Button';
import { Type } from '../../../../../modern-ui/Type';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Input } from '../../../../../modern-ui/Input';
import { ReactComponent as CollapseIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/collapseTriangle2.svg';
import { ReactComponent as InfoIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { Spacer } from '../../../../../modern-ui/Spacer';
import { Tooltip2, TooltipPure } from '../../../../../modern-ui/Tooltip2';
import { ButtonUnstyled } from '../../../../../modern-ui/Button';
import { AudienceSyncState } from '../../../AudienceSyncContext';
import { useAudienceSyncState } from '../../../hooks/useAudienceSyncState';
import { BannerDialog, PlaceholderItem } from '../../Banner';
import { SyncSegmentsTable } from '../../SyncSegmentsTable';
import { sendEvent } from '../../../../../utils/Analytics';
import { useFbPermissions } from '../../../../../utils/FacebookPages/useFbPermissions';
import {
  useFacebookAdAccounts,
  useLazyFacebookAdAccounts,
  useConnectedFacebookAdAccountWithToS,
  useFacebookSyncedSegments,
} from '../../../../../utils/MarketingApi';
import { FacebookSyncedSegmentsQuery_bot_facebookSyncedSegments as FacebookSyncedSegment } from '../../../../../utils/MarketingApi/@types/FacebookSyncedSegmentsQuery';
import * as css from '../SyncBanner.css';

interface DialogProps {
  botId: string;

  onDismiss(): void;
}

const MIN_TIME_TO_CREATE_ACCOUNT_TIMEOUT = 5 * 1000;

export const SyncPrepareAddAdAccountDialog: React.FC<DialogProps> = ({
  botId,
  onDismiss,
}) => {
  const [leftForAddingAccount, setLeftState] = useState(false);
  const { setState } = useAudienceSyncState();

  const {
    fetchFacebookAdAccounts,
    facebookAdAccounts,
    loading: fbAdAccountsLoading,
    called,
  } = useLazyFacebookAdAccounts();

  const { syncedSegments, syncingSegments } = useFacebookSyncedSegments(botId);

  useVisibilityChange({
    onShow: async ({ lastSeenDate }) => {
      if (!leftForAddingAccount) {
        return;
      }
      setLeftState(false);

      const creatingAccountTime = Date.now() - lastSeenDate;
      if (creatingAccountTime > MIN_TIME_TO_CREATE_ACCOUNT_TIMEOUT) {
        await fetchFacebookAdAccounts();
      }
    },
  });

  useEffect(() => {
    if (called && !fbAdAccountsLoading && facebookAdAccounts?.length) {
      setState(AudienceSyncState.needPermissions);
    }
  }, [called, facebookAdAccounts, fbAdAccountsLoading, setState]);

  return (
    <BannerDialog onDismiss={onDismiss}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <FacebookAccountInfo />
      <Spacer factor={4} />
      <div className={css.accountBox}>
        <Type size="15px_DEPRECATED">
          {window.i18next.t(
            'Dialogs-JSXText--148-you-dont-have-any-ad-accounts-at-the-moment-it',
          )}
        </Type>
        <Button
          data-testid="audience-sync__create-ad-account-button"
          intent={ButtonIntent.primary}
          onClick={() => {
            sendEvent({
              category: 'people tab',
              action: 'create ad account',
              label: 'segments sync',
            });
            setLeftState(true);

            const facebookCreateAdAccountURL = `https://business.facebook.com/`;
            window.open(facebookCreateAdAccountURL, '_blank');
          }}
        >
          {window.i18next.t('Dialogs-JSXText--208-create')}
        </Button>
      </div>
      <Spacer factor={4} />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <SyncingSegmentsStats
        synced={syncedSegments}
        syncing={syncingSegments}
        disabled
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceSizeInfo disabled />
      <Spacer />
      <SyncSegmentsTable botId={botId} disabled />
    </BannerDialog>
  );
};

export const SyncPrepareSetUpAccountDialog: React.FC<DialogProps> = ({
  botId,
  onDismiss,
}) => {
  const { setState } = useAudienceSyncState();

  const [adAccount, setAdAccount] = useState<{
    id: string;
    title: string;
  } | null>(null);

  const { bindFacebookAdAccount, loading: accountBinding } =
    useConnectedFacebookAdAccountWithToS(botId);

  const { syncedSegments, syncingSegments } = useFacebookSyncedSegments(botId);

  const { facebookAdAccounts } = useFacebookAdAccounts();
  const accountList = facebookAdAccounts.map(({ id, name: title }) => ({
    id,
    title,
  }));

  return (
    <BannerDialog onDismiss={onDismiss}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <FacebookAccountInfo />
      <Spacer factor={4} />
      <div className={css.accountBox}>
        <SimpleCombobox
          downshiftContainerClassName={css.accountSelect}
          items={accountList}
          onChange={(adAcc) => {
            if (adAcc) {
              sendEvent({
                category: 'people tab',
                action: 'ad account click',
                label: 'segments sync',
                propertyBag: {
                  adAccountId: adAcc.id,
                },
              });
            }
            setAdAccount(adAcc);
          }}
          renderInput={({
            getInputProps,
            openMenu,
            clearSelection,
            selectedItem,
          }) => (
            <Input
              data-testid="audience-sync__select-ad-account-input"
              className={css.accountInput}
              renderIconEnd={() => (
                <ButtonUnstyled
                  className={css.collapseButton}
                  onClick={openMenu}
                >
                  <CollapseIcon className={css.collapseIcon} />
                </ButtonUnstyled>
              )}
              {...getInputProps({
                placeholder: window.i18next.t(
                  'Dialogs-string-6676-select-ad-account',
                ),
                name: 'fieldName',
                onFocus: () => {
                  sendEvent({
                    category: 'people tab',
                    action: 'select ad account',
                    label: 'segments sync',
                    propertyBag: {
                      accountId: selectedItem?.id,
                      accountName: selectedItem?.title,
                    },
                  });
                  openMenu();
                },
                onChange: clearSelection,
              })}
              disabled={accountBinding}
            />
          )}
        />
        <Button
          data-testid="audience-sync__set-up-button"
          intent={ButtonIntent.primary}
          onClick={async () => {
            sendEvent({
              category: 'people tab',
              action: 'set up ad account',
              label: 'segments sync',
              propertyBag: {
                adAccountId: adAccount?.id,
              },
            });
            const { data, errors } = await bindFacebookAdAccount(
              adAccount?.id!,
            );
            if (!errors?.length) {
              if (
                data?.bindFacebookAdAccount.connectedFacebookAdAccount
                  ?.tos_accepted?.custom_audience_tos === 1
              ) {
                setState(AudienceSyncState.allowed);
              } else {
                setState(AudienceSyncState.needTerms);
              }
            }
          }}
          disabled={!adAccount || accountBinding}
        >
          {accountBinding ? (
            <PlaceholderItem />
          ) : (
            window.i18next.t('Dialogs-string--182-set-up')
          )}
        </Button>
      </div>
      <Spacer factor={4} />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <SyncingSegmentsStats
        synced={syncedSegments}
        syncing={syncingSegments}
        disabled
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceSizeInfo disabled />
      <Spacer />
      <SyncSegmentsTable botId={botId} disabled />
    </BannerDialog>
  );
};

const MIN_TIME_TO_ACCEPT_TERMS = 1 * 1000;

export const SyncPrepareNeedTermsDialog: React.FC<DialogProps> = ({
  botId,
  onDismiss,
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [leftForAcceptTerms, setLeftState] = useState(false);

  const { setState } = useAudienceSyncState();

  const {
    permissions: { hasMarketingPermissions },
  } = useFbPermissions();

  const {
    unbindFacebookAdAccount,
    unbindFacebookAdAccountCalled,
    connectedFacebookAdAccount,
    loading: accountLoading,
    refetch: refetchConnectedFacebookAdAccount,
  } = useConnectedFacebookAdAccountWithToS(botId);

  const { facebookAdAccounts, loading: facebookAdAccountsLoading } =
    useFacebookAdAccounts();

  const hasAdAccountOwnership = facebookAdAccounts.some(
    ({ id }) => id === connectedFacebookAdAccount?.id,
  );

  const { syncedSegments, syncingSegments } = useFacebookSyncedSegments(botId);

  useVisibilityChange({
    onShow: async ({ lastSeenDate }) => {
      if (!leftForAcceptTerms) {
        return;
      }
      setLeftState(false);

      const creatingAccountTime = Date.now() - lastSeenDate;
      if (creatingAccountTime > MIN_TIME_TO_ACCEPT_TERMS) {
        await refetchConnectedFacebookAdAccount();
      }
    },
  });

  useEffect(() => {
    if (unbindFacebookAdAccountCalled && !accountLoading) {
      if (!hasMarketingPermissions) {
        setState(AudienceSyncState.needPermissions);
      } else {
        setState(AudienceSyncState.needAdAccount);
      }
    }
  }, [
    hasMarketingPermissions,
    unbindFacebookAdAccountCalled,
    accountLoading,
    setState,
  ]);

  useEffect(() => {
    if (
      !accountLoading &&
      connectedFacebookAdAccount?.tos_accepted?.custom_audience_tos === 1
    ) {
      setState(AudienceSyncState.allowed);
    }
  }, [accountLoading, connectedFacebookAdAccount, setState]);

  useEffect(() => {
    if (showTooltip) {
      if (!hasAdAccountOwnership) {
        sendEvent({
          category: 'people tab',
          action: 'show tooltip',
          label: 'segments sync',
          propertyBag: {
            reason: 'account was added by another admin',
          },
        });
      } else {
        sendEvent({
          category: 'people tab',
          action: 'show tooltip',
          label: 'segments sync',
          propertyBag: {
            reason: 'permissions needed',
          },
        });
      }
    }
  }, [showTooltip, hasAdAccountOwnership]);

  return (
    <BannerDialog onDismiss={onDismiss}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceExportInfo />
      <Spacer factor={4} />
      <div className={css.accountBox}>
        <div>
          <Type as="p" weight="medium" size="15px_DEPRECATED">
            {window.i18next.t('Dialogs-JSXText--184-facebook-ad-account')}
          </Type>
          <FacebookAdAccountName>
            {connectedFacebookAdAccount?.name}
          </FacebookAdAccountName>
          <TooltipPure
            placement="bottom"
            boundariesElement="viewport"
            show={showTooltip}
            content={
              !hasAdAccountOwnership ? (
                <>
                  <Type as="p" color="white" size="15px_DEPRECATED">
                    {window.i18next.t(
                      'Dialogs-JSXText--139-this-account-was-added-by-another',
                    )}
                  </Type>
                  <Type as="p" color="white" size="15px_DEPRECATED">
                    {window.i18next.t(
                      'Dialogs-JSXText-1151-bot-admin-you-can-either-ask-them',
                    )}
                  </Type>
                  <Type as="p" color="white" size="15px_DEPRECATED">
                    {window.i18next.t(
                      'Dialogs-JSXText--222-to-accept-terms-or-switch-to-your',
                    )}
                  </Type>
                  <Type as="p" color="white" size="15px_DEPRECATED">
                    {window.i18next.t('Dialogs-JSXText--141-account-instead')}
                  </Type>
                </>
              ) : (
                window.i18next.t('Dialogs-string--226-permissions-needed')
              )
            }
            positionFixed
          >
            {(ref) => (
              <div ref={ref} className={css.infoIconContainer}>
                <InfoIcon
                  className={css.alertIcon}
                  onMouseEnter={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                />
              </div>
            )}
          </TooltipPure>
        </div>
        <div>
          {facebookAdAccountsLoading || accountLoading ? (
            <PlaceholderItem className={css.acceptTermsButtonsPlaceholder} />
          ) : (
            <>
              <Button
                data-testid="audience-sync__accept-terms-button"
                className={css.acceptTermsButton}
                intent={ButtonIntent.primary}
                disabled={!hasAdAccountOwnership}
                onClick={() => {
                  sendEvent({
                    category: 'people tab',
                    action: 'accept terms',
                    label: 'segments sync',
                  });
                  setLeftState(true);

                  const normalizedId = connectedFacebookAdAccount?.id.replace(
                    'act_',
                    '',
                  );
                  const facebookCustomAudiencesTOCHref = `https://business.facebook.com/ads/manage/customaudiences/tos/?act=${normalizedId}`;
                  window.open(facebookCustomAudiencesTOCHref, '_blank');
                }}
              >
                {window.i18next.t('Dialogs-JSXText-1924-accept-terms')}
              </Button>
              <Button
                data-testid="audience-sync__change-ad-account-button"
                intent={ButtonIntent.secondary}
                onClick={() => {
                  sendEvent({
                    category: 'people tab',
                    action: 'change ad account',
                    label: 'segments sync',
                    propertyBag: {
                      prevAdAccountId: connectedFacebookAdAccount?.id,
                    },
                  });
                  unbindFacebookAdAccount();
                }}
              >
                {window.i18next.t('Dialogs-JSXText-1822-change')}
              </Button>
            </>
          )}
        </div>
      </div>
      <Spacer factor={4} />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <SyncingSegmentsStats
        synced={syncedSegments}
        syncing={syncingSegments}
        disabled
      />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceSizeInfo disabled />
      <Spacer />
      <SyncSegmentsTable botId={botId} disabled />
    </BannerDialog>
  );
};

const SYNC_LIMIT_V1 = 10;

export const SyncDialog: React.FC<DialogProps> = ({ botId, onDismiss }) => {
  const [showLimitReachedTooltip, setShowLimitReachedTooltip] = useState(false);
  const [showViewOnlyTooltip, setShowViewOnlyTooltip] = useState(false);

  const { setState } = useAudienceSyncState();

  const { syncedSegments, syncingSegments } = useFacebookSyncedSegments(botId);

  const freeSegmentsSyncLimitReached = syncingSegments.length >= SYNC_LIMIT_V1;

  const {
    permissions: { hasMarketingPermissions },
  } = useFbPermissions();

  const {
    connectedFacebookAdAccount,
    unbindFacebookAdAccount,
    loading: accountLoading,
    unbindFacebookAdAccountCalled,
  } = useConnectedFacebookAdAccountWithToS(botId);

  const { facebookAdAccounts } = useFacebookAdAccounts();

  const hasAdAccountOwnership = facebookAdAccounts.some(
    ({ id }) => id === connectedFacebookAdAccount?.id,
  );

  useEffect(() => {
    if (unbindFacebookAdAccountCalled && !accountLoading) {
      if (!hasMarketingPermissions) {
        setState(AudienceSyncState.needPermissions);
      } else {
        setState(AudienceSyncState.needAdAccount);
      }
    }
  }, [
    hasMarketingPermissions,
    unbindFacebookAdAccountCalled,
    accountLoading,
    setState,
  ]);

  useEffect(() => {
    if (showLimitReachedTooltip && hasAdAccountOwnership) {
      sendEvent({
        category: 'people tab',
        action: 'show tooltip',
        label: 'segments sync',
        propertyBag: {
          reason: 'free sync limit reached',
          'segments syncing': syncingSegments.length,
        },
      });
    }
  }, [showLimitReachedTooltip, hasAdAccountOwnership, syncingSegments]);

  useEffect(() => {
    if (showViewOnlyTooltip && !hasAdAccountOwnership) {
      sendEvent({
        category: 'people tab',
        action: 'show tooltip',
        label: 'segments sync',
        propertyBag: {
          reason: 'ad account was added by another bot admin',
        },
      });
    }
  }, [showViewOnlyTooltip, hasAdAccountOwnership]);

  return (
    <BannerDialog onDismiss={onDismiss}>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceExportInfo />
      <Spacer factor={4} />
      <div className={css.accountBox}>
        <div>
          <Type as="p" weight="medium" size="15px_DEPRECATED">
            {window.i18next.t('Dialogs-JSXText--184-facebook-ad-account')}
          </Type>
          <FacebookAdAccountName>
            {connectedFacebookAdAccount?.name}
          </FacebookAdAccountName>
          {!hasAdAccountOwnership && (
            <TooltipPure
              placement="bottom"
              show={showViewOnlyTooltip}
              content={
                <Type as="p" color="white" size="15px_DEPRECATED">
                  {window.i18next.t(
                    'Dialogs-JSXText--161-this-ad-account-was-added-by-another-bot-admin-you-dont-have-access-to-it',
                  )}
                </Type>
              }
              positionFixed
            >
              {(ref) => (
                <div ref={ref} className={css.infoIconContainer}>
                  <InfoIcon
                    className={css.alertIcon}
                    onMouseEnter={() => setShowViewOnlyTooltip(true)}
                    onMouseLeave={() => setShowViewOnlyTooltip(false)}
                  />
                </div>
              )}
            </TooltipPure>
          )}
        </div>
        <Button
          className={css.changeButton}
          intent={ButtonIntent.secondary}
          disabled={accountLoading}
          data-testid="audience-sync__change-ad-account-button"
          onClick={() => {
            sendEvent({
              category: 'people tab',
              action: 'change ad account',
              label: 'segments sync',
              propertyBag: {
                prevAdAccountId: connectedFacebookAdAccount?.id,
              },
            });

            unbindFacebookAdAccount();
          }}
        >
          {accountLoading ? (
            <PlaceholderItem className={css.changeButtonPlaceholder} />
          ) : (
            window.i18next.t('Dialogs-string-2017-change')
          )}
        </Button>
      </div>
      <Spacer factor={4} />
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <SyncingSegmentsStats
        synced={syncedSegments}
        syncing={syncingSegments}
        disabled={!hasAdAccountOwnership}
      />
      {freeSegmentsSyncLimitReached && (
        <Tooltip2
          placement="right"
          content={
            <>
              <Type as="p" color="white" size="15px_DEPRECATED">
                {window.i18next.t(
                  'Dialogs-JSXText-1148-you-can-only-sync-10-segments-at',
                )}
                <br />
                {window.i18next.t(
                  'Dialogs-JSXText--142-a-time-pause-some-segments-to',
                )}
                <br />
                {window.i18next.t(
                  'Dialogs-JSXText--638-sync-new-ones-or-contact-us-at',
                )}
              </Type>
              <Anchor
                intent="tooltip"
                size="regular"
                href="mailto:team@chatfuel.com"
              >
                team@chatfuel.com
              </Anchor>
              <Type color="white" size="15px_DEPRECATED">
                {' '}
                {window.i18next.t('Dialogs-JSXText--119-for-help')}
              </Type>
            </>
          }
          hoverable={hasAdAccountOwnership}
          positionFixed
        >
          {(ref, { onMouseEnter, onMouseLeave }) => (
            <div ref={ref} className={css.infoIconContainer}>
              <InfoIcon
                data-testid="audience-sync__free-sync-limit-icon"
                className={cn(css.alertIcon, {
                  [css.disabledIcon]: !hasAdAccountOwnership,
                })}
                onMouseEnter={() => {
                  setShowLimitReachedTooltip(true);
                  onMouseEnter();
                }}
                onMouseLeave={() => {
                  setShowLimitReachedTooltip(false);
                  onMouseLeave();
                }}
              />
            </div>
          )}
        </Tooltip2>
      )}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <AudienceSizeInfo disabled={!hasAdAccountOwnership} />
      <Spacer />
      <SyncSegmentsTable
        botId={botId}
        disabledIdle={freeSegmentsSyncLimitReached}
        disabled={!hasAdAccountOwnership}
      />
    </BannerDialog>
  );
};

const SyncingSegmentsStats: React.FC<{
  syncing: FacebookSyncedSegment[];
  synced: FacebookSyncedSegment[];
  disabled?: boolean;
}> = ({ synced, syncing, disabled = false }) => (
  <>
    <Type
      weight="semibold"
      color={disabled ? 'grey' : 'black'}
      size="15px_DEPRECATED"
    >
      {window.i18next.t('Dialogs-JSXText--213-segments-syncing')}
    </Type>
    &nbsp;&nbsp;&nbsp;
    <Type
      data-testid="sync-dialog__syncing-segments-stats-label"
      color={disabled ? 'grey' : 'black'}
      size="15px_DEPRECATED"
    >
      {syncing.length}/{synced.length}
    </Type>
  </>
);

const FacebookAccountInfo: React.FC<{}> = () => (
  <>
    <Type as="p" color="greyDark" size="15px_DEPRECATED">
      {window.i18next.t(
        'Dialogs-JSXText-1053-to-sync-segments-to-facebook-please-select-your-facebook-ad',
      )}
      <br />
      {window.i18next.t(
        'Dialogs-JSXText--685-account-below-keep-in-mind-that-youll-need-to-have-an-active',
      )}
    </Type>
    <Anchor
      intent="external"
      href="https://www.facebook.com/business/help/1710077379203657?id=180505742745347"
      target="_blank"
      rel="noopener noreferrer"
      onMouseDown={() => {
        sendEvent({
          category: 'people tab',
          action: 'business manager click',
          label: 'segments sync',
        });
      }}
    >
      {window.i18next.t('Dialogs-JSXText--548-business-manager')}
    </Anchor>
    <Type color="greyDark" size="15px_DEPRECATED">
      {' '}
      {window.i18next.t(
        'Dialogs-JSXText--328-account-that-has-at-least-one',
      )}{' '}
    </Type>
    <Anchor
      intent="external"
      href="https://www.facebook.com/business/help/910137316041095?id=420299598837059"
      target="_blank"
      rel="noopener noreferrer"
      onMouseDown={() => {
        sendEvent({
          category: 'people tab',
          action: 'ad account link click',
          label: 'segments sync',
        });
      }}
    >
      {window.i18next.t('Dialogs-JSXText--755-ad-account')}
    </Anchor>
    <Type color="greyDark" size="15px_DEPRECATED">
      {' '}
      {window.i18next.t('Dialogs-JSXText--933-within-it')}
    </Type>
  </>
);

const AudienceExportInfo: React.FC = () => {
  const { t } = useSafeTranslation();
  const learnMoreHref = t('common.helpDocLinks.people_tab');
  return (
    <>
      <Type as="p" color="greyDark" size="15px_DEPRECATED">
        {window.i18next.t(
          'Dialogs-JSXText--202-export-audience-segments-to-your-facebook-ads-manager-account',
        )}
        <br />
        {window.i18next.t(
          'Dialogs-JSXText-6628-segments-will-be-automatically-updated-every-hour-via-continuous',
        )}
      </Type>
      <Type color="greyDark" size="15px_DEPRECATED">
        {window.i18next.t('Dialogs-JSXText--186-online-sync')}{' '}
      </Type>
      <Anchor
        hideArrow
        intent="external"
        href={learnMoreHref}
        target="_blank"
        rel="noopener noreferrer"
        onMouseDown={() => {
          sendEvent({
            category: 'people tab',
            action: 'learn more',
            label: 'segments sync',
            propertyBag: {
              reason: 'send segments to ads manager',
            },
          });
        }}
      >
        {window.i18next.t('Dialogs-JSXText-1076-learn-more')}
      </Anchor>
    </>
  );
};

const AudienceSizeInfo: React.FC<{ disabled?: boolean }> = ({ disabled }) => (
  <Type as="p" color={disabled ? 'grey' : 'black'} size="15px_DEPRECATED">
    {window.i18next.t(
      'Dialogs-JSXText-1568-you-can-sync-only-segments-with-audience-size-is-of-30-users-or-more',
    )}
    <br />
    {window.i18next.t(
      'Dialogs-JSXText--110-it-may-take-up-to-one-hour-for-all-segment-users-to-appear-in-ads-manager',
    )}
  </Type>
);
