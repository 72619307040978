import { Dispatch, SetStateAction, useEffect } from 'react';
import { usePrevious } from 'cf-common/src/utils/hooks';

export const useButtonPopupBlocksSelectorAngularForceUpdate = (
  keyId: string | undefined,
  setState: Dispatch<SetStateAction<boolean>>,
) => {
  const prevKeyId = usePrevious(keyId, keyId);
  useEffect(() => {
    if (prevKeyId !== keyId) {
      setTimeout(() => {
        setState(false); // Dirty hack for force update in angular
      });
    }
  }, [prevKeyId, keyId, setState]);
};
