import { keys } from 'ramda';
import {
  ConnectPageField,
  ConnectPageOrigin,
  ConnectPageUrlParams,
} from './types';

export const getConnectPageEventPostfix = (
  origin?: ConnectPageOrigin | string,
) => {
  if (
    origin === ConnectPageOrigin.entryPoint ||
    origin === ConnectPageOrigin.botLinkEntryPoint ||
    origin === ConnectPageOrigin.entryPointDeepLink
  ) {
    return ' for entry point';
  }
  if (origin === ConnectPageOrigin.testThis) {
    return ' for test this flow';
  }
  if (origin === ConnectPageOrigin.upgrade) {
    return ' for upgrade';
  }
  if (origin === ConnectPageOrigin.commentsAddPosts) {
    return ' for comments entry point';
  }
  return '';
};

export const getConnectPageUrlParams = (
  urlParams?: ConnectPageUrlParams,
): ConnectPageUrlParams => {
  if (urlParams) {
    return urlParams;
  }
  const queryParams = new URLSearchParams(window.location.search);
  const extractedUrlParams: ConnectPageUrlParams = Object.values(
    ConnectPageField,
  ).reduce(
    (acc, connectPageField) =>
      queryParams.has(connectPageField)
        ? {
            ...acc,
            [connectPageField]: queryParams.get(connectPageField),
          }
        : acc,
    {} as ConnectPageUrlParams,
  );
  if (extractedUrlParams[ConnectPageField.origin]) {
    return extractedUrlParams;
  }
  return {
    ...extractedUrlParams,
    [ConnectPageField.origin]: ConnectPageOrigin.default,
  };
};

export const setConnectPageUrl = (fields: ConnectPageUrlParams) => {
  const url = new URL(window.location.href);
  Object.entries(fields).forEach(([key, value]) => {
    if (value) {
      url.searchParams.set(key, value);
    }
  });
  window.history.replaceState(null, '', url.toString());
};

export const resetConnectPageUrl = () => {
  const url = new URL(window.location.href);
  Object.values(ConnectPageField).forEach((key) => {
    url.searchParams.delete(key);
  });
  window.history.replaceState(null, '', url.toString());
};

export const isConnectPageOrigin = (
  param: unknown,
): param is ConnectPageOrigin => {
  return (
    typeof param === 'string' && Object.keys(ConnectPageOrigin).includes(param)
  );
};

export const runIfConnectPageUrlParamsSubmatches = (
  urlParamsToCheck: ConnectPageUrlParams,
  cb: () => void,
) => {
  const currentUrlParams = getConnectPageUrlParams();
  const isSubmatches = keys(urlParamsToCheck).every(
    (key) => currentUrlParams[key] === urlParamsToCheck[key],
  );
  if (isSubmatches) {
    cb();
  }
};
