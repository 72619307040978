import React, { useCallback, useRef, useState } from 'react';
import gql from 'graphql-tag';
import { complement, curry, prop } from 'ramda';
import memoize from 'memoize-one';
import { useHistory, useLocation } from 'react-router-dom';
import debounce from 'lodash-es/debounce';
import Dragula from 'react-dragula';
import { useCurrentBlockId, useCurrentBotId } from '@utils/Routing';
import { useRolePermission } from '@utils/Roles';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useQuery } from '@apollo/react-hooks';
import { useIsBlockStatsEnabled } from '@utils/Bot/stats/useIsBlockStatsEnabled';
import { sendEvent } from '@utils/Analytics';
import { AccessType, Permission } from '../../common/services/RoleService';
import {
  AsideGroupsQuery,
  AsideGroupsQuery_bot_blocksGroups as BlocksGroups,
  AsideGroupsQueryVariables,
} from './@types/AsideGroupsQuery';
import { Group, JS_GROUP_DND_HANDLE_CLASS, DND_DISABLED_CLASS } from './Group';
import { AddGroup, AddGroupInline } from './AddGroup';
import {
  createGroup,
  GroupType,
  updateGroupIndex,
} from './Mutations/GroupMutations';
import { BLOCKS_GROUP_FRAGMENT } from './Mutations/GQL';
import { ReactComponent as LoadingImg } from './images/aside-loading.svg';
import { updateBlockIndex } from './Mutations/BlockMutations';
import { isWhiteLabelDomain } from '../../utils/WhiteLabelUtils';
import { plurals } from '../../utils/Plurals';
import { Input } from '../../modern-ui/Input';
import { ButtonUnstyled } from '../../modern-ui/Button';
import { ReactComponent as RemoveIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_trash.svg';
import { ReactComponent as SearchIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_search.svg';
import * as css from './Aside.css';

export const ASIDE_GROUPS_QUERY = gql`
  query AsideGroupsQuery($botId: String!) {
    bot(id: $botId) {
      id
      first_block
      blocksGroups {
        ...blocksGroupFragment
      }
      archiveBlocks {
        id
        title
        removed
        reachable
      }
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

const isBuiltinGroup = prop('builtin');
const isNotBuiltinGroup = complement(isBuiltinGroup);
const getHTMLElementIndex = (el: HTMLElement) =>
  [...el.parentElement!.childNodes].indexOf(el);

const isCoincidenceByTitle = curry(
  <T extends { title: string }>(search: string, item: T) =>
    item.title.toLowerCase().indexOf(search.toLowerCase()) !== -1,
);

const filterBySearch = memoize((search: string, blocksGroups: BlocksGroups[]) =>
  blocksGroups.reduce((filteredBlocksGroups, blockGroup) => {
    const coincidenceByBlockTitle = blockGroup.blocks.filter(
      isCoincidenceByTitle(search),
    );
    if (coincidenceByBlockTitle.length) {
      const updatedGroup: BlocksGroups = {
        ...blockGroup,
        blocks: coincidenceByBlockTitle,
      };
      filteredBlocksGroups.push(updatedGroup);
    } else if (
      !blockGroup.builtin &&
      isCoincidenceByTitle(search, blockGroup)
    ) {
      filteredBlocksGroups.push(blockGroup);
    }

    return filteredBlocksGroups;
  }, [] as BlocksGroups[]),
);

const getBlocksCountDifference = memoize(
  (group: BlocksGroups, blocksGroups: BlocksGroups[]) => {
    const sameGroup = blocksGroups.find((bg) => bg.id === group.id);
    return sameGroup && sameGroup.blocks.length - group.blocks.length;
  },
  ([lGroup, lBlocksGroup], [rGroup, rBlocksGroup]) =>
    lGroup.id === rGroup.id && lBlocksGroup === rBlocksGroup,
);

export const Aside: React.FC = () => {
  const { t } = useSafeTranslation();
  const history = useHistory();
  const location = useLocation();
  const [search, setSearch] = useState('');
  const botId = useCurrentBotId();
  const currentBlockId = useCurrentBlockId();
  const { isBlockStatsEnabled, loading: blockStatsStatusLoading } =
    useIsBlockStatsEnabled();

  const { data, loading } = useQuery<
    AsideGroupsQuery,
    AsideGroupsQueryVariables
  >(ASIDE_GROUPS_QUERY, {
    variables: { botId: botId ?? '' },
    skip: !botId,
  });

  const { allowed: isAllowedEditGroups } = useRolePermission({
    domain: 'groups',
    can: Permission.EDIT,
    accessType: AccessType.FULL,
  });

  const goToPeopleTab = (filter: any) => {
    history.push(`/bot/${botId}/users`, {
      ...location.state,
      filter,
    });
  };

  const searchInputRef = useRef<HTMLInputElement>(null);
  const updateSearchDebounced = useCallback(debounce(setSearch, 300), [
    setSearch,
  ]);

  const sortableGroups = useRef(
    Dragula([], {
      moves: (_: HTMLElement, __: HTMLElement, handle: HTMLElement) =>
        handle.classList.contains(JS_GROUP_DND_HANDLE_CLASS),
    }).on('drop', (el: HTMLElement) => {
      const { groupId } = el.dataset;
      const destinationIndex = getHTMLElementIndex(el) + 1;
      if (botId && groupId) {
        updateGroupIndex({
          botId,
          groupId,
          destinationIndex,
        });
      }
    }),
  );

  const sortableBlocks = useRef(
    Dragula([], {
      accepts: (
        _: HTMLElement,
        __: HTMLElement,
        ___: HTMLElement,
        sibling: HTMLElement,
      ) => !!sibling,
      moves: (el: HTMLElement) => !el.classList.contains(DND_DISABLED_CLASS),
      invalid: (el: HTMLElement) =>
        el.classList.contains('js-add-block-button'),
    }).on(
      'drop',
      (el: HTMLElement, target: HTMLElement, source: HTMLElement) => {
        const { blockId: id } = el.dataset;
        const { blockTitle: title } = el.dataset;
        const { groupId: targetGroupId } = target.dataset;
        const { groupId: sourceGroupId } = source.dataset;
        const destinationIndex = getHTMLElementIndex(el);
        if (id && title && targetGroupId && sourceGroupId) {
          if (targetGroupId !== sourceGroupId) {
            source.appendChild(el); // move El (block) to Source node for correct destroy El in React (by GQL initial re render)
          }
          updateBlockIndex({
            targetGroupId,
            sourceGroupId,
            destinationIndex,
            block: { id, title },
          });
        }
      },
    ),
  );

  const handleMountSortableGroups = (el: HTMLDivElement) =>
    el && sortableGroups.current.containers.push(el);

  const handleMountSortableBlocks = (el: HTMLDivElement) =>
    el && sortableBlocks.current.containers.push(el);

  const handleCreateGroupRequest = (groupType: GroupType, index?: number) => {
    return (
      botId &&
      createGroup({
        botId,
        index: index && index + 1,
        asSequence: groupType === GroupType.sequence,
      })
    );
  };

  const renderRemoveIcon = () => (
    <ButtonUnstyled
      onClick={() => {
        if (searchInputRef.current) {
          searchInputRef.current.value = '';
        }
        setSearch('');
      }}
    >
      <RemoveIcon className={css.removeIcon} />
    </ButtonUnstyled>
  );

  if (!botId) {
    return null;
  }

  const renderContent = () => {
    if (loading || blockStatsStatusLoading) {
      return (
        <div className={css.loader}>
          <LoadingImg />
        </div>
      );
    }

    if (!(data && data.bot)) {
      return null;
    }
    const withSearch = Boolean(search);
    const foundBlocksGroups = withSearch
      ? filterBySearch(search, data.bot.blocksGroups)
      : data.bot.blocksGroups;

    const renderIconEnd = withSearch && renderRemoveIcon;

    return (
      <React.Fragment>
        <div className={css.searchInput}>
          <Input
            ref={searchInputRef}
            placeholder={t('modernComponents.Aside.searchPlaceholder')}
            onChange={(event) => {
              const { value } = event.target;
              updateSearchDebounced(value);
            }}
            onClick={() =>
              sendEvent({
                category: 'search',
                action: 'click',
              })
            }
            renderIcon={() => <SearchIcon className={css.searchIcon} />}
            {...(renderIconEnd && { renderIconEnd })}
          />
        </div>
        <div className={css.title}>{t('modernComponents.Aside.title')}</div>
        <div className={css.subtitle}>
          <span>{t('modernComponents.Aside.description')}</span>
          <span>&nbsp;</span>
          {!isWhiteLabelDomain() && (
            <>
              <a
                href="https://docs.chatfuel.com/getting-started/read-this-before-building-your-first-bot-it-will-save-you-time"
                target="_blank"
                className={css.subtitleLink}
                rel="noopener noreferrer"
              >
                {t('modernComponents.Aside.learnMore')}
              </a>
              .
            </>
          )}
        </div>
        {!!foundBlocksGroups.length && (
          <>
            {foundBlocksGroups.filter(isBuiltinGroup).map((group) => (
              <Group
                key={group.id}
                botId={botId}
                group={group}
                currentBlockId={currentBlockId}
                navigate={goToPeopleTab}
                statisticFeatureEnabled={isBlockStatsEnabled}
              />
            ))}
            <div ref={handleMountSortableGroups}>
              {foundBlocksGroups.filter(isNotBuiltinGroup).map((group, i) => {
                let blocksCountDifference = null;
                let collapsed;

                if (withSearch) {
                  const difference = getBlocksCountDifference(
                    group,
                    data.bot.blocksGroups,
                  );
                  if (difference && difference > 0) {
                    blocksCountDifference = (
                      <div className={css.blocksDifferenceText}>
                        {t('Aside-JSXText--620-there-are')}{' '}
                        {plurals(
                          difference,
                          group.sequence
                            ? t('Aside-string-1805-more-sequence')
                            : t('Aside-string--143-more-block'),
                          group.sequence
                            ? t('Aside-string-1406-more-sequences')
                            : t('Aside-string--164-more-blocks'),
                        )}{' '}
                        {t('Aside-JSXText--925-in-this-group')}
                      </div>
                    );
                  }

                  collapsed =
                    isCoincidenceByTitle(search, group) &&
                    !group.blocks.some(isCoincidenceByTitle(search));
                }

                return (
                  <div key={group.id} data-group-id={group.id}>
                    {!withSearch && isAllowedEditGroups ? (
                      <AddGroupInline
                        onCreateGroupRequest={handleCreateGroupRequest}
                        index={i}
                      />
                    ) : (
                      <div className={css.addGroupPlaceholder} />
                    )}

                    <Group
                      botId={botId}
                      group={group}
                      currentBlockId={currentBlockId}
                      collapsed={collapsed}
                      selectedGroupName={search}
                      defaultBlockId={data.bot.first_block || undefined}
                      sortableBlocksDecorator={handleMountSortableBlocks}
                      navigate={goToPeopleTab}
                      statisticFeatureEnabled={isBlockStatsEnabled}
                    />
                    {blocksCountDifference}
                  </div>
                );
              })}
            </div>
          </>
        )}
        {!withSearch && isAllowedEditGroups && (
          <AddGroup onCreateGroupRequest={handleCreateGroupRequest} />
        )}
        {!foundBlocksGroups.length && (
          <div className={css.blocksNotFoundText}>
            <span role="img" aria-label={t('Aside-string-1289-thinking')}>
              🤔
            </span>
            {t(
              'Aside-JSXText--210-nothing-found-try-changing-your-search-options',
            )}
          </div>
        )}
      </React.Fragment>
    );
  };

  return <div className={css.aside}>{renderContent()}</div>;
};
