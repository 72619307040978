import React, { SyntheticEvent } from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { propOr } from 'ramda';
import cn from 'classnames';

import { Flex } from '@ui/Flex';
import { Input } from '@ui/Input';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { MenuItem } from '@ui/Menu';
import { ReactComponent as DropdownIcon } from '@ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { Item, SimpleCombobox } from '@ui/SimpleCombobox';
import { Frequency } from '@globals';

import * as css from './NotificationsCombobox.css';

export type NotificationsComboboxProps = {
  onSelect: (id?: string | null, frequency?: Frequency) => void;
  isSelected: boolean;
  initialSelectedItem: Item<{
    isGroupTitle?: boolean;
    title: string;
    frequency?: Frequency;
  }> | null;
  items: Item<{
    isGroupTitle?: boolean;
    title: string;
    frequency?: Frequency;
  }>[];
  dataTestId: string;
  inputTestId: string;
};

export const NotificationsCombobox: React.FC<NotificationsComboboxProps> = ({
  onSelect,
  isSelected,
  items,
  initialSelectedItem,
  dataTestId,
  inputTestId,
}) => {
  const { t } = useSafeTranslation();

  const handleContainerInteraction = (e: SyntheticEvent) => {
    if (!initialSelectedItem) {
      e.stopPropagation();
    }
  };

  return (
    <Flex
      className={css.notificationsComboboxContainer}
      onClick={handleContainerInteraction}
      onKeyDown={handleContainerInteraction}
    >
      <div className={css.notificationsComboboxTitleContainer}>
        <Type
          size="15px"
          weight="semibold"
          color={isSelected ? 'white' : 'black'}
        >
          {t('modernComponents.BroadcastingTags.NotificationsCombobox.topic')}
        </Type>
      </div>
      <Spacer horizontalFactor={4} />
      <SimpleCombobox
        dataTestId={dataTestId}
        initialSelectedItem={initialSelectedItem}
        itemToString={propOr<string>('', 'title')}
        items={items}
        menuBoxClassName={css.menuBox}
        className={css.notificationsCombobox}
        onSelect={(selectedItem) => {
          onSelect(selectedItem?.id, selectedItem?.frequency);
        }}
        renderItem={({ getItemProps, item, index, highlightedIndex }) => {
          return (
            <MenuItem
              {...getItemProps({ item })}
              key={item.id}
              className={cn(item.isGroupTitle && css.titleMenuItem)}
              disabled={item.isGroupTitle}
              active={index === highlightedIndex}
              title={item.title}
            />
          );
        }}
        renderInput={({
          getInputProps,
          openMenu,
          clearSelection,
          selectedItem,
        }) => {
          return (
            <Input
              data-testid={inputTestId}
              {...getInputProps({
                placeholder:
                  items.length > 0
                    ? selectedItem?.title ||
                      t(
                        'modernComponents.BroadcastingTags.NotificationsCombobox.chooseTopic',
                      )
                    : t(
                        'modernComponents.BroadcastingTags.NotificationsCombobox.noTopicsCreatedYet',
                      ),
                name: 'broadcasting-tags-notifications',
                onFocus: openMenu,
                onChange: () => clearSelection(),
              })}
              renderIconEnd={() => (
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  className={css.notificationsComboboxDropdownContainer}
                  onClick={openMenu}
                  onKeyDown={openMenu}
                >
                  <DropdownIcon />
                </Flex>
              )}
            />
          );
        }}
      />
    </Flex>
  );
};
