import { Translation } from '@translations';
import { TOptions } from 'i18next';
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

interface CancelProTranslationProps {
  i18nKey: Translation;
  options?: TOptions;
}

export const CancelProTranslation: React.FC<CancelProTranslationProps> = ({
  i18nKey,
  options,
}) => {
  const { t } = useSafeTranslation();

  return <>{t(i18nKey, options)}</>;
};
