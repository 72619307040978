import ng from 'angular';
import { react2angular } from 'react2angular';
import { ButtonPopupTitle } from './ButtonPopupTitle';

export const ngButtonPopupTitle = ng
  .module('app.modernComponents.buttonPopupTitle', [])
  .component(
    'buttonPopupTitle',
    react2angular(
      ButtonPopupTitle,
      [
        'buttonTitle',
        'onTitleChange',
        'error',
        'activeTab',
        'allowBuyButton',
        'cost',
        'costError',
        'buyButtonWarningText',
        'popupOpen',
      ],
      [],
    ),
  ).name;
