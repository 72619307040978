import React from 'react';
import { Query } from '@apollo/react-components';
import nanoid from 'nanoid';
import { AttributeGroupEditor } from '../../UiUserAttributeEditor/AttributeGroupEditor';
import {
  UserAttribute,
  UserAttributeType,
} from '../../UiUserAttributeEditor/UserAttributeTypes';
import client from '../../../common/services/ApolloService';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as AddIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_add_small.svg';
import { variableSuggestUpdate } from '../../../common/services/UserFilterComponentService';
import { AttributesData } from '../../../utils/AttributesUtils/AttributesData';
import { ATTRIBUTE_VALUES_QUERY } from '../../../utils/AttributesUtils/AttributesUtilsGQL';
import {
  AttributeValuesQuery as AttributeValueSuggest,
  AttributeValuesQueryVariables as AttributeValueSuggestVariables,
} from '../../../utils/AttributesUtils/@types/AttributeValuesQuery';
import { attributeIdFromNameAndType } from '../../../utils/AttributesUtils/AttributesUtils';
import { ATTRIBUTE_TYPE_CODES } from '../../../components/users/consts';

const customAttributeIdFromName = attributeIdFromNameAndType(
  ATTRIBUTE_TYPE_CODES.custom,
);

interface UserAttributeEditorProps {
  attributes: UserAttribute[];
  botId: string;
  onChange: (attributes: UserAttribute[]) => void;
  attributeNamePlaceholder?: string;
  attributeValuePlaceholder?: string;
  attributeValueColumnName?: string;
  undeletable?: boolean;
}

interface UserAttributeEditorState {
  attributes: UserAttribute[];
  activeAttribute: string;
}

export class UserAttributeEditor extends React.Component<
  UserAttributeEditorProps,
  UserAttributeEditorState
> {
  state = {
    attributes: [],
    activeAttribute: '',
  };

  timeoutFocusToNewLine: number | undefined;

  lastAttributeRef: HTMLDivElement | null = null;

  componentDidMount() {
    if (this.props.attributes.length > 0) {
      this.setState({ attributes: this.props.attributes });
    } else {
      this.addAttribute();
      this.focusLastAttribute();
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.timeoutFocusToNewLine);
  }

  focusLastAttribute = () => {
    window.clearTimeout(this.timeoutFocusToNewLine);
    this.timeoutFocusToNewLine = window.setTimeout(() => {
      if (this.lastAttributeRef) {
        // wait show animations
        this.lastAttributeRef.focus();
      }
    }, 100);
  };

  addAttribute = () => {
    this.setState((prevState) => ({
      attributes: [
        ...prevState.attributes,
        {
          id: nanoid(),
          name: '',
          values: [''],
          type: UserAttributeType.custom,
          __typename: 'UserAttribute',
        },
      ],
    }));
  };

  onAttributeChange = (attributes: UserAttribute[]) => {
    this.setState({ attributes });
  };

  onSaveRequest = (attributes: UserAttribute[]) => {
    this.props.onChange(attributes);
  };

  onKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      this.addAttribute();
      this.focusLastAttribute();
    }
  };

  render() {
    return (
      <AttributesData botId={this.props.botId} client={client}>
        {({ attributes: attributesSuggests }) => (
          <>
            <Query<AttributeValueSuggest, AttributeValueSuggestVariables>
              client={client}
              query={ATTRIBUTE_VALUES_QUERY}
              variables={{
                botId: this.props.botId,
                prefix: '',
                variable: this.state.activeAttribute || '',
                type: UserAttributeType.custom as any,
              }}
              fetchPolicy="cache-and-network"
              skip={!this.state.activeAttribute}
            >
              {({
                error: valueError,
                loading: valueLoading,
                data: attributeValueSuggests,
                fetchMore,
              }) => {
                const customAttributes = attributesSuggests.filter(
                  (attribute) =>
                    attribute.type === (UserAttributeType.custom as string),
                );

                const activeAttributeIndex =
                  valueError || valueLoading || !attributeValueSuggests
                    ? -1
                    : attributeValueSuggests.bot &&
                      attributeValueSuggests.bot.variableValueSuggest
                        .map((item) => (item ? item.id : '@'))
                        .indexOf(
                          customAttributeIdFromName(this.state.activeAttribute),
                        );

                return (
                  <AttributeGroupEditor
                    attributesType={UserAttributeType.custom}
                    attributes={this.state.attributes}
                    onChange={this.onAttributeChange}
                    attributesSuggestsName={
                      customAttributes
                        ? customAttributes.map((item) => item && item.name)
                        : []
                    }
                    attributesSuggestsValues={
                      valueError ||
                      valueLoading ||
                      !attributeValueSuggests ||
                      activeAttributeIndex < 0
                        ? ([] as any[])
                        : attributeValueSuggests.bot!.variableValueSuggest[
                            activeAttributeIndex
                          ]!.suggests!.map((item) => item && item.value)
                    }
                    onValueInputKeyDown={this.onKeyDown}
                    readOnly={false}
                    onSaveRequest={this.onSaveRequest}
                    lastAttributeRef={(ref) => {
                      this.lastAttributeRef = ref;
                    }}
                    onRequestAttributeValuesLoad={(attribute, value) => {
                      if (this.state.activeAttribute) {
                        fetchMore({
                          query: ATTRIBUTE_VALUES_QUERY,
                          variables: {
                            botId: this.props.botId,
                            prefix: value,
                            variable: attribute,
                            type: UserAttributeType.custom,
                          },
                          updateQuery: variableSuggestUpdate as (
                            previousResult: {},
                            moreResult: {},
                          ) => AttributeValueSuggest,
                        });
                      }
                      this.setState({
                        activeAttribute: attribute,
                      });
                    }}
                    attributeNamePlaceholder={
                      this.props.attributeNamePlaceholder
                    }
                    attributeValuePlaceholder={
                      this.props.attributeValuePlaceholder
                    }
                    attributeValueColumnName={
                      this.props.attributeValueColumnName
                    }
                    undeletable={this.props.undeletable}
                  />
                );
              }}
            </Query>
            <Button
              onClick={this.addAttribute}
              intent={ButtonIntent.primary}
              colorWay={ButtonColorWay.white}
              renderIcon={() => <AddIcon />}
              size={ButtonSize.m}
            >
              {window.i18next.t(
                'UserAttributesEditor-JSXText--695-add-attribute',
              )}
            </Button>
          </>
        )}
      </AttributesData>
    );
  }
}
