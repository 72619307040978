import React, { useCallback } from 'react';
import { Modal } from '@services/.';
import { useCurrentBotId } from '@utils/Routing';
import { sendEvent } from '@utils/Analytics';
import {
  ChurnSurveyAction,
  DiscountStatus,
  DiscountType,
  LeaveReason,
} from '@globals';
import {
  CancelProSubscriptionDialog,
  SelectedLeaveReason,
} from './CancelProSubscriptionDialog/CancelProSubscriptionDialog';
import { PauseProDialog } from './PauseProDialog/PauseProDialog';
import { DiscountProDialog } from './DiscountProDialog/DiscountProDialog';
import { RateExperienceDialog } from './RateExperienceDialog/RateExperienceDialog';
import { useDiscountStatus } from '@utils/Discount';
import { ModalChainable } from '@services/ModalService/types';
import { useDiscountPro } from './useDiscountPro';
import { useCancelPro } from './useCancelPro';
import { useChurnSurvey } from './useChurnSurvey';
import { usePausePro } from './usePausePro';
import { showTransferPlanDialog } from '@components/TransferPlanDialog';
import { TransferDialog } from './TransferDialog/TransferDialog';
import css from './showCancelProSubscriptionDialog.css';

const CANCEL_PRO_SUBSCRIPTION_MODAL = 'CANCEL_PRO_SUBSCRIPTION_MODAL';

export type ShowCancelProSubscriptionPayload = Partial<{
  /** final step */
  onClose(): void;
  onGoBack(): void;
  onCancelPro(): void;
}>;

interface ModalResolveData {
  action: ChurnSurveyAction;
  reasons: LeaveReason[];
}

function isMobileResolveData(data: unknown): data is ModalResolveData {
  return typeof data === 'object' && !!data && 'action' in data;
}

type ModalData =
  | Promise<ModalResolveData>
  | ModalChainable<ModalResolveData, unknown>
  | null;

const mobileModalProps = { className: css.gradientModal };

export const useCancelProSubscriptionDialog = (
  cancelingPaywallCard = false,
) => {
  const botId = useCurrentBotId()!;

  const { discountStatus } = useDiscountStatus(botId, DiscountType.cancel_pro);

  const [cancelPro, cancelProLoading] = useCancelPro();
  const [activateDiscount, activateDiscountLoading] = useDiscountPro();
  const [startChurnSurvey, finishChurnSurvey, startChurnSurveyLoading] =
    useChurnSurvey(cancelingPaywallCard);
  const [pausePro, pauseProLoading] = usePausePro();

  const showCancelProSubscriptionDialog = useCallback(
    (payload?: ShowCancelProSubscriptionPayload) => {
      const { onClose, onGoBack, onCancelPro } = payload ?? {};

      Modal.show<SelectedLeaveReason>(
        ({ close, resolve }) => (
          <CancelProSubscriptionDialog
            cancelingPaywallCard={cancelingPaywallCard}
            onSubmit={resolve}
            onClose={() => {
              close();
              onGoBack?.();
            }}
          />
        ),
        {
          onOverlayDismiss: onGoBack,
          id: CANCEL_PRO_SUBSCRIPTION_MODAL,
          mobileAdaptive: true,
        },
      )

        ?.then((selectedLeaveReason): ModalData => {
          if (!selectedLeaveReason) return Promise.reject();
          const reasons = Object.keys(selectedLeaveReason) as LeaveReason[];

          if (
            selectedLeaveReason.my_page_is_restricted &&
            !cancelingPaywallCard
          )
            return Modal.show(
              ({ resolve, close }) => (
                <TransferDialog
                  onClose={() => {
                    close();
                    onGoBack?.();
                  }}
                  onCancel={() =>
                    resolve({ action: ChurnSurveyAction.cancel, reasons })
                  }
                  onDiscount={() => {
                    close();
                    showTransferPlanDialog();
                  }}
                />
              ),
              {
                onOverlayDismiss: onGoBack,
                mobileAdaptive: true,
                mobileProps: mobileModalProps,
              },
            );

          if (
            selectedLeaveReason.too_expensive &&
            discountStatus === DiscountStatus.eligible &&
            !cancelingPaywallCard
          )
            return Modal.show<ModalResolveData>(
              ({ resolve, close }) => (
                <DiscountProDialog
                  onClose={() => {
                    close();
                    onGoBack?.();
                  }}
                  onCancel={() =>
                    resolve({ action: ChurnSurveyAction.cancel, reasons })
                  }
                  onDiscount={() =>
                    resolve({ action: ChurnSurveyAction.discount, reasons })
                  }
                />
              ),
              {
                onOverlayDismiss: onGoBack,
                mobileAdaptive: true,
                mobileProps: mobileModalProps,
              },
            );

          if (
            (selectedLeaveReason.temporary_cancellation ||
              selectedLeaveReason.wasnt_using_it_enough) &&
            !cancelingPaywallCard
          )
            return Modal.show<ModalResolveData>(
              ({ resolve, close }) => (
                <PauseProDialog
                  cancelProButton
                  onPaused={() =>
                    resolve({ action: ChurnSurveyAction.pause, reasons })
                  }
                  onCancel={() =>
                    resolve({ action: ChurnSurveyAction.cancel, reasons })
                  }
                  onClose={() => {
                    close();
                    onGoBack?.();
                  }}
                />
              ),
              {
                onOverlayDismiss: onGoBack,
                mobileAdaptive: true,
                mobileProps: mobileModalProps,
              },
            );

          return Promise.resolve({
            action: ChurnSurveyAction.cancel,
            reasons,
          });
        })

        .then((payload: unknown) => {
          if (isMobileResolveData(payload) && botId) {
            const { action, reasons } = payload;
            switch (action) {
              case ChurnSurveyAction.discount:
                activateDiscount().then(onClose);
                startChurnSurvey(action, reasons);
                break;
              case ChurnSurveyAction.pause:
                pausePro().then(onClose);
                startChurnSurvey(action, reasons);
                break;
              case ChurnSurveyAction.cancel:
                Promise.all([
                  cancelPro().then((data) => {
                    if (data) {
                      sendEvent({ event: 'monetization_pro-disabled' });
                      onCancelPro?.();
                      return data;
                    }
                    onGoBack?.();
                    return Promise.reject();
                  }),
                  startChurnSurvey(action, reasons).catch(onClose),
                ]).then((values) => {
                  const startChurnSurveyId =
                    values[1]?.data?.startChurnSurvey.id;
                  if (startChurnSurveyId)
                    Modal.show(
                      ({ close }) => (
                        <RateExperienceDialog
                          reasons={reasons}
                          onClose={(feedback, other) => {
                            close();
                            finishChurnSurvey(
                              startChurnSurveyId,
                              other,
                              feedback,
                            );
                          }}
                        />
                      ),
                      { mobileAdaptive: true },
                    );
                });
                break;
              default:
            }
          }
        });
    },
    [
      botId,
      discountStatus,
      cancelPro,
      activateDiscount,
      startChurnSurvey,
      finishChurnSurvey,
      pausePro,
      cancelingPaywallCard,
    ],
  );

  return {
    showCancelProSubscriptionDialog,
    loading:
      cancelProLoading ||
      activateDiscountLoading ||
      startChurnSurveyLoading ||
      pauseProLoading,
  };
};
