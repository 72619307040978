import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Icon } from '@ui/Icon';

import { sendEvent } from '@utils/Analytics';
import { useFacebookPageCreate } from '@utils/FacebookPages/useFacebookPageCreate';

import { useLocation } from 'react-router-dom';
import { useRefetchAdminData } from '@utils/Admin/useRefetchAdminData';
import * as css from './FacebookPagesFooter.css';
import { useFacebookLogin } from '@pages/MultiSystemAuth/hooks/useFacebookLogin';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface FacebookPagesFooterProps extends React.HTMLProps<HTMLDivElement> {
  showCreatePageButton: boolean;
  hideRefreshPageButton?: boolean;
  hasFacebookPages?: boolean;
  onReturnAfterCreatePage?: () => void;
  facebookPagesRefetch: () => void;
}

export const FacebookPagesFooter: React.FC<FacebookPagesFooterProps> = ({
  className,
  showCreatePageButton,
  hideRefreshPageButton,
  onReturnAfterCreatePage,
  facebookPagesRefetch,
  hasFacebookPages,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const { facebookPageCreate } = useFacebookPageCreate(onReturnAfterCreatePage);
  const location = useLocation();
  const refetchAdminData = useRefetchAdminData();
  const [needRefetch, setNeedRefetch] = useState<boolean>(false);

  useEffect(() => {
    if (needRefetch) {
      setNeedRefetch(false);
      refetchAdminData();
      facebookPagesRefetch();
    }
  }, [facebookPagesRefetch, needRefetch, refetchAdminData]);

  const { facebookLogin, ready: fbLoginReady } = useFacebookLogin({
    interruptedHref: window.location.href,
    location,
    reRequestPermissions: true,
  });

  const needAccentOnCreatePage = hasFacebookPages === false;

  return (
    <Flex
      justifyContent="space-between"
      alignItems="center"
      flexDirection={isSmallScreenSize ? 'column' : 'row'}
      className={cn(isSmallScreenSize ? css.box_mobile : css.box, className)}
      data-testid="facebook-pages__footer"
    >
      {showCreatePageButton ? (
        <Button
          className={cn(isSmallScreenSize && css.button_mobile)}
          intent={needAccentOnCreatePage ? 'primary' : 'secondary'}
          onClick={() => {
            sendEvent({
              category: 'connect facebook page dialog',
              action: 'create fb page',
            });
            facebookPageCreate();
          }}
          data-testid="facebook-pages__create-page-button"
          icon={
            <Icon
              icon="plus"
              size="16px"
              color={needAccentOnCreatePage ? 'white' : 'black'}
            />
          }
        >
          {t('modernComponents.FacebookPagesSetup.footer.create')}
        </Button>
      ) : (
        <div />
      )}

      {hideRefreshPageButton ? (
        <div />
      ) : (
        <Button
          className={cn(isSmallScreenSize && css.button_mobile)}
          intent="secondary"
          onClick={async () => {
            sendEvent({
              category: 'connect facebook page dialog',
              action: 'update pages',
            });
            await facebookLogin();
            setNeedRefetch(true);
          }}
          data-testid="facebook-pages__update-list-button"
          icon={<Icon icon="refresh" size="16px" />}
          disabled={!fbLoginReady}
        >
          {t('modernComponents.FacebookPagesSetup.footer.refresh')}
        </Button>
      )}
    </Flex>
  );
};
