import React from 'react';
import { Trans } from 'react-i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as AlertIcon } from '../assets/Alert.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';
import { getTierNameByType } from '../../constants';
import { TierType } from '@globals';

interface UpgradeConfirmationDialogProps {
  tier: TierType;
  onUpgrade(): void;
  onDismiss(): void;
}

export const UpgradeConfirmationDialog: React.FC<UpgradeConfirmationDialogProps> =
  ({ tier, onUpgrade, onDismiss }) => {
    const { t } = useSafeTranslation();
    return (
      <DefaultPricingDialog
        title={t('pages.Pricing.dialogs.UpgradeConfirmationDialog.title', {
          tier: getTierNameByType(tier),
        })}
        icon={<AlertIcon />}
        description={
          <Trans
            t={t}
            i18nKey="pages.Pricing.dialogs.UpgradeConfirmationDialog.description"
          />
        }
        button={
          <DefaultPricingBtn onClick={onUpgrade}>
            {t('pages.Pricing.dialogs.UpgradeConfirmationDialog.button')}
          </DefaultPricingBtn>
        }
        onClose={onDismiss}
      />
    );
  };
