import React, { useEffect } from 'react';
import cn from 'classnames';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { sendEvent } from 'cf-common/src/analytics';
import { Flex } from '../../modern-ui/Flex';
import { Icon } from '../../modern-ui/Icon';
import { IconButton } from '../../modern-ui/IconButton';
import { Spacer } from '../../modern-ui/Spacer';
import { useLocalStorage, LSKey } from '../../utils/LocalStorage';
import { Type } from '../../modern-ui/Type';
import * as css from './ChromeAlert.css';

export const ChromeAlert = ({ mode }: { mode: 'bottom' | 'block' }) => {
  const { t } = useSafeTranslation();
  const [isHidden, setIsHidden] = useLocalStorage(LSKey.hideChromeAlert);

  useEffect(() => {
    sendEvent({
      category: 'chrome-alert',
      action: 'shown',
    });
  }, []);

  if (isHidden) {
    return null;
  }

  return (
    <Flex
      data-testid="chrome-alert"
      className={cn(css.alert, css[mode])}
      width="100%"
      alignItems="center"
      justifyContent="center"
    >
      <Flex alignItems="center" justifyContent="center">
        <Icon icon="info" color="blue" size="16px" />
        <Spacer factor={0} horizontalFactor={2} />
        <Type size="15px">{t('modernComponents.ChromeAlert.message')}</Type>
        {mode === 'bottom' && (
          <IconButton
            data-testid="chrome-alert-close"
            className={css.close}
            icon="close"
            size="24px"
            color="black"
            onClick={() => {
              setIsHidden(1);
              sendEvent({
                category: 'chrome-alert',
                action: 'close',
              });
            }}
          />
        )}
      </Flex>
    </Flex>
  );
};
