import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { ActivatePageDiscountStatus } from '@globals';
import {
  ActivatePageDiscountMutation,
  ActivatePageDiscountMutationVariables,
} from './@types/ActivatePageDiscountMutation';

const ACTIVATE_PAGE_DISCOUNT_MUTATION = gql`
  mutation ActivatePageDiscountMutation($pageId: String!, $token: String!) {
    activatePageDiscount(pageId: $pageId, token: $token)
  }
`;
interface UseActivatePageDiscountParams {
  pageId?: string | null;
  token?: string | null;
  onCompleted(status: ActivatePageDiscountStatus | null | undefined): void;
  onFailed(error: any, retry: () => void): void;
}
export const useActivatePageDiscount = ({
  pageId,
  token,
  onCompleted,
  onFailed,
}: UseActivatePageDiscountParams) => {
  const [activatePageDiscountMutation, { called }] = useMutation<
    ActivatePageDiscountMutation,
    ActivatePageDiscountMutationVariables
  >(ACTIVATE_PAGE_DISCOUNT_MUTATION);
  const activatePageDiscount = () => {
    if (pageId && token) {
      activatePageDiscountMutation({
        variables: { pageId, token },
      })
        .then(({ data }) => {
          onCompleted(data?.activatePageDiscount);
        })
        .catch((error) => {
          onFailed(error, activatePageDiscount);
        });
    }
  };
  return {
    activatePageDiscount,
    activatePageDiscountCalled: called,
  };
};
