import { Period } from '@globals';

export const getMonthsCount = (period: Period | null) => {
  switch (period) {
    case Period.annual:
      return 12;
    case Period.semiannual:
      return 6;
    case Period.monthly:
    default:
      return 1;
  }
};
