import { log } from 'cf-common/src/logger';
import i18next from 'i18next';
import { clone } from 'ramda';
import { MutationUpdaterFn } from 'apollo-client';
import { toaster } from '@services/MessageService';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { DeleteBotMutation } from './@types/DeleteBotMutation';
import { BotListQueryInDeletion as Bots } from './@types/BotListQueryInDeletion';
import { DataProxy } from 'apollo-cache';
import Maybe from 'graphql/tsutils/Maybe';
import { findBotsInWorkspacesBots } from '@utils/Data/Workspaces/addToWorkspacesBotsCache';
import { WORKSPACES_BOTS_QUERY } from '@utils/Data/Workspaces/queries';
import { WorkspacesBots } from '@utils/Data/Workspaces/@types/WorkspacesBots';
import { BOTS_LIST_QUERY_IN_DELETION } from './queries';
import { LIGHT_BOTS_QUERY } from '@utils/Data/Bot/queries';
import { LightBotsQuery } from '@utils/Data/Bot/@types/LightBotsQuery';

function showDeleteError(error: Error) {
  log.warn({ error, msg: 'Delete bot' });
  toaster.show({
    type: ServiceMessageType.error,
    payload: {
      message: i18next.t(
        'DeleteBotDialog-string--165-couldnt-delete-bot-please-try-again-later',
      ),
    },
  });
}

const updateBotListQuery = (store: DataProxy, botId: string) => {
  const data = store.readQuery<Bots>({
    query: BOTS_LIST_QUERY_IN_DELETION,
  });

  if (!data || !Array.isArray(data.bots)) {
    return;
  }

  const mutatedBots = data.bots.filter((bot) => bot && bot.id !== botId);
  data.bots = mutatedBots;
  store.writeQuery({
    data,
    query: BOTS_LIST_QUERY_IN_DELETION,
  });
};

const updateLlightBotListQuery = (store: DataProxy, botId: string) => {
  let data: Maybe<LightBotsQuery>;
  try {
    data = clone(
      store.readQuery<LightBotsQuery>({
        query: LIGHT_BOTS_QUERY,
      }),
    );
  } catch {
    data = null;
  }
  if (!data || !Array.isArray(data.lightBots)) {
    return;
  }
  data.lightBots = data.lightBots.filter((bot) => bot && bot.id !== botId);
  store.writeQuery<LightBotsQuery>({
    data,
    query: LIGHT_BOTS_QUERY,
  });
};

const updateWorkspaceBotsQuery = (
  store: DataProxy,
  botId: string,
  workspaceId: Maybe<string>,
) => {
  try {
    const workspacesBotsData = store.readQuery<WorkspacesBots>({
      query: WORKSPACES_BOTS_QUERY,
    });
    if (!workspacesBotsData) return;

    const newWorkspacesBotsData: WorkspacesBots = JSON.parse(
      JSON.stringify(workspacesBotsData),
    );
    const { workspacesBots } = newWorkspacesBotsData;
    const bots = findBotsInWorkspacesBots(workspacesBots, workspaceId);
    bots?.splice(
      bots.findIndex((bot) => bot.id === botId),
      1,
    );
    store.writeQuery<WorkspacesBots>({
      query: WORKSPACES_BOTS_QUERY,
      data: newWorkspacesBotsData,
    });
  } catch (error) {
    log.error({
      error,
      msg: 'Cannot update workspaces bots data cash',
    });
  }
};

const getUpdateStore =
  (workspaceId: Maybe<string>): MutationUpdaterFn<DeleteBotMutation> =>
  (store, mutationResult) => {
    const response = mutationResult && mutationResult.data;
    if (!response?.deleteBot.botId) {
      return;
    }

    const { botId } = response.deleteBot;

    updateBotListQuery(store, botId);
    updateLlightBotListQuery(store, botId);
    updateWorkspaceBotsQuery(store, botId, workspaceId);
  };

export const deleteBotDialogUtils = {
  getUpdateStore,
  showDeleteError,
};
