import gql from 'graphql-tag';
import memoize from 'lodash-es/memoize';
import { path } from 'ramda';
import { Observable, ObservableInput } from 'rxjs/Observable';
import client from '../../common/services/ApolloService';
import { BLOCKS_GROUP_FRAGMENT } from './Mutations/GQL';
import { BlocksGroupsQueries } from './@types/BlocksGroupsQueries';
import { OneTitleBlockFragment } from './@types/OneTitleBlockFragment';
import { IsValidBlockFragment } from './@types/IsValidBlockFragment';
import { IsValidBlockCardsFragment } from './@types/IsValidBlockCardsFragment';

const BLOCKS_GROUPS_QUERY = gql`
  query BlocksGroupsQueries($botId: String!) {
    bot(id: $botId) {
      id
      blocksGroups {
        ...blocksGroupFragment
      }
    }
  }
  ${BLOCKS_GROUP_FRAGMENT}
`;

export const BLOCKS_TITLES_ARCHIVE_QUERY = gql`
  query BlocksTitlesArchiveQuery($botId: String!) {
    bot(id: $botId) {
      id
      archiveBlocks {
        id
        title
        removed
        reachable
      }
    }
  }
`;

const ONE_TITLE_BLOCK_FRAGMENT = gql`
  fragment OneTitleBlockFragment on Block {
    id
    title
  }
`;

const IS_VALID_BLOCK_FRAGMENT = gql`
  fragment IsValidBlockFragment on Block {
    id
    is_valid
  }
`;

const IS_VALID_BLOCK_CARDS_FRAGMENT = gql`
  fragment IsValidBlockCardsFragment on Block {
    id
    is_valid
    cards {
      id
      is_valid
    }
  }
`;

export const getBlocksGroupsObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery({
      query: BLOCKS_GROUPS_QUERY,
      variables: {
        botId,
      },
    }) as ObservableInput<BlocksGroupsQueries>,
  ).map((data) => path(['data', 'bot', 'blocksGroups'], data)),
);

export const getBlocksTitlesArchiveObservable = memoize((botId: string) =>
  Observable.from(
    client.watchQuery({
      query: BLOCKS_TITLES_ARCHIVE_QUERY,
      variables: {
        botId,
      },
    }) as ObservableInput<BlocksGroupsQueries>,
  ).map((data) => path(['data', 'bot', 'archiveBlocks'], data)),
);

export const updateBlockTitleInGqlCache = (params: {
  blockId: string;
  title: string;
}) => {
  const { blockId, title } = params;
  let blockFragment: OneTitleBlockFragment | null = null;
  try {
    blockFragment = client.readFragment({
      id: blockId,
      fragment: ONE_TITLE_BLOCK_FRAGMENT,
    });
  } catch (e) {
    blockFragment = null;
  }
  if (blockFragment) {
    client.writeFragment({
      id: blockId,
      fragment: ONE_TITLE_BLOCK_FRAGMENT,
      data: {
        ...blockFragment,
        title,
      },
    });
  }
};

export const updateBlockValidStatusInGqlCache = (params: {
  block: IsValidBlockCardsFragment;
}) => {
  const { block } = params;
  const isValid =
    block.cards && block.cards.every((card) => card.is_valid !== false);
  let blockFragment: IsValidBlockFragment | null = null;
  try {
    blockFragment = client.readFragment({
      id: block.id,
      fragment: IS_VALID_BLOCK_FRAGMENT,
    });
  } catch (e) {
    blockFragment = null;
  }
  if (blockFragment) {
    client.writeFragment({
      id: block.id,
      fragment: IS_VALID_BLOCK_FRAGMENT,
      data: {
        ...blockFragment,
        is_valid: isValid,
      },
    });
  }
};

export const updateBlockValidStatusInGqlCacheById = (id: string) => {
  let block: IsValidBlockCardsFragment | null = null;
  try {
    block = client.readFragment({
      id,
      fragment: IS_VALID_BLOCK_CARDS_FRAGMENT,
    });
  } catch (e) {
    block = null;
  }
  if (block) {
    updateBlockValidStatusInGqlCache({ block });
  }
};
