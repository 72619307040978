import React, { useCallback, useEffect, useRef } from 'react';
import cn from 'classnames';
import { useLazyQuery, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useHistory } from 'react-router-dom';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import {
  ConnectPageField,
  ConnectPageOrigin,
  useConnectPage,
} from '@components/ConnectPageDialog';
import { Badge, Kind } from '@ui/Badge';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { sendEvent } from '@utils/Analytics';
import { ButtonUnstyled } from '@ui/Button';
import { Type } from '@ui/Type';
import { isBotPro } from '@utils/Pro';
import { useAdminId } from '@utils/Admin/useAdminId';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as BotDefaultLogo } from './images/bot-default-logo.svg';
import { ReactComponent as SortingOrderIcon } from './images/sorting-order.svg';
import { BotOptionsMenu } from './components/BotOptionsMenu';
import { BotListSortingSelector } from './components/BotListSortingSelector';
import {
  BotListSortingProps,
  sortBots,
  SortingConfigShape,
  SortingOptions,
  SortingOrder,
} from './sortConfig';
import { BotNotificationTag } from './components/BotNotificationTag';
import { BotTitleInput } from './components/BotTitleInput';
import { ActionButton } from './components/BotActionButton';
import { ReactComponent as InfoIcon } from '../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { BotTabs, getTabLink, useGoToTab } from '@utils/Routing';
import { Button, ButtonIntent } from '../../modern-ui/_deprecated/Button';
import {
  BOT_QUERY,
  BOTS_LIST_QUERY,
} from '@common/services/GQLqueries/BotGQService.const';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import {
  BotsListQuery,
  BotsListQuery_bots,
  BotsListQuery_bots_status_page_info,
} from '@common/services/GQLqueries/@types/BotsListQuery';
import { BotQuery } from '@common/services/GQLqueries/@types/BotQuery';
import { BotStatusName, Platform } from '@globals';
import { BotStatusPollingQuery } from './@types/BotStatusPollingQuery';
import { ReactComponent as ExclamationMarkIcon } from '../../assets/zero-state/exclamation-mark.svg';
import * as css from './BotList.css';
import { useBotOptionsHandler } from './components/BotOptionsHandler/BotOptionsHandler';
import { isBotCopying } from '@utils/Bot/isBotCopying';
import { DeepLinksMode } from '@pages/DeepLinks/types';
import { CreateBlankBotButton } from './components/ActionButtons/CreateBlankBotButton';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import { TextEllipsis } from '@ui/TextEllipsis';
import Maybe from 'graphql/tsutils/Maybe';
import { ColorKey } from '@ui/_common/colors';

const BOT_STATUS_POLLING_QUERY = gql`
  query BotStatusPollingQuery($botId: String!) {
    botStatus(id: $botId) {
      status
    }
  }
`;

const LIST_ITEM_HEIGHT_WITH_MARGIN = 92;
const OVERSCAN_ITEMS_COUNT = 20;

const usNumberFormatter = new Intl.NumberFormat('en-US');

const CurrentAndLastWeekMetrics: React.FC<{
  currentWeekValue: number;
  lastWeekValue: number;
}> = ({ currentWeekValue: current, lastWeekValue: last }) => {
  const metricExists = Number.isFinite(current);
  const diff = Number.isFinite(last) ? current - last : 0;

  return metricExists ? (
    <div className={css.numbersMetrics}>
      <Type size="15px_DEPRECATED">
        {current && usNumberFormatter.format(current)}
      </Type>
      {diff !== 0 ? (
        <span className={css.diffValueText}>
          <Type color={diff > 0 ? 'greenLight' : 'redLight'} size="12px">
            {diff > 0 && '+'}
            {usNumberFormatter.format(diff)}
          </Type>
        </span>
      ) : (
        <span />
      )}
    </div>
  ) : null;
};

const renderTotalUsers = (bot: BotsListQuery_bots) => {
  const currentWeekValue = bot?.statistic?.current_week_total_subscribed_users;
  if (!currentWeekValue) {
    return null;
  }
  const lastWeekValue = bot?.statistic?.last_week_total_subscribed_users ?? 0;

  return (
    <CurrentAndLastWeekMetrics
      currentWeekValue={currentWeekValue}
      lastWeekValue={lastWeekValue}
    />
  );
};

const renderWeeklyActive = (bot: BotsListQuery_bots) => {
  const currentWeekValue = bot?.statistic?.current_week_active_users;
  if (!currentWeekValue) {
    return null;
  }
  const lastWeekValue = bot?.statistic?.last_week_active_users ?? 0;

  return (
    <CurrentAndLastWeekMetrics
      currentWeekValue={currentWeekValue}
      lastWeekValue={lastWeekValue}
    />
  );
};

interface LeftOnPlanProps {
  bot: BotsListQuery_bots;
  isWhatsappBot?: boolean;
  onUpgradeToProClick(botId: string): void;
}

const LeftOnPlan: React.FC<LeftOnPlanProps> = ({
  bot,
  isWhatsappBot,
  onUpgradeToProClick,
}) => {
  const history = useHistory();
  const currentUsers = bot?.statistic?.current_week_total_subscribed_users ?? 0;
  const { connectPage } = useConnectPage({
    botId: bot.id,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.botList,
      [ConnectPageField.botId]: bot.id,
    },
  });

  const handleUpgradeToProClick = useCallback(() => {
    sendEvent({
      category: 'bot list',
      action: 'Pro',
      label: 'bot',
      propertyBag: {
        botId: bot.id,
        source: 'tooltip',
      },
    });
    onUpgradeToProClick(bot.id);
  }, [onUpgradeToProClick, bot]);

  const handleSettingsClick = useCallback(() => {
    sendEvent({
      category: 'bot list',
      action: 'update payment',
      label: 'bot',
      propertyBag: {
        botId: bot.id,
        source: 'tooltip',
      },
    });
    history.push(`/bot/${bot.id}/${BotTabs.configure}/`);
  }, [history, bot]);

  const handleConnectClick = useCallback(() => {
    sendEvent({
      category: 'bot',
      action: 'connect',
      propertyBag: {
        botId: bot.id,
      },
    });
    if (isWhatsappBot) {
      history.push(
        getTabLink(BotTabs.home, bot.id, {
          dlMode: DeepLinksMode.connectWhatsapp,
        }),
      );
    } else {
      connectPage();
    }
  }, [bot.id, connectPage, history, isWhatsappBot]);

  return (
    <BotNotificationTag
      bot={bot}
      currentUsers={currentUsers}
      isWhatsappBot={isWhatsappBot}
      onUpgradeClick={handleUpgradeToProClick}
      onSettingsClick={handleSettingsClick}
      onPageConnectClick={handleConnectClick}
    />
  );
};

const BotCopyingStatus: React.FC<{ bot: BotsListQuery_bots }> = ({ bot }) => {
  const { t } = useSafeTranslation();
  const { data, stopPolling } = useQuery<BotStatusPollingQuery>(
    BOT_STATUS_POLLING_QUERY,
    {
      variables: { botId: bot.id },
      pollInterval: 1000,
    },
  );

  const [fetchBot] = useLazyQuery<BotQuery>(BOT_QUERY, {
    fetchPolicy: 'cache-and-network',
  });

  const mounted = useRef<BotStatusName>();

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = bot.status?.status;
    } else if (
      mounted.current === 'copying' &&
      data?.botStatus.status !== 'copying'
    ) {
      stopPolling();
      fetchBot({
        variables: {
          botId: bot.id,
        },
      });
    }
  }, [bot.id, bot.status, data, fetchBot, stopPolling]);

  return (
    <div className={css.copyingStatus}>
      <InfoIcon />
      <Type color="greyDark" size="15px_DEPRECATED">
        {t('BotList-JSXText--140-bot-is-copying')}
        <br />
        {t('BotList-JSXText-1436-please-wait')}
      </Type>
    </div>
  );
};

interface BotListItemProps {
  data: {
    bots: BotsListQuery_bots[];
    userLimit: number;
    onUpgradeToProClick: (botId: string) => void;
  };
  index: number;
  style: React.CSSProperties;
}

enum ColumnAlign {
  start = 'start',
  center = 'center',
  end = 'end',
}

interface ColumnProps {
  align?: keyof typeof ColumnAlign;
  className?: string;
}

const Column: React.FC<ColumnProps> = ({
  align = ColumnAlign.start,
  children,
  className,
}) => (
  <div
    data-testid="bot-list__column"
    className={cn(css.column, className, {
      [css.startAlignedColumn]: align === ColumnAlign.start,
      [css.centerAlignedColumn]: align === ColumnAlign.center,
      [css.endAlignedColumn]: align === ColumnAlign.end,
    })}
  >
    {children}
  </div>
);

interface VersionedServerSortingConfig extends SortingConfigShape {
  version: number;
}

interface ColumnWithSortProps extends ColumnProps, BotListSortingProps {
  title: string;
  sortBy: SortingOptions;
}

const ColumnWithSort: React.FC<ColumnWithSortProps> = ({
  align,
  title,
  sortBy,
  changeSortingConfig,
  sortingConfig,
  className,
}) => {
  const [, , { loading }] = useServerStorage<VersionedServerSortingConfig>(
    ServerStorageItemKeys.botListSortAndPagination,
  );

  function handleChangeSortingOrderClick() {
    const order =
      sortingConfig.order === SortingOrder.asc
        ? SortingOrder.desc
        : SortingOrder.asc;

    changeSortingConfig({
      sortBy,
      order,
    });

    sendEvent({
      category: 'bot list',
      action: 'sort by',
      label: title.toLowerCase(),
      propertyBag: {
        ascending: order === SortingOrder.asc,
      },
    });
  }

  const isCurrentSortSelected = !loading && sortBy === sortingConfig.sortBy;
  return (
    <Column align={align} className={className}>
      <ButtonUnstyled
        title={title}
        data-testid="bot-list__sort-button"
        onClick={handleChangeSortingOrderClick}
        className={css.sortingChangeOrderButton}
      >
        <Type
          color="greyDark"
          size="15px_DEPRECATED"
          className={css.sortingOrderTitle}
        >
          {title}
        </Type>
        <SortingOrderIcon
          className={cn(css.sortingOrderIcon, {
            [css.sortingOrderIconSelected]: isCurrentSortSelected,
            [css.sortingOrderIconInvert]:
              isCurrentSortSelected && sortingConfig.order === SortingOrder.asc,
          })}
        />
      </ButtonUnstyled>
    </Column>
  );
};

interface BotListHeaderProps extends BotListSortingProps {}

const BotListHeader: React.FC<BotListHeaderProps> = ({
  children,
  ...sortingSelectorProps
}) => {
  const { t } = useSafeTranslation();

  return (
    <div data-testid="bot-list__header" className={css.botListHeader}>
      <Column />
      <ColumnWithSort
        {...sortingSelectorProps}
        align="center"
        title={t('BotList-string--526-total-users')}
        sortBy={SortingOptions.totalUsers}
        className={css.totalUsersColumn}
      />
      <ColumnWithSort
        {...sortingSelectorProps}
        align="center"
        title={t('BotList-string-1040-weekly-active-users')}
        sortBy={SortingOptions.weeklyActiveUsers}
        className={css.weeklyActiveUsersColumn}
      />
      <ColumnWithSort
        {...sortingSelectorProps}
        align="center"
        title={t('BotList-string--139-users-left-on-trial')}
        sortBy={SortingOptions.usersLeftOnFree}
      />
      <Column align="end">
        <BotListSortingSelector
          sortingConfig={sortingSelectorProps.sortingConfig}
          changeSortingConfig={sortingSelectorProps.changeSortingConfig}
        />
      </Column>
    </div>
  );
};

const BotListLoadingError: React.FC<{ refetch: () => void }> = (props) => {
  const { t } = useSafeTranslation();

  const { refetch } = props;

  const handleCLick = () => {
    sendEvent({
      category: 'bot list',
      action: 'reload',
    });
    refetch();
  };

  return (
    <div className={css.pageMessageWrapper}>
      <div className={css.pageMessageEmoji}>
        <ExclamationMarkIcon />
      </div>
      <div className={css.pageMessageText}>
        <Type weight="semibold" size="24px">
          {t('BotList-JSXText--252-couldnt-load-bots-list')}
        </Type>
      </div>
      <div className={css.pageMessageDetailsText}>
        <Type color="greyDark" size="15px_DEPRECATED">
          {t(
            'BotList-JSXText-1355-try-again-or-check-your-internet-connection',
          )}
        </Type>
      </div>
      <Button intent={ButtonIntent.primary} onClick={handleCLick}>
        {t('BotList-JSXText--151-reload')}
      </Button>
    </div>
  );
};

interface BotCreateSuggestionProps {
  onCreateBlankBot: (platforms?: Platform[]) => void;
}

const BotCreateSuggestion: React.FC<BotCreateSuggestionProps> = ({
  onCreateBlankBot,
}) => {
  const { t } = useSafeTranslation();

  const handleCreateBlackBotClick = (platforms?: Platform[]) => {
    sendEvent({
      category: 'bot list',
      action: 'add blank bot',
      propertyBag: {
        platforms,
      },
    });
    onCreateBlankBot(platforms);
  };

  return (
    <div className={css.pageMessageWrapper}>
      <div className={css.pageMessageIcon}>
        <ExclamationMarkIcon />
      </div>
      <div className={css.pageMessageText}>
        <Type weight="semibold" size="24px">
          {t('BotList-JSXText--287-lets-create-your-first-bot')}
        </Type>
      </div>
      <div className={css.pageMessageDetailsText}>
        <Type color="greyDark" size="15px_DEPRECATED">
          {t(
            'BotList-JSXText-1061-start-getting-subscribers-today-choose-a-template',
          )}
          <br />
          {t('BotList-JSXText-1754-or-start-from-scratch')}
        </Type>
      </div>
      <div>
        <CreateBlankBotButton
          onCreateBlankBot={handleCreateBlackBotClick}
          disabled={false}
          title={t('BotList-JSXText--111-add-blank-bot')}
          platformSelectorPlacement="bottom-start"
        />
      </div>
    </div>
  );
};

const BotListItemLoading: React.FC<{}> = () => (
  <div className={cn(css.listItem, css.listItemLoader)}>
    <Column>
      <div className={cn(css.itemImage, css.itemImageLoading)} />
      <div className={css.loadingTitle}>
        <div className={css.loadingLine} />
        <div className={css.loadingLine} />
      </div>
    </Column>
    <Column align="end" className={css.totalUsersColumn}>
      <div className={css.loadingLine} />
    </Column>
    <Column align="end">
      <div className={css.loadingLine} />
    </Column>
    <Column align="center">
      <div className={css.loadingLine} />
    </Column>
    <Column />
  </div>
);

export const BotListNothingFound: React.FC = () => {
  const { t } = useSafeTranslation();
  return (
    <Flex
      className={css.nothingFound}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <Type size="18px" weight="medium">
        {t('BotList.nothingFound.title')}
      </Type>
      <Spacer factor={4} />
      <Type size="15px_DEPRECATED">
        {t('BotList.nothingFound.description')}
      </Type>
    </Flex>
  );
};

export interface BotListIconProps {
  picture: Maybe<string>;
  title: string;
}

export const BotListIcon: React.FC<BotListIconProps> = ({ picture, title }) => {
  const imageContainerStyle = picture
    ? { backgroundImage: `url(${picture})` }
    : undefined;

  return (
    <div
      className={css.itemImage}
      style={imageContainerStyle}
      title={title}
      data-testid="bot-list__bot-logo"
    >
      {!picture && <BotDefaultLogo />}
    </div>
  );
};

export interface BotListSubtitleBot {
  pageInfo: Maybe<BotsListQuery_bots_status_page_info>;
  statusPage: Maybe<string>;
}

export interface BotListSubtitleProps extends BotListSubtitleBot {
  color: ColorKey;
}

export const BotListSubtitle: React.FC<BotListSubtitleProps> = ({
  pageInfo,
  statusPage,
  color,
  children,
}) => {
  const { t } = useSafeTranslation();

  const fbPageHref = pageInfo?.id && `https://www.facebook.com/${pageInfo.id}`;

  if (pageInfo?.whatsapp_business_account?.id) {
    const name = pageInfo?.whatsapp_business_account?.name || '';
    const connectedToText = `${t('BotList-connected-to')} ${name}`;

    return (
      <>
        <TextEllipsis title={connectedToText}>
          <Type color={color}>{connectedToText}</Type>
        </TextEllipsis>
        <Type color={color} as="div" noWrap>
          {pageInfo?.whatsapp_phone?.display_phone_number}
        </Type>
      </>
    );
  }

  if (statusPage) {
    return (
      <>
        {t('BotList-JSXText-5237-connected-to')}{' '}
        <a
          className={css.secondaryTextLink}
          target="_blank"
          rel="noopener noreferrer"
          href={fbPageHref}
        >
          {pageInfo?.title}
        </a>
      </>
    );
  }

  return <>{children}</>;
};

const BotListItem: React.FC<BotListItemProps> = (props) => {
  const {
    data: { bots, onUpgradeToProClick },
    index,
    style,
  } = props;
  const bot = bots[index];
  const rankIndex = index + 1;
  const isLast = index === bots.length - 1;
  const isWhatsappBot =
    !!bot.allowedPlatforms &&
    testAllowedPlatforms([Platform.whatsapp], bot.allowedPlatforms);

  const { notificationsLimit } = bot.limits;

  const { t } = useSafeTranslation();
  const { id } = useAdminId();
  const { connectPage } = useConnectPage({
    botId: bot.id,
    urlParams: {
      [ConnectPageField.origin]: ConnectPageOrigin.botList,
      [ConnectPageField.botId]: bot.id,
    },
  });
  const { handleOptionSelect, isRenameMode, exitRenameMode } =
    useBotOptionsHandler({ userId: id! });

  const botCopying = isBotCopying(bot);
  const isBotReadyForInteraction = !botCopying;

  const titleBlockRef = React.useRef(null);
  const goToTab = useGoToTab(bot.id);

  const botPicture = bot.status?.page_info?.picture;

  const handleListItemClick = useCallback(async () => {
    if (!isBotReadyForInteraction) {
      return;
    }

    // prevent open page while text selecting
    const textSelection = window.getSelection();
    if (textSelection && textSelection.type === 'Range') {
      return;
    }

    sendEvent({
      category: 'bot list',
      action: 'bot click',
      label: 'bot',
    });

    goToTab(BotTabs.home);
  }, [isBotReadyForInteraction, goToTab]);

  const handleConnectClick = useCallback(() => {
    sendEvent({
      category: 'bot',
      action: 'connect',
      propertyBag: {
        botId: bot.id,
      },
    });
    connectPage();
  }, [bot.id, connectPage]);

  const renameMode = isRenameMode(bot);

  return (
    <div data-testid="bot-list__list-item" style={style}>
      <div // eslint-disable-line jsx-a11y/click-events-have-key-events
        className={cn(css.listItem, {
          [css.listItemDisabled]: !isBotReadyForInteraction,
        })}
        onClick={handleListItemClick}
      >
        <Column>
          <BotListIcon picture={botPicture} title={bot.title} />
          {!renameMode && (
            <div ref={titleBlockRef} className={css.titleBlockWrapper}>
              <div className={css.titleWrapper}>
                {isBotReadyForInteraction ? (
                  <div className={css.title}>
                    <TextEllipsis width={220} data-testid="bot-list__bot-title">
                      {bot.title}
                    </TextEllipsis>
                  </div>
                ) : (
                  <TextEllipsis width={220} data-testid="bot-list__bot-title">
                    <span className={css.title}>{bot.title}</span>
                  </TextEllipsis>
                )}
                {isBotPro(bot) && (
                  <div className={css.proBadgeWrapper}>
                    <Badge kind={Kind.alert} data-testid="bot-list__pro-badge">
                      {t('BotList-JSXText-8052-pro')}
                    </Badge>
                  </div>
                )}
              </div>
              <div className={css.subtitle}>
                <Type
                  color="greyDark"
                  size="15px_DEPRECATED"
                  data-testid="bot-list__bot-subtitle"
                >
                  <BotListSubtitle
                    pageInfo={bot.status?.page_info}
                    statusPage={bot.status?.page}
                    color="greyDark"
                  >
                    {isWhatsappBot
                      ? t('Whatsapp.connectThisBotToNumber')
                      : t(
                          'BotList-string-1985-connect-this-bot-to-a-facebook-page',
                        )}
                  </BotListSubtitle>
                </Type>
              </div>
            </div>
          )}
          {renameMode && (
            <BotTitleInput
              rankIndex={rankIndex}
              bot={bot}
              onClose={exitRenameMode}
            />
          )}
        </Column>
        <Column align="center">
          {isBotReadyForInteraction && renderTotalUsers(bot)}
        </Column>
        <Column align="center">
          {isBotReadyForInteraction && renderWeeklyActive(bot)}
        </Column>
        <Column align="center">
          {isBotReadyForInteraction && (
            <LeftOnPlan
              bot={bot}
              isWhatsappBot={isWhatsappBot}
              onUpgradeToProClick={onUpgradeToProClick}
            />
          )}
        </Column>
        <Column align="end">
          {isBotReadyForInteraction && (
            <div className={css.actionButtonColumn}>
              <ActionButton
                bot={bot}
                isWhatsappBot={isWhatsappBot}
                notificationsLimit={notificationsLimit}
                onUpgradeToProClick={onUpgradeToProClick}
                onPageConnectClick={handleConnectClick}
              />
            </div>
          )}
          {botCopying && <BotCopyingStatus bot={bot} />}
          <Spacer horizontalFactor={2} />
          <BotOptionsMenu
            isLast={isLast}
            botId={bot.id}
            onActionSelect={(selectedItem) =>
              handleOptionSelect(bot, selectedItem.type)
            }
            disabled={botCopying}
          />
        </Column>
      </div>
    </div>
  );
};

const SERVER_SORTING_CONFIG_VERSION = 1;

export interface BotListProps extends BotCreateSuggestionProps {
  search: string;
  showLoaderTile: Boolean;
  onUpgradeToProClick: (botId: string) => void;
}

export const BotList: React.FC<BotListProps> = (props) => {
  const { search, showLoaderTile, onCreateBlankBot, onUpgradeToProClick } =
    props;

  const { data, error, refetch, loading } =
    useQuery<BotsListQuery>(BOTS_LIST_QUERY);

  const [
    serverSortingConfig,
    setSortingConfig,
    { loading: sortingConfigLoading },
  ] = useServerStorage<VersionedServerSortingConfig>(
    ServerStorageItemKeys.botListSortAndPagination,
  );

  const sortingConfig =
    serverSortingConfig?.version === SERVER_SORTING_CONFIG_VERSION
      ? serverSortingConfig
      : {
          order: SortingOrder.asc,
          sortBy: SortingOptions.dateCreated,
        };

  const changeSortingConfig = ({
    order,
    sortBy,
  }: {
    order: SortingOrder;
    sortBy: SortingOptions;
  }) => {
    if (order !== sortingConfig.order || sortBy !== sortingConfig.sortBy) {
      setSortingConfig({
        order,
        sortBy,
        version: SERVER_SORTING_CONFIG_VERSION,
      });
    }
  };

  if (error) {
    return <BotListLoadingError refetch={refetch} />;
  }
  const searchString = search.trim().toUpperCase();
  const bots = (data?.bots ?? []).filter(
    (bot) =>
      !searchString ||
      bot.title.toUpperCase().includes(searchString) ||
      bot.status?.page_info?.title.toUpperCase().includes(searchString),
  );

  if (data?.bots.length === 0 && !showLoaderTile) {
    return <BotCreateSuggestion onCreateBlankBot={onCreateBlankBot} />;
  }

  if (searchString && bots.length === 0) {
    return <BotListNothingFound />;
  }

  const sortedBots = sortBots(bots, sortingConfig);
  const botLoading = showLoaderTile || loading || sortingConfigLoading;
  const isVirtualList = sortedBots.length > OVERSCAN_ITEMS_COUNT;

  return (
    <div>
      <BotListHeader
        sortingConfig={sortingConfig}
        changeSortingConfig={changeSortingConfig}
      />
      {botLoading &&
        Array.from({ length: showLoaderTile ? 1 : 5 }).map((_, idx) => (
          // eslint-disable-next-line react/no-array-index-key
          <BotListItemLoading key={idx} />
        ))}
      {!botLoading && bots.length !== 0 && (
        <div className={cn({ [css.botListContainer]: isVirtualList })}>
          <AutoSizer disableWidth disableHeight={!isVirtualList}>
            {({ height }: { height: number }) => (
              <List
                data-testid="bot-list__list"
                height={
                  isVirtualList
                    ? height
                    : sortedBots.length * LIST_ITEM_HEIGHT_WITH_MARGIN
                }
                style={{ minHeight: '300px' }}
                overscanCount={OVERSCAN_ITEMS_COUNT}
                width="100%"
                layout="vertical"
                itemKey={(index) => sortedBots[index].id}
                itemCount={sortedBots.length}
                itemSize={LIST_ITEM_HEIGHT_WITH_MARGIN}
                itemData={{
                  bots: sortedBots,
                  onUpgradeToProClick,
                }}
              >
                {BotListItem}
              </List>
            )}
          </AutoSizer>
        </div>
      )}
    </div>
  );
};
