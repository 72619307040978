import React from 'react';
import cn from 'classnames';

import { Flex } from '../../../../modern-ui/Flex';
import { ReactComponent as ErrorInfoIcon } from '../../../../modern-ui/_deprecated/Icon/icons/error-info.svg';
import { Anchor } from '../../../../modern-ui/Links';

import * as css from '../../BroadcastingTags.css';

interface ErrorBlockProps {
  selected?: boolean;
  href?: string;
  text?: string;
}

export const ErrorBlock: React.FC<ErrorBlockProps> = ({
  selected,
  href,
  text,
  children,
}) => (
  <Flex alignItems="baseline" className={css.error}>
    <div className={css.errorIconCell}>
      <ErrorInfoIcon className={css.errorIcon} />
    </div>
    <div
      className={cn(css.itemText, {
        [css.itemTextSelected]: selected,
        [css.itemPlainText]: !href,
      })}
    >
      {text && href && (
        <Anchor
          hideArrow
          intent="external"
          className={cn(css.itemText, css.itemTextLink, {
            [css.itemTextSelected]: selected,
          })}
          href={href}
          target="_blank"
          rel="noopener noreferrer"
        >
          {text}
        </Anchor>
      )}
      {text && !href && text}
      {children}
    </div>
  </Flex>
);
