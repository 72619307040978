import React from 'react';
import cn from 'classnames';
import { ButtonUnstyled } from '../../../../../modern-ui/Button';
import { ReactComponent as ClicksIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_clicks.svg';
import { ReactComponent as ViewsIcon } from '../../../../../modern-ui/_deprecated/Icon/icons/ic_sequense_views.svg';
import { sendEvent } from '../../../../../utils/Analytics';
import * as css from './BlockStat.css';

const roundFormatter = new Intl.NumberFormat('en', {
  maximumSignificantDigits: 2,
});
const numberFormatter = new Intl.NumberFormat('en');

interface IBlockStatProps extends React.HTMLProps<HTMLDivElement> {
  views: number;
  clicks: number;
  defaultBlock: boolean;
  isActive?: boolean;
  onViewsClick?: () => void;
  onClickRateClick?: () => void;
}

const getCountString = (count: number): string => {
  if (count >= 1000000) {
    return `${roundFormatter.format(count / 1000000)}M`;
  }
  if (count >= 1000) {
    return `${roundFormatter.format(count / 1000)}K`;
  }
  return count.toString();
};

export const BlockStat: React.FC<IBlockStatProps> = React.memo(
  ({
    className,
    views,
    clicks,
    defaultBlock,
    isActive,
    onViewsClick,
    onClickRateClick,
    ...props
  }) => {
    const clickedRate = Math.min(
      100,
      Math.floor((100 * (clicks || 0)) / Math.max(1, views)),
    );

    return (
      <div
        className={cn(
          {
            [css.blockStat]: true,
            [css.defaultBlockStat]: defaultBlock,
            [css.isActive]: isActive,
          },
          className,
        )}
        {...props}
      >
        <div className={css.box}>
          <ButtonUnstyled
            className={cn(css.itemMargin, css.link, {
              [css.linkActive]: views > 0,
            })}
            onClick={
              views > 0
                ? () => {
                    sendEvent({
                      category: 'block',
                      action: 'click views',
                      label: 'stats',
                    });
                    if (onViewsClick) {
                      onViewsClick();
                    }
                  }
                : undefined
            }
          >
            <ViewsIcon className={css.icon} />
            <span>{getCountString(views)}</span>
          </ButtonUnstyled>
          <ButtonUnstyled
            className={cn(css.link, { [css.linkActive]: clicks > 0 })}
            title={numberFormatter.format(clicks) as string}
            onClick={
              clicks > 0
                ? () => {
                    sendEvent({
                      category: 'block',
                      action: 'click clicks',
                      label: 'stats',
                    });
                    if (onClickRateClick) {
                      onClickRateClick();
                    }
                  }
                : undefined
            }
          >
            <ClicksIcon className={css.icon} />
            <span>{roundFormatter.format(clickedRate)}%</span>
          </ButtonUnstyled>
        </div>
      </div>
    );
  },
);
