import React from 'react';
import Downshift from 'downshift';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import {
  SortingOptions,
  BotListSortingProps,
  SortingOrder,
} from '../sortConfig';
import { Menubox, MenuItem } from '../../../modern-ui/Menu';
import { ButtonUnstyled } from '../../../modern-ui/Button';
import { Type } from '../../../modern-ui/Type';
import { sendEvent } from '../../../utils/Analytics';
import * as css from '../BotList.css';

type SortByTreeDotsSelector =
  | SortingOptions.nameAsc
  | SortingOptions.nameDesc
  | SortingOptions.type
  | SortingOptions.lastOpened
  | SortingOptions.dateCreated;

const sortingNames = () => ({
  [SortingOptions.nameAsc]: window.i18next.t(
    'BotListSortingSelector-string--109-name-a-z',
  ),
  [SortingOptions.nameDesc]: window.i18next.t(
    'BotListSortingSelector-string--109-name-z-a',
  ),
  [SortingOptions.type]: window.i18next.t(
    'BotListSortingSelector-string--158-type-folders-first',
  ),
  [SortingOptions.lastOpened]: window.i18next.t(
    'BotListSortingSelector-string-1151-last-opened-newest-first',
  ),
  [SortingOptions.dateCreated]: window.i18next.t(
    'BotListSortingSelector-string-4392-date-created-newest-first',
  ),
});

const sortingOptions: SortByTreeDotsSelector[] = [
  SortingOptions.nameAsc,
  SortingOptions.nameDesc,
  SortingOptions.lastOpened,
  // SortingOptions.type,    // TODO: add after folders support
  SortingOptions.dateCreated,
];

export const BotListSortingSelector: React.FC<BotListSortingProps> = (
  props,
) => {
  const { t } = useSafeTranslation();
  const { sortingConfig, changeSortingConfig } = props;

  return (
    <Downshift
      selectedItem={null}
      onChange={(sortBy) => {
        if (sortingConfig.sortBy === sortBy) {
          return;
        }

        changeSortingConfig({
          sortBy,
          order: SortingOrder.asc,
        });

        sendEvent({
          category: 'bot list',
          action: 'sort by',
          label:
            sortingNames()[sortBy as SortByTreeDotsSelector]?.toLowerCase(),
        });
      }}
      itemToString={(item) => item}
    >
      {(downshift) => {
        const {
          getItemProps,
          getToggleButtonProps,
          getMenuProps,
          isOpen,
          highlightedIndex,
        } = downshift;

        let label = (sortingNames() as any)[sortingConfig.sortBy];
        if (!label) {
          label = t('modernComponents.BotList.SortingSelector.placeholder');
        } else if (
          [
            sortingNames()[SortingOptions.lastOpened],
            sortingNames()[SortingOptions.dateCreated],
          ].includes(label)
        ) {
          [label] = label.split(':');
        }

        return (
          <div className={css.sortingButtons}>
            <ButtonUnstyled
              className={css.sortingOpenMenuButton}
              {...getToggleButtonProps({
                onClick(event) {
                  event.stopPropagation();
                },
              })}
            >
              <span className={css.sortByButton}>
                <Type color="greyDark" size="15px_DEPRECATED">
                  {window.i18next.t(
                    'BotListSortingSelector-JSXText--849-sort-by',
                  )}
                </Type>{' '}
                <Type
                  color={
                    sortingOptions.includes(sortingConfig.sortBy as any)
                      ? 'blue'
                      : 'greyDark'
                  }
                  size="15px_DEPRECATED"
                >
                  {label}
                </Type>
              </span>
            </ButtonUnstyled>
            <div {...getMenuProps()}>
              {isOpen && (
                <Menubox className={css.sortingMenuBox}>
                  {sortingOptions.map((item, idx) => (
                    <MenuItem
                      {...getItemProps({
                        item,
                        onClick: (event: React.SyntheticEvent) =>
                          event.stopPropagation(),
                      })}
                      key={item}
                      active={
                        highlightedIndex === null
                          ? item === sortingConfig.sortBy
                          : highlightedIndex === idx
                      }
                      title={sortingNames()[item]}
                      className={css.settingsMenuItem}
                    />
                  ))}
                </Menubox>
              )}
            </div>
          </div>
        );
      }}
    </Downshift>
  );
};
