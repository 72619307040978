import React from 'react';
import cx from 'classnames';
import Downshift from 'downshift';
import { Hover } from 'react-powerplug';
import { path, pathOr } from 'ramda';
import { Link } from 'react-router-dom';
import { ReactComponent as DotsIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_dots.svg';
import { Divider, Menubox, MenuItem } from '../../../../modern-ui/Menu/index';
import { ScrollBox } from '../../../../modern-ui/ScrollBox/index';
import { Modal } from '@ui/Modal';
import { RemoveEntityDialog } from '../../../RemoveEntityDialog';
import { BlockActionsDialog } from '../../../BlockActionsDialog';
import { toaster as userActionsToaster } from '../../../../services/MessageService/index';
import { RoleConsumer, RoleProvider } from '@utils/Roles';
import { Permission } from '@common/services/RoleService';
import { Tooltip } from '@ui/Tooltip';
import { AsideGroupsQuery_bot_blocksGroups_blocks as IBlock } from '../../@types/AsideGroupsQuery';
import * as css from './BlockItem.css';
import { BlockStat } from './BlockStat';
import { sendEvent } from '@utils/Analytics';
import { getBlockStatsForEvents } from '@utils/BlocksUtils';
import { SequenceBlockStat } from './SequenceBlockStat';
import { BotTabs, getTabLink } from '../../../../utils/Routing';
import i18next from 'i18next';
import { TextEllipsis } from '@ui/TextEllipsis';

function ellipsizeString(trimLength: number, str: string) {
  if (str.length < trimLength) {
    return str;
  }
  return `${str.slice(0, trimLength)}…`;
}

interface Group {
  id: string;
  title: string;
  builtin: boolean | null;
  synced_from: {} | null;
}

const COMMENT_PADDING = 13;
const testTrimmedComment = (el: Element) =>
  el.parentElement!.clientWidth - el.clientWidth - COMMENT_PADDING <= 0;

interface IBlockItemProps {
  botId: string;
  groupId: string;
  groups: Group[];
  block: IBlock;
  statisticFeatureEnabled: boolean;
  onBlockClick(): void;
  // TODO:
  // we're exposing this props only to enable rerender when block.title changes
  // get rid of it when this component is not wrapped with react2angular
  blockTitle?: string;
  blockComment?: string;
  isActive: boolean;
  isValid?: boolean;
  isHideContextMenu?: boolean;
  isSequenceBlock?: boolean;
  isShowSequenceStat?: boolean;
  isShowBlockStat?: boolean;
  onBlockRemove: (id: string) => Promise<any>;
  onBlockClone: (params: {
    blockId: string;
    groupId: string;
    botId: string;
  }) => Promise<any>;
  onBlockMove: (params: {
    blockId: string;
    groupId: string;
    botId: string;
  }) => Promise<any>;
  contextMenuAlignRight?: boolean;
  onStatisticClick: (id: string, filter: string) => void;
  onContextMenuOpenChange: (status: boolean) => void;
}

export type DialogAction = 'move' | 'remove' | 'clone';

type NoDialog = {
  dropdownOpen: boolean;
  showDialog: false;
  dialogAction: null;
};
type SomeDialog = NoDialog & { showDialog: false; dialogAction: DialogAction };
type IBlockItemState = (NoDialog | SomeDialog) & {
  isTrimmedComment: boolean;
  dropdownButtonHoverState: boolean;
};

type MenuItem =
  | {
      index: number;
      type: DialogAction;
      title: string;
    }
  | { type: 'divider' };

const menuItems: () => MenuItem[] = () => [
  {
    index: 0,
    type: 'clone',
    title: i18next.t('BlockItem-string--167-copy'),
  },
  {
    index: 1,
    type: 'move',
    title: i18next.t('BlockItem-string--138-move'),
  },
  { type: 'divider' },
  {
    index: 2,
    type: 'remove',
    title: i18next.t('BlockItem-string-2043-delete'),
  },
];

const builtInBlockMenuItems: () => MenuItem[] = () => [
  {
    index: 0,
    type: 'clone',
    title: i18next.t('BlockItem-string--167-copy'),
  },
];

function itemToString(item?: MenuItem) {
  if (!item || item.type === 'divider') {
    return '';
  }
  return item.title;
}

const getItemsByBlockType = (blockIsBuiltIn: boolean): MenuItem[] => {
  return blockIsBuiltIn ? builtInBlockMenuItems() : menuItems();
};

export class BlockItem extends React.Component<
  IBlockItemProps,
  IBlockItemState
> {
  commentEl: HTMLSpanElement | undefined;

  timeoutForHandleRef: number | undefined;

  constructor(props: IBlockItemProps) {
    super(props);
    this.state = {
      dropdownOpen: false,
      showDialog: false,
      dialogAction: null,
      isTrimmedComment: false,
      dropdownButtonHoverState: false,
    };
    this.handleMenuSelect = this.handleMenuSelect.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.handleRemoveBlock = this.handleRemoveBlock.bind(this);
    this.handleSubmitClone = this.handleSubmitClone.bind(this);
    this.handleSubmitMove = this.handleSubmitMove.bind(this);
  }

  shouldComponentUpdate(
    nextProps: IBlockItemProps,
    nextState: IBlockItemState,
  ) {
    if (this.state !== nextState) {
      return true;
    }

    if (
      nextProps.isActive !== this.props.isActive ||
      nextProps.isValid !== this.props.isValid ||
      nextProps.block !== this.props.block ||
      nextProps.isShowSequenceStat !== this.props.isShowSequenceStat ||
      nextProps.isHideContextMenu !== this.props.isHideContextMenu ||
      nextProps.isShowBlockStat !== this.props.isShowBlockStat
    ) {
      return true;
    }

    return false;
  }

  componentWillUnmount() {
    if (this.timeoutForHandleRef) {
      window.clearTimeout(this.timeoutForHandleRef);
    }
  }

  handleMenuSelect(menuItem: MenuItem) {
    this.setState({
      showDialog: true,
      dialogAction: menuItem.type,
    } as unknown as SomeDialog);
  }

  handleDismiss() {
    this.setState({ showDialog: false, dialogAction: null } as IBlockItemState);
  }

  handleBlockClick = () => {
    const { onBlockClick } = this.props;
    if (onBlockClick) {
      onBlockClick();
    }
  };

  handleRemoveBlock() {
    sendEvent({
      category: 'block',
      action: 'delete',
      propertyBag: getBlockStatsForEvents(this.props.block),
    });

    this.setState({
      showDialog: false,
      dialogAction: null,
    } as IBlockItemState);
    this.props.onBlockRemove(this.props.block.id);
  }

  handleSubmitClone({ groupId, botId }: { groupId: string; botId: string }) {
    sendEvent({
      category: 'block',
      action: 'clone',
      propertyBag: getBlockStatsForEvents(this.props.block),
    });

    this.setState({
      showDialog: false,
      dialogAction: null,
    });
    this.props
      .onBlockClone({ groupId, botId, blockId: this.props.block.id })
      .then(() => {
        userActionsToaster.show({
          payload: {
            message: i18next.t(
              'BlockItem-string--155-block-successfully-copied',
            ),
          },
        });
      });
  }

  handleSubmitMove({ groupId, botId }: { groupId: string; botId: string }) {
    sendEvent({
      category: 'block',
      action: 'move',
      propertyBag: getBlockStatsForEvents(this.props.block),
    });

    this.setState({
      showDialog: false,
      dialogAction: null,
    });
    this.props
      .onBlockMove({ groupId, botId, blockId: this.props.block.id })
      .then(() => {
        userActionsToaster.show({
          payload: {
            message: i18next.t(
              'BlockItem-string-1898-block-successfully-moved',
            ),
          },
        });
      });
  }

  handleRef = (el: Element) => {
    this.timeoutForHandleRef = window.setTimeout(() => {
      const isTrimmedComment = testTrimmedComment(el);
      if (this.state.isTrimmedComment !== isTrimmedComment) {
        this.setState({ isTrimmedComment });
      }
    });
  };

  setDropdownButtonHoverState = (dropdownButtonHoverState: boolean) => {
    this.setState({
      dropdownButtonHoverState,
    });
  };

  render() {
    const {
      botId,
      groupId,
      groups,
      block,
      isActive,
      isValid,
      blockComment,
      isHideContextMenu,
      isSequenceBlock,
      isShowSequenceStat,
      contextMenuAlignRight,
      isShowBlockStat,
      onStatisticClick,
      statisticFeatureEnabled,
    } = this.props;
    const {
      showDialog,
      dialogAction,
      dropdownOpen,
      isTrimmedComment,
      dropdownButtonHoverState,
    } = this.state;
    const showBlockStat: boolean = Boolean(
      !isSequenceBlock && isShowBlockStat && block.stats,
    );
    const TITLE_TRIM_LENGTH = 27;
    const hasComment = !!blockComment;

    const getHandler: (
      filter: 'seen' | 'clicked' | 'sent',
    ) => (() => void) | undefined = (filter) =>
      statisticFeatureEnabled
        ? () => onStatisticClick(block.id, filter)
        : undefined;

    return (
      <RoleProvider botId={botId}>
        <Hover
          render={({ bind, hovered }) => (
            <div
              className={cx({
                [css.blockWrapper]: true,
                [css.defaultBlockWrapper]: hasComment,
                [css.blockWrapperWithStats]: showBlockStat,
              })}
            >
              <div
                {...bind}
                className={cx(css.container, {
                  [css.activeItem]: isActive,
                  [css.itemError]: isValid === false,
                  [css.hasComment]: hasComment,
                  [css.off]:
                    isSequenceBlock &&
                    path(['broadcast_options', 'time_period'], block) === 'off',
                })}
              >
                <Link
                  to={getTabLink(BotTabs.automate, botId, {
                    blockId: block.id,
                  })}
                  onClick={this.handleBlockClick}
                  className={cx(css.itemWrapper, {
                    [css.sequenceMode]: isSequenceBlock,
                  })}
                >
                  <span className={css.item}>
                    {ellipsizeString(TITLE_TRIM_LENGTH, block.title)}
                  </span>
                  {isSequenceBlock && (hovered || isShowSequenceStat) && (
                    <SequenceBlockStat
                      sent={pathOr<number>(0, ['stats', 'sent'], block)}
                      views={pathOr<number>(0, ['stats', 'seen'], block)}
                      clicks={pathOr<number>(0, ['stats', 'clicked'], block)}
                      isActive={isActive}
                      className={cx({
                        [css.marginForContextButton]:
                          !isShowSequenceStat && hovered,
                      })}
                      onReceivedClick={getHandler('sent')}
                      onOpenedRateClick={getHandler('seen')}
                      onClickRateClick={getHandler('clicked')}
                    />
                  )}
                  {hasComment && (
                    <React.Fragment>
                      <span
                        className={css.comment}
                        ref={(el) => el && this.handleRef(el)}
                      >
                        {blockComment}
                      </span>
                      {isTrimmedComment && (
                        <Hover
                          render={({ bind, hovered: hoveredComment }) => (
                            <React.Fragment>
                              <Tooltip
                                show={
                                  hoveredComment || dropdownButtonHoverState
                                }
                                content={blockComment}
                                modifiers={{
                                  preventOverflow: {
                                    boundariesElement: 'viewport',
                                  },
                                }}
                                placement="right-start"
                                className={css.commentTooltip}
                              >
                                {(ref) => <div ref={ref} />}
                              </Tooltip>
                              <div
                                className={css.commentMaskHoverTarget}
                                {...bind}
                              >
                                <span
                                  className={cx(css.commentMask, {
                                    [css.white]: !isActive,
                                  })}
                                />
                              </div>
                            </React.Fragment>
                          )}
                        />
                      )}
                    </React.Fragment>
                  )}
                </Link>

                <RoleConsumer
                  domain="groups"
                  can={Permission.EDIT}
                  groupId={groupId}
                >
                  {!isHideContextMenu && (hovered || dropdownOpen) ? (
                    <Downshift
                      // do not save "selected" item to downshift state,
                      // because this is not a "select", but a "dropdown"
                      selectedItem={null}
                      itemToString={itemToString}
                      onChange={this.handleMenuSelect}
                      onStateChange={(change) => {
                        /**
                         * In safari, depending on user settings, click on a button
                         * does not necessary focus this button, while in other browsers
                         * clicked button (or anchor) is always focused.
                         *
                         * Hence we might get in a situation where the user revealed
                         * the menu button (dots) by hovering this element,
                         * clicked the menu button to open the menu
                         * and then hovered away from this element to the opened menu,
                         * and hovering away leads to hiding the menu button, because the
                         * focus did not stick on the button.
                         *
                         * To handle this, we keep track of the state of
                         * the dropdown (`state.dropdownOpen`) to prevent hiding the menu
                         * when it's open.
                         */
                        if ('isOpen' in change) {
                          this.setState({
                            dropdownOpen: change.isOpen,
                          } as NoDialog);
                        }
                      }}
                    >
                      {({
                        isOpen,
                        getToggleButtonProps,
                        getItemProps,
                        highlightedIndex,
                      }) => {
                        this.props.onContextMenuOpenChange(isOpen);
                        const items = getItemsByBlockType(
                          Boolean(block.builtin),
                        );
                        return (
                          <div>
                            <button
                              data-testid="automate-aside__block-menu"
                              {...getToggleButtonProps()}
                              onMouseEnter={() =>
                                this.setDropdownButtonHoverState(true)
                              }
                              onMouseLeave={() =>
                                this.setDropdownButtonHoverState(false)
                              }
                              className={cx(
                                css.unstyledButton,
                                css.dropdownIcon,
                                {
                                  [css.blueGradient]: isActive,
                                },
                              )}
                            >
                              <DotsIcon />
                            </button>
                            {isOpen ? (
                              <Menubox
                                style={{
                                  position: 'absolute',
                                  top: 38,
                                  zIndex: 20,
                                  width: 180,
                                  right:
                                    hasComment ||
                                    isSequenceBlock ||
                                    contextMenuAlignRight
                                      ? 0
                                      : undefined,
                                }}
                              >
                                <ScrollBox>
                                  {items.map((item, index) =>
                                    item.type === 'divider' ? (
                                      // eslint-disable-next-line react/no-array-index-key
                                      <Divider key={index} />
                                    ) : (
                                      <MenuItem
                                        key={item.title}
                                        {...getItemProps({
                                          item,
                                          index: item.index,
                                        })}
                                        active={item.index === highlightedIndex}
                                        title={item.title}
                                      />
                                    ),
                                  )}
                                </ScrollBox>
                              </Menubox>
                            ) : null}
                          </div>
                        );
                      }}
                    </Downshift>
                  ) : null}
                </RoleConsumer>
                {showDialog ? (
                  <Modal onDismiss={this.handleDismiss}>
                    {dialogAction === 'remove' && (
                      <RemoveEntityDialog
                        renderHeading={() => (
                          <>
                            {i18next.t('BlockItem-Template--107-delete')}{' '}
                            <TextEllipsis inline>{block.title}</TextEllipsis>
                          </>
                        )}
                        renderActionText={() => (
                          <>
                            {i18next.t('BlockItem-Template--107-delete')}{' '}
                            <TextEllipsis width={200} inline>
                              {block.title}
                            </TextEllipsis>
                          </>
                        )}
                        onSubmit={this.handleRemoveBlock}
                        onDismiss={this.handleDismiss}
                      />
                    )}
                    {dialogAction === 'clone' && (
                      <BlockActionsDialog
                        currentBotId={botId}
                        currentGroupId={groupId}
                        currentBotGroups={groups}
                        renderHeading={() =>
                          `${i18next.t('BlockItem-Template-6529-copy')}${
                            block.title
                          }${i18next.t('BlockItem-Template-1009-block')}`
                        }
                        renderActionText={() =>
                          i18next.t('BlockItem-string--425-copy-block')
                        }
                        onSubmit={this.handleSubmitClone}
                        onDismiss={this.handleDismiss}
                      />
                    )}
                    {dialogAction === 'move' && (
                      <BlockActionsDialog
                        currentBotId={botId}
                        currentGroupId={groupId}
                        currentBotGroups={groups}
                        renderHeading={() =>
                          `${i18next.t('BlockItem-Template-7453-move')}${
                            block.title
                          }${i18next.t('BlockItem-Template-1009-block')}`
                        }
                        renderActionText={() =>
                          i18next.t('BlockItem-string--109-move-block')
                        }
                        onSubmit={this.handleSubmitMove}
                        onDismiss={this.handleDismiss}
                      />
                    )}
                  </Modal>
                ) : null}
              </div>
              {!isSequenceBlock && showBlockStat && (
                <BlockStat
                  views={block.stats!.seen!}
                  clicks={block.stats!.clicked!}
                  defaultBlock={hasComment}
                  onClickRateClick={getHandler('clicked')}
                  onViewsClick={getHandler('seen')}
                />
              )}
            </div>
          )}
        />
      </RoleProvider>
    );
  }
}
