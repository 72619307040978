import React from 'react';

import { sendEvent } from '../../../utils/Analytics';
import { BotTabs, getTabLink, useGoToTab } from '../../../utils/Routing';

import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../modern-ui/_deprecated/Button';
import {
  BotNotificationTagType,
  getNotificationType,
} from './BotNotificationTag';

import { DeepLinksMode } from '@pages/DeepLinks/types';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { BotsListQuery_bots } from '../../../common/services/GQLqueries/@types/BotsListQuery';

import * as css from '../BotList.css';

const actionButtonPropsByTagType = (
  isWhatsappBot: boolean | undefined,
  t: TFunction,
) => ({
  [BotNotificationTagType.NONE]: null,
  [BotNotificationTagType.BOT_DISCONNECTED]: {
    intent: ButtonIntent.primary,
    colorWay: ButtonColorWay.white,
    text:
      t('BotActionButton-string--167-connect') + (isWhatsappBot ? ' ↗︎' : ''),
  },
  [BotNotificationTagType.PAUSED]: {
    intent: ButtonIntent.primary,
    colorWay: ButtonColorWay.white,
    text: t('BotActionButton-string-1384-unpause'),
  },
  [BotNotificationTagType.SOON_PAYMENT_PROBLEM]: {
    intent: ButtonIntent.primary,
    text: t('BotActionButton-string--104-update-payment'),
  },
  [BotNotificationTagType.PAYMENT_PROBLEM]: {
    intent: ButtonIntent.primary,
    colorWay: ButtonColorWay.red,
    text: t('BotActionButton-string--104-update-payment'),
  },
  [BotNotificationTagType.LIMIT_REACHING]: {
    intent: ButtonIntent.primary,
    text: t('BotActionButton-string--165-upgrade-to-pro'),
  },
  [BotNotificationTagType.LIMIT_REACHED]: {
    intent: ButtonIntent.primary,
    text: t('BotActionButton-string--165-upgrade-to-pro'),
  },
  [BotNotificationTagType.N_USERS_LEFT]: {
    intent: ButtonIntent.primary,
    colorWay: ButtonColorWay.white,
    text: t('BotActionButton-string--165-upgrade-to-pro'),
  },
});

interface ActionButtonProps {
  bot: BotsListQuery_bots;
  isWhatsappBot?: boolean;
  notificationsLimit: number;
  onPageConnectClick(): void;
  onUpgradeToProClick(botId: string): void;
}

export const ActionButton: React.FC<ActionButtonProps> = (props) => {
  const { bot, notificationsLimit, onPageConnectClick, onUpgradeToProClick } =
    props;
  const { isWhatsappBot } = props;
  const { t } = useSafeTranslation();
  const history = useHistory();
  const goToTab = useGoToTab(bot.id);

  const type = getNotificationType(
    bot,
    bot?.statistic?.current_week_total_subscribed_users ?? 0,
    bot?.limits.usersLimit ?? 0,
    notificationsLimit,
  );

  if (type === BotNotificationTagType.NONE) {
    return null;
  }

  const { text, ...buttonProps } = actionButtonPropsByTagType(isWhatsappBot, t)[
    type
  ];

  function handleClick(event: React.SyntheticEvent) {
    event.stopPropagation();

    sendEvent({
      category: 'bot list',
      action: text.toLowerCase(),
      label: 'bot',
      propertyBag: { isWhatsappBot },
    });

    switch (type) {
      case BotNotificationTagType.LIMIT_REACHING:
      case BotNotificationTagType.LIMIT_REACHED:
      case BotNotificationTagType.N_USERS_LEFT:
        onUpgradeToProClick(bot.id);
        break;
      case BotNotificationTagType.BOT_DISCONNECTED:
        if (isWhatsappBot) {
          history.push(
            getTabLink(BotTabs.home, bot.id, {
              dlMode: DeepLinksMode.connectWhatsapp,
            }),
          );
        } else {
          onPageConnectClick();
        }
        break;
      case BotNotificationTagType.PAUSED:
      case BotNotificationTagType.SOON_PAYMENT_PROBLEM:
      case BotNotificationTagType.PAYMENT_PROBLEM:
        goToTab(BotTabs.configure);
        break;
      default:
        break;
    }
  }

  return (
    <Button className={css.actionButton} {...buttonProps} onClick={handleClick}>
      {text}
    </Button>
  );
};
