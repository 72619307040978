import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { sendEvent } from '@utils/Analytics';
import { BotTabs, getTabLink } from '@utils/Routing';
import { useQueryParams } from '@utils/queryParams';

import { ActivatePageDiscountStatus } from '@globals';

import { useActivatePageDiscount } from './hooks/useActivatePageDiscount';
import { useActivatePageDiscountData } from './hooks/useActivatePageDiscountData';
import { useActivatePageDiscountFinishedDialog } from './components/ActivatePageDiscountFinishedDialog';

import { ActivatePageDiscountExtendedStatus } from './types';

export const ActivateDiscount: React.FC = () => {
  const history = useHistory();
  const { showActivatePageDiscountFinishedDialog } =
    useActivatePageDiscountFinishedDialog();
  const { pageId, token } = useQueryParams<{ pageId: string; token: string }>();
  const [popupDisplayed, setPopupDisplayed] = useState(false);
  const showInfoDialog = useCallback(
    (
      status?:
        | ActivatePageDiscountExtendedStatus
        | ActivatePageDiscountStatus
        | null,
      onRetry?: () => void,
    ) => {
      if (!popupDisplayed) {
        setPopupDisplayed(true);
        showActivatePageDiscountFinishedDialog({ status, onRetry });
      }
    },
    [popupDisplayed, showActivatePageDiscountFinishedDialog],
  );
  const { activatePageDiscount, activatePageDiscountCalled } =
    useActivatePageDiscount({
      pageId,
      token,
      onCompleted(status) {
        showInfoDialog(status);
        sendEvent({
          category: 'page discount',
          action: `${status}`,
          propertyBag: { pageId },
        });
      },
      onFailed(_, retry) {
        showInfoDialog(ActivatePageDiscountExtendedStatus.is_failed, retry);
        sendEvent({
          category: 'page discount',
          action: 'failed',
          propertyBag: { pageId },
        });
      },
    });
  const { activatePageDiscountBotId, activatePageDiscountDataLoading } =
    useActivatePageDiscountData(pageId);

  useEffect(() => {
    if (!activatePageDiscountDataLoading) {
      if (activatePageDiscountBotId) {
        history.push(getTabLink(BotTabs.upgrade, activatePageDiscountBotId));
      } else {
        history.push('/');
      }
    }
  }, [activatePageDiscountBotId, activatePageDiscountDataLoading, history]);

  useEffect(() => {
    if (pageId && token && !activatePageDiscountCalled) {
      activatePageDiscount();
    }
  }, [
    activatePageDiscount,
    activatePageDiscountCalled,
    pageId,
    showInfoDialog,
    token,
  ]);

  return null;
};
