import React, { useMemo } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { compose } from 'ramda';
import { BOTS_SELECTOR_QUERY } from '@utils/Data/Bot';
import {
  BotsSelectorQuery_bots,
  BotsSelectorQuery,
} from '@utils/Data/Bot/@types/BotsSelectorQuery';
import { Loader } from '@ui/Loader';
import { SimpleCombobox } from '@ui/SimpleCombobox';
import { Autofocus } from '@ui/Autofocus';
import { Input } from '@ui/Input';
import { Icon } from '@ui/_deprecated/Icon';
import { ReactComponent as DefaultBotIcon } from '../../modern-ui/_deprecated/Icon/icons/default-bot.svg';
import { MenuItem } from '@ui/Menu';
import css from './BotSelector.css';
import { Platform } from '@globals';
import { testAllowedPlatforms } from '@utils/Platform/testAllowedPlatforms';
import Maybe from 'graphql/tsutils/Maybe';

interface BotSelectorProps {
  selectedBotId?: string;
  excludedBotIds?: string[];
  platforms?: Maybe<Platform[]>;
  onChange: (bot: BotsSelectorQuery_bots) => void;
}

export const BotSelector: React.FC<BotSelectorProps> = ({
  selectedBotId,
  excludedBotIds,
  onChange,
  platforms,
}) => {
  const { data, loading } = useQuery<BotsSelectorQuery, {}>(
    BOTS_SELECTOR_QUERY,
  );

  const selectedBot = useMemo(
    () => data?.bots.find((bot) => bot.id === selectedBotId) ?? data?.bots?.[0],
    [data, selectedBotId],
  );

  const items = useMemo(
    () =>
      data?.bots.filter(
        (bot) =>
          !excludedBotIds?.includes(bot.id) &&
          (!platforms?.length ||
            !bot.allowedPlatforms ||
            testAllowedPlatforms(platforms, bot.allowedPlatforms)),
      ) || [],
    [data, excludedBotIds, platforms],
  );

  if (loading) {
    return <Loader />;
  }

  if (!selectedBot) {
    return <div />;
  }

  return (
    <SimpleCombobox<BotsSelectorQuery_bots>
      renderInput={({
        getInputProps,
        openMenu,
        ref,
        selectInputValue,
        selectItem,
        selectedItem,
      }) => (
        <Autofocus
          render={({ bind }) => (
            <Input
              {...getInputProps({
                ref: (n: HTMLInputElement) => {
                  ref(n);
                  bind.ref(n);
                },
                onFocus: compose(openMenu, selectInputValue),
                onChange: () => {
                  selectItem(null);
                },
              })}
              required
              renderIconEnd={() => {
                if (!selectedItem) {
                  return null;
                }
                return selectedItem.status?.page_info?.picture ? (
                  <Icon url={selectedItem.status?.page_info?.picture} />
                ) : (
                  <DefaultBotIcon />
                );
              }}
            />
          )}
        />
      )}
      renderItem={({ item, index, highlightedIndex, getItemProps }) => {
        return (
          <MenuItem
            key={item.id}
            leftElement={
              item.status?.page_info?.picture ? (
                <Icon url={item.status?.page_info?.picture} />
              ) : (
                <DefaultBotIcon />
              )
            }
            {...getItemProps({ item })}
            title={item.title}
            active={index === highlightedIndex}
          />
        );
      }}
      items={items}
      initialSelectedItem={selectedBot}
      onChange={(item) => {
        if (item) {
          onChange(item);
        }
      }}
      menuBoxClassName={css.menubox}
      dataTestId="bot_selector__combobox"
    />
  );
};
