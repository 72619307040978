import React from 'react';
import { DefaultDialog } from '@ui/Dialog';
import { ReactComponent as DoneSvg } from './done.svg';
import { CancelProTranslation } from '@components/CancelProDialog/CancelProTranslation';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';

interface RemoveUserSuccesDialogProps {
  onClose: VoidFunction;
  userCount: string;
  costPerMonth: string;
}

export const RemoveUserSuccesDialog: React.FC<RemoveUserSuccesDialogProps> = ({
  onClose,
  userCount,
  costPerMonth,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  return (
    <DefaultDialog onDismiss={onClose} mobileAdaptive={isSmallScreenSize}>
      <Flex flexDirection="column" alignItems="center">
        <DoneSvg />
        <Type size="24px" weight="semibold">
          <CancelProTranslation
            i18nKey="CancelPro.RemoveUserSuccesDialog.youRemovedUsers"
            options={{ userCount }}
          />
        </Type>
        <Type size="15px">
          <CancelProTranslation
            i18nKey="CancelPro.RemoveUserSuccesDialog.startingNextNonth"
            options={{ costPerMonth }}
          />
        </Type>
        <Spacer factor={8} />
        <Button onClick={onClose}>
          <CancelProTranslation i18nKey="CancelPro.RemoveUserSuccesDialog.close" />
        </Button>
      </Flex>
    </DefaultDialog>
  );
};
