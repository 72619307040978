import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';

import { Button } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';

import { useLocation } from 'react-router-dom';
import { getConnectPageEventPostfix } from '../../helpers';
import { ConnectPageField, ConnectPageOrigin } from '../../types';

import { FacebookPagesFooter } from '../FacebookPagesFooter';
import { useFacebookLogin } from '@pages/MultiSystemAuth/hooks/useFacebookLogin';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import css from './FacebookPagesGivePermissions.css';

const sendEventFn = (action: string) => {
  const url = new URL(window.location.href);
  const {
    [ConnectPageField.name]: label,
    [ConnectPageField.flowId]: flowId,
    [ConnectPageField.blockId]: blockId,
    [ConnectPageField.cardId]: cardId,
  } = Object.fromEntries(url.searchParams);
  sendEvent({
    category: 'connect facebook page dialog',
    action,
    label,
    propertyBag: { flowId, blockId, cardId },
  });
};

interface FacebookPagesGivePermissionsProps {
  botId: string;
  origin: keyof typeof ConnectPageOrigin;
  onPermissionsUpdated: () => void;
  facebookPagesRefetch: () => void;
}

export const FacebookPagesGivePermissions: React.FC<FacebookPagesGivePermissionsProps> =
  ({ origin, onPermissionsUpdated, facebookPagesRefetch }) => {
    const { isSmallScreenSize } = useDeviceMedia();
    const eventsPostfix = getConnectPageEventPostfix(origin);
    const { t } = useSafeTranslation();
    const location = useLocation();
    const { facebookLogin, ready: fbLoginReady } = useFacebookLogin({
      interruptedHref: window.location.href,
      location,
      reRequestPermissions: true,
    });

    return (
      <Flex
        flexDirection="column"
        data-testid="facebook-pages__give-permissions"
      >
        <Type size="24px" weight="semibold">
          {t('modernComponents.FacebookPagesSetup.givePermissions.title')}
        </Type>
        <Spacer factor={1} />
        <Flex
          className={cn(!isSmallScreenSize && css.container)}
          flexDirection={isSmallScreenSize ? 'column' : 'row'}
          alignItems="center"
          justifyContent="space-between"
        >
          <Type size="15px">
            {t(
              'modernComponents.FacebookPagesSetup.givePermissions.description',
            )}
          </Type>
          <Spacer horizontalFactor={2} />
          <Button
            className={cn(isSmallScreenSize && css.givePermissionButton_mobile)}
            intent="primary"
            data-testid="facebook-pages__give-permissions-button"
            onClick={async () => {
              sendEventFn(`give permissions${eventsPostfix}`);
              await facebookLogin();
              onPermissionsUpdated();
            }}
            fitToText
            disabled={!fbLoginReady}
          >
            {t('modernComponents.FacebookPagesSetup.givePermissions.button')}
          </Button>
        </Flex>
        <FacebookPagesFooter
          showCreatePageButton
          hideRefreshPageButton
          facebookPagesRefetch={facebookPagesRefetch}
        />
      </Flex>
    );
  };
