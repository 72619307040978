import ng from 'angular';
import { react2angular } from 'react2angular';
import { DashboardTokenAPIDialog } from './DashboardTokenAPIDialog';

export const ngDashboardTokenAPIDialog = ng
  .module('app.components.dashboardTokenApiDialog', [])
  .component(
    'dashboardTokenApiDialog',
    react2angular(DashboardTokenAPIDialog, ['onRequestClose'], []),
  ).name;
