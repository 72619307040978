import React from 'react';
import { Input } from '@ui/Input';
import { AttributesQuery_bot_variableSuggest as Attribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { Platform } from '@globals';
import { AttributeInputBase } from './AttributeInputBase/AttributeInputBase';
import * as css from './AttributeInput.css';

interface AttributeInputProps {
  value: string;
  placeholder?: string;
  error?: boolean;
  attributes: Attribute[];
  platform: Platform;
  shouldShowTagsButton?: boolean;
  customAttributesOnly?: boolean;
  onChange(nextValue: string): void;
  onSelect?(item: any): void;
  onBlur?(): void;
  renderIconEnd?: () => React.ReactNode;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  containerClassName?: string;
}

const MAX_ATTRIBUTE_NAME_LENGTH = 120;

export const AttributeInput = React.forwardRef<
  HTMLInputElement,
  AttributeInputProps
>(
  (
    {
      value,
      placeholder,
      error,
      attributes,
      platform,
      customAttributesOnly,
      shouldShowTagsButton,
      onChange,
      onSelect,
      onBlur,
      renderIconEnd,
      inputStyle,
      style,
      containerClassName,
    },
    ref,
  ) => {
    const attributeInputStyle: React.CSSProperties = {
      color: error ? 'var(--red)' : 'var(--blue)',
      fontSize: 15,
      lineHeight: '20px',
      textAlign: 'left',
      display: 'flex',
      alignItems: 'center',
    };

    return (
      <Input
        style={style}
        error={error}
        containerClassName={containerClassName}
        render={() => (
          <AttributeInputBase
            value={value}
            placeholder={placeholder}
            attributes={attributes}
            platform={platform}
            shouldShowTagsButton={shouldShowTagsButton}
            inputContainerClassName={css.inputContainer}
            style={attributeInputStyle}
            inputStyle={inputStyle}
            customAttributesOnly={customAttributesOnly}
            onChange={onChange}
            onSelect={onSelect}
            onBlur={onBlur}
            maxLength={MAX_ATTRIBUTE_NAME_LENGTH}
            renderIconEnd={renderIconEnd}
            ref={ref}
          />
        )}
      />
    );
  },
);
