import React from 'react';
import { BotLivelinessStatus } from '@globals';
import { Tooltip2, Tooltip2Props } from '@ui/Tooltip2';
import { useWhatsappEnabled } from '@utils/Whatsapp';
import Maybe from 'graphql/tsutils/Maybe';
import { getTooltipContent } from './utils';
import { useShopifyAccount } from '@utils/Integrations/Shopify/useShopifyAccount';

type TooltipProps = Omit<Tooltip2Props, 'children' | 'content' | 'disabled'>;

interface BotLivelinessStatusTooltipProps extends TooltipProps {
  status: Maybe<BotLivelinessStatus>;
  children: React.ReactNode;
}

export const BotLivelinessStatusTooltip: React.FC<BotLivelinessStatusTooltipProps> =
  ({ status, children, ...props }) => {
    const { isWhatsappEnabled } = useWhatsappEnabled();
    const { account } = useShopifyAccount();

    const tooltipContent =
      status &&
      getTooltipContent(
        status,
        isWhatsappEnabled,
        account?.subscription?.botWillBeStoppedAt,
      );

    return (
      <Tooltip2
        boundariesElement="viewport"
        type="small"
        hoverable
        {...props}
        content={tooltipContent}
        disabled={!tooltipContent}
      >
        {(ref, bind) => (
          <div ref={ref} {...bind}>
            {children}
          </div>
        )}
      </Tooltip2>
    );
  };
