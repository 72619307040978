import React, { useEffect, useRef, useState } from 'react';
import Downshift from 'downshift';
import { Portal } from 'react-portal';
import { Hover } from 'react-powerplug';
import AutosizeInput from 'react-input-autosize';
import { Manager, Popper, Reference } from 'react-popper';
import { Menubox, MenuItem, MenuItemProps } from '@ui/Menu';
import { TooltipPure } from '@ui/Tooltip2';
import { ATTR_SIGN } from '@ui/TextWithAttributesEditor';
import ScrollBox from '@ui/ScrollBox/ScrollBox';
import { TextOutsideControls } from '@ui/TextOutsideControls';
import { ReactComponent as AddIcon } from '@ui/_deprecated/Icon/icons/ic_add_small.svg';
import { ReactComponent as TagIcon } from '@ui/Icon/icons/actions/Tag.svg';
import {
  itemsFilter,
  itemToString,
  nameMatches,
} from '@ui/TextWithAttributesEditor/Attribute';
import { InputLikeElement, insertText } from '@utils/documentExecCommand';
import { getAttributeDescription } from '@utils/AttributesUtils/AttributesDescription';
import { VariablesType } from '@utils/AttributesUtils/AttributesUtilsTypes';
import { AttributesQuery_bot_variableSuggest as IAttribute } from '@utils/AttributesUtils/@types/AttributesQuery';
import { useCurrentBotId } from '@utils/Routing';
import { usePreventWheel } from '@utils/Event/usePreventWheel';
import {
  addAttributeToGQLCaches,
  filterAttributes,
} from '@utils/AttributesUtils/AttributesUtils';
import { Platform } from '@globals';
import * as css from './AttributeInputBase.css';
import { combineRefs } from '@utils/combineRefs';
import { TAGS_HASH } from '@components/WhatsappImportUsers/component/WhatsappImportUsersDialog/hocks/consts';

enum InputAttributeMode {
  overlay = 'overlay',
  domElement = 'domElement',
}

type InputAttributeModeKey = keyof typeof InputAttributeMode;

const randomTagHash = 'Tags';

interface AttributeInputBaseProps {
  value: string;
  attributes: IAttribute[];
  maxLength?: number;
  placeholder?: string;
  mode?: InputAttributeModeKey;
  platform: Platform | null;
  customAttributesOnly?: boolean;
  inputContainerStyle?: React.CSSProperties;
  inputContainerClassName?: string;
  textControlsClassName?: string;
  style?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  shouldShowTagsButton?: boolean;
  onChange(nextValue: string): void;
  onSelect?(item: any): void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
  onBlur?(): void;
  renderIconEnd?: () => React.ReactNode;
  disabled?: boolean;
  title?: string;
}

export const AttributeInputBase = React.forwardRef<
  HTMLInputElement,
  AttributeInputBaseProps
>(
  (
    {
      value,
      attributes,
      placeholder,
      maxLength,
      mode = InputAttributeMode.domElement,
      platform,
      customAttributesOnly,
      style,
      inputContainerStyle,
      inputContainerClassName,
      textControlsClassName,
      shouldShowTagsButton,
      onChange,
      onSelect,
      onBlur,
      onKeyDown,
      renderIconEnd,
      inputStyle,
      disabled,
      title,
    },
    outsideRef,
  ) => {
    const botId = useCurrentBotId();
    const isOverlayMode = mode === InputAttributeMode.overlay;
    const [caretOffset, setCaretOffset] = useState<number>(0);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const scheduleUpdateRef = useRef<undefined | (() => void)>();
    const dropdownBoxRef = usePreventWheel();
    const [showItems, setShowItems] = useState(isOverlayMode);

    const query = value.substring(0, caretOffset);
    const queryLowerCase = query.toLowerCase();
    const isInputFocused = document.activeElement === inputRef.current;
    const showAttrSign =
      (isOverlayMode || isInputFocused || !!value.length) &&
      value !== randomTagHash;

    const handleSelect =
      onSelect ??
      ((item: any) => {
        onChange(itemToString(item));
      });

    const getHandleItemClick =
      (itemProps: MenuItemProps) =>
      (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!isOverlayMode) {
          setShowItems(false);
        }
        itemProps.onClick?.(event);
      };

    useEffect(() => {
      if (!isOverlayMode || !inputRef.current) {
        return undefined;
      }
      inputRef.current.focus();
      inputRef.current.select();
      if (scheduleUpdateRef.current) {
        const timeoutHandle = setTimeout(scheduleUpdateRef.current); // reposition after focus
        return () => {
          clearTimeout(timeoutHandle);
        };
      }
      return undefined;
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const inputPlaceholder = isInputFocused ? undefined : placeholder;
    const iconEnd = renderIconEnd?.() || null;
    return (
      <Downshift
        itemToString={itemToString}
        onStateChange={({ isOpen }) => {
          if (isOpen === undefined) {
            return;
          }

          if (!isOpen) {
            addAttributeToGQLCaches(botId!!, value, platform);
          }
        }}
        onSelect={handleSelect}
        initialHighlightedIndex={0}
      >
        {({ getItemProps, highlightedIndex, getInputProps }) => {
          const items = filterAttributes(
            attributes,
            !!customAttributesOnly,
          ).filter(itemsFilter(queryLowerCase));
          const shouldShowAddButton =
            queryLowerCase.length > 0 &&
            !items.some(nameMatches(queryLowerCase)) &&
            value !== TAGS_HASH;
          return (
            <div className={inputContainerClassName}>
              <Manager>
                <Reference>
                  {({ ref: boxRef }) => (
                    <TextOutsideControls
                      onInsertRequest={(text, el) => {
                        insertText(text, el as InputLikeElement);
                      }}
                      currentTextLimit={
                        maxLength ? maxLength - value.length : undefined
                      }
                      initialShow={isOverlayMode}
                      shouldShowOutsideControls={{ attributes: true }}
                      className={textControlsClassName}
                    >
                      {({ ref }) => (
                        // eslint-disable-next-line jsx-a11y/label-has-associated-control
                        <label
                          style={{
                            display: 'flex',
                            ...style,
                          }}
                          ref={boxRef}
                        >
                          {showAttrSign && (
                            <span className={css.attrSign}>
                              {ATTR_SIGN.start}
                            </span>
                          )}
                          <AutosizeInput
                            {...getInputProps({
                              disabled,
                              title,
                              placeholder: inputPlaceholder,
                              value,
                              onChange: ({
                                currentTarget: { value: updatedValue },
                              }: React.FormEvent<HTMLInputElement>) => {
                                onChange(updatedValue);
                              },
                              onKeyDown: (
                                event: React.KeyboardEvent<HTMLInputElement>,
                              ) => {
                                if (event.key !== 'Enter') {
                                  setShowItems(true);
                                  onKeyDown?.(event);
                                } else if (!isOverlayMode) {
                                  setShowItems(false);
                                  inputRef.current?.blur();
                                }
                                if (['{', '}'].includes(event.key)) {
                                  event.preventDefault();
                                }
                              },
                              onBlur: () => {
                                if (!isOverlayMode) {
                                  setShowItems(false);
                                }
                                onBlur?.();
                              },
                              onFocus: ({
                                currentTarget,
                              }: React.FocusEvent<HTMLInputElement>) => {
                                if (!isOverlayMode) {
                                  setShowItems(true);
                                }
                                const { value } = currentTarget;
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.value = '';
                                // eslint-disable-next-line no-param-reassign
                                currentTarget.value = value;
                              },
                            })}
                            data-testid="flowbuilder__input-with-attributes"
                            minWidth={3}
                            inputStyle={{
                              display: 'block',
                              padding: 0,
                              border: 'none',
                              margin: '0px 0 0 0',
                              background: 'none',
                              outline: 'none',
                              resize: 'none',
                              whiteSpace: 'nowrap',
                              maxWidth: '100%',
                              ...style,
                              ...inputStyle,
                            }}
                            style={{
                              maxWidth: '100%',
                              minWidth: 0,
                              ...inputContainerStyle,
                            }}
                            maxLength={maxLength}
                            inputRef={combineRefs([ref, inputRef, outsideRef])}
                            onSelect={({
                              currentTarget,
                            }: React.FocusEvent<HTMLInputElement>) => {
                              setCaretOffset(currentTarget.selectionStart || 0);
                              scheduleUpdateRef.current?.();
                            }}
                          />
                          {showAttrSign && (
                            <span
                              className={css.attrSign}
                              style={{
                                marginLeft: isOverlayMode ? -2.5 : 0,
                              }}
                            >
                              {ATTR_SIGN.end}
                            </span>
                          )}
                          {iconEnd && (
                            <div className={css.iconEnd}>{iconEnd}</div>
                          )}
                        </label>
                      )}
                    </TextOutsideControls>
                  )}
                </Reference>
                {showItems &&
                (items.length ||
                  shouldShowAddButton ||
                  shouldShowTagsButton) ? (
                  <Portal>
                    <div ref={dropdownBoxRef}>
                      <Popper
                        placement="bottom-start"
                        modifiers={{
                          preventOverflow: {
                            enabled: true,
                            boundariesElement: 'viewport',
                            escapeWithReference: false,
                          },
                          flip: {
                            enabled: true,
                            boundariesElement: 'viewport',
                          },
                        }}
                      >
                        {({ ref, style, scheduleUpdate }) => {
                          scheduleUpdateRef.current = scheduleUpdate;
                          const actions = [];
                          if (shouldShowTagsButton) {
                            // getItemProps should be run only once per item or hoveredIndex would be incorrect
                            const actionProps = getItemProps({
                              item: { name: randomTagHash },
                            });
                            const currentIndex = actions.length;
                            actions.push(
                              <MenuItem
                                {...actionProps}
                                onClick={getHandleItemClick(actionProps)}
                                title="Tags"
                                active={highlightedIndex === currentIndex}
                                className={css.menuItem}
                                leftElement={
                                  <TagIcon className={css.addIcon} />
                                }
                              />,
                            );
                          }

                          if (shouldShowAddButton) {
                            const actionProps = getItemProps({
                              item: { name: query },
                            });
                            const currentIndex = actions.length;
                            actions.push(
                              <MenuItem
                                {...actionProps}
                                onClick={getHandleItemClick(actionProps)}
                                title={query}
                                active={highlightedIndex === currentIndex}
                                className={css.menuItem}
                                leftElement={
                                  <AddIcon className={css.addIcon} />
                                }
                              />,
                            );
                          }

                          return (
                            <div className={css.menu} ref={ref} style={style}>
                              <Menubox>
                                <ScrollBox>
                                  {actions}

                                  {items.map((item, i) => (
                                    <Hover
                                      key={`${item.id}--${actions.length}`}
                                    >
                                      {({ hovered, bind }) => {
                                        const description =
                                          getAttributeDescription(item.name);
                                        const itemProps = getItemProps({
                                          item,
                                          ...bind,
                                        });
                                        return (
                                          <TooltipPure
                                            content={description}
                                            show={hovered && description}
                                            type="small"
                                            placement="right"
                                            disableFlip={false}
                                            boundariesElement="viewport"
                                          >
                                            {(ref) => (
                                              <MenuItem
                                                {...itemProps}
                                                onClick={getHandleItemClick(
                                                  itemProps,
                                                )}
                                                title={item.name}
                                                subtitle={
                                                  item.type ===
                                                  VariablesType.custom
                                                    ? item.type
                                                    : undefined
                                                }
                                                active={
                                                  highlightedIndex !== null &&
                                                  highlightedIndex -
                                                    actions.length ===
                                                    i
                                                }
                                                className={css.menuItem}
                                                innerRef={ref}
                                              />
                                            )}
                                          </TooltipPure>
                                        );
                                      }}
                                    </Hover>
                                  ))}
                                </ScrollBox>
                              </Menubox>
                            </div>
                          );
                        }}
                      </Popper>
                    </div>
                  </Portal>
                ) : null}
              </Manager>
            </div>
          );
        }}
      </Downshift>
    );
  },
);
