import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
import { ActivatePageDiscountDataQuery } from './@types/ActivatePageDiscountDataQuery';

const ACTIVATE_PAGE_DISCOUNT_DATA_QUERY = gql`
  query ActivatePageDiscountDataQuery {
    bots {
      id
      status {
        page_info {
          id
        }
      }
    }
  }
`;
export const useActivatePageDiscountData = (pageId?: string) => {
  const { data, loading } = useQuery<ActivatePageDiscountDataQuery>(
    ACTIVATE_PAGE_DISCOUNT_DATA_QUERY,
  );
  const bot = data?.bots.find((bot) => bot.status?.page_info?.id === pageId);
  return {
    activatePageDiscountData: data,
    activatePageDiscountDataLoading: loading,
    activatePageDiscountBotId: bot?.id,
  };
};
