import React from 'react';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
} from '../../../../modern-ui/_deprecated/Button';
import { Type } from '../../../../modern-ui/Type';
import { Banner, Text } from '../Banner';
import { goToGetMarketingPermissions } from '../../../../utils/FacebookPages/goToGetPermissions';
import { sendEvent } from '../../../../utils/Analytics';

export const NeedPermissionsBanner: React.FC<{}> = () => (
  <Banner>
    {() => (
      <>
        <Text>
          <Type as="p" size="15px_DEPRECATED">
            {window.i18next.t(
              'NeedPermissionsBanner-JSXText--106-sync-audience-segments-from-chatfuel-to-facebook-ads-manager',
            )}
          </Type>
          <Type as="p" size="15px_DEPRECATED">
            {window.i18next.t(
              'NeedPermissionsBanner-JSXText--106-a-pro-plan-subscription-is-required-for-this-feature',
            )}
          </Type>
        </Text>
        <div>
          <Button
            intent={ButtonIntent.primary}
            colorWay={ButtonColorWay.blue}
            onClick={() => {
              sendEvent({
                category: 'people tab',
                action: 'give permissions',
                label: 'segments sync',
              });
              goToGetMarketingPermissions();
            }}
          >
            {window.i18next.t(
              'NeedPermissionsBanner-JSXText--561-give-permissions',
            )}
          </Button>
        </div>
      </>
    )}
  </Banner>
);
