import { ChurnSurveyAction, LeaveReason } from '@globals';
import {
  FINISH_CHURN_SURVEY_MUTATION,
  START_CHURN_SURVEY_MUTATION,
} from '@utils/Data/Pricing';
import {
  FinishChurnSurveyMutation,
  FinishChurnSurveyMutationVariables,
} from '@utils/Data/Pricing/@types/FinishChurnSurveyMutation';
import {
  StartChurnSurveyMutation,
  StartChurnSurveyMutationVariables,
} from '@utils/Data/Pricing/@types/StartChurnSurveyMutation';
import { useCurrentBotId } from '@utils/Routing';
import { useCallback } from 'react';
import { useMutation } from 'react-apollo';
import { log } from 'cf-common/src/logger';

const AFTER_PAYWALL_TAG = 'afterPaywall';

export const useChurnSurvey = (cancelingPaywall: Boolean) => {
  const botId = useCurrentBotId();
  const [startChurnSurvey, { loading: startLoading }] = useMutation<
    StartChurnSurveyMutation,
    StartChurnSurveyMutationVariables
  >(START_CHURN_SURVEY_MUTATION, {
    onError(error) {
      log.error({
        error,
        msg: 'start churn survey',
      });
    },
  });

  const [finishChurnSurvey, { loading: finishLoading }] = useMutation<
    FinishChurnSurveyMutation,
    FinishChurnSurveyMutationVariables
  >(FINISH_CHURN_SURVEY_MUTATION, {
    onError(error) {
      log.error({
        error,
        msg: 'finish churn survey',
      });
    },
  });

  const handleStartChurnSurvey = useCallback(
    (action: ChurnSurveyAction, reasons: LeaveReason[]) => {
      if (botId)
        return startChurnSurvey({
          variables: {
            botId,
            action,
            reasons,
            tags: cancelingPaywall ? [AFTER_PAYWALL_TAG] : [],
          },
        });
      return Promise.reject();
    },
    [botId, startChurnSurvey, cancelingPaywall],
  );

  const handleFinishChurnSurvey = useCallback(
    (startId: string, other?: string, feedback?: string) => {
      return finishChurnSurvey({
        variables: { startId, other, feedback },
      });
    },
    [finishChurnSurvey],
  );

  return [
    handleStartChurnSurvey,
    handleFinishChurnSurvey,
    startLoading,
    finishLoading,
  ] as const;
};
