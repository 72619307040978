import React, { useState } from 'react';
import cn from 'classnames';
import { pathOr, values, invertObj } from 'ramda';
import OutsideClick from 'react-outsideclick';
import { Rifm } from 'rifm';
import { AsideGroupsQuery_bot_blocksGroups_blocks as IBlock } from '../../@types/AsideGroupsQuery';
import { Menubox } from '../../../../modern-ui/Menu';
import { Input } from '../../../../modern-ui/Input';
import {
  Button,
  ButtonIntent,
  ButtonSize,
  ButtonColorWay,
} from '../../../../modern-ui/_deprecated/Button';
import { Dropdown } from '../../../../modern-ui/_deprecated/Dropdown';
import { ReactComponent as DropdownIcon } from '../../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { Autofocus } from '../../../../modern-ui/Autofocus';
import * as css from './BlockSequenceControlItem.css';

interface IBlockSequenceControlItemProps
  extends React.HTMLProps<HTMLDivElement> {
  block: IBlock;
  currentBlockId: string | undefined;
  index: number;
  readOnly: boolean;
  onBroadcastOptionsChange: (
    block: IBlock,
    broadcastOptions: { time_period?: string; time_value?: number },
  ) => void;
}

const TIME_PERIODS: () => { [key: string]: string } = () => ({
  immediately: window.i18next.t(
    'BlockSequenceControlItem-string--132-immediately',
  ),
  seconds: window.i18next.t('BlockSequenceControlItem-string--660-seconds'),
  minutes: window.i18next.t('BlockSequenceControlItem-string--156-minutes'),
  hours: window.i18next.t('BlockSequenceControlItem-string-6991-hours'),
  days: window.i18next.t('BlockSequenceControlItem-string-2122-days'),
  off: 'Off',
});

const TIME_PERIODS_INVERTED_MAP: () => { [key: string]: string } = () =>
  invertObj(TIME_PERIODS());

const NOT_ENUMERATED_TIME_PERIODS = ['immediately', 'off'];
export const INACTIVE_TIME_PERIODS = ['off'];
const timePeriodIsNotEnumerated = (timePeriod: string) =>
  NOT_ENUMERATED_TIME_PERIODS.includes(timePeriod);
const prepareTimePeriod = (timePeriod: string, timeValue: number | null) =>
  !timeValue || timeValue > 1 || timePeriodIsNotEnumerated(timePeriod)
    ? timePeriod
    : timePeriod.slice(0, -1);

const numberFormat = (str: string) => {
  const r = parseInt(str.replace(/[^\d]+/gi, ''), 10);
  return r ? r.toString().substr(0, 2) : '';
};

export const BlockSequenceControlItem: React.FC<IBlockSequenceControlItemProps> = React.memo(
  ({
    block,
    currentBlockId,
    index,
    className,
    readOnly,
    onBroadcastOptionsChange,
    ...props
  }) => {
    const [isEdit, setIsEdit] = useState(false);
    const propTimePeriod = pathOr(
      'immediately',
      ['broadcast_options', 'time_period'],
      block,
    );
    const propTimeValue = pathOr(
      undefined,
      ['broadcast_options', 'time_value'],
      block,
    );
    const [timePeriod, setTimePeriod] = useState<string>(propTimePeriod);
    const [timeValue, setTimeValue] = useState<number>(propTimeValue || 1);
    const isNotEnumerated = timePeriodIsNotEnumerated(timePeriod);

    return (
      <div
        className={cn(css.BlockSequenceControlItem, className, {
          [css.isCurrentBlock]: currentBlockId === block.id,
          [css.off]: timePeriod === 'off',
        })}
        {...props}
      >
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <div className={css.box} onClick={() => !readOnly && setIsEdit(true)}>
          <div className={css.capitalise}>
            {!isNotEnumerated && (
              <span>
                {window.i18next.t(
                  'BlockSequenceControlItem-JSXText-5422-after',
                )}
                {timeValue}
                &nbsp;
              </span>
            )}
            <span>{prepareTimePeriod(timePeriod, timeValue)}</span>
          </div>
        </div>
        {isEdit && (
          <OutsideClick
            onClickOutside={() => {
              setIsEdit(false);
              const newTimeValue = Number(timeValue) || 1;
              setTimeValue(newTimeValue);
              if (
                propTimePeriod !== timePeriod ||
                propTimeValue !== newTimeValue
              ) {
                onBroadcastOptionsChange(block, {
                  time_period: timePeriod,
                  time_value: newTimeValue,
                });
              }
            }}
          >
            <Menubox className={css.editor}>
              <div className={css.editorTitle}>
                {window.i18next.t(
                  'BlockSequenceControlItem-JSXText-1979-send-in',
                )}
              </div>
              <div className={css.editorControls}>
                {!isNotEnumerated && (
                  <Rifm
                    value={timeValue.toString()}
                    onChange={(s) => {
                      setTimeValue(parseInt(s, 10));
                    }}
                    format={numberFormat}
                  >
                    {(bind) => (
                      <div className={css.inputBox}>
                        <Autofocus
                          selectAll
                          render={({ bind: autoFocusBind }) => (
                            <Input
                              className={css.input}
                              {...bind}
                              {...autoFocusBind}
                            />
                          )}
                        />
                      </div>
                    )}
                  </Rifm>
                )}
                <Dropdown
                  items={values(TIME_PERIODS())}
                  selectedItem={TIME_PERIODS()[timePeriod]}
                  onChange={(value) =>
                    setTimePeriod(TIME_PERIODS_INVERTED_MAP()[value])
                  }
                  itemClassName={css.dropdownItem}
                  boxClasses={css.dropdownBox}
                  buttonsFactory={(props, ref) => (
                    <Button
                      {...(props as React.ButtonHTMLAttributes<
                        HTMLButtonElement
                      >)}
                      renderIconRight={() => <DropdownIcon />}
                      intent={ButtonIntent.primary}
                      size={ButtonSize.m}
                      colorWay={ButtonColorWay.white}
                      innerRef={ref}
                      className={cn(css.dropdownButton, {
                        [css.dropdownButtonFull]: isNotEnumerated,
                      })}
                    >
                      {TIME_PERIODS()[timePeriod]}
                    </Button>
                  )}
                />
              </div>
              {index === 0 ? (
                <div className={css.editorInfo}>
                  {window.i18next.t(
                    'BlockSequenceControlItem-JSXText--924-after-subscription-to-this-sequence',
                  )}
                </div>
              ) : (
                <div className={css.editorInfo}>
                  {window.i18next.t(
                    'BlockSequenceControlItem-JSXText--252-after-previous-block',
                  )}
                </div>
              )}
            </Menubox>
          </OutsideClick>
        )}
      </div>
    );
  },
);
