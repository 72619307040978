import { useLocation } from 'react-router-dom';
import { useCallback, useEffect } from 'react';
import { equals } from 'ramda';

import { BotStatus } from '@utils/Bot';
import { useCurrentBotId } from '@utils/Routing';
import { useCurrentTestPageId } from '@utils/FacebookPages/useCurrentTestPageId';
import { getFlowController } from '@components/FlowBuilder/PixiFieldRepository';

import { connectPage } from './connectPage';
import { ConnectPageDialogPropsLite } from './ConnectPageDialog';
import { getConnectPageUrlParams } from './helpers';
import { ConnectPageField, ConnectPageOrigin } from './types';

export const useConnectPage = (params: ConnectPageDialogPropsLite) => {
  const location = useLocation();
  useEffect(() => {
    const urlParams = getConnectPageUrlParams();
    const queryOrigin = urlParams[ConnectPageField.origin];
    if (
      queryOrigin !== ConnectPageOrigin.default &&
      params.urlParams &&
      equals(params.urlParams, urlParams)
    ) {
      connectPage(params);
    }
  }, [location.search, params]);
  return {
    connectPage: (connectPageParams?: Partial<ConnectPageDialogPropsLite>) => {
      connectPage({ ...params, ...connectPageParams });
    },
  };
};

interface UseShowConnectPageForTestThisModalIfNeedArgs {
  onConnecting?: () => void;
  onConnected?: (status: BotStatus, pageId?: string | null) => void;
  onClose?: () => void;
}
export const useShowConnectPageForTestThisModalIfNeed = () => {
  const botId = useCurrentBotId();
  const { isDefaultTestPage } = useCurrentTestPageId();
  return useCallback(
    ({
      onConnecting,
      onConnected,
      onClose,
    }: UseShowConnectPageForTestThisModalIfNeedArgs = {}) => {
      if (!botId || !isDefaultTestPage) {
        return false;
      }
      onConnecting?.();
      connectPage({
        botId,
        urlParams: {
          [ConnectPageField.origin]: ConnectPageOrigin.default,
        },
        onClose,
        onPageConnected: ({
          status,
          pageId: connectedPageId,
          verifiedPermissions,
        }) => {
          onConnected?.(status, connectedPageId);
          const controller = getFlowController();
          if (controller) {
            controller.updateFlowBuilderPage(
              status,
              connectedPageId,
              verifiedPermissions,
            );
          }
        },
      });
      return true;
    },
    [botId, isDefaultTestPage],
  );
};
