import React, { useCallback } from 'react';
import { Modal } from '@services/index';
import { PremiumPaymentDialog } from '@components/Payments';
import { PaymentFailureDialog } from '../dialogs';
import { PaymentDialogProps } from '@components/Payments/PaymentDialog/types';

export const useUpdatePremiumPaymentDialog = () => {
  const showPaymentUpdateDialog = useCallback(
    (setupPayment?: PaymentDialogProps['setupPayment']) =>
      Modal.show(
        ({ close, resolve }) => (
          <PremiumPaymentDialog
            onSuccess={resolve}
            onClose={close}
            renderPaymentError={(props) => <PaymentFailureDialog {...props} />}
            setupPayment={setupPayment}
          />
        ),
        {
          id: 'ShowPremiumPaymentUpdateDialog',
          mobileAdaptive: true,
        },
      ),
    [],
  );

  return showPaymentUpdateDialog;
};
