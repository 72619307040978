import React from 'react';
import { Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-common';
import ng from 'angular';
import { react2angular } from 'react2angular';
import {
  ButtonPopupBlocksSelector,
  ButtonTabBlockSelectorProps,
} from './ButtonPopupBlocksSelector';
import { globalHistory } from '../../../utils/Routing';
import client from '../../../common/services/ApolloService';

const ButtonPopupBlocksSelectorWithProviders: React.FC<ButtonTabBlockSelectorProps> =
  (props) => (
    <Router history={globalHistory}>
      <ApolloProvider client={client}>
        <ButtonPopupBlocksSelector {...props} />
      </ApolloProvider>
    </Router>
  );

export const ngButtonTabBlockSelector = ng
  .module('app.modernComponents.blocksSelector', [])
  .component(
    'blocksSelector',
    react2angular(
      ButtonPopupBlocksSelectorWithProviders,
      [
        'keyId',
        'onChange',
        'onBlocksTitlesChange',
        'autofocus',
        'validateOnBlur',
        'error',
        'blockId',
        'enablePortal',
        'placeholder',
      ],
      [],
    ),
  ).name;
