import * as ng from 'angular';
import { react2angular } from 'react2angular';
import { DeprecateMessage } from './DeprecateMessage';

export const ngDeprecateMessage = ng
  .module('app.pages.deprecateMessage', [])
  .component(
    'deprecateMessage',
    react2angular(DeprecateMessage, ['text', 'button', 'style']),
  ).name;
