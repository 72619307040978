import React from 'react';
import cn from 'classnames';
import * as css from './Circle.css';

export interface CircleProps {
  active: boolean;
  className?: string;
}

export const Circle: React.FC<CircleProps> = ({ active, className }) => (
  <span
    className={cn(className, css.circle, {
      [css.enabled]: active,
      [css.disabled]: !active,
    })}
  />
);
