import { Translation } from '@translations';
import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { TextArea } from '@ui/Input/TextArea';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { CancelProTranslation } from '../CancelProTranslation';
import css from './RateExperienceDialog.css';
import { LeaveReason } from '@globals';

interface RateExperienceProps {
  reasons: LeaveReason[];
  onClose(feedback: string, other: string): void;
}

export const RateExperienceDialog: React.FC<RateExperienceProps> = ({
  onClose,
  reasons,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const [feedback, setFeedback] = useState<string>('');
  const [other, setOther] = useState<string>('');
  const { t } = useSafeTranslation<Translation>();
  const hasOtherReason = reasons.includes(LeaveReason.other);

  const handleClose = () => {
    onClose(feedback, other);
  };

  useEffect(() => {
    sendEvent({
      category: 'churn survey',
      action: 'show feedback dialog',
      label: 'feedback dialog',
    });
  }, []);

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      className={cn(css.dialog, isSmallScreenSize && css.mobile)}
      header={
        <Type size="24px" weight={isSmallScreenSize ? 'semibold' : 'regular'}>
          <CancelProTranslation i18nKey="CancelPro.RateExperience.header" />
        </Type>
      }
      onDismiss={() => {
        handleClose();
        sendEvent({
          category: 'churn survey',
          action: 'close feedback dialog',
          label: 'feedback dialog',
        });
      }}
      footer={
        <Button
          className={cn(css.submit, isSmallScreenSize && css.mobile)}
          data-testid="churn-survey__rate-dialog__submit-button"
          onClick={() => {
            handleClose();
            sendEvent({
              category: 'churn survey',
              action: 'click submit button',
              label: 'feedback dialog',
              propertyBag: {
                feedback,
                other,
              },
            });
          }}
        >
          <CancelProTranslation i18nKey="CancelPro.RateExperience.submit" />
        </Button>
      }
    >
      {hasOtherReason && (
        <>
          <Type size="15px" weight="medium">
            <CancelProTranslation i18nKey="CancelPro.RateExperience.other" />
          </Type>
          <Spacer factor={3} />
          <TextArea
            data-testid="churn-survey__rate-dialog__feedback"
            placeholder={t('CancelPro.RateExperience.feedback')}
            value={other}
            onChange={(e) => setOther(e.currentTarget.value)}
          />
          <Spacer factor={6} />
        </>
      )}
      <Type size="15px" weight="medium">
        <CancelProTranslation
          i18nKey={`CancelPro.CancelProSubscription.leaveReasonDialogText.${
            reasons.length > 1 ? 'multi' : reasons[0]
          }`}
        />
      </Type>
      <Spacer factor={3} />
      <TextArea
        data-testid="churn-survey__rate-dialog__feedback"
        placeholder={t('CancelPro.RateExperience.feedback')}
        value={feedback}
        onChange={(e) => setFeedback(e.currentTarget.value)}
      />
    </DefaultDialog>
  );
};
