import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import React, { useEffect } from 'react';
import cn from 'classnames';
import { CancelProTranslation } from '../CancelProTranslation';
import { ReactComponent as DiscountSvg } from './discount.svg';
import css from './DiscountProDialog.css';

interface DiscountProProps {
  onDiscount(): void;
  onCancel(): void;
  onClose(): void;
}

export const DiscountProDialog: React.FC<DiscountProProps> = ({
  onDiscount,
  onCancel,
  onClose,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  useEffect(() => {
    sendEvent({
      category: 'churn survey',
      action: 'show discount pro dialog',
      label: 'discount pro dialog',
    });
  }, []);

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      className={cn(css.dialog, isSmallScreenSize && css.mobile)}
      header={null}
      onDismiss={() => {
        onClose();
        sendEvent({
          category: 'churn survey',
          action: 'close discount pro dialog',
          label: 'discount pro dialog',
        });
      }}
      footer={
        <Flex
          justifyContent="space-between"
          className={cn(css.footer, isSmallScreenSize && css.mobile)}
        >
          <Button
            data-testid="churn-survey__discount-dialog__cancel-anyway-button"
            className={css.cancel}
            onClick={() => {
              onCancel();
              sendEvent({
                category: 'churn survey',
                action: 'click cancel anyway',
                label: 'discount pro dialog',
              });
            }}
          >
            <CancelProTranslation i18nKey="CancelPro.DiscountPro.cancel" />
          </Button>
          <Spacer horizontalFactor={3} factor={2} />
          <div className={css.maxDiscountContainer}>
            <Button
              className={cn(css.apply, isSmallScreenSize && css.mobile)}
              data-testid="churn-survey__discount-dialog__apply-button"
              intent="secondary"
              onClick={() => {
                onDiscount();
                sendEvent({
                  category: 'churn survey',
                  action: 'click apply discount',
                  label: 'discount pro dialog',
                });
              }}
            >
              <CancelProTranslation i18nKey="CancelPro.DiscountPro.apply" />
            </Button>
            {/* TODO: implement maximum discount amount on backend
            <Type size="12px" color="white" className={css.maxDiscount}>
              <CancelProTranslation i18nKey="CancelPro.DiscountPro.maxDiscount" />
            </Type> */}
          </div>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <DiscountSvg />
        <Spacer factor={5} />
        <Type size="24px" weight="semibold" color="white">
          <CancelProTranslation i18nKey="CancelPro.DiscountPro.header" />
        </Type>
        <Spacer factor={2} />
        <Type size="15px" weight="medium" color="white">
          <CancelProTranslation i18nKey="CancelPro.DiscountPro.description" />
        </Type>
      </Flex>
    </DefaultDialog>
  );
};
