import React, { ReactElement, ReactNode } from 'react';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button, ButtonProps } from '@ui/Button';
import * as css from './DefaultPricingDialog.css';
import { DefaultDialog } from '@ui/Dialog';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';

interface DefaultPricingDialogProps {
  title: string;
  description: ReactNode;
  button: ReactElement;
  icon: ReactElement;
  onClose(): void;
}

export const DefaultPricingBtn: React.FC<ButtonProps> = (props) => {
  const { getValueDueToSize } = useDeviceMedia();
  return (
    <Button
      {...props}
      className={getValueDueToSize(css.btnMobile, undefined)}
    />
  );
};

export const DefaultPricingDialog: React.FC<DefaultPricingDialogProps> = ({
  button,
  description,
  icon,
  title,
  onClose,
}) => {
  const { isSmallScreenSize, getValueDueToSize } = useDeviceMedia();
  return (
    <DefaultDialog
      header={null}
      mobileAdaptive={isSmallScreenSize}
      className={getValueDueToSize(css.pricingDialogMobile, css.pricingDialog)}
      onDismiss={onClose}
    >
      {!isSmallScreenSize && <Spacer factor={3} />}
      <div style={{ textAlign: 'center' }}>{icon}</div>
      <Spacer factor={getValueDueToSize(3, 6)} />
      <Type size="24px" weight="semibold">
        {title}
      </Type>
      <Spacer factor={getValueDueToSize(2, 4)} />
      <Type size="15px" whiteSpace="pre-wrap">
        {description}
      </Type>
      <Spacer />
      {button}
      <Spacer factor={6} />
    </DefaultDialog>
  );
};
