import React from 'react';
import cn from 'classnames';
import { ReactComponent as AddIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_add_smallest.svg';
import { GroupType } from '../Mutations/GroupMutations';
import * as css from './AddGroup.css';
import { AddGroupDropDown } from './AddGroupDropDown';

export interface IAddGroupProps extends React.HTMLProps<HTMLDivElement> {
  onCreateGroupRequest: (groupType: GroupType, index?: number) => void;
}

export const AddGroup: React.SFC<IAddGroupProps> = ({
  onCreateGroupRequest,
  className,
  ...props
}) => (
  <div className={cn(css.AddGroup, className)}>
    <AddGroupDropDown
      anchorElementRender={(getToggleButtonProps) => (
        <div
          {...getToggleButtonProps(props as any)}
          className={css.button}
          data-testid="automate-aside__add-seq-group-button"
        >
          <AddIcon className={css.icon} />
          <span>
            {window.i18next.t('AddGroup-JSXText--210-add-sequence-or-group')}
          </span>
        </div>
      )}
      onSelect={onCreateGroupRequest}
    />
  </div>
);
