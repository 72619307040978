import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as AlertIcon } from '../assets/Alert.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';

interface PlanPausedDialogProps {
  onDone(): void;
  onDismiss(): void;
}

export const PlanPausedDialog: React.FC<PlanPausedDialogProps> = ({
  onDone,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  return (
    <DefaultPricingDialog
      title={t('pages.Pricing.dialogs.PlanPausedDialog.title')}
      icon={<AlertIcon />}
      description={t('pages.Pricing.dialogs.PlanPausedDialog.description')}
      button={
        <DefaultPricingBtn intent="secondary" onClick={onDone}>
          {t('pages.Pricing.dialogs.PlanPausedDialog.button')}
        </DefaultPricingBtn>
      }
      onClose={onDismiss}
    />
  );
};
