import React, { useState } from 'react';
import cn from 'classnames';
import { PhoneInput } from '../../../modern-ui/PhoneInput/PhoneInput';
import { ButtonErrors } from '../common/validation';
import * as commonCss from '../common/ButtonPopupCommon.css';
import * as css from './ButtonPopupPhoneCallTab.css';

interface ButtonPhoneCallTabConfig {
  phone_number: string;
}

interface ButtonPopupPhoneCallTabProps {
  defaultValue: string;
  onChange: (config: ButtonPhoneCallTabConfig) => void;
  error: ButtonErrors[];
}

const addPlusToNumber = (number: string) => {
  return number === undefined ?
    '' :
    (number[0] === '+' ? number : `+${number}`);
};

export const ButtonPopupPhoneCallTab: React.FC<ButtonPopupPhoneCallTabProps> = (
  props,
) => {
  const [value, setValue] = useState<string>(
    addPlusToNumber(props.defaultValue),
  );
  const [phoneError, setPhoneError] = useState<boolean>(
    props.error && props.error.indexOf(ButtonErrors.phoneCall) !== -1,
  );

  return (
    <div className={cn(commonCss.buttonPopupTab, css.phoneCallTab)}>
      <PhoneInput
        defaultValue={value}
        onFocus={() => setPhoneError(false)}
        onChange={(value) => setValue(value)}
        onBlur={() => {
          props.onChange({ phone_number: value });
          setPhoneError(value.length === 0);
        }}
        error={phoneError}
      />
    </div>
  );
};
