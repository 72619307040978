import ng from 'angular';
import { react2angular } from 'react2angular';
import { ButtonPopupPhoneCallTab } from './ButtonPopupPhoneCallTab';

export const ngButtonPopupPhoneCallTab = ng
  .module('app.modernComponents.buttonPopupPhoneCallTab', [])
  .component(
    'buttonPopupPhoneCallTab',
    react2angular(
      ButtonPopupPhoneCallTab,
      ['defaultValue', 'onChange', 'error'],
      [],
    ),
  ).name;
