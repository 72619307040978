import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { ReactComponent as RocketIcon } from '../assets/Rocket.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';

interface PlanUnpausedDialogProps {
  onDone(): void;
  onDismiss(): void;
}

export const PlanUnpausedDialog: React.FC<PlanUnpausedDialogProps> = ({
  onDone,
  onDismiss,
}) => {
  const { t } = useSafeTranslation();
  return (
    <DefaultPricingDialog
      title={t('pages.Pricing.dialogs.PlanUnpausedDialog.title')}
      icon={<RocketIcon />}
      description={t('pages.Pricing.dialogs.PlanUnpausedDialog.description')}
      button={
        <DefaultPricingBtn intent="secondary" onClick={onDone}>
          {t('pages.Pricing.dialogs.PlanUnpausedDialog.button')}
        </DefaultPricingBtn>
      }
      onClose={onDismiss}
    />
  );
};
