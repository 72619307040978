import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AudienceSyncState } from '../../AudienceSyncContext';
import { useAudienceSyncState } from '../../hooks/useAudienceSyncState';
import { Banner, ToggleFunction } from '../Banner';
import {
  SyncPrepareBanner,
  SyncPreviewBanner,
  SyncDialog,
  SyncPrepareAddAdAccountDialog,
  SyncPrepareNeedTermsDialog,
  SyncPrepareSetUpAccountDialog,
} from './parts';
import {
  useFacebookAdAccounts,
  useFacebookSyncedSegments,
} from '../../../../utils/MarketingApi';
import {
  useServerStorage,
  ServerStorageItemKeys,
} from '../../../../utils/ServerStorage';

interface SyncBannerProps {
  botId: string;
}

export const SyncBanner: React.FC<SyncBannerProps> = ({ botId }) => {
  const { state, loading } = useAudienceSyncState();
  const { facebookAdAccounts, loading: facebookAdAccountsLoading } =
    useFacebookAdAccounts();
  const { loading: syncedSegmentsLoading } = useFacebookSyncedSegments(botId);
  const [, , { loading: facebookSegmentsIdsWithShownFbAlertLoading }] =
    useServerStorage<string[]>(
      ServerStorageItemKeys.facebookSegmentsIdsWithShownFbAlert,
    );

  const bannerLoading =
    loading ||
    facebookAdAccountsLoading ||
    syncedSegmentsLoading ||
    (state === AudienceSyncState.allowed &&
      facebookSegmentsIdsWithShownFbAlertLoading);

  return (
    <Banner
      loading={bannerLoading}
      renderDialog={(toggleDialog) => {
        const handleDismiss = () => toggleDialog(false);

        switch (state) {
          case AudienceSyncState.needTerms:
            return (
              <SyncPrepareNeedTermsDialog
                botId={botId}
                onDismiss={handleDismiss}
              />
            );
          case AudienceSyncState.allowed:
            return <SyncDialog botId={botId} onDismiss={handleDismiss} />;
          case AudienceSyncState.needAdAccount:
          default:
            if (!facebookAdAccounts.length) {
              return (
                <SyncPrepareAddAdAccountDialog
                  botId={botId}
                  onDismiss={handleDismiss}
                />
              );
            }
            return (
              <SyncPrepareSetUpAccountDialog
                botId={botId}
                onDismiss={handleDismiss}
              />
            );
        }
      }}
    >
      {(toggleDialog) => (
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        <SyncBannerContent
          botId={botId}
          bannerState={state}
          toggleDialog={toggleDialog}
        />
      )}
    </Banner>
  );
};

interface AudienceSyncSharedLocationState {
  audienceSyncBannerOpened: boolean;
}

interface SyncBannerContentProps {
  botId: string;
  bannerState: AudienceSyncState;
  toggleDialog: ToggleFunction;
}

const SyncBannerContent: React.FC<SyncBannerContentProps> = ({
  botId,
  bannerState,
  toggleDialog,
}) => {
  const {
    replace,
    location: { state, pathname },
  } = useHistory<AudienceSyncSharedLocationState>();
  const shouldOpenDialog = state?.audienceSyncBannerOpened;
  useEffect(() => {
    if (shouldOpenDialog) {
      toggleDialog(true);
      replace(pathname);
    }
  }, [shouldOpenDialog]); // eslint-disable-line react-hooks/exhaustive-deps

  switch (bannerState) {
    case AudienceSyncState.allowed:
      return <SyncPreviewBanner botId={botId} toggleDialog={toggleDialog} />;
    default:
      return <SyncPrepareBanner toggleDialog={toggleDialog} />;
  }
};
