import React from 'react';
import { ShowNTimes } from '@utils/ShowNTimes';
import * as css from './ButtonPopupCommon.css';
import { HoverDisclosure } from '@ui/HoverDisclosure';
import { ButtonPopupActionMenu } from './ButtonPopupActionMenu';
import { ButtonPopupActionAdder } from './ButtonPopupActionAdder';
import { Loader } from '@ui/Loader';
import { Flex } from '@ui/Flex';
import { ServerStorageItemKeys } from '@utils/ServerStorage';

interface ButtonTabActionItem {
  id: string;
  title: string;
}

interface ButtonTabCommonProps {
  botId: string;
  initActions: () => string[];
  actionConfig: { [key: string]: ButtonTabActionItem };
  getActionItem: (action: string) => React.ReactNode | null;
  onActionDelete: (type: string) => void;
  onActionsChange?: (actions: string[]) => void;
  children: (params: { show: boolean }) => React.ReactNode | null;
  onTutorialShow?: (status: boolean) => void;
  actionFilter?: (key: string) => boolean;
  showAdditionalActions?: boolean;
}

interface ButtonTabCommonState {
  actions: string[];
}

export class ButtonPopupCommon extends React.Component<
  ButtonTabCommonProps,
  ButtonTabCommonState
> {
  state = {
    actions: [] as string[],
  };

  componentDidMount(): void {
    this.setState({ actions: this.props.initActions() });
  }

  componentDidUpdate(
    _: Readonly<ButtonTabCommonProps>,
    prevState: Readonly<ButtonTabCommonState>,
  ): void {
    if (
      prevState.actions.length !== this.state.actions.length &&
      this.props.onActionsChange
    ) {
      this.props.onActionsChange(this.state.actions);
    }
  }

  filterButtonConfig: () => { [key: string]: ButtonTabActionItem } = () => {
    return Object.keys(this.props.actionConfig)
      .filter((key) => this.props.actionFilter && this.props.actionFilter(key))
      .reduce(
        (acc, key) =>
          Object.assign(acc, { [key]: this.props.actionConfig[key] }),
        {},
      );
  };

  getRemainingActions: () => string[] = () =>
    Object.values(this.filterButtonConfig())
      .map((item) => item.id)
      .filter((ActionKey) => !this.state.actions.includes(ActionKey));

  onActionAdd: (action: ButtonTabActionItem | null) => void = (action) => {
    if (action !== null) {
      this.setState((prevState) => ({
        actions: [...prevState.actions, action.id],
      }));
    }
  };

  render() {
    return (
      <div className={css.buttonPopupTab}>
        <ShowNTimes
          showLimit={5}
          storageKey={ServerStorageItemKeys.countOfOneBlockSelectorPopup}
        >
          {({ show: showTutorial, loading }) => {
            const showAdditionalActions =
              !showTutorial && this.props.showAdditionalActions;

            return loading ? (
              <div className={css.loaderWrapper}>
                <Loader />
              </div>
            ) : (
              <>
                <div className={showTutorial ? '' : css.actionItem}>
                  {this.props.children({ show: showTutorial })}
                </div>

                {showAdditionalActions &&
                  this.state.actions.map((action, index) => (
                    <HoverDisclosure
                      key={action}
                      render={({ isVisible, bind }) => (
                        <div className={css.actionItem}>
                          <div {...bind} className={css.actionTitle}>
                            <span>{`${index + 2}. ${
                              this.props.actionConfig[action].title
                            }`}</span>
                            <ButtonPopupActionMenu
                              hovered={isVisible}
                              onItemDelete={() => {
                                this.props.onActionDelete(action);
                                this.setState((prevState) => ({
                                  actions: prevState.actions.filter(
                                    (_, i) => i !== index,
                                  ),
                                }));
                              }}
                            />
                          </div>
                          {this.props.getActionItem(action)}
                        </div>
                      )}
                    />
                  ))}

                {showAdditionalActions ? (
                  <Flex alignItems="flex-start" className={css.addActionLine}>
                    <Flex alignItems="center">
                      {this.state.actions.length === 0 && (
                        <span className={css.addActionNumber}>
                          {`${this.state.actions.length + 2}.`}
                        </span>
                      )}
                      <ButtonPopupActionAdder
                        items={this.getRemainingActions().map(
                          (action) => this.props.actionConfig[action],
                        )}
                        onChange={this.onActionAdd}
                        disabled={this.getRemainingActions().length === 0}
                      />
                    </Flex>
                  </Flex>
                ) : (
                  <div style={{ height: '32px' }} />
                )}
              </>
            );
          }}
        </ShowNTimes>
      </div>
    );
  }
}
