import { TFunction } from 'i18next';
import { ListItem } from '@ui/ChooseFromListPopup';
import { MessageTagsAndOtn_messageTags } from './@types/MessageTagsAndOtn';
import {
  NON_PROMOTIONAL_SUBSCRIPTION_ID,
  NTN_GROUP_ID,
  OTN_GROUP_ID,
  TAG_DEFAULT_ORDER,
  TAG_ID_TO_TAG_MAP,
  TAG_ORDER,
} from './TagsData';

const isNonPromoSubscriptionDisabledTag = (
  tag: MessageTagsAndOtn_messageTags,
) => tag.tag === NON_PROMOTIONAL_SUBSCRIPTION_ID && tag.disabled;
const getListItemFromTag = (
  tag: MessageTagsAndOtn_messageTags,
  t: TFunction,
): ListItem | undefined => {
  const listItemTag = TAG_ID_TO_TAG_MAP(t).get(tag.tag);
  const order = TAG_ORDER[tag.tag] ?? TAG_DEFAULT_ORDER;
  return listItemTag && !isNonPromoSubscriptionDisabledTag(tag)
    ? {
        ...listItemTag,
        disabled: !!tag.disabled,
        order,
      }
    : undefined;
};
export const getFilteredAndSortedTagList = (
  data: MessageTagsAndOtn_messageTags[],
  t: TFunction,
) =>
  data
    .reduce((tags: ListItem[], tag) => {
      const listItem = getListItemFromTag(tag, t);
      return listItem ? [...tags, listItem] : tags;
    }, [])
    .sort((tagA: ListItem, tagB: ListItem) => tagA.order! - tagB.order!);

export const getInitialSelectedId = (
  selectedTagId?: string,
  selectedPurpose?: string,
  selectedTopicId?: string,
) =>
  selectedPurpose
    ? OTN_GROUP_ID
    : selectedTopicId
    ? NTN_GROUP_ID
    : selectedTagId;
