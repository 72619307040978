/* eslint-disable react/no-danger */
import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { useHistory } from 'react-router-dom';
import cn from 'classnames';

import { sendEvent } from '@utils/Analytics';
import { BotStatus, getBotStatus } from '@utils/Bot';
import { Anchor } from '@ui/Links';
import { BotTabs, getTabLink } from '@utils/Routing';
import { BindBotToPageMutation_bindBotToPage_verifiedPermissions } from '@utils/FacebookPages/@types/BindBotToPageMutation';
import { PermissionGroup } from '@utils/Permissions';
import { useBindBotToPage } from '@utils/FacebookPages/useBindBotToPage';
import { useUnbindPage } from '@utils/FacebookPages/useUnbindPage';

import { Button, ButtonUnstyled } from '@ui/Button';
import { Flex } from '@ui/Flex';
import { CenteredLoader } from '@ui/Loader';
import { ScrollBox } from '@ui/ScrollBox';
import { Spacer } from '@ui/Spacer';
import { TextEllipsis } from '@ui/TextEllipsis';
import { Type } from '@ui/Type';
import { Tooltip2 } from '@ui/Tooltip2';
import { Icon } from '@ui/Icon';
import { FacebookPageTitle } from '@components/FacebookPageTitle';

import { useRefetchAdminData } from '@utils/Admin/useRefetchAdminData';
import { FacebookPagesHookPayload } from '@utils/FacebookPages/useFacebookPages';
import { facebookPagesListFragment_pages } from '@utils/FacebookPages/@types/facebookPagesListFragment';
import { FacebookPageArchivingStatus } from '../FacebookPageArchivingStatus';
import { FacebookPagesFooter } from '../FacebookPagesFooter';

import { useShowDisconnectPageDialog } from './hooks';

import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { showIntercom } from '@utils/Intercom';
import * as css from './FacebookPagesList.css';
import { FacebookPageStatus } from './types';
import { useBotWorkspaceId } from '@utils/Data/Bot/useBotWorkspaceId';
import { getPageContentStatus } from './utils';

interface FacebookPagesListProps extends FacebookPagesHookPayload {
  botId: string;
  onPageConnectSucceed(
    status: BotStatus,
    pageId?: string | null,
    verifiedPermissions?: BindBotToPageMutation_bindBotToPage_verifiedPermissions[],
  ): void;
  onPageConnectFailed(error: string): void;
  onPageConnect(pageId: string): void;
  onConnectedBotClick?(): void;
  requestPermissionOnBind?: PermissionGroup[];
}

const getFacebookPageStatus = (page: facebookPagesListFragment_pages) => {
  if (!page.bot_id && !page.archiving_status) {
    return FacebookPageStatus.notConnected;
  }
  if (!page.bot_id && page.archiving_status) {
    return FacebookPageStatus.archived;
  }
  if (page.currentUserBot) {
    return FacebookPageStatus.connectedToOtherBot;
  }
  if (page.bot_id && !page.currentUserBot) {
    return FacebookPageStatus.connectedToUnknownBot;
  }
  return FacebookPageStatus.unknown;
};

export const FacebookPagesList: React.FC<FacebookPagesListProps> = ({
  botId,
  onPageConnectSucceed,
  onPageConnectFailed,
  onPageConnect,
  onConnectedBotClick,
  requestPermissionOnBind,
  facebookPagesData,
  facebookPagesRefetch,
  facebookPagesLoading,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  const { t } = useSafeTranslation();
  const history = useHistory();
  const refetchAdminData = useRefetchAdminData();
  const [workspaceId] = useBotWorkspaceId(botId);

  const { bindBotToPage, bindBotToPageLoading } = useBindBotToPage({
    botId,
    onCompleted(bot) {
      onPageConnectSucceed(
        getBotStatus(bot),
        bot.status?.page,
        bot.verifiedPermissions ?? undefined,
      );
    },
    onError: onPageConnectFailed,
  });

  const { unbindPageLoading } = useUnbindPage(botId);
  const { showDisconnectPageDialog } = useShowDisconnectPageDialog();

  const { pages } = facebookPagesData || {};

  if (bindBotToPageLoading || unbindPageLoading || facebookPagesLoading) {
    return (
      <>
        <CenteredLoader />
        <Spacer factor={8} />
      </>
    );
  }

  return (
    <>
      <Type size="24px" weight="semibold">
        {t('modernComponents.FacebookPagesSetup.pagesList.title')}
      </Type>
      <Spacer factor={8} />
      <div>
        {pages?.length === 0 && (
          <Flex
            justifyContent="center"
            alignItems="center"
            className={cn(!isSmallScreenSize && css.box)}
          >
            <Type weight="medium" color="black" size="15px">
              {t('modernComponents.FacebookPagesSetup.pagesList.nothingFound')}
            </Type>
          </Flex>
        )}
        {pages && (
          <ScrollBox className={cn(!isSmallScreenSize && css.scrollBox)}>
            {pages?.map((page, index) => {
              const pageStatus = getFacebookPageStatus(page);
              const pageContentStatus = getPageContentStatus(
                page,
                pageStatus,
                workspaceId,
              );
              const titleWidthNarrow =
                pageStatus === FacebookPageStatus.connectedToUnknownBot ||
                pageStatus === FacebookPageStatus.notConnected;
              return (
                <Flex
                  // eslint-disable-next-line react/no-array-index-key
                  key={`${page.id}_${index}`}
                  justifyContent="space-between"
                  alignItems="center"
                  className={css.pageItem}
                  data-testid={`facebook-pages__page-container__${page.id}`}
                >
                  <FacebookPageTitle
                    page={page}
                    avatarSize={36}
                    titleWidthNarrow={titleWidthNarrow}
                    titleWeight="regular"
                    titleSize="15px"
                    botInfo={
                      <>
                        {pageStatus ===
                          FacebookPageStatus.connectedToUnknownBot && (
                          <>
                            <Spacer factor={1} />
                            <Flex>
                              <Type size="12px">
                                {t(
                                  'modernComponents.FacebookPagesSetup.pagesList.connectedToOtherBot',
                                )}
                              </Type>
                            </Flex>
                          </>
                        )}
                        {pageStatus ===
                          FacebookPageStatus.connectedToOtherBot && (
                          <Flex data-testid="facebook-pages__connected-to">
                            <ButtonUnstyled
                              onClick={() => {
                                sendEvent({
                                  category: 'connect facebook page dialog',
                                  action: 'click bot title',
                                });
                                onConnectedBotClick?.();
                                history.push(
                                  getTabLink(
                                    BotTabs.flows,
                                    page.currentUserBot?.id!,
                                  ),
                                );
                              }}
                              className={css.botTitleContainer}
                            >
                              <TextEllipsis>
                                <Anchor intent="internal" hideArrow>
                                  <Type size="12px">
                                    {page.currentUserBot?.title}
                                  </Type>
                                </Anchor>
                              </TextEllipsis>
                            </ButtonUnstyled>
                          </Flex>
                        )}
                      </>
                    }
                  />

                  {pageContentStatus === FacebookPageStatus.alreadyOnPro && (
                    <>
                      <Spacer factor={1} />
                      <Flex justifyContent="center" alignItems="center">
                        <Type size="15px">
                          {t(
                            'modernComponents.FacebookPagesSetup.pagesList.cantConnect',
                          )}
                        </Type>
                        <Spacer horizontalFactor={2} />
                        <Tooltip2
                          content={
                            <>
                              {t(
                                'modernComponents.FacebookPagesSetup.pagesList.alreadyOnPro',
                              )}
                              <br />
                              <Anchor
                                intent="tooltip"
                                onMouseDown={() => {
                                  sendEvent({
                                    category: 'connect facebook page dialog',
                                    action: 'get support',
                                    label: 'already on pro',
                                  });
                                  showIntercom();
                                }}
                              >
                                <Type size="12px" color="white">
                                  {t(
                                    'modernComponents.FacebookPagesSetup.pagesList.getSupport',
                                  )}
                                </Type>
                              </Anchor>
                            </>
                          }
                          hoverable
                        >
                          {(ref, bind) => (
                            <Icon {...bind} ref={ref} icon="info" size="20px" />
                          )}
                        </Tooltip2>
                      </Flex>
                    </>
                  )}

                  {pageContentStatus ===
                    FacebookPageStatus.connectedToWorkspace && (
                    <>
                      <Spacer factor={1} />
                      <Flex justifyContent="center" alignItems="center">
                        <Type size="15px">
                          {t(
                            'modernComponents.FacebookPagesSetup.pagesList.cantConnect',
                          )}
                        </Type>
                        <Spacer horizontalFactor={2} />
                        <Tooltip2
                          content={t(
                            'modernComponents.FacebookPagesSetup.pagesList.connectedToWorkspace',
                          )}
                        >
                          {(ref, bind) => (
                            <Icon {...bind} ref={ref} icon="info" size="20px" />
                          )}
                        </Tooltip2>
                      </Flex>
                    </>
                  )}

                  {pageContentStatus === FacebookPageStatus.notConnected && (
                    <Button
                      intent="primary"
                      className={css.button}
                      onClick={() => {
                        bindBotToPage(page.id, requestPermissionOnBind ?? []);
                        onPageConnect(page.id);
                      }}
                      data-testid="facebook-pages__connect-button"
                    >
                      {t(
                        'modernComponents.FacebookPagesSetup.pagesList.connect',
                      )}
                    </Button>
                  )}
                  {pageContentStatus === FacebookPageStatus.archived && (
                    <FacebookPageArchivingStatus page={page} />
                  )}
                  {(pageContentStatus ===
                    FacebookPageStatus.connectedToUnknownBot ||
                    pageContentStatus ===
                      FacebookPageStatus.connectedToOtherBot) && (
                    <Flex alignItems="center">
                      <Button
                        intent="secondary"
                        className={css.button}
                        onClick={() => {
                          sendEvent({
                            category: 'connect facebook page dialog',
                            action: 'disconnect from another bot',
                          });
                          showDisconnectPageDialog(page.id);
                        }}
                        data-testid="facebook-pages__disconnect-button"
                      >
                        {t(
                          'modernComponents.FacebookPagesSetup.pagesList.disconnect',
                        )}
                      </Button>
                    </Flex>
                  )}
                </Flex>
              );
            })}
          </ScrollBox>
        )}
      </div>
      <FacebookPagesFooter
        facebookPagesRefetch={facebookPagesRefetch}
        hasFacebookPages={!!facebookPagesData?.pages?.length}
        showCreatePageButton
        onReturnAfterCreatePage={() => {
          facebookPagesRefetch();
          refetchAdminData();
        }}
      />
    </>
  );
};
