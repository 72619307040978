import { Callout } from '@ui/Callout';
import { CheckBox } from '@ui/CheckBox';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import { Button } from '@ui/Button';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import { CancelProTranslation } from '../CancelProTranslation';
import css from './CancelProSubscriptionDialog.css';
import { Spacer } from '@ui/Spacer';
import { LeaveReason } from '@globals';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { useCurrentBot } from '@utils/Data/Bot';
import { getLeaveReasonList } from './helpers/getLeaveReasonList';

export type SelectedLeaveReason = {
  [key in LeaveReason]?: true;
};

interface CancelProSubscriptionProps {
  cancelingPaywallCard: boolean;
  onSubmit(selectedLeaveReason: SelectedLeaveReason): void;
  onClose(): void;
}

export const CancelProSubscriptionDialog: React.FC<CancelProSubscriptionProps> =
  ({ cancelingPaywallCard, onSubmit, onClose }) => {
    const [selectedReasons, setSelectedReasons] = useState<SelectedLeaveReason>(
      {},
    );
    const { isSmallScreenSize } = useDeviceMedia();
    const { bot } = useCurrentBot();

    const eventsLabel = cancelingPaywallCard
      ? 'cancel paywall dialog'
      : 'cancel pro dialog';

    useEffect(() => {
      sendEvent({
        category: 'churn survey',
        action: 'show cancel pro dialog',
        label: eventsLabel,
      });
    }, [eventsLabel]);

    return (
      <DefaultDialog
        mobileAdaptive={isSmallScreenSize}
        className={cn(css.dialog, isSmallScreenSize && css.mobile)}
        header={
          <Type
            size="24px"
            weight={isSmallScreenSize ? 'semibold' : 'regular'}
            color="black"
          >
            <CancelProTranslation i18nKey="CancelPro.CancelProSubscription.header" />
          </Type>
        }
        onDismiss={() => {
          onClose();
          sendEvent({
            category: 'churn survey',
            action: 'close cancel pro dialog',
            label: eventsLabel,
          });
        }}
        footer={
          <Flex
            className={cn(css.footer, isSmallScreenSize && css.mobile)}
            justifyContent="space-between"
          >
            <Button
              data-testid="churn-survey__cancel-dialog__keep-button"
              intent="primary"
              onClick={() => {
                onClose();
                sendEvent({
                  category: 'churn survey',
                  action: 'click keep subscription',
                  label: eventsLabel,
                  propertyBag: {
                    selectedReasons: Object.keys(selectedReasons),
                  },
                });
              }}
            >
              <CancelProTranslation i18nKey="CancelPro.CancelProSubscription.keep" />
            </Button>
            {isSmallScreenSize && <Spacer factor={2} />}
            <Button
              data-testid="churn-survey__cancel-dialog__cancel-button"
              intent="secondary"
              disabled={!Object.keys(selectedReasons).length}
              onClick={() => {
                onSubmit(selectedReasons);
                sendEvent({
                  category: 'churn survey',
                  action: 'click cancel subscription',
                  label: eventsLabel,
                });
              }}
            >
              <CancelProTranslation i18nKey="CancelPro.CancelProSubscription.cancel" />
            </Button>
          </Flex>
        }
      >
        <Callout
          type="info"
          className={cn(css.callout, isSmallScreenSize && css.mobile)}
          text={
            <CancelProTranslation i18nKey="CancelPro.CancelProSubscription.cancelingInfo" />
          }
        />
        <Spacer factor={6} />
        <Type size="15px" weight="medium">
          <CancelProTranslation i18nKey="CancelPro.CancelProSubscription.leaveReasonTitle" />
        </Type>
        {getLeaveReasonList(bot?.allowedPlatforms).map((leaveReason) => (
          <label
            key={leaveReason}
            className={css.listItem}
            htmlFor={leaveReason}
          >
            <CheckBox
              data-testid={`churn-survey__leave-reason__${leaveReason}`}
              id={leaveReason}
              checked={selectedReasons[leaveReason]}
              onChange={() =>
                setSelectedReasons((state) => {
                  const newState = { ...state };
                  if (state[leaveReason]) delete newState[leaveReason];
                  else newState[leaveReason] = true;

                  return newState;
                })
              }
            />
            <Spacer factor={0} horizontalFactor={2} />
            <Type size="15px">
              <CancelProTranslation
                i18nKey={`CancelPro.CancelProSubscription.leaveReasonList.${leaveReason}`}
              />
            </Type>
          </label>
        ))}
      </DefaultDialog>
    );
  };
