import React, { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { DefaultDialog, DefaultDialogProps } from '@ui/Dialog';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import * as css from './ChooseTemplateDialog.css';
import { useWhatsappTemplates } from '@components/ChooseWhatsappTempalteDialog/hooks/useWhatsappTemplates';
import { ChooseTemplateContent, ChooseTemplateEmptyState } from './components';
import { OnboardingTourHTMLElementId } from '@globals';
import { useGetMessageTemplates } from '@pages/BotPage/MessagesTemplatesTab/data/useGetMessageTemplates';
import { useCreateWhatsappTemplateInCfEnable } from '@utils/Whatsapp/hooks/useCreateWhatsappTemplateInCfEnable';
import { Template } from './types';
import { BotTabs, getTabLink, useCurrentBotId } from '@utils/Routing';

const logger = (action: string, propertyBag?: Object) =>
  sendEvent({
    category: 'whatsapp template modal',
    action,
    propertyBag,
  });

export interface ChooseTemplateDialogProps
  extends Pick<DefaultDialogProps, 'onDismiss'> {
  onSubmit: (
    templateId: string | undefined,
    managedTemplateId: string | undefined,
  ) => void;
  loading?: boolean;
  initTemplateId?: string;
}

export const ChooseTemplateDialog: React.FC<ChooseTemplateDialogProps> = ({
  loading,
  initTemplateId,
  onSubmit,
  onDismiss,
}) => {
  const botId = useCurrentBotId();
  const { t } = useSafeTranslation();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState<string>('');
  const { isCreateWhatsappTemplateInCfEnable, loading: abtLoading } =
    useCreateWhatsappTemplateInCfEnable();
  const { fetchMore, search, ...whatsappTemplatesState } = useWhatsappTemplates(
    {
      skip: abtLoading || isCreateWhatsappTemplateInCfEnable,
    },
  );
  const messageTemplatesState = useGetMessageTemplates({
    skip: abtLoading || !isCreateWhatsappTemplateInCfEnable,
    fetchPolicy: 'network-only',
  });

  const {
    templates = [],
    loading: templatesLoading,
    refetch,
  } = isCreateWhatsappTemplateInCfEnable
    ? messageTemplatesState
    : whatsappTemplatesState;

  const templatesFiltered = useMemo(
    () =>
      (templates as Template[]).filter(({ name }) =>
        name.toLowerCase().includes(searchValue.toLowerCase()),
      ),
    [searchValue, templates],
  );

  const buttonDisabled = loading || templatesLoading || abtLoading;

  const noTemplates = !templatesLoading && templatesFiltered.length === 0;
  const showEmptyState = noTemplates && !searchValue;
  const isNothingFound = noTemplates && Boolean(searchValue);

  return (
    <DefaultDialog
      dialogId={OnboardingTourHTMLElementId.ChooseWATemplateDialog}
      header={
        isCreateWhatsappTemplateInCfEnable
          ? t('pages.BotPage.ReengageWhatsappTab.templateModal.header')
          : t('pages.BotPage.ReengageWhatsappTab.templateModal.header_old')
      }
      onDismiss={onDismiss}
      className={css.dialog}
    >
      {showEmptyState ? (
        isCreateWhatsappTemplateInCfEnable ? (
          <ChooseTemplateEmptyState
            header={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState.header',
            )}
            message={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState.createNew',
            )}
            buttonText={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState.action',
            )}
            onClick={() => {
              logger('go to templates click');
              history.push(getTabLink(BotTabs.messagesTemplates, botId));
              onDismiss?.();
            }}
          />
        ) : (
          <ChooseTemplateEmptyState
            header={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState_old.header',
            )}
            message={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState_old.createNew',
            )}
            buttonText={t(
              'pages.BotPage.ReengageWhatsappTab.templateModal.emptyState_old.refresh',
            )}
            onClick={() => {
              logger('refresh templates click');
              refetch();
            }}
          />
        )
      ) : (
        <ChooseTemplateContent
          initTemplateId={initTemplateId}
          disabled={buttonDisabled}
          onSubmit={(id) => {
            logger('submit click', { templateId: id });
            onSubmit(
              !isCreateWhatsappTemplateInCfEnable ? id : undefined,
              isCreateWhatsappTemplateInCfEnable ? id : undefined,
            );
          }}
          loading={templatesLoading || abtLoading}
          templates={templatesFiltered}
          isNothingFound={isNothingFound}
          fetchMore={
            isCreateWhatsappTemplateInCfEnable
              ? () => Promise.resolve()
              : fetchMore
          }
          searchValue={searchValue}
          setSearchValue={(value) => {
            setSearchValue(value);
            if (!isCreateWhatsappTemplateInCfEnable) {
              search(value);
            }
          }}
        />
      )}
    </DefaultDialog>
  );
};
