import React, { useEffect } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { log } from 'cf-common/src/logger';

import { sendEvent } from '@utils/Analytics';
import { getRequestIdFromApolloError } from '@utils/GQL/utils';
import { Messages, toaster } from '@services/MessageService';

import { Button } from '@ui/Button';
import { ReactComponent as InfoIcon } from '@ui/_deprecated/Icon/icons/error-info.svg';
import { CenteredLoader } from '@ui/Loader';
import { ServiceMessageType } from '@ui/ServiceMessage2';
import { ShimmerText } from '@ui/ShimmerText';
import { Type } from '@ui/Type';
import { useFacebookPageArchivingStatus } from './hooks';

import { PageArchivingStatus } from '@globals';
import {
  UnarchivePageMutation,
  UnarchivePageMutationVariables,
} from './@types/UnarchivePageMutation';

import * as css from './FacebookPageArchivingStatus.css';

interface Page {
  id: string;
  title: string;
}

export interface FacebookPageArchivingStatusProps
  extends React.HTMLProps<HTMLDivElement> {
  page: Page;
  onPageArchivingStatusChange?: (
    status: PageArchivingStatus | undefined,
  ) => void;
}

const UNARCHIVE_PAGE_MUTATION = gql`
  mutation UnarchivePageMutation($pageId: String!) {
    unarchivePage(pageId: $pageId) {
      id
      archiving_status
    }
  }
`;

export const FacebookPageArchivingStatus: React.FC<FacebookPageArchivingStatusProps> =
  ({ page, onPageArchivingStatusChange }) => {
    const { t } = useSafeTranslation();
    const { id: pageId, title: pageTitle } = page;

    const { archivingStatus, loading } = useFacebookPageArchivingStatus(pageId);

    const [unarchivePage] = useMutation<
      UnarchivePageMutation,
      UnarchivePageMutationVariables
    >(UNARCHIVE_PAGE_MUTATION, {
      variables: {
        pageId,
      },
      optimisticResponse: {
        unarchivePage: {
          __typename: 'Page',
          id: pageId,
          archiving_status: PageArchivingStatus.UNARCHIVING,
        },
      },
      onError: (error) => {
        log.error({
          error,
          msg: 'Unarchive page error',
          data: {
            label: 'fb_page',
            requestId: getRequestIdFromApolloError(error),
          },
        });
        toaster.show({
          type: ServiceMessageType.error,
          payload: {
            message: Messages.somethingWentWrong,
          },
        });
      },
    });

    useEffect(() => {
      if (loading) {
        return;
      }
      onPageArchivingStatusChange?.(archivingStatus || undefined);

      sendEvent({
        category: 'grow tools',
        action: 'page archiving status change',
        propertyBag: {
          'archiving status': archivingStatus,
        },
      });
    }, [archivingStatus, onPageArchivingStatusChange, loading]);

    if (loading) {
      return <CenteredLoader style={{ width: 152 }} />;
    }

    if (!archivingStatus) {
      return null;
    }

    return (
      <>
        {archivingStatus === PageArchivingStatus.ARCHIVED && (
          <Button
            data-testid="facebook-page__archiving-status__unarchive-button"
            intent="secondary"
            onClick={() => {
              unarchivePage();
              sendEvent({
                category: 'connect facebook page dialog',
                action: 'unarchive page',
                propertyBag: {
                  pageId,
                  pageTitle,
                },
              });
            }}
            style={{ width: 152 }}
          >
            {t('FacebookPageArchivingStatus-JSXText-1838-unarchive-page')}
          </Button>
        )}
        {archivingStatus === PageArchivingStatus.UNARCHIVING && (
          <ShimmerText play>
            <InfoIcon className={css.infoIcon} />
            <Type color="greyDark" size="15px_DEPRECATED">
              {t(
                'FacebookPageArchivingStatus-JSXText-1253-unarchiving-page-please-wait',
              )}
            </Type>
          </ShimmerText>
        )}
        {archivingStatus === PageArchivingStatus.ARCHIVING && (
          <ShimmerText play>
            <InfoIcon className={css.infoIcon} />
            <Type color="greyDark" size="15px_DEPRECATED">
              {t(
                'FacebookPageArchivingStatus-JSXText--174-archiving-page-please-wait',
              )}
            </Type>
          </ShimmerText>
        )}
      </>
    );
  };
