import React from 'react';
import { RadioButton, RadioButtonProps } from '@ui/Radio';
import { NameColumn } from './NameColumn';
import { LanguageColumn } from './LanguageColumn';
import { StatusColumn } from './StatusColumn';
import { TableRowBox } from './TableRowBox';
import { LANGUAGE_KEY_TO_NAME } from '@utils/internationalization';
import {
  getCategoryName,
  getStatusRepresentation,
} from '@utils/Whatsapp/Templates/utils';
import { Template } from '../../../types';

export interface TableRowProps extends RadioButtonProps {
  rowBoxId?: string;
  style: React.CSSProperties;
  rowData: Template;
}

export const TableRow: React.FC<TableRowProps> = ({
  rowBoxId,
  id,
  name,
  onChange,
  value,
  style,
  rowData,
}) => {
  const statusRepresentation = getStatusRepresentation(rowData);
  const category = rowData.category ? getCategoryName(rowData.category) : '';
  const languageName =
    // @ts-expect-error
    LANGUAGE_KEY_TO_NAME[rowData.language] || rowData.language;

  return (
    <TableRowBox id={rowBoxId} style={style}>
      <RadioButton
        name={name}
        id={id}
        onChange={onChange}
        value={value}
        renderLabel={() => (
          <NameColumn name={rowData.name} category={category} />
        )}
        data-testid="whatsapp-templates__radio-button"
      />

      <LanguageColumn language={languageName} />
      <StatusColumn {...statusRepresentation} />
    </TableRowBox>
  );
};
