import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';

import { Anchor } from '../../../../modern-ui/Links';
import { FB_POLICY_LINK, linkToDoc } from '../../TagsData';

import * as css from './PopupSubheading.css';

export const PopupSubheading = () => {
  const { t } = useSafeTranslation();

  return (
    <div className={css.popupSubHead}>
      <Anchor
        className={css.popupSubHeadLink}
        intent="external"
        hideArrow
        href={FB_POLICY_LINK}
        target="_blank"
        rel="noopener noreferrer"
      >
        {window.i18next.t(
          'PopupSubheading-JSXText-3507-messenger-platform-policy',
        )}
      </Anchor>{' '}
      {window.i18next.t(
        'PopupSubheading-JSXText--182-requires-each-message-to-be-labeled-with-the-appropriate-tag-refer-to-our',
      )}{' '}
      <Anchor
        className={css.popupSubHeadLink}
        intent="external"
        hideArrow
        href={linkToDoc(t)}
        target="_blank"
        rel="noopener noreferrer"
      >
        {window.i18next.t('PopupSubheading-JSXText-1331-messaging-guide')}
      </Anchor>{' '}
      {window.i18next.t('PopupSubheading-JSXText--192-for-more-details')}
    </div>
  );
};
