import React, { useEffect } from 'react';
import { Modal } from '@services/index';
import { useHistory, useLocation } from 'react-router-dom';
import { usePlanLimitReached } from '@utils/Data/Pricing';
import { sendEvent } from '@utils/Analytics';
import { PlanLimitReachedDialog } from '../dialogs';
import { BotTabs, getPlansPageUrl, useCurrentTab } from '@utils/Routing';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { isMobileDevice } from 'cf-common/src/utils/isMobileDevice';

let isShowedModal = false;

const MOBILE_ADAPTIVE_TABS = [
  BotTabs.home,
  BotTabs.grow,
  BotTabs.liveChat,
  BotTabs.broadcastPage,
  BotTabs.broadcastsIQ,
  BotTabs.broadcastsPage,
  BotTabs.createBroadcast,
];

/**
 * Opening modal when the free plan limit is reached
 */
export const usePlanLimitReachedDialog = () => {
  const history = useHistory();
  const { isSmallScreenSize } = useDeviceMedia();
  const tab = useCurrentTab();
  const searchParams = new URLSearchParams(useLocation().search);
  if (searchParams.get('planLimitModal') === 'disabled') {
    isShowedModal = true;
  }

  const {
    nextPlanText,
    nextPlanType,
    currentPlanText,
    currentPlanType,
    nextPlanLimit,
    planLimit,
    isLimitReached,
    botId,
    dialogsPricingEnabled,
    botStatus,
  } = usePlanLimitReached() ?? {};

  useEffect(() => {
    /**
     * Временный костыль. Может произойти баг, когда в мобильной модалке отрисовывается
     * десктопная версия модалки. Происходит это из-за того что, при открытии прилажухи
     * мы изначально предпологаем, что открыт десктоп, мобильную версию адаптивные табы
     * включают самостоятельно. Этот хук вызывается еще до того как отрендерилась таба
     * и включилась мобильная версия, поэтому могут происходить моргания (десктопная
     * версия перерисовалась на мобилку)
     *
     * Поэтому нужна проверка, если таба адаптивная, но по какой то причине isMobileDevice
     * (проверка на юзер агент) не равна isSmallScreenSize (проверка на размер экрана),
     * то таба адаптивная, но еще не отрендерилась чтобы переключиться на мобильную версию
     *
     * Костыль можно удалить когда все табы будут адаптивны.
     */
    if (
      MOBILE_ADAPTIVE_TABS.includes(tab as BotTabs) &&
      isSmallScreenSize !== isMobileDevice()
    ) {
      return;
    }

    if (
      !isShowedModal &&
      currentPlanText &&
      nextPlanText &&
      nextPlanType &&
      isLimitReached
    ) {
      isShowedModal = true;

      sendEvent({
        category: 'billing',
        action: 'show update message',
        label: 'plan limit reached',
        propertyBag: {
          dialogsPricingEnabled,
          botId,
          botStatus,
          sourcePage: 'plan limit modal',
          currentTier: currentPlanType,
        },
      });

      Modal.show(
        ({ close }) => {
          const handleSuccess = () => {
            sendEvent({
              category: 'billing',
              action: 'go to plan update page',
              label: 'plan limit reached',
              propertyBag: {
                dialogsPricingEnabled,
                botId,
                botStatus,
                sourcePage: 'plan limit modal',
                currentTier: currentPlanType,
              },
            });
            close();
            if (botId)
              history.push(
                getPlansPageUrl(
                  botId,
                  Boolean(dialogsPricingEnabled),
                  undefined,
                  { tier: nextPlanType },
                ),
              );
          };

          return (
            <PlanLimitReachedDialog
              onClose={close}
              onSuccess={handleSuccess}
              dialogPricingEnabled={dialogsPricingEnabled}
              nextPlanText={nextPlanText}
              currentPlanText={currentPlanText}
              currentPlanType={currentPlanType}
              nextPlanLimit={nextPlanLimit}
              planLimit={planLimit}
            />
          );
        },
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      );
    }
  }, [
    tab,
    botStatus,
    planLimit,
    nextPlanLimit,
    currentPlanText,
    currentPlanType,
    nextPlanText,
    nextPlanType,
    dialogsPricingEnabled,
    botId,
    isLimitReached,
    history,
    isSmallScreenSize,
  ]);
};
