import React from 'react';
import { useHistory } from 'react-router-dom';
import { Flex } from '@ui/Flex';
import { ButtonUnstyled } from '@ui/Button';
import { Icon } from '@ui/Icon';
import { Type } from '@ui/Type';

export interface BackButtonProps extends React.HTMLProps<HTMLButtonElement> {
  text: string;
  url: string;
}

export const BackButton = React.forwardRef<HTMLButtonElement, BackButtonProps>(
  ({ text, url, onClick, ...buttonProps }, ref) => {
    const history = useHistory();

    return (
      <ButtonUnstyled
        {...buttonProps}
        ref={ref}
        onClick={(e) => {
          onClick?.(e);
          history.push(url);
        }}
      >
        <Flex alignItems="center">
          <Icon icon="leftArrow" color="blue" />

          <Type size="15px" color="blue" weight="medium">
            {text}
          </Type>
        </Flex>
      </ButtonUnstyled>
    );
  },
);
