import { FacebookPagesSetupQuery_pages } from '@utils/FacebookPages/@types/FacebookPagesSetupQuery';
import Maybe from 'graphql/tsutils/Maybe';
import { FacebookPageStatus } from './types';

export const getPageContentStatus = (
  page: FacebookPagesSetupQuery_pages,
  pageStatus: FacebookPageStatus,
  workspaceId: Maybe<string>,
) => {
  if (page.pro && !page.whitelabeled && workspaceId) {
    return FacebookPageStatus.alreadyOnPro;
  }

  const connectedToAnotherWorkspace =
    page.workspace_id && page.workspace_id !== workspaceId;
  if (connectedToAnotherWorkspace) {
    return FacebookPageStatus.connectedToWorkspace;
  }

  return pageStatus;
};
