import React from 'react';
import cn from 'classnames';
import { equals, prop, pipe, anyPass, propEq, path } from 'ramda';
import debounce from 'lodash-es/debounce';
import { Hover } from 'react-powerplug';
import memoize from 'lodash-es/memoize';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { AsideGroupsQuery_bot_blocksGroups as IGroup } from '../@types/AsideGroupsQuery';
import { ReactComponent as AngleIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import { ReactComponent as DragIcon } from '../../../modern-ui/_deprecated/Icon/icons/drag-icon2.svg';
import { UpdateBlocksGroup } from '../Mutations/UpdateTypes';
import { CloneBlockMutation_cloneBlock_clonedBlock as IBlock } from '../Mutations/@types/CloneBlockMutation';
import {
  cloneBlock,
  createBlock,
  deleteBlock,
  moveBlock,
  updateBlockBroadcastOptions,
} from '../Mutations/BlockMutations';
import {
  OPTIMISTIC_GROUP_ID_PREFIX,
  updateGroup,
  updateStats,
} from '../Mutations/GroupMutations';
import { Permission } from '../../../common/services/RoleService';
import { RoleConsumer } from '../../../utils/Roles';
import { getBlockStatsForEvents } from '../../../utils/BlocksUtils';
import { sendEvent } from '../../../utils/Analytics';
import { GroupTitle } from './GroupTitle';
import { GroupContextMenu } from './GroupContextMenu';
import { BlockItem } from './BlockItem';
import * as css from './Group.css';
import { AddBlock } from './AddBlock';
import { SequenceStat } from './SequenceStat';
import { BlockSequenceControlItem } from './BlockSequenceControlItem';
import { ButtonUnstyled } from '../../../modern-ui/Button';
import {
  FieldNameType,
  prepareBlockFilterConfig,
} from '../../../utils/PeopleTabUtils';

export const DND_DISABLED_CLASS = 'block-context-menu-open';

export interface IAsideGroupsProps extends RouteComponentProps {
  group: IGroup;
  botId: string;
  currentBlockId: string | undefined;
  defaultBlockId?: string;
  sortableBlocksDecorator?: (el: HTMLDivElement) => void;
  navigate: (filter: any, stateParams?: any) => void;
  statisticFeatureEnabled: boolean;
  selectedGroupName?: string;
  collapsed?: boolean;
}

interface IAsideGroupsState {
  collapsed: boolean;
  renderBlocksEnabled: boolean;
  isShowSequenceStatInBlocks: boolean;
  isHoverOnSequenceStat: boolean;
  isBlockCreating: boolean; // true if the block is being created now, need to lock a button for to exclude simultaneous block creation
  contextMenusOpen: string[];
}

const BUILTIN_BLOCKS_COMMENTS = () => [
  window.i18next.t(
    'Group-string-3993-every-person-communicating-with-the-bot-sees-this-block-first',
  ),
  window.i18next.t(
    'Group-string--159-a-person-will-see-this-block-if-the-bot-does-not-recognize-a-text-message-from-them',
  ),
];
const BLOCK_LINE_HEIGHT = 40;
const BLOCK_LINE_WITCH_STAT_HEIGHT = 64;
const calcGroupHeight = (
  { blocks: { length: blocksQty }, builtin, sequence, with_stats }: IGroup,
  statisticFeatureEnabled: boolean,
) => {
  const lineHeight =
    with_stats && statisticFeatureEnabled
      ? BLOCK_LINE_WITCH_STAT_HEIGHT
      : BLOCK_LINE_HEIGHT;
  return builtin
    ? 'unset'
    : sequence
    ? lineHeight * (blocksQty + 1)
    : Math.ceil((blocksQty + 1) / 3) * lineHeight;
};

const calcInitialGroupUnCollapsedState = memoize(
  ({ group, currentBlockId, collapsed }: IAsideGroupsProps) =>
    !collapsed &&
    (!group.collapsed || group.blocks.some(propEq('id', currentBlockId))),
);

export const JS_GROUP_DND_HANDLE_CLASS = 'js-group-dnd-handle';

class GroupInner extends React.Component<IAsideGroupsProps, IAsideGroupsState> {
  state = {
    collapsed: !calcInitialGroupUnCollapsedState(this.props),
    renderBlocksEnabled: calcInitialGroupUnCollapsedState(this.props),
    isShowSequenceStatInBlocks: false,
    isHoverOnSequenceStat: false,
    isBlockCreating: false,
    contextMenusOpen: [] as string[],
  };

  timeoutForRenderBlocksDisable: number | undefined;

  blocksInGridLineQty: number = 3;

  updateGroupMutationDebounced = debounce((groupData: UpdateBlocksGroup) => {
    const { group, botId } = this.props;
    updateGroup({
      botId,
      groupData,
      groupId: group.id,
    });
  }, 300);

  updateGroupStatisticDebounced = debounce((groupData: UpdateBlocksGroup) => {
    const { group, botId } = this.props;
    updateStats({
      botId,
      groupData,
      groupId: group.id,
    });
  }, 300);

  shouldComponentUpdate(
    nextProps: IAsideGroupsProps,
    nextState: IAsideGroupsState,
  ) {
    if (this.state !== nextState) {
      return true;
    }
    const { currentBlockId, botId, group, selectedGroupName } = this.props;
    const {
      currentBlockId: nextCurrentBlockId,
      botId: nextBotId,
      group: nextGroup,
      selectedGroupName: nextSelectedGroupName,
    } = nextProps;
    if (botId !== nextBotId || selectedGroupName !== nextSelectedGroupName) {
      return true;
    }
    if (
      currentBlockId !== nextCurrentBlockId &&
      this.props.group.blocks.some(
        pipe(
          prop('id'),
          anyPass([equals(currentBlockId), equals(nextCurrentBlockId)]),
        ),
      )
    ) {
      return true;
    }
    return group !== nextGroup;
  }

  componentDidUpdate(prevProps: IAsideGroupsProps) {
    const { collapsed, selectedGroupName } = this.props;
    if (
      collapsed !== undefined &&
      (collapsed !== prevProps.collapsed ||
        selectedGroupName !== prevProps.selectedGroupName)
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ collapsed, renderBlocksEnabled: !collapsed });
    }
  }

  componentWillUnmount() {
    this.updateGroupMutationDebounced.cancel();
    this.updateGroupStatisticDebounced.cancel();

    if (this.timeoutForRenderBlocksDisable) {
      window.clearTimeout(this.timeoutForRenderBlocksDisable);
    }
  }

  handleCollapseToggle = (shouldSaveCollapsedState: boolean) => {
    const {
      group: { id, sequence },
    } = this.props;
    // eslint-disable-next-line react/no-access-state-in-setstate
    const collapsed = !this.state.collapsed;
    this.setState({
      collapsed,
    });

    if (!collapsed) {
      this.setState({
        renderBlocksEnabled: true,
      });
    } else {
      this.timeoutForRenderBlocksDisable = window.setTimeout(
        () => this.setState({ renderBlocksEnabled: false }),
        150, // wait collapse group animation
      );
    }

    if (shouldSaveCollapsedState) {
      this.updateGroupMutationDebounced({ collapsed, id, sequence });
    }
  };

  handleDeleteBlockRequest = (blockId: string) => {
    const { group, botId, currentBlockId, defaultBlockId } = this.props;
    return deleteBlock({
      group,
      botId,
      currentBlockId,
      defaultBlockId,
      blockId,
      history: this.props.history,
    });
  };

  handleCloneBlockRequest = (
    sourceBlock: IBlock,
    targetGroupId: string,
    targetBotId: string,
  ) => {
    return cloneBlock({
      sourceBlock,
      targetBotId,
      targetGroupId,
    });
  };

  handleMoveBlockRequest = (
    sourceBlock: IBlock,
    sourceGroupId: string,
    targetGroupId: string,
    targetBotId: string,
  ) => {
    const { defaultBlockId, botId: currentBotId } = this.props;
    return moveBlock({
      sourceBlock,
      sourceGroupId,
      targetGroupId,
      currentBotId,
      targetBotId,
      defaultBlockId,
      history: this.props.history,
    });
  };

  handleCreateBlockRequest = async () => {
    const { group, botId } = this.props;
    this.setState({
      isBlockCreating: true,
    });
    await createBlock({
      botId,
      group,
      history: this.props.history,
    });
    this.setState({
      isBlockCreating: false,
    });
  };

  handleToggleIsShowSequenceStatInBlocks = () =>
    this.setState((prevState) => ({
      isShowSequenceStatInBlocks: !prevState.isShowSequenceStatInBlocks,
    }));

  handleMouseEnterSequenceStat = () =>
    this.setState({ isHoverOnSequenceStat: true });

  handleMouseLeaveSequenceStat = () =>
    this.setState({ isHoverOnSequenceStat: false });

  handleBroadcastOptionsChange = (
    block: IBlock,
    broadcastOptions: { time_period?: string; time_value?: number },
  ) => {
    updateBlockBroadcastOptions({
      block,
      updateBlockData: {
        title: block.title,
        broadcast_options: broadcastOptions,
      },
    });
  };

  testBlocksInGridLineQty = (el: HTMLDivElement) => {
    this.blocksInGridLineQty =
      (el && el.getBoundingClientRect().width) < 400 ? 3 : 4;
  };

  handleToggleStatistic = (isAllowedEditGroup: boolean) => {
    if (!isAllowedEditGroup) {
      return;
    }
    const {
      group: { id, sequence, with_stats },
    } = this.props;

    const { collapsed } = this.state;
    if (collapsed) {
      this.handleCollapseToggle(isAllowedEditGroup);
    }

    if (isAllowedEditGroup) {
      const nextWithStats = !with_stats;
      sendEvent({
        category: 'sequence or group',
        action: nextWithStats ? 'show stats' : 'hide stats',
        label: sequence ? 'sequence' : 'group',
      });
      this.updateGroupStatisticDebounced({
        id,
        sequence,
        with_stats: nextWithStats,
      });
    }
  };

  navigateToPeopleTab = (id: string, filter: string) => {
    this.props.navigate(prepareBlockFilterConfig(filter as FieldNameType, id));
  };

  render() {
    const {
      group,
      botId,
      currentBlockId,
      sortableBlocksDecorator,
      statisticFeatureEnabled,
      selectedGroupName,
    } = this.props;
    const {
      collapsed,
      renderBlocksEnabled,
      isShowSequenceStatInBlocks,
      isHoverOnSequenceStat,
      isBlockCreating,
    } = this.state;
    const isSyncedGroup = !!path(['synced_from', 'bot'], group);
    const iOptimisticGroupData = group.id.includes(OPTIMISTIC_GROUP_ID_PREFIX);
    const isShowAddBlockButton =
      !group.builtin && !group.synced_from && !iOptimisticGroupData;

    return (
      <RoleConsumer domain="groups" can={Permission.EDIT} groupId={group.id}>
        {({ allowed }) => {
          const isAllowedEditGroup = allowed && !selectedGroupName;
          return (
            <Hover
              render={({ hovered, bind }) => (
                <div
                  className={css.group}
                  ref={this.testBlocksInGridLineQty}
                  {...bind}
                >
                  {group.builtin ? (
                    allowed &&
                    statisticFeatureEnabled &&
                    group.blocks.length > 0 && (
                      <div
                        className={cn(css.groupHeader, css.defaultGroupHeader)}
                      >
                        {group.with_stats !== null && (
                          <ButtonUnstyled
                            className={css.showStatsToggle}
                            onClick={() => this.handleToggleStatistic(allowed)}
                          >
                            {group.with_stats
                              ? window.i18next.t('Group-string-8835-hide-stats')
                              : window.i18next.t(
                                  'Group-string-1286-show-stats',
                                )}
                          </ButtonUnstyled>
                        )}
                      </div>
                    )
                  ) : (
                    <div className={css.groupHeader}>
                      <div className={css.groupHeaderLeftPart}>
                        <ButtonUnstyled
                          data-testid="automate-aside__group-collapse-button"
                          className={cn(css.collapseControl, {
                            [css.collapsed]: collapsed,
                          })}
                          onClick={() =>
                            this.handleCollapseToggle(isAllowedEditGroup)
                          }
                        >
                          <AngleIcon />
                        </ButtonUnstyled>
                        {!selectedGroupName && (
                          <div
                            data-testid="automate-aside__group-drag-button"
                            className={cn(
                              css.dragHandle,
                              { [css.show]: hovered },
                              JS_GROUP_DND_HANDLE_CLASS,
                            )}
                          >
                            <DragIcon className={css.DragIcon} />
                          </div>
                        )}
                        <GroupTitle
                          data-testid="automate-aside__group-title"
                          title={group.title}
                          forSequence={!!group.sequence}
                          onTitleChange={(title) =>
                            this.updateGroupMutationDebounced({
                              title,
                              id: group.id,
                              sequence: group.sequence,
                            })
                          }
                        />
                        <GroupContextMenu
                          show={allowed && hovered && !iOptimisticGroupData}
                          currentGroup={group}
                          synced={!!group.synced_from}
                          currentBotId={botId}
                          withSearch={!!selectedGroupName}
                        />
                      </div>
                      {!group.sequence && allowed && (
                        <ButtonUnstyled
                          data-testid="automate-aside__group-show-stats-button"
                          className={css.showStatsToggle}
                          onClick={() => this.handleToggleStatistic(allowed)}
                        >
                          {group.with_stats !== null &&
                            statisticFeatureEnabled &&
                            (group.with_stats
                              ? window.i18next.t('Group-string-8835-hide-stats')
                              : window.i18next.t(
                                  'Group-string-1286-show-stats',
                                ))}
                        </ButtonUnstyled>
                      )}
                      {group.sequence && (
                        <SequenceStat
                          data-testid="automate-aside__group-show-stats-button"
                          group={group}
                          on={
                            isShowSequenceStatInBlocks || isHoverOnSequenceStat
                          }
                          onClick={this.handleToggleIsShowSequenceStatInBlocks}
                          onMouseEnter={this.handleMouseEnterSequenceStat}
                          onMouseLeave={this.handleMouseLeaveSequenceStat}
                        />
                      )}
                    </div>
                  )}
                  {isSyncedGroup && (
                    <div className={css.synced}>
                      {window.i18next.t('Group-JSXText--129-synced-with')}
                      {group.synced_from!.bot.title}
                    </div>
                  )}
                  <div
                    className={cn(css.blocksList, {
                      [css.collapsed]: collapsed,
                    })}
                    style={{
                      maxHeight: collapsed
                        ? 0
                        : calcGroupHeight(group, statisticFeatureEnabled),
                    }}
                  >
                    {renderBlocksEnabled ? (
                      <React.Fragment>
                        <div
                          ref={sortableBlocksDecorator}
                          data-group-id={group.id}
                        >
                          {group.blocks.map((block, i) => (
                            <div
                              data-testid={`automate-aside__block-${block.title}`}
                              key={block.id}
                              data-block-id={block.id}
                              data-block-title={block.title}
                              className={cn(
                                css.block,
                                { [css.builtin]: block.builtin },
                                { [css.sequenceBlock]: group.sequence },
                                'test-aside-block-item',
                                {
                                  [DND_DISABLED_CLASS]: this.state.contextMenusOpen.includes(
                                    block.id,
                                  ),
                                },
                              )}
                            >
                              {group.sequence && (
                                <BlockSequenceControlItem
                                  block={block}
                                  currentBlockId={currentBlockId}
                                  index={i}
                                  onBroadcastOptionsChange={
                                    this.handleBroadcastOptionsChange
                                  }
                                  readOnly={
                                    !isAllowedEditGroup || isSyncedGroup
                                  }
                                />
                              )}
                              <BlockItem
                                botId={botId}
                                groupId={group.id}
                                groups={[] as IGroup[]}
                                block={block}
                                isActive={block.id === currentBlockId}
                                isValid={!!block.is_valid}
                                statisticFeatureEnabled={
                                  statisticFeatureEnabled
                                }
                                blockComment={
                                  block.builtin
                                    ? BUILTIN_BLOCKS_COMMENTS()[i]
                                    : undefined
                                }
                                onBlockClick={() =>
                                  sendEvent({
                                    category: 'block',
                                    action: 'click',
                                    label: selectedGroupName
                                      ? 'search'
                                      : undefined,
                                    propertyBag: getBlockStatsForEvents(block),
                                  })
                                }
                                onBlockRemove={this.handleDeleteBlockRequest}
                                onBlockClone={({ botId, groupId }) =>
                                  this.handleCloneBlockRequest(
                                    block,
                                    groupId,
                                    botId,
                                  )
                                }
                                onBlockMove={({
                                  botId: targetBotId,
                                  groupId,
                                }) =>
                                  this.handleMoveBlockRequest(
                                    block,
                                    group.id,
                                    groupId,
                                    targetBotId,
                                  )
                                }
                                isHideContextMenu={
                                  !isAllowedEditGroup || isSyncedGroup
                                }
                                isSequenceBlock={!!group.sequence}
                                isShowBlockStat={
                                  statisticFeatureEnabled && !!group.with_stats
                                }
                                isShowSequenceStat={
                                  isShowSequenceStatInBlocks ||
                                  isHoverOnSequenceStat
                                }
                                contextMenuAlignRight={
                                  (i + 1) % this.blocksInGridLineQty === 0
                                } // align to right context menu for blocks in end line
                                onStatisticClick={this.navigateToPeopleTab}
                                onContextMenuOpenChange={(status) => {
                                  this.setState((currentState) => ({
                                    contextMenusOpen: status
                                      ? [
                                          ...currentState.contextMenusOpen,
                                          block.id,
                                        ]
                                      : currentState.contextMenusOpen.filter(
                                          (elem) => elem !== block.id,
                                        ),
                                  }));
                                }}
                              />
                            </div>
                          ))}
                          {isShowAddBlockButton && isAllowedEditGroup && (
                            <AddBlock
                              data-testid="automate-aside__add-block-button"
                              key={`add_${group.id}`}
                              className={cn(
                                css.block,
                                'js-add-block-button',
                                'test-add-block-button',
                              )}
                              disabled={isBlockCreating}
                              onClick={this.handleCreateBlockRequest}
                            />
                          )}
                        </div>
                        {!isAllowedEditGroup && group.blocks.length === 0 && (
                          <div className={css.emptyGroupLabel}>
                            {window.i18next.t(
                              'Group-JSXText--919-this-group-is-empty',
                            )}
                          </div>
                        )}
                      </React.Fragment>
                    ) : null}
                  </div>
                </div>
              )}
            />
          );
        }}
      </RoleConsumer>
    );
  }
}

export const Group = withRouter(GroupInner);
