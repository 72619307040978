import React from 'react';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import Maybe from 'graphql/tsutils/Maybe';
import { formatDateShort } from '@utils/DateTime/formatDate';
import { DateUtils } from '@utils/DateTime';
import i18next from 'i18next';
import { Translation } from '@translations';

export interface PricingStatusBadgeProps {
  nextPrepaymentDate: Maybe<number>;
  canceled: boolean;
  currentTierTimeLimitedTrial: Maybe<boolean>;
  trialRemainingDays: Maybe<number>;
  trialEndDate: Maybe<number>;
}

const getCanceledDate = (date: Maybe<number>) => {
  if (date) {
    const isTomorrow = DateUtils.isTommorow(date);

    return isTomorrow
      ? i18next.t<string, Translation>('pages.Pricing.status.tomorrow')
      : i18next.t<string, Translation>('pages.Pricing.status.on', {
          date: formatDateShort(date),
        });
  }

  return '-';
};

export const PricingStatusBadge: React.FC<PricingStatusBadgeProps> = ({
  nextPrepaymentDate,
  canceled,
  currentTierTimeLimitedTrial,
  trialRemainingDays,
  trialEndDate,
}) => {
  const { t } = useSafeTranslation();

  if (currentTierTimeLimitedTrial) {
    if (
      typeof trialRemainingDays !== 'number' ||
      typeof trialEndDate !== 'number'
    )
      return null;
    if (trialRemainingDays <= 0)
      return (
        <Type size="15px" color="accent4Normal">
          {t('pages.Pricing.ended')}
        </Type>
      );

    return (
      <Type
        size="15px"
        color={trialRemainingDays < 3 ? 'accent3Orange' : 'baseSecondary'}
      >
        {t('pages.Pricing.dayLeft', {
          count: trialRemainingDays,
        })}
      </Type>
    );
  }

  if (canceled) {
    return (
      <Type size="15px" color="red">
        {t('pages.Pricing.status.canceled')}
        {nextPrepaymentDate &&
          ` ${t('pages.Pricing.status.expired', {
            date: getCanceledDate(nextPrepaymentDate),
          })}`}
      </Type>
    );
  }

  if (nextPrepaymentDate)
    return (
      <Type size="15px" data-testid="billing-page__renews">
        {t('pages.Billing.dialogues.renews', {
          date: formatDateShort(nextPrepaymentDate),
        })}
      </Type>
    );

  return <Spacer factor={4.5} />;
};
