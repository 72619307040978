import { Button } from '@ui/Button';
import { DefaultDialog } from '@ui/Dialog';
import { Flex } from '@ui/Flex';
import { Spacer } from '@ui/Spacer';
import { Type } from '@ui/Type';
import { sendEvent } from '@utils/Analytics';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import React, { useEffect } from 'react';
import cn from 'classnames';
import { CancelProTranslation } from '../CancelProTranslation';
import { ReactComponent as TransferSvg } from './transfer.svg';
import css from './TransferDialog.css';

interface TransferDialogProps {
  onDiscount(): void;
  onCancel(): void;
  onClose(): void;
}

export const TransferDialog: React.FC<TransferDialogProps> = ({
  onDiscount,
  onCancel,
  onClose,
}) => {
  const { isSmallScreenSize } = useDeviceMedia();
  useEffect(() => {
    sendEvent({
      category: 'churn survey',
      action: 'show transfer dialog',
      label: 'transfer dialog',
    });
  }, []);

  return (
    <DefaultDialog
      mobileAdaptive={isSmallScreenSize}
      className={cn(css.dialog, isSmallScreenSize && css.mobile)}
      header={null}
      onDismiss={() => {
        onClose();
        sendEvent({
          category: 'churn survey',
          action: 'close transfer dialog',
          label: 'transfer dialog',
        });
      }}
      footer={
        <Flex
          justifyContent="space-between"
          className={cn(css.footer, isSmallScreenSize && css.mobile)}
        >
          <Button
            data-testid="churn-survey__transfer-dialog__cancel-anyway-button"
            className={css.cancel}
            onClick={() => {
              onCancel();
              sendEvent({
                category: 'churn survey',
                action: 'click cancel anyway',
                label: 'transfer dialog',
              });
            }}
          >
            <CancelProTranslation i18nKey="CancelPro.Transfer.cancel" />
          </Button>
          <Spacer horizontalFactor={3} factor={2} />
          <Button
            className={cn(css.apply, isSmallScreenSize && css.mobile)}
            data-testid="churn-survey__transfer-dialog__transfer-button"
            intent="secondary"
            onClick={() => {
              onDiscount();
              sendEvent({
                category: 'churn survey',
                action: 'click transfer',
                label: 'transfer dialog',
              });
            }}
          >
            <CancelProTranslation i18nKey="CancelPro.Transfer.transfer" />
          </Button>
        </Flex>
      }
    >
      <Flex flexDirection="column">
        <TransferSvg />
        <Spacer factor={5} />
        <Type size="24px" weight="semibold" color="white">
          <CancelProTranslation i18nKey="CancelPro.Transfer.header" />
        </Type>
        <Spacer factor={2} />
        <Type size="15px" weight="medium" color="white">
          <CancelProTranslation i18nKey="CancelPro.Transfer.description" />
        </Type>
      </Flex>
    </DefaultDialog>
  );
};
