import { Flex } from '@ui/Flex';
import React, { useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import { CancelPlanBlock } from '../CancelPlanBlock/CancelPlanBlock';
import line from './line.svg';
import { CancelProTranslation } from '../../CancelProTranslation';
import { Type } from '@ui/Type';
import { Spacer } from '@ui/Spacer';
import { Button } from '@ui/Button';
import { TierCard, TierCardLoader } from '@pages/Plans/components';
import { ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan_tier } from '@utils/Data/Pricing/@types/ChurnSurveyAdviceQuery';
import {
  useDialoguesPricing,
  useSwitchPricingTierMutation,
  useTiersData,
} from '@utils/Data/Pricing';
import { BotTabs, getTabLink } from '@utils/Routing';
import { useAdminFeatures } from '@utils/Data/Admin';
import noop from 'lodash-es/noop';
import { getSwitchTierCallback } from '@pages/Plans/hooks/getSwitchTierCallback';
import { useUpdatePaymentDialog } from '@components/DialogsPricing/hooks';
import { submitPaymentEvent } from 'cf-common/src/conversionTracking';
import { DialogContainer } from '../DialogContainer/DialogContainer';
import { ChurnSurveyAdviceType } from '@globals';
import css from './DowngradeAvailableDialog.css';
import { sendEvent } from '@utils/Analytics';

interface DowngradeAvailableDialogProps {
  tier: ChurnSurveyAdviceQuery_churnSurveyAdvice_data_ChurnSurveyAdviceDowngradePlan_tier;
  currency: string;
  onClose: VoidFunction;
  onCancellPro: VoidFunction;
  botId: string;
  churnSurveyAdviceType: ChurnSurveyAdviceType;
}

export const DowngradeAvailableDialog: React.FC<DowngradeAvailableDialogProps> =
  ({ onClose, tier, currency, onCancellPro, botId, churnSurveyAdviceType }) => {
    const history = useHistory();
    const { pricing, pricingLoading } = useDialoguesPricing();
    const { tiers, tiersLoading } = useTiersData(
      botId,
      pricing?.pricing_plan_id,
      pricing?.currency,
    );
    const { adminFeatures, adminFeaturesLoading } = useAdminFeatures();

    const currentTier = tiers.find(
      (item) => item.type === pricing?.current_tier,
    );

    const { switchPricingTierMutation, handleMutationError, switchingTier } =
      useSwitchPricingTierMutation({
        botId,
        onSwitchingCompleted(data) {
          const tier = data.switchPricingTier;
          submitPaymentEvent(
            {
              currency: tier.currency,
              tierName: tier.next_tier || tier.current_tier!,
              value: tier.invoice_total!,
            },
            botId,
          );
        },
      });
    const showPaymentUpdateDialog = useUpdatePaymentDialog();

    const switchTier = useCallback(
      getSwitchTierCallback({
        botId: botId!,
        currentTier,
        switchPricingTierMutation,
        handleMutationError,
        showPaymentUpdateDialog,
        pricing,
        isFirstSessionTab: false,
        tiers,
      }),
      [
        botId,
        currentTier,
        switchPricingTierMutation,
        handleMutationError,
        showPaymentUpdateDialog,
        pricing,
        tiers,
      ],
    );

    return (
      <DialogContainer onClose={onClose}>
        <Flex
          className={css.downgradeBlock}
          flexDirection="column"
          style={{ backgroundImage: `url(${line})` }}
        >
          <Type size="44px" weight="extrabold" color="accent1Semilight">
            <CancelProTranslation i18nKey="CancelPro.DowngradeAvailable.header" />
          </Type>
          <Spacer factor={2} />
          <Type size="24px" color="accent1Tertiary">
            <CancelProTranslation i18nKey="CancelPro.DowngradeAvailable.description" />
          </Type>
          <Spacer factor={6} />
          <div className={css.tierCardContainer}>
            {pricingLoading ||
            tiersLoading ||
            adminFeaturesLoading ||
            switchingTier ? (
              <TierCardLoader />
            ) : (
              <TierCard
                readOnly={false}
                tier={tier}
                currency={currency!}
                pricing={pricing}
                selectedTier={currentTier}
                botIsPaused={false}
                showYearPrices={adminFeatures?.show_year_prices}
                onDowngrade={async (tier, period) => {
                  sendEvent({
                    category: 'churn survey advice dialog',
                    action: 'switch tier button click',
                    propertyBag: {
                      botId,
                      churnSurveyAdviceType,
                    },
                  });
                  await switchTier(tier, period);
                  onClose();
                }}
                onUnpause={noop}
                onUpgrade={noop}
              />
            )}
          </div>
          <Spacer factor={5} />
          <Button
            className={css.seeOtherPlans}
            textClassName={css.seeOtherPlansText}
            onClick={() => {
              onClose();
              history.push(getTabLink(BotTabs.plans, botId));
            }}
          >
            <CancelProTranslation i18nKey="CancelPro.DowngradeAvailable.seeOtherPlans" />
          </Button>
        </Flex>
        <Spacer factor={5} />
        <CancelPlanBlock
          botId={botId}
          churnSurveyAdviceType={churnSurveyAdviceType}
          onCancellPro={onCancellPro}
        />
      </DialogContainer>
    );
  };
