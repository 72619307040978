import React from 'react';
import { ReactComponent as DoneIcon } from '../assets/Done.svg';
import {
  DefaultPricingBtn,
  DefaultPricingDialog,
} from '../DefaultPricingDialog';
import { getTierNameByType } from '../../constants';
import { TierType } from '@globals';
import { Pricing } from '@utils/Data/Pricing';
import { getMonthsCount } from '@components/DialogsPricing/utils/getMonthsCount';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { DateFormat } from '@utils/DateTime';

interface DowngradedPricingDialogProps {
  close(): void;
  newNextTierType: TierType;
  prevTierType: TierType;
  newPricing: Pricing;
}

export const DowngradedPricingDialog: React.FC<DowngradedPricingDialogProps> =
  ({ close, newNextTierType, prevTierType, newPricing }) => {
    const { t } = useSafeTranslation();
    const startingDate = newPricing.next_prepayment_date
      ? DateFormat.ll(newPricing.next_prepayment_date)
      : '';
    const billingPeriod = t(
      'pages.Pricing.dialogs.UpgradedPricingDialog.everyMonth',
      {
        count: getMonthsCount(
          newPricing.next_subscription_period ||
            newPricing.current_subscription_period,
        ),
      },
    );

    return (
      <DefaultPricingDialog
        title={t('pages.Pricing.dialogs.DowngradedPricingDialog.title', {
          tier: getTierNameByType(newNextTierType),
        })}
        icon={<DoneIcon />}
        description={
          newNextTierType === prevTierType
            ? t(
                'pages.Pricing.dialogs.DowngradedPricingDialog.samePlanDescription',
                {
                  startingDate,
                  billingPeriod,
                },
              )
            : t(
                'pages.Pricing.dialogs.DowngradedPricingDialog.newPlanDescription',
                {
                  startingDate,
                  billingPeriod,
                  planName: getTierNameByType(newNextTierType),
                },
              )
        }
        button={
          <DefaultPricingBtn intent="primary" onClick={close}>
            {t('pages.Pricing.dialogs.DowngradedPricingDialog.button')}
          </DefaultPricingBtn>
        }
        onClose={close}
      />
    );
  };
