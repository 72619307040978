import React from 'react';
import { Type } from '@ui/Type';
import { TextEllipsis } from '@ui/TextEllipsis';

export interface LanguageColumnProps {
  language: string;
}

export const LanguageColumn: React.FC<LanguageColumnProps> = ({ language }) => (
  <TextEllipsis width="110px" title={language}>
    <Type size="15px" color="grey" data-testid="whatsapp-templates__language">
      {language}
    </Type>
  </TextEllipsis>
);
