import { useCallback, useState } from 'react';

export interface RenameBot {
  id: string;
}

export const useStateForRenameBot = <T extends RenameBot>() => {
  const [renameModeBotId, setRenameModeBotId] = useState<string | null>(null);

  const setRenameMode = useCallback(
    (bot: T) => {
      setRenameModeBotId(bot.id);
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setRenameModeBotId],
  );

  const removeRenameMode = () => {
    if (renameModeBotId !== null) {
      setRenameModeBotId(null);
    }
  };

  const isRenameMode = (bot: T) => renameModeBotId === bot.id;

  const setIsRenameMode = (bot: T, renameMode: boolean) =>
    renameMode ? setRenameMode(bot) : removeRenameMode();

  return { setRenameMode, removeRenameMode, isRenameMode, setIsRenameMode };
};
