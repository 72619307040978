import gql from 'graphql-tag';
import { useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { PageArchivingStatus } from '@globals';
import {
  FacebookPageArchivingStatusQuery,
  FacebookPageArchivingStatusQueryVariables,
} from './@types/FacebookPageArchivingStatusQuery';

const FACEBOOK_PAGE_ARCHIVING_STATUS_QUERY = gql`
  query FacebookPageArchivingStatusQuery($pageId: String!) {
    page(pageId: $pageId) {
      id
      archiving_status
    }
  }
`;

export const useFacebookPageArchivingStatus = (pageId: string) => {
  const { data, startPolling, stopPolling, loading } = useQuery<
    FacebookPageArchivingStatusQuery,
    FacebookPageArchivingStatusQueryVariables
  >(FACEBOOK_PAGE_ARCHIVING_STATUS_QUERY, { variables: { pageId } });

  const archivingStatus = data?.page?.archiving_status;

  useEffect(() => {
    if (!archivingStatus || archivingStatus === PageArchivingStatus.ARCHIVED) {
      stopPolling();
    } else {
      startPolling(5000);
    }
  }, [archivingStatus, stopPolling, startPolling]);

  return { archivingStatus, loading };
};
