import ng from 'angular';
import { ApolloProvider } from '@apollo/react-common';
import React from 'react';
import { Router } from 'react-router-dom';
import { react2angular } from 'react2angular';
import {
  ButtonBlockTabProps,
  ButtonPopupBlocksTab,
} from './ButtonPopupBlocksTab';
import { globalHistory } from '../../../utils/Routing';
import client from '../../../common/services/ApolloService';

const ButtonPopupBlocksTabWrapper: React.FC<ButtonBlockTabProps> = (props) => {
  return (
    <ApolloProvider client={client}>
      <Router history={globalHistory}>
        <ButtonPopupBlocksTab {...props} />
      </Router>
    </ApolloProvider>
  );
};

export const ngButtonPopupBlocksTab = ng
  .module('app.modernComponents.buttonPopupBlocksTab', [])
  .component(
    'buttonPopupBlocksTab',
    react2angular(
      ButtonPopupBlocksTabWrapper,
      [
        'botId',
        'counterId',
        'onChange',
        'attributeUpdates',
        'blockId',
        'onTutorialShow',
        'error',
        'onBlocksTitlesChange',
        'allowChangeUserAttributes',
        'donePopup',
        'showAdditionalActions',
      ],
      [],
    ),
  ).name;
