import React from 'react';
import cn from 'classnames';

import { Anchor } from '../../../../modern-ui/Links';

import * as css from '../../BroadcastingTags.css';

export interface RightPartBlockProps {
  selected?: boolean;
  disabled?: boolean;
  linkHref?: string;
  linkTitle?: string;
  text: string;
  disabledAppendRender?: (selected?: boolean) => React.ReactElement;
}

export const RightPartBlock: React.FC<RightPartBlockProps> = ({
  text,
  selected,
  disabled,
  linkHref,
  linkTitle,
  disabledAppendRender,
}) => (
  <div
    className={cn(css.itemText, {
      [css.itemTextSelected]: selected,
      [css.disabledText]: disabled,
    })}
  >
    {text}
    &nbsp;
    {linkHref && linkTitle && (
      <Anchor
        className={cn(css.itemText, css.itemTextLink, {
          [css.itemTextSelected]: selected,
        })}
        intent="external"
        hideArrow
        href={linkHref}
        target="_blank"
        rel="noopener noreferrer"
      >
        {linkTitle}
      </Anchor>
    )}
    {disabled && disabledAppendRender && disabledAppendRender(selected)}
  </div>
);

export const getRenderRightPart = (params: RightPartBlockProps) => (
  selected?: boolean,
  disabled?: boolean,
) => <RightPartBlock {...params} selected={selected} disabled={disabled} />;
