import React, { useCallback } from 'react';
import { useProPlanUnpause } from '@utils/Data/Pricing/hooks';
import { Modal } from '@services/index';
import { sendEvent } from '@utils/Analytics';
import { PlanUnpausedDialog } from '../dialogs/PlanUnpausedDialog';
import { TierType } from '@globals';

export interface UnpausePlanConfig {
  tier?: TierType;
  from: 'plans' | 'billing' | 'home';
}

export const useUnpausePlan = (botId: string, config?: UnpausePlanConfig) => {
  const [unpauseMutation, { loading }] = useProPlanUnpause(botId);

  const unpausePlan = useCallback(async () => {
    const res = await unpauseMutation({ variables: { botId } });

    if (!res.errors?.length && res.data?.unpauseProPlan) {
      Modal.show(
        ({ close }) => (
          <PlanUnpausedDialog
            onDone={() => {
              sendEvent({
                category: 'pricing',
                action: 'click done after unpause dialog',
                label: 'new dialogs pricing',
                propertyBag: {
                  botId,
                  from: config?.from,
                  plan: config?.tier,
                },
              });
              close();
            }}
            onDismiss={close}
          />
        ),
        {
          mobileAdaptive: true,
          mobileProps: {
            fitHeight: true,
          },
        },
      );
    }
  }, [botId, config, unpauseMutation]);

  return { unpausePlan, loading };
};
