import React from 'react';
import { Router } from 'react-router-dom';
import ng from 'angular';
import { react2angular } from 'react2angular';
import { WhiteLabelUserDataWithApolloProvider } from '../../utils/WhiteLabelUtils';
import { AudienceSync } from './AudienceSync';
import { AudienceSyncProps } from './AudienceSyncContext';
import { globalHistory } from '../../utils/Routing';

const AudienceSyncWrapper = ({ bot }: AudienceSyncProps) => (
  <WhiteLabelUserDataWithApolloProvider>
    {({ loading, isWhiteLabelUser }) => {
      if (isWhiteLabelUser || loading || !bot) {
        return null;
      }

      return (
        <Router history={globalHistory}>
          <AudienceSync bot={bot} />
        </Router>
      );
    }}
  </WhiteLabelUserDataWithApolloProvider>
);

export const ngAudienceSyncBanner = ng
  .module('app.components.audienceSyncBanner', [])
  .component(
    'audienceSyncBanner',
    react2angular(AudienceSyncWrapper, ['bot'], []),
  ).name;
