import { useQuery } from '@apollo/react-hooks';
import { BLOCKS_SELECTOR_DATA_QUERY } from './BlocksSelectorData';
import {
  BlocksSelectorDataQuery,
  BlocksSelectorDataQueryVariables,
} from './@types/BlocksSelectorDataQuery';
import { useCurrentBotId } from '@utils/Routing';

export const useBlocksSelectorData = () => {
  const botId = useCurrentBotId() || '';
  const { data, ...state } = useQuery<
    BlocksSelectorDataQuery,
    BlocksSelectorDataQueryVariables
  >(BLOCKS_SELECTOR_DATA_QUERY, {
    skip: !botId,
    variables: {
      botId,
    },
  });

  return {
    blocksGroups: data?.bot.blocksGroups || [],
    flowsGroups: data?.bot.flowGroupsSuggest || [],
    ...state,
  };
};
