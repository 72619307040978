import { Period, TierType } from '@globals';
import { Button } from '@ui/Button';
import { FadeInCallout } from '@ui/Callout';
import { Flex } from '@ui/Flex';
import { Type } from '@ui/Type';
import {
  getPlanLimitReached,
  PRICE_PERIOD_LIST_QUERY,
  UPDATE_CARD_QUERY_PARAMS,
  useDialoguesPricing,
  useHasPaymentDebt,
  useTiersData,
} from '@utils/Data/Pricing';
import React, { useEffect } from 'react';
import cn from 'classnames';
import css from './AnnualPricingCallout.css';
import { useHistory, useRouteMatch } from 'react-router-dom';
import {
  getBillingPageUrl,
  getPlansPageUrl,
  useCurrentBotId,
} from '@utils/Routing';
import { useQuery } from 'react-apollo';
import { ReactComponent as ScheduleIcon } from './schedule.svg';
import {
  PricePeriodListQuery,
  PricePeriodListQueryVariables,
} from '@utils/Data/Pricing/@types/PricePeriodListQuery';
import { formatPrice } from '@utils/Format';
import { getTierNameByType } from '@components/DialogsPricing/constants';
import { ServerStorageItemKeys, useServerStorage } from '@utils/ServerStorage';
import { sendEvent } from '@utils/Analytics';
import { useSafeTranslation } from '@utils/useSafeTranslation';
import { getMoreExpensiveTier } from '@utils/Data/Pricing/utils';
import { useDeviceMedia } from '@utils/DOM/useDeviceMedia';
import { DateFormat, DateUtils } from '@utils/DateTime';

interface AnnualPricingCalloutPayload {
  open: boolean;
  dateChange: number;
}

export function AnnualPricingCallout() {
  const { getValueDueToSize, isSmallScreenSize } = useDeviceMedia();
  const botId = useCurrentBotId();
  const matchPlansPage = useRouteMatch('/bot/:botId/plans');
  const history = useHistory();
  const { t } = useSafeTranslation();
  const { pricing } = useDialoguesPricing();
  const currentPeriod = pricing?.current_subscription_period;
  const currentTier = pricing?.current_tier;
  const hasPaymentDebt = useHasPaymentDebt(botId);
  const { tiers } = useTiersData(
    botId || '',
    pricing?.pricing_plan_id,
    pricing?.currency,
  );
  const moreExpensiveTier = getMoreExpensiveTier(tiers, currentTier);

  const { data: pricePeriodData } = useQuery<
    PricePeriodListQuery,
    PricePeriodListQueryVariables
  >(PRICE_PERIOD_LIST_QUERY, {
    variables: {
      botId: botId!,
      pricingPlanId: pricing?.pricing_plan_id!,
      currency: pricing?.currency!,
      tierType: moreExpensiveTier?.type!,
    },
    skip: !botId || !pricing || !moreExpensiveTier,
  });
  const annualPeriod =
    pricePeriodData &&
    pricePeriodData.bot.pricePeriodList.find(
      (item) => item.period === Period.annual,
    );
  const [
    calloutPayload,
    setCalloutPayload,
    { loading: calloutPayloadLoading },
  ] = useServerStorage<AnnualPricingCalloutPayload>(
    ServerStorageItemKeys.annualPricingCalloutPayload,
  );

  const isDebtMessage =
    currentTier !== TierType.trial && hasPaymentDebt.adjustmentFee;
  const isBotStoppedMessage =
    hasPaymentDebt.prepayment && matchPlansPage?.isExact;
  const isRecommendedMessage =
    pricing?.current_subscription_period !== Period.annual &&
    pricing?.next_subscription_period !== Period.annual &&
    moreExpensiveTier &&
    annualPeriod;
  let eventAction: string | undefined;
  if (isBotStoppedMessage) eventAction = 'showed bot has stopped banner';
  else if (isDebtMessage)
    eventAction = 'showed unable to process payment banner';
  else if (isRecommendedMessage && calloutPayload?.open)
    eventAction = 'showed pay and save banner';

  useEffect(() => {
    if (
      calloutPayloadLoading ||
      (calloutPayload && DateUtils.isMonthNotPassed(calloutPayload.dateChange))
    )
      return;
    setCalloutPayload({
      open: true,
      dateChange: Date.now(),
    });
  }, [calloutPayload, calloutPayloadLoading, setCalloutPayload]);

  useEffect(() => {
    if (!eventAction || !currentTier) return;
    sendEvent({
      category: 'pricing',
      action: eventAction,
      label: 'new dialogs pricing',
      propertyBag: {
        botId,
        currentPeriod,
        currentPlan: currentTier,
      },
    });
  }, [eventAction, currentTier, currentPeriod, botId]);

  const handleUpdate = () => {
    if (!botId) return;
    sendEvent({
      category: 'pricing',
      action: isBotStoppedMessage
        ? 'click bot has stopped banner'
        : 'click unable to process payment banner',
      label: 'new dialogs pricing',
      propertyBag: {
        botId,
        currentPeriod,
        currentPlan: currentTier,
      },
    });
    history.push(
      getBillingPageUrl(botId, true, `${UPDATE_CARD_QUERY_PARAMS}=true`),
    );
  };

  if (isBotStoppedMessage) {
    return (
      <FadeInCallout
        className={cn(css.root, css.warningCallout)}
        iconDisabled
        buttonNewLine={isSmallScreenSize}
        flexDirection={getValueDueToSize('column', 'row')}
        alignItems={getValueDueToSize('flex-start', 'center')}
        text={
          <Flex flexDirection="column">
            <Type size="18px" weight="semibold" color="red">
              {t('pages.Billing.unabledPaymentsCallout.unabledPayment')}
            </Type>
            <Type size="15px" weight="medium">
              {t('pages.Billing.unabledPaymentsCallout.youBotStoped')}
            </Type>
          </Flex>
        }
        type="info"
        button={
          <Button
            data-testid="annual-pricing-callout__update-card-button"
            intent="red"
            onClick={handleUpdate}
            style={{ width: getValueDueToSize('100%', 'auto') }}
          >
            {t('pages.Billing.unabledPaymentsCallout.updateCard')}
          </Button>
        }
      />
    );
  }
  if (isDebtMessage) {
    // TODO: replace moment with formatDate and move formatDate to utils
    const prepaymentDate = pricing?.next_prepayment_date
      ? DateFormat.ll(pricing.next_prepayment_date)
      : '';
    return (
      <FadeInCallout
        className={cn(css.root, css.warningCallout)}
        iconDisabled
        flexDirection={getValueDueToSize('column', 'row')}
        alignItems={getValueDueToSize('flex-start', 'center')}
        closeButtonClassName={getValueDueToSize(
          css.closeButtonPositionMobile,
          '',
        )}
        text={
          <Flex flexDirection="column">
            <Type size="18px" weight="semibold" color="red">
              {t('pages.Billing.unabledPaymentsCallout.unabledPayment')}
            </Type>
            <Type size="15px" weight="medium">
              {getPlanLimitReached(pricing)
                ? t(
                    'pages.Billing.unabledPaymentsCallout.weCouldntChargeStop',
                    {
                      date: prepaymentDate,
                      dialogsCount: pricing?.current_tier_limit,
                    },
                  )
                : t('pages.Billing.unabledPaymentsCallout.weCouldntCharge')}
            </Type>
          </Flex>
        }
        type="info"
        buttonNewLine={isSmallScreenSize}
        button={
          <Button
            data-testid="annual-pricing-callout__update-card-info-button"
            intent="red"
            onClick={handleUpdate}
            className={getValueDueToSize(css.calloutBtnMobile, css.calloutBtn)}
          >
            {t('pages.Billing.unabledPaymentsCallout.updateCardInfo')}
          </Button>
        }
      />
    );
  }

  if (isRecommendedMessage) {
    return (
      <FadeInCallout
        open={calloutPayload?.open}
        closeButtonClassName={cn(
          css.closeButton,
          getValueDueToSize(css.closeButtonPositionMobile, ''),
        )}
        onClose={() => {
          setCalloutPayload({
            open: false,
            dateChange: Date.now(),
          });
        }}
        className={cn(css.root, css.annualCallout)}
        flexDirection={getValueDueToSize('column', 'row')}
        alignItems={getValueDueToSize('flex-start', 'center')}
        iconView={
          <ScheduleIcon
            className={getValueDueToSize(
              css.scheduleIconMobile,
              css.scheduleIcon,
            )}
          />
        }
        text={
          <Type size="18px" color="white" weight="bold">
            {t('pages.Billing.unabledPaymentsCallout.payAndSave', {
              saveValue: formatPrice(annualPeriod.save, {
                currency: pricing?.currency,
                decimals: 2,
              }),
              plan: getTierNameByType(moreExpensiveTier.type),
            })}
          </Type>
        }
        type="info"
        buttonNewLine={isSmallScreenSize}
        button={
          <Button
            className={getValueDueToSize(css.calloutBtnMobile, css.calloutBtn)}
            data-testid="annual-pricing-callout__pay-and-save-details-button"
            intent="secondary"
            onClick={() => {
              sendEvent({
                category: 'pricing',
                action: 'click see details in pay and save banner',
                label: 'new dialogs pricing',
                propertyBag: {
                  botId,
                  currentPeriod,
                  currentPlan: currentTier,
                },
              });
              history.push(
                getPlansPageUrl(botId!, true, undefined, {
                  tier: moreExpensiveTier.type,
                  period: Period.annual,
                }),
              );
            }}
          >
            {pricing?.current_tier === TierType.trial
              ? t('pages.Billing.unabledPaymentsCallout.upgradeEntrepreneur')
              : t('pages.Billing.unabledPaymentsCallout.seeDetails')}
          </Button>
        }
      />
    );
  }

  return null;
}
