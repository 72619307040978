import React, { useState } from 'react';
import cn from 'classnames';
import { Hover } from 'react-powerplug';
import { ReactComponent as AddIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_add.svg';
import { HoverDisclosure } from '../../../modern-ui/HoverDisclosure';
import { Tooltip } from '../../../modern-ui/Tooltip';
import { GroupType } from '../Mutations/GroupMutations';
import * as css from './AddGroup.css';
import { AddGroupDropDown } from './AddGroupDropDown';
import { IAddGroupProps } from './AddGroup';

interface IAddGroupInlineProps extends IAddGroupProps {
  index: number;
}

export const AddGroupInline: React.FC<IAddGroupInlineProps> = React.memo(
  ({ onCreateGroupRequest, className, index, ...props }) => {
    const [isOpenDropdown, setIsOpenDropdown] = useState(false);

    return (
      <Hover
        render={({ hovered, bind }) => (
          <div
            className={cn(className, css.AddGroupInline)}
            {...props}
            {...bind}
          >
            {(hovered || isOpenDropdown) && (
              <AddGroupDropDown
                anchorElementRender={(getToggleButtonProps) => (
                  <div {...getToggleButtonProps()}>
                    <HoverDisclosure
                      render={({ isVisible, bind }) => (
                        <Tooltip
                          content="ADD SEQUENCE OR GROUP"
                          show={isVisible}
                          placement="right"
                        >
                          {(ref) => (
                            <div {...bind}>
                              <div ref={ref}>
                                <AddIcon className={css.iconInline} />
                              </div>
                            </div>
                          )}
                        </Tooltip>
                      )}
                    />
                  </div>
                )}
                onSelect={(groupType: GroupType) =>
                  onCreateGroupRequest(groupType, index)
                }
                onOpenStateChange={setIsOpenDropdown}
              />
            )}
          </div>
        )}
      />
    );
  },
  (prevProps: IAddGroupInlineProps, nextProps: IAddGroupInlineProps) =>
    prevProps.index === nextProps.index,
);
