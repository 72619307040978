import memoize from 'lodash-es/memoize';
import { getCopyMenuItem } from './Copy/menuItem';
import { getDeleteMenuItem } from './Delete/menuItems';
import { getLeaveMenuItem } from './Leave/menuItem';
import { getRenameMenuItem } from './Rename/menuItem';
import { BotOptionsItemProps } from './types';

export const botActionsSorted = memoize(
  <T>(): Array<BotOptionsItemProps<T>> => [
    getRenameMenuItem(),
    getCopyMenuItem(),
    getLeaveMenuItem(),
    getDeleteMenuItem(),
  ],
);
