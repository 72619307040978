import { BotsListQuery_bots as Bot } from '../../common/services/GQLqueries/@types/BotsListQuery';
import {
  SortingOrder,
  compareValues,
} from '../../utils/compareValues/compareValues';
import { isBotPro } from '../../utils/Pro';

export { SortingOrder };

export enum SortingOptions {
  totalUsers,
  weeklyActiveUsers,
  usersLeftOnFree,
  nameAsc,
  nameDesc,
  type,
  lastOpened,
  dateCreated,
}

export interface SortingConfigShape {
  order: SortingOrder;
  sortBy: SortingOptions;
}

export interface BotListSortingProps {
  changeSortingConfig: (sorting: SortingConfigShape) => void;
  sortingConfig: SortingConfigShape;
}

const subscribersLeft = (bot: Bot) => {
  const usersLimit = bot?.limits?.usersLimit;
  const currentUsers = bot?.statistic?.current_week_total_subscribed_users ?? 0;
  return usersLimit - currentUsers;
};

const compareWithOrder = (order: SortingOrder) => (comparison: number) =>
  order === SortingOrder.desc ? comparison * -1 : comparison;

const isPageDisconnected = (bot: Bot) => bot.status?.status === 'draft';

export const sortBots = (
  bots: Bot[],
  { order, sortBy }: SortingConfigShape,
) => {
  if (!bots.length) {
    return bots;
  }
  const botsCopy = bots.slice();

  switch (sortBy) {
    case SortingOptions.dateCreated:
      return botsCopy.sort(compareValues('date_added', SortingOrder.desc));
    case SortingOptions.lastOpened:
      return botsCopy.sort(
        compareValues('last_opened_date_for_current_user', SortingOrder.desc),
      );
    case SortingOptions.nameAsc:
      return botsCopy.sort(compareValues('title', SortingOrder.asc));
    case SortingOptions.nameDesc:
      return botsCopy.sort(compareValues('title', SortingOrder.desc));
    case SortingOptions.totalUsers:
      return botsCopy.sort(
        compareValues(
          (bot) => bot.statistic?.current_week_total_subscribed_users,
          order,
        ),
      );
    case SortingOptions.usersLeftOnFree:
      return botsCopy.sort((leftBot, rightBot) => {
        const leftBotPro = isBotPro(leftBot);
        const rightBotPro = isBotPro(rightBot);
        const leftBotDisconnected = isPageDisconnected(leftBot);
        const rightBotDisconnected = isPageDisconnected(rightBot);
        const withOrder = compareWithOrder(order);

        if (leftBotPro && rightBotPro) {
          return 0;
        }

        if (leftBotDisconnected && rightBotDisconnected) {
          return 0;
        }

        if (!leftBotPro || !rightBotPro) {
          if (leftBotPro && rightBotDisconnected) {
            return withOrder(1);
          }

          if (leftBotPro && !rightBotDisconnected) {
            return withOrder(1);
          }

          if (rightBotPro && leftBotDisconnected) {
            return withOrder(-1);
          }

          if (rightBotPro && !leftBotDisconnected) {
            return withOrder(-1);
          }
        }

        if (!leftBotDisconnected || !rightBotDisconnected) {
          if (leftBotPro && rightBotDisconnected) {
            return withOrder(1);
          }

          if (!leftBotPro && rightBotDisconnected) {
            return withOrder(-1);
          }

          if (rightBotPro && leftBotDisconnected) {
            return withOrder(-1);
          }

          if (!rightBotPro && leftBotDisconnected) {
            return withOrder(1);
          }
        }

        return withOrder(subscribersLeft(leftBot) - subscribersLeft(rightBot));
      });
    case SortingOptions.weeklyActiveUsers:
      return botsCopy.sort(
        compareValues((bot) => bot.statistic?.current_week_active_users, order),
      );
    // TODO: add after folders support
    case SortingOptions.type:
    default:
      return bots;
  }
};
