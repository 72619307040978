import { getFullAttributesBoundaries } from '@ui/TextWithAttributesEditor/attributesBoundariesUtils';
import React from 'react';
import { GetToggleButtonPropsOptions } from 'downshift';
import gql from 'graphql-tag';
import { ApolloProvider, Query } from '@apollo/react-components';
import { isValidUrl } from '@utils/UrlUtils';
import * as css from './UrlTab.css';
import * as commonCss from '../common/ButtonPopupCommon.css';
import client from '../../../common/services/ApolloService';
import { Input } from '../../../modern-ui/Input';
import {
  deserialize,
  TextWithAttributesEditor,
} from '../../../modern-ui/TextWithAttributesEditor';
import { AttributesData } from '../../../utils/AttributesUtils/AttributesData';
import { Dropdown } from '../../../modern-ui/_deprecated/Dropdown';
import {
  Button,
  ButtonColorWay,
  ButtonIntent,
  ButtonSize,
} from '../../../modern-ui/_deprecated/Button';
import { ReactComponent as DropdownIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_dropdown_arr.svg';
import {
  Attribute,
  ButtonUserAttributeEditor,
} from '../common/ButtonPopupUserAttributesEditor';
import { ButtonPopupBlocksSelector } from '../common/ButtonPopupBlocksSelector';
import { ButtonErrors } from '../common/validation';
import { ButtonPopupCommon } from '../common/ButtonPopupCommon';
import {
  getBotWhitelistDomains as BotWhitelistDomains,
  getBotWhitelistDomainsVariables as BotWhitelistDomainsVariables,
} from './@types/getBotWhitelistDomains';
import { ReactComponent as AlertIcon } from '../../../modern-ui/_deprecated/Icon/icons/ic_alert_circle2.svg';
import { ignoreEnterKey } from '@utils/DOM/keyHandlers';

const GET_WHITELIST_DOMAINS_QUERY = gql`
  query getBotWhitelistDomains($botId: String) {
    bot(id: $botId) {
      id
      domains_whitelist
    }
  }
`;

enum ButtonUrlTabActions {
  REDIRECT_TO_BLOCK = 'REDIRECT_TO_BLOCK',
  SET_USER_ATTRIBUTE = 'SET_USER_ATTRIBUTE',
}

const URL_BUTTON_EXTRA_ACTIONS: () => {
  [key: string]: ButtonPopupURLTabActionItem;
} = () => ({
  [ButtonUrlTabActions.REDIRECT_TO_BLOCK]: {
    id: ButtonUrlTabActions.REDIRECT_TO_BLOCK,
    title: window.i18next.t(
      'ButtonPopupURLTab-string--176-and-redirect-to-block',
    ),
  },
  [ButtonUrlTabActions.SET_USER_ATTRIBUTE]: {
    id: ButtonUrlTabActions.SET_USER_ATTRIBUTE,
    title: window.i18next.t(
      'ButtonPopupURLTab-string--180-and-set-user-attribute',
    ),
  },
});

const WEBVIEW_HEIGHT_RATIO: () => { [key: string]: string } = () => ({
  full: window.i18next.t('ButtonPopupURLTab-string-1274-100-size'),
  tall: window.i18next.t('ButtonPopupURLTab-string-2108-70-size'),
  compact: window.i18next.t('ButtonPopupURLTab-string-1174-40-size'),
});

interface ButtonPopupURLTabConfig {
  blockId?: string[];
  attributeUpdates?: Attribute[];
  url?: string;
  webviewHeightRatio?: string;
}

interface ButtonPopupURLTabActionItem {
  id: ButtonUrlTabActions;
  title: string;
}

export interface ButtonPopupURLTabProps extends ButtonPopupURLTabConfig {
  botId: string;
  counterId: string;
  onChange: (config: ButtonPopupURLTabConfig) => void;
  error: ButtonErrors[];
  onBlocksTitlesChange: (titles: string[]) => void;
  allowChangeUserAttributes?: boolean;
  donePopup?: () => void;
  showAdditionalActions?: boolean;
}

interface ButtonPopupURLTabState {
  url: string;
  webviewHeightRatio: string;
  urlError: boolean;
  actions: string[];
}

type TGetActionItem = (type: string) => React.ReactNode | null;

const isValidUrlOrHasAttributes = (url: string) =>
  isValidUrl(url) || getFullAttributesBoundaries(url).length;

export class ButtonPopupURLTab extends React.Component<
  ButtonPopupURLTabProps,
  ButtonPopupURLTabState
> {
  state = {
    actions: [] as string[],
    url: this.props.url || '',
    webviewHeightRatio: this.props.webviewHeightRatio || 'full',
    urlError: this.props.error
      ? this.props.error.indexOf(ButtonErrors.url) !== -1
      : this.props.url
      ? !isValidUrlOrHasAttributes(this.props.url)
      : false,
  };

  initActions = () => {
    const constructedActions = [] as ButtonUrlTabActions[];
    if (this.props.blockId && this.props.blockId.length > 0) {
      constructedActions.push(ButtonUrlTabActions.REDIRECT_TO_BLOCK);
    }
    if (
      this.props.attributeUpdates &&
      Object.keys(this.props.attributeUpdates).length > 0
    ) {
      constructedActions.push(ButtonUrlTabActions.SET_USER_ATTRIBUTE);
    }
    return constructedActions;
  };

  validateRequiredField = () => {
    if (this.state.url.length === 0) {
      this.setState({ urlError: true });
    }
  };

  getActionItem: TGetActionItem = (action) => {
    switch (action) {
      case ButtonUrlTabActions.REDIRECT_TO_BLOCK:
        return (
          <ButtonPopupBlocksSelector
            botId={this.props.botId}
            onChange={(blocks) => {
              this.props.onChange(blocks);
              this.validateRequiredField();
            }}
            blockId={this.props.blockId}
            autofocus={
              this.state.actions.indexOf(
                ButtonUrlTabActions.REDIRECT_TO_BLOCK,
              ) === -1
            }
            validateOnBlur
            onBlocksTitlesChange={this.props.onBlocksTitlesChange}
            onGoToBlock={this.props.donePopup}
          />
        );
      case ButtonUrlTabActions.SET_USER_ATTRIBUTE:
        return (
          <ButtonUserAttributeEditor
            attributeUpdates={this.props.attributeUpdates}
            botId={this.props.botId}
            key={this.props.counterId}
            onChange={(attributes) => {
              this.props.onChange(attributes);
              this.validateRequiredField();
            }}
          />
        );
      default:
        return null;
    }
  };

  onActionDelete = (type: string) => {
    switch (type) {
      case ButtonUrlTabActions.REDIRECT_TO_BLOCK:
        this.props.onChange({ blockId: [] });
        break;
      case ButtonUrlTabActions.SET_USER_ATTRIBUTE:
        this.props.onChange({ attributeUpdates: [] });
        break;
      default:
    }
  };

  render() {
    return (
      <ButtonPopupCommon
        botId={this.props.botId}
        actionConfig={URL_BUTTON_EXTRA_ACTIONS()}
        getActionItem={this.getActionItem}
        initActions={this.initActions}
        onActionDelete={this.onActionDelete}
        onActionsChange={(actions) => this.setState({ actions })}
        actionFilter={(key) =>
          key !== ButtonUrlTabActions.SET_USER_ATTRIBUTE ||
          !!this.props.allowChangeUserAttributes
        }
        showAdditionalActions={this.props.showAdditionalActions}
      >
        {({ show }) => (
          <ApolloProvider client={client}>
            <div className={commonCss.actionTitle}>
              <span>{`${show ? '' : '1. '}${window.i18next.t(
                'ButtonPopupURLTab-Template--441-open-url',
              )}`}</span>
            </div>
            <AttributesData botId={this.props.botId}>
              {({ attributes }) => (
                <div>
                  <Input
                    error={this.state.urlError}
                    render={() => (
                      <TextWithAttributesEditor
                        defaultValue={deserialize(this.state.url)}
                        onStringChange={(value) => {
                          const isValid = isValidUrlOrHasAttributes(value);
                          this.setState({ url: value, urlError: !isValid });
                          if (isValid) {
                            this.props.onChange({ url: value });
                          }
                        }}
                        onFocus={() => this.setState({ urlError: false })}
                        onBlur={() => {
                          const isValid = isValidUrlOrHasAttributes(
                            this.state.url,
                          );

                          this.setState((prevState) => ({
                            urlError: prevState.url.length === 0 || !isValid,
                          }));
                          if (isValid) {
                            this.props.onChange({
                              url: this.state.url,
                            });
                          }
                        }}
                        placeholder={window.i18next.t(
                          'ButtonPopupURLTab-string-5145-add-url',
                        )}
                        onKeyDown={ignoreEnterKey}
                        attributes={attributes}
                        className={css.urlInput}
                      />
                    )}
                  />
                </div>
              )}
            </AttributesData>
            <div className={css.windowHeightRatioLine}>
              <span>
                {window.i18next.t(
                  'ButtonPopupURLTab-JSXText-1999-in-app-browser-window',
                )}
              </span>
              <Dropdown
                items={Object.values(WEBVIEW_HEIGHT_RATIO())}
                selectedItem={this.state.webviewHeightRatio}
                className={css.windowHeightRatioInput}
                onChange={(heightRatio) => {
                  const heightRatioKey = Object.entries(
                    WEBVIEW_HEIGHT_RATIO(),
                  ).find((item) => item && item[1] === heightRatio) || ['full'];
                  this.setState({ webviewHeightRatio: heightRatioKey[0] });
                  this.props.onChange({
                    webviewHeightRatio: heightRatioKey[0],
                  });
                  this.validateRequiredField();
                }}
                buttonsFactory={(
                  props: GetToggleButtonPropsOptions,
                  ref: React.Ref<HTMLButtonElement>,
                ) => (
                  <Button
                    {...(props as React.ButtonHTMLAttributes<HTMLButtonElement>)}
                    intent={ButtonIntent.primary}
                    innerRef={ref}
                    style={{ width: '100%' }}
                    size={ButtonSize.m}
                    colorWay={ButtonColorWay.white}
                    renderIconRight={() => <DropdownIcon />}
                  >
                    {WEBVIEW_HEIGHT_RATIO()[this.state.webviewHeightRatio]}
                  </Button>
                )}
              />
            </div>
            <Query<BotWhitelistDomains, BotWhitelistDomainsVariables>
              query={GET_WHITELIST_DOMAINS_QUERY}
              variables={{ botId: this.props.botId }}
            >
              {({ data }) =>
                data &&
                data.bot &&
                data.bot.domains_whitelist &&
                this.state.actions.length > 0 &&
                data.bot.domains_whitelist.indexOf(this.state.url) !== -1 ? (
                  <div className={css.whitelistLine}>
                    <AlertIcon />
                    <div className={css.whitelistText}>
                      {window.i18next.t(
                        'ButtonPopupURLTab-JSXText--534-messenger-extensions-will-be-disabled-for-this-url-because-they-are-not-compatible-with-additional-actions',
                      )}
                    </div>
                  </div>
                ) : null
              }
            </Query>
          </ApolloProvider>
        )}
      </ButtonPopupCommon>
    );
  }
}
